import React from 'react'
import { Box, Grid, GridItem, Spinner } from '@chakra-ui/react'
import { YearlyResult } from './Graphs/YearlyResult'
import { LanguageAnalysisType } from './LanguageAnalysisType'
import { useDashboardOverview } from './useDashboardOverview'
import { useTranslation } from 'react-i18next'

interface LanguageAnalysisOverviewProps {
  filter: LanguageAnalysisType
  resetStatistics: () => void
}

export const LanguageAnalysisOverview = ({
  filter,
  resetStatistics,
}: LanguageAnalysisOverviewProps) => {
  const { summaryData, loading } = useDashboardOverview({
    filter,
    resetStatistics,
  })
  const { t } = useTranslation()
  return (
    <Box style={{ fontFamily: 'Open Sans' }}>
      <Box>
        <Grid templateColumns='repeat(4, 1fr)' gap={6}>
          <GridItem w='100%'>
            <div className='box-detail' style={{ opacity: loading ? 0.6 : 1 }}>
              <div className='box-detail-f1'>
                {t('DashboardOverview_Number_of_Preschools')}
              </div>
              <div className='mt-10'>
                <img className='d-inline' src='/images/orangeIcon.png' alt='' />
                <span className='box-detail-f2'>
                  {loading ? <Spinner size='sm' /> : summaryData.preschoolCount}
                </span>
              </div>
            </div>
          </GridItem>
          <GridItem w='100%'>
            <div className='box-detail' style={{ opacity: loading ? 0.6 : 1 }}>
              <div className='box-detail-f1'>
                {t('This_week_completed_followups')}
              </div>
              <div className='mt-10'>
                <img
                  className='d-inline'
                  width='40'
                  src='/images/blueIcon.png'
                  alt=''
                />
                <span className='box-detail-f2'>
                  {loading ? (
                    <Spinner size='sm' />
                  ) : (
                    summaryData.thisWeekSubmitedCount
                  )}
                </span>
              </div>
            </div>
          </GridItem>
          <GridItem w='100%'>
            <div className='box-detail' style={{ opacity: loading ? 0.6 : 1 }}>
              <div className='box-detail-f1'>
                {' '}
                {t('DashboardOverview_Number_Completed_FollowUps')}
              </div>
              <div className='mt-10'>
                <img
                  className='d-inline'
                  width='34'
                  src='/images/blueIconPeople.png'
                  alt=''
                />
                <span className='box-detail-f2'>
                  {loading ? (
                    <Spinner size='sm' />
                  ) : (
                    summaryData.totalSubmitedCount
                  )}
                </span>
              </div>
            </div>
          </GridItem>
          <GridItem w='100%'>
            <div className='box-detail' style={{ opacity: loading ? 0.6 : 1 }}>
              <div className='box-detail-f1'>
                {' '}
                {t('DashboardOverview_Number_of_Children')}
              </div>
              <div className='mt-10'>
                <img
                  className='d-inline'
                  width='34'
                  src='/images/blueIconPeople.png'
                  alt=''
                />
                <span className='box-detail-f2'>
                  {loading ? (
                    <Spinner size='sm' />
                  ) : (
                    summaryData.totalParticipant
                  )}
                </span>
              </div>
            </div>
          </GridItem>
        </Grid>
      </Box>
      <YearlyResult filter={filter} />
    </Box>
  )
}
