import { useUser } from 'providers/UserProvider'
import { useState } from 'react'
import { AuthService } from 'services/authService'

export const useLoginAvesta = () => {
  const { setUser } = useUser()
  const [isLoading, setIsLoading] = useState(false)
  const [authError, setAuthError] = useState(false)

  const handleLogin = () => {
    setIsLoading(true)
    setAuthError(false)
    return AuthService.loginWithCustomProvider()
      .then((payload: { access_token: string; refresh_token: string }) => {
        AuthService.clearCookie()
        AuthService.storeTokens(payload)
        const usr = AuthService.getUser()
        if (usr) {
          setUser(usr)
          setAuthError(false)
        }
      })
      .catch(_ => {
        setAuthError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { handleLogin, isLoading, authError }
}
