import React, { useState } from 'react'
import { ImageSortingTask, PlacementPoint } from 'data-models/task-models'
import { SimpleGrid } from '@chakra-ui/react'
import ImagePlacementPoint from './ImagePlacementPoint'
import Ellipse from '../../../../../assets/images/Ellipse.svg'
import Square from '../../../../../assets/images/Square.svg'
import Triangle from '../../../../../assets/images/Triangle.svg'
import Rectangle from '../../../../../assets/images/Rectangle.svg'

export default function ImagePlacementAnswer(props: {
  task: any
  onDrag: (draggedItem: string, placedItem: string) => void
}) {
  const currentTask = props.task
  const [task, setTask] = useState(currentTask as ImageSortingTask)
  const sortShapesTask = ((currentTask.name === 'Shapes' || currentTask.name === 'Former' || currentTask.name === 'Formen') && (currentTask.knowledge === 'Mathematics Level 1' || currentTask.knowledge === 'Matematik Nivå 1' || currentTask.knowledge === 'Mathematik Niveau 1'));
  const sortShapesLevel2Task = ((currentTask.name === 'Shapes' || currentTask.name === 'Former' || currentTask.name === 'Formen') && (currentTask.knowledge === 'Mathematics Level 2' || currentTask.knowledge === 'Matematik Nivå 2' || currentTask.knowledge === 'Mathematik Niveau 2'));

  const changePlacement = (newXY: PlacementPoint, draggedItem: string) => {
    let placedItem = ''
    const newQues = task
    newQues.answerOptions.forEach((a, index) => {
      if (newXY)
        if (a.correctPoint == newXY.id) {
          if (a.placedAtPoint != null) placedItem = a.placedAtPoint
          newQues.answerOptions[index].placedAtPoint = draggedItem
        } else if (a.placedAtPoint == draggedItem)
          newQues.answerOptions[index].placedAtPoint = null
    })
    const answerPlacement = newQues.answerOptions
    setTask(v => ({ ...v, ...{ answerOptions: answerPlacement } }))
    props.onDrag(draggedItem, placedItem)
  }

  let i = 1

 
  const countNumbersTaskArray = [3, 4, 5, 6, 9];
  const sortShapesTaskArray = [Ellipse,Ellipse,Ellipse,Square,Square,Square]

  const sortShapesLevel2TaskArray = [Ellipse,Ellipse,Square,Square,Triangle,Triangle,Rectangle,Rectangle]
  const taskIndex = task.orderIndex
  let columnsDnD;
  if(task.name === 'Shapes' || task.name === 'Former' || task.name === 'Formen'){
    if (task.images.length > 6) 
    columnsDnD = 6;
    else{
      columnsDnD = task.images.length
    }
    
  }
  else{
    columnsDnD = 5;
  }


  return (
    <SimpleGrid columns={columnsDnD} spacing="30px">
    {task.placementPoints.map((pp: any, index: number) => {
      const placedImage = task.answerOptions.find(
        (a: any) => a.correctPoint === pp.id
      );

      let textValue;
      if (currentTask.id === '3f2504e0-4f89-11d3-9a0c-0305e82c3301') {
        textValue = countNumbersTaskArray[index];
      } else {
        textValue = i++;
      }

      return (
        <ImagePlacementPoint
          key={pp.id}
          pp={pp}
          pi={placedImage}
          onChange={changePlacement}
          text={sortShapesTask 
            ? sortShapesTaskArray[index]
            :
            sortShapesLevel2Task
            ?
            sortShapesLevel2TaskArray[index] 
            :
            textValue}
          taskIndex={taskIndex}
        />
      );
    })}
  </SimpleGrid>
  )
}
