import { useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import axiosAPIInstance from './api'
import { useTranslation } from 'react-i18next'

interface UseDownloadFileProps {
  path: string
}

export const useDownloadFile = ({ path }: UseDownloadFileProps) => {
  const toast = useToast()
  const [resourceURL, setResourceURL] = useState<string>('')
  const { t } = useTranslation()
  const getResourceBlob = () =>
    axiosAPIInstance
      .get(`${path}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const href = URL.createObjectURL(data)

        return href
      })
      .catch(() => {
        toast({
          title: `${t('Resource_Retrieval_Failed')}`,
          isClosable: true,
          duration: 3000,
          status: 'error',
        })
      })

  const refreshResourceURL = async () => {
    const response = await getResourceBlob()
    if (response && response.length > 0) {
      setResourceURL(response)
    }
  }

  useEffect(() => {
    if (!resourceURL) {
      refreshResourceURL()
    }
  }, [path])

  return {
    resourceURL,
  }
}
