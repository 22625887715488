import { User, UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useHistory, useParams } from 'react-router-dom'
import { useUserDetails } from 'services/userService'

const defaultValues: User = {
  id: '',
  name: '',
  email: '',
  phoneNumber: '',
  preschoolIds: [],
  organizationId: '',
  role: UserRole.TEACHER,
}

export const useUserContainer = () => {
  const params = useParams()
  const { id } = params as any
  const history = useHistory()
  const {
    user: { organizationId },
  } = useUser()

  const { data: user = defaultValues, isInitialLoading } = useUserDetails({
    id,
    organizationId,
  })
  // if (id && !user?.name && !isInitialLoading) {
  //   history.push('/settings/users')
  // }

  return {
    user,
    loading: isInitialLoading,
  }
}
