import { useEffect, useState } from 'react'
import axiosAPIInstance from './api'
import Preschool, {
  PreschoolList,
  EditPreschoolDTO,
  CreatePreschoolDTO,
} from 'data-models/preschool'
import {Area} from 'data-models/area'
import mockAssignments from '../pages/MainTabs/StatisticTab/Dashboard/Graphs/mockAssignments.json'
import { AssignmentType } from 'data-models/chart'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const PreschoolService = {
  getPreschools: (organizationId: string) => {
    return axiosAPIInstance
      .get(`/preschools/${organizationId}`)
      .then(r => r.data as Preschool[])
  },

  getAreas: (organizationId: string) =>
    axiosAPIInstance
      .get(`/areas/${organizationId}`)
      .then(({ data }) => data as Area[]),
  getAssignmentTypes: () =>
    mockAssignments.data.assignments as AssignmentType[],
}

export const usePreschoolService = (organizationId: string) => {
  const [preschools, setPreschools] = useState<Preschool[]>([])

  const getPreschools = () =>
    axiosAPIInstance
      .get(`/preschools/${organizationId}`)
      .then(({ data }) => data as Preschool[])

  const refresh = async () => {
    const response = await getPreschools()
    if (response && response.length > 0) {
      setPreschools(response)
    }
  }

  useEffect(() => {
    refresh()
  }, [organizationId])

  return {
    preschools,
    refresh,
  }
}

interface UsePreschoolDetailsProps {
  organizationId?: string
  preschoolId?: string
}

const getPreschoolDetails = ({
  organizationId,
  preschoolId,
}: UsePreschoolDetailsProps): Promise<Preschool | void> => {
  if (!organizationId || !preschoolId) return Promise.resolve(undefined)
  return axiosAPIInstance
    .get(`/preschools/${organizationId}/${preschoolId}`)
    .then(({ data }) => data as Preschool)
}

export const usePreschoolDetails = ({
  organizationId,
  preschoolId,
}: UsePreschoolDetailsProps) => {
  const getPreschoolDetailsQuery = useQuery({
    queryKey: ['preschool', `${preschoolId}` || ''],
    queryFn: () => getPreschoolDetails({ organizationId, preschoolId }),
    staleTime: 1000 * 60 * 10,
    enabled: !!preschoolId,
  })

  return getPreschoolDetailsQuery
}

//Get preschool list query
interface UsePreschoolListProps {
  organizationId: string
}

export const getPreschoolsList = ({
  organizationId,
}: UsePreschoolListProps): Promise<PreschoolList> => {
  return axiosAPIInstance
    .get(`/preschools/${organizationId}/list`)
    .then(({ data }) => data as PreschoolList)
}

export const usePreschoolList = ({ organizationId }: UsePreschoolListProps) => {
  const getPreschoolListQuery = useQuery({
    queryKey: ['preschoolList'],
    queryFn: () => getPreschoolsList({ organizationId }),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = {
      preschools: [],
      numberOfUsers: 0,
      numberOfPreschools: 0,
    },
    error,
    isLoading: loading,
  } = getPreschoolListQuery

  return {
    error,
    loading,
    response,
  }
}

//Delete mutation
interface UseDeletePreschoolProps {
  id: string
}

const deletePreschool = ({ id }: UseDeletePreschoolProps) =>
  axiosAPIInstance.delete(`/preschools/${id}`)

export const useDeletePreschool = () => {
  const queryClient = useQueryClient()
  const deletePreschoolMutation = useMutation({
    mutationFn: ({ id }: UseDeletePreschoolProps) => deletePreschool({ id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['preschoolList'], { exact: true }),
        queryClient.invalidateQueries(['preschool', `${variables.id}`], {
          exact: true,
        })
    },
  })

  return deletePreschoolMutation
}

//Create mutation
interface UseCreatePreschoolProps {
  values: CreatePreschoolDTO
  organizationId: string
}

const createPreschool = async ({
  values,
  organizationId,
}: UseCreatePreschoolProps) =>
  axiosAPIInstance.post(`/preschools`, {
    ...values,
    organizationId,
  })

export const useCreatePreschool = () => {
  const queryClient = useQueryClient()
  const createPreschoolMutation = useMutation({
    mutationFn: ({ organizationId, values }: UseCreatePreschoolProps) =>
      createPreschool({ organizationId, values }),
    onSuccess: () => {
      queryClient.invalidateQueries(['preschoolList'], { exact: true })
    },
  })

  return createPreschoolMutation
}

//Edit mutation
interface UseEditPreschoolProps {
  values: EditPreschoolDTO
  preschoolId: string
}

const editPreschool = ({ preschoolId, values }: UseEditPreschoolProps) =>
  axiosAPIInstance.put(`/preschools/${preschoolId}`, {
    ...values,
    id: preschoolId,
  })

export const useEditPreschool = () => {
  const queryClient = useQueryClient()
  const editPreschoolMutation = useMutation({
    mutationFn: ({ preschoolId, values }: UseEditPreschoolProps) =>
      editPreschool({ preschoolId, values }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['preschoolList'], { exact: true }),
        queryClient.invalidateQueries(
          ['preschool', `${variables.preschoolId}`],
          {
            exact: true,
          },
        )
    },
  })

  return editPreschoolMutation
}
