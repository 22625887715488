import React from 'react'
import { capitalize } from 'lodash'
import { Button, ButtonProps, Text } from '@chakra-ui/react'

import { useDeleteButton } from './hooks/useDeleteButton'
import { ConfirmationModal } from 'common/Confirmation'
import { useTranslation } from 'react-i18next'

interface DeleteButtonProps extends ButtonProps {
  entityName: string
  onDelete: () => void
}

export const DeleteButton = ({
  onDelete,
  entityName,
  isLoading,
  ...rest
}: DeleteButtonProps) => {
  const { isConfirmDeleteOpen, onConfirmDeleteClose, onConfirmDeleteOpen } =
    useDeleteButton()
  const { t } = useTranslation()
  return (
    <>
      <Button
        mr='2'
        color='red'
        bgColor='white'
        borderColor='red'
        variant='outline'
        fontWeight='bold'
        border='2px solid'
        _hover={{ opacity: 0.7 }}
        onClick={onConfirmDeleteOpen}
        isLoading={isLoading}
        {...rest}
      >
        {t('Delete')}
      </Button>

      <ConfirmationModal
        isOpen={isConfirmDeleteOpen}
        onClose={onConfirmDeleteClose}
        onConfirmCallback={onDelete}
        header={<Text color='mainBlue.900'>{capitalize(entityName)}</Text>}
        body={
          <Text color='black'>
            {t('confirmation_about_delete')} {entityName}?
          </Text>
        }
        cancelText={t('No_GoAhead')}
        confirmText={t('Yes_Del')}
        isLoading={isLoading}
      />
    </>
  )
}
