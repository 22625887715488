import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

export const ChartWrapper = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      border={'1px solid #B1C5D9'}
      borderRadius='8px'
      paddingY='16px'
      paddingX='20px'
      w='100%'
      {...rest}
    >
      {children}
    </Box>
  )
}
