import { useOrganizations } from 'services/organizationService'
import { CommentsFilterType, defaultFilter } from '../types'
import { Followup } from 'data-models/followup'
import { Area } from 'recharts'
import Preschool from 'data-models/preschool'
import { useFilters } from 'providers/FilterProvider'
import { useUser } from 'providers/UserProvider'
import { useFollowups } from 'services/followupService'
import { useAreas } from 'services/areaService'
import { usePreschoolList } from 'services/preschoolService'
import {
  SelectOption,
} from 'data-models'

interface UseCommentsFilterProps {
  onFilterChange: (newFilter: Partial<CommentsFilterType>) => void
  filter: CommentsFilterType
}

export const useCommentsFilter = ({
  filter,
  onFilterChange,
}: UseCommentsFilterProps) => {
  const { user } = useUser()
  const { response: organizations } = useOrganizations()

  const {
    user: { organizationId },
  } = useUser()
  const {
    response: { data: followups },
  } = useFollowups({ organizationId })

  const {
    setCurrentFollowupId,
    currentFollowupId,
  } = useFilters()

  const { response: areaOptions } = useAreas({
    organizationId: user.organizationId,
  })

  const {
    response: { preschools: preschoolOptions },
  } = usePreschoolList({ organizationId: user.organizationId })


  const filteredPreschoolOptions =
    filter.areaIds.length > 0
      ? preschoolOptions.filter(preschool =>
        filter.areaIds.includes(preschool.areaId || ''),
      )
      : preschoolOptions

 

  const followupOptions = followups
    .filter(followup => followup.tasks.length !== 0)
    .sort(function (a, b) {
      return Date.parse(b.createdAt) - Date.parse(a.createdAt)
    })
    .map(followup => {
      return {
        id: followup.id,
        name: followup.name,
        year: followup.academicYear,
        startDate: followup.startDate,
        endDate: followup.endDate,
      }
    })

  const resetFilter = () => {
    onFilterChange({
      ...defaultFilter,
      followupId: currentFollowupId,
    })
  }

  const onFollowupChange = (selectedFollowup: Followup) => {
    setCurrentFollowupId(selectedFollowup.id)
    onFilterChange({
      followupId: selectedFollowup.id,
      areaIds: [],
      preschoolIds: [],
      startDate: undefined,
      endDate: undefined,
      pageNumber: 1,
    })
  }

  const onAreaChange = (areas?: Area[]) => {
    const areaIds = areas ? areas?.map(area => area.id) : []
    const preschools = preschoolOptions.filter(preschool =>
      filter.preschoolIds.includes(preschool.id || ''),
    )
    const preschoolIdsBelongingToAreas = preschools
      .filter(preschool => areaIds.includes(preschool.areaId || ''))
      .map(preschool => preschool.id!)

    onFilterChange({
      areaIds,
      preschoolIds: preschoolIdsBelongingToAreas,
      pageNumber: 1,
    })
  }

  const onPreschoolChange = (options?: SelectOption[]) => {
    onFilterChange({
      preschoolIds: options ? options.map(option => option.value) : [],
      pageNumber: 1,
    })
  }

  const onStartDateChange = (newStartDate: Date) => {
    onFilterChange({
      startDate: newStartDate,
      pageNumber: 1,
    })
  }
  const onEndDateChange = (newEndDate: Date | null) => {
    if (newEndDate) {
      newEndDate.setHours(23, 59, 59); // set the time till end of the day
    }
    onFilterChange({
      endDate: newEndDate || undefined, // use undefined if newEndDate is null
      pageNumber: 1,
    });
  };

  return {
    resetFilter,
    areaOptions,
    organizations,
    followupOptions,
    preschoolOptions: filteredPreschoolOptions,
    onFollowupChange,
    onAreaChange,
    onPreschoolChange,
    onStartDateChange,
    onEndDateChange,
  }
}
