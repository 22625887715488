import qs from 'qs'
import axiosAPIInstance from './api'
import { IndividualCommentsResponse } from 'data-models/comment'
import { useQuery } from '@tanstack/react-query'

interface UseIndividualCommentsProps {
  organizationId: string
  followupId: string
  startDate?: string
  endDate?: string
  areaIds?: string[]
  preschoolIds?: string[]
  pageNumber?: number
  pageSize?: number
}

const getIndividualComments = ({
  followupId,
  organizationId,
  areaIds,
  endDate,
  pageNumber,
  pageSize,
  preschoolIds,
  startDate,
}: UseIndividualCommentsProps) => {
  if (!organizationId || !followupId)
    return {
      data: [],
      totalCount: 0,
    }

  return axiosAPIInstance
    .get(`/comments/individual/${organizationId}/${followupId}`, {
      params: {
        followupId,
        organizationId,
        areaIds,
        preschoolIds,
        startDate,
        endDate,
        pageNumber,
        pageSize,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
    .then(({ data }) => data as IndividualCommentsResponse)
}

export const useIndividualComments = ({
  organizationId,
  followupId,
  ...rest
}: UseIndividualCommentsProps) => {
  const getFollowupsQuery = useQuery({
    queryKey: ['individualComments', organizationId, followupId, { ...rest }],
    queryFn: () =>
      getIndividualComments({ organizationId, followupId, ...rest }),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = {
      data: [],
      totalCount: 0,
    },
    error,
    isLoading: loading,
  } = getFollowupsQuery

  return {
    error,
    loading,
    response,
  }
}
