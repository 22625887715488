import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'

import { areaSchema } from '../schema'
import { AreaShape, UserRole } from 'data-models'
import {
  useCreateArea,
  useDeleteArea,
  useEditArea,
} from 'services/areaService'
import { Area } from 'data-models/area'
import { useUser } from 'providers/UserProvider'
import { useTranslation } from 'react-i18next'

interface UseAreaEditorProps {
  initialArea: Area
}

export const useAreaEditor = ({
  initialArea,
}: UseAreaEditorProps) => {
  const history = useHistory()
  const toast = useToast()
  const { user } = useUser()

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  const editArea = useEditArea()
  const createArea = useCreateArea()
  const deleteArea = useDeleteArea()

  const formMethods = useForm<AreaShape>({
    defaultValues: initialArea,
    resolver: yupResolver(areaSchema),
  })

  const { handleSubmit } = formMethods
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (initialArea) {
      formMethods.reset(initialArea)
    }
  }, [initialArea])

  const onCreate = async (values: AreaShape) => {
    try {
      await createArea.mutateAsync({
        values,
        organizationId: user.organizationId,
      })
      toast({
        title: i18n.language === 'se' ?  'Område har skapats' : i18n.language === 'de' ? 'Der Bereich wurde erstellt' : 'Area has been created',
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/areas')
    } catch (error) {
      toast({
        title: i18n.language === 'se' ? 'Området kunde inte skapas' : i18n.language === 'de' ?  'Der Bereich konnte nicht erstellt werden' : 'The area could not be created',
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleCreate = handleSubmit(onCreate)

  const onEdit = async (values: AreaShape) => {
    try {
      await editArea.mutateAsync({
        values: {
          ...values,
          id: initialArea.id,


        },
        areaId: initialArea.id,
        organizationId: user.organizationId,
      })
      toast({
        title: i18n.language === 'se' ? 'Området har uppdaterats' : i18n.language === 'de' ? 'Der Bereich wurde aktualisiert' : 'The area has been updated',
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
    } catch (error) {
      toast({
        title: i18n.language === 'se' ? 'Områdesuppdatering misslyckades' : i18n.language === 'de' ? 'Bereichsaktualisierung fehlgeschlagen' : 'Area update failed',
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleEdit = handleSubmit(onEdit)

  const handleDelete = async () => {
    try {
      await deleteArea.mutateAsync({ id: initialArea.id, organizationId: user.organizationId })
      toast({
        title: i18n.language === 'se' 
    ? 'Området har raderats' 
    : i18n.language === 'de' 
    ? 'Der Bereich wurde gelöscht' 
    : 'The area has been deleted',
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/areas')
    } catch (error) {
      toast({
        title: i18n.language === 'se' 
    ? 'Området kunde inte raderas' 
    : i18n.language === 'de' 
    ? 'Der Bereich konnte nicht gelöscht werden' 
    : 'The area could not be deleted',
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  return {
    isAdmin,
    formMethods,
    handleEdit,
    isEditLoading: editArea.isLoading,
    handleDelete,
    isDeleteLoading: deleteArea.isLoading,
    handleCreate,
    isCreateLoading: createArea.isLoading,
  }
}
