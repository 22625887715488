import React from 'react'
import PropTypes from 'prop-types'

const CustomizedAxisTick = props => {
  const { x, y, payload } = props
  const angle = 45
  const splittedString = payload.value.split('<br>')
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor='start'
        fill='#666'
        transform={`rotate(${angle})`}
        style={{ fontSize: 14 }}
      >
        <tspan x='0'>{splittedString[0]}</tspan>
        <tspan x='-10' dy='15'>
          {splittedString[1].length > 25
            ? `${splittedString[1].substring(0, 25)}...`
            : splittedString[1]}
        </tspan>
      </text>
    </g>
  )
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object, // Add payload to propTypes
}

export default CustomizedAxisTick
