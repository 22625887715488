import { useQuery } from '@tanstack/react-query'
import { ResultInfo, ResultResponse } from 'data-models'
import { ChartData, ProgressionStats } from 'data-models/progression'
import qs from 'qs'
import {
  AveragePerAgeHorizonStatistics,
  AveragePerAgeStatistics,
  AveragePerGenderStatistics,
  AveragePerSchoolAgeStatistics,
  LanguageAnalysisStatisticsType,
  OverviewStatistics,
  PreschoolStatistics,
  ResultsStatistics,
} from '../data-models/preschool'
import axiosAPIInstance from './api'
import axios, { CancelTokenSource } from 'axios'

const buildFiltersString = (areaId?: string[], preschoolId?: string[], departmentId?: string[]) => {


  // Create query parameters for areaId and departmentId
  let areaParams = areaId?.map(id => `areaId=${id}`).join('&') || '';
  let departmentParams = departmentId?.map(id => `departmentId=${id}`).join('&') || '';
  
  // Combine areaId and departmentId parameters
  let paramString = [areaParams, departmentParams].filter(Boolean).join('&');
  
  if (preschoolId && preschoolId?.length > 0) {
    if (areaId && areaId?.length > 0) {
      paramString = paramString?.concat('&')
    }
    if (departmentId && departmentId?.length > 0) {
      paramString = paramString?.concat('&')
    }
    paramString = paramString?.concat(
      preschoolId?.map(id => `preschoolId=${id}`).join('&') || '',
    )
  }


  return paramString || ''
}

export const getPreschoolStatistics = async (
  organizationId: string,
  followupId: string,
): Promise<PreschoolStatistics[]> => {
  if (!organizationId) {
    return []
  }

  const res = await axiosAPIInstance.get(`/statistics/${organizationId}`, {
    params: { followupId },
  })
  return res.data
}

interface TriggerPreschoolStatisticsProps {
  organizationId?: string
  followupId?: string
}

export const triggerPreschoolStatistics = async ({
  followupId,
  organizationId,
}: TriggerPreschoolStatisticsProps): Promise<PreschoolStatistics[]> => {
  if (!organizationId) {
    return []
  }

  const res = await axiosAPIInstance.post(`/statistics/${organizationId}`, {
    followupId,
  })

  return res.data
}


// Global variable to store the cancel token source
let cancelTokenSource3: CancelTokenSource | null = null;

export const getOverviewStatistics = async (
  organizationId: string,
  followupId: string,
  areaId?: string[],
  preschoolId?: string[],
  departmentId?: string[]
): Promise<OverviewStatistics> => {
  const paramPayload = buildFiltersString(areaId, preschoolId,departmentId);

  if (!organizationId || !followupId) {
    return {
      areaId: '',
      followupId: '',
      numberOfParticipants: 0,
      numberOfSchools: 0,
      organizationId: '',
      preschoolId: '',
      submittedTotalCount: 0,
      submittedWeekCount: 0,
      needsUpdate: false,
    };
  }

  // Cancel the previous request if it exists
  if (cancelTokenSource3) {
    cancelTokenSource3.cancel('canceled');
  }

  // Create a new cancel token source
  cancelTokenSource3 = axios.CancelToken.source();

  try {
    const res = await axiosAPIInstance.get(
      `/statistics/${organizationId}/${followupId}/overview`,
      {
        params: {
          areaId,
          preschoolId,
          departmentId
        },
        paramsSerializer: () => {
          return paramPayload;
        },
        // Attach the cancel token to the request
        cancelToken: cancelTokenSource3.token,
      },
    );

    return res.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // Handle cancellation here, if needed
      console.log('Request canceled', error.message);
    } else {
      // Handle other errors
      console.error('Error fetching data:', error.message);
    }
    return {
      areaId: '',
      followupId: '',
      numberOfParticipants: 0,
      numberOfSchools: 0,
      organizationId: '',
      preschoolId: '',
      submittedTotalCount: 0,
      submittedWeekCount: 0,
      needsUpdate: false,
    };
  }
};



// Global variable to store the cancel token source
let cancelTokenSource: CancelTokenSource | null = null;

export const getAveragePerAgeStatistics = async (
  organizationId: string,
  followupId: string,
  areaId?: string[],
  preschoolId?: string[],
  departmentId?:string[]
): Promise<AveragePerAgeStatistics[]> => {
  const paramPayload = buildFiltersString(areaId, preschoolId,departmentId);

  if (!organizationId || !followupId) {
    return [];
  }

  // Cancel the previous request if it exists
  if (cancelTokenSource) {
    cancelTokenSource.cancel('canceled');
  }

  // Create a new cancel token source
  cancelTokenSource = axios.CancelToken.source();


  try {
    const res = await axiosAPIInstance.get(
      `/statistics/${organizationId}/${followupId}/average-per-age`,
      {
        params: {
          areaId,
          preschoolId,
          departmentId
        },
        paramsSerializer: () => {
          return paramPayload;
        },
        // Attach the cancel token to the request
        cancelToken: cancelTokenSource.token,
      },
    );
    return res.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // Handle cancellation here, if needed
      console.log('canceled', error.message);
    } else {
      // Handle other errors
      console.error('Error fetching data:', error.message);
    }
    return [];
  }
};
let cancelTokenSource2: CancelTokenSource | null = null;

export const getAveragePerTaskStatistics = async (
  organizationId: string,
  followupId: string,
  areaId?: string[],
  preschoolId?: string[],
  departmentId?:string[]
): Promise<AveragePerAgeStatistics[]> => {
  const paramPayload = buildFiltersString(areaId, preschoolId,departmentId);

  if (!organizationId || !followupId) {
    return [];
  }

  // Cancel the previous request if it exists
  if (cancelTokenSource2) {
    cancelTokenSource2.cancel('canceled');
  }

  // Create a new cancel token source
  cancelTokenSource2 = axios.CancelToken.source();


  try {
    const res = await axiosAPIInstance.get(
      `/statistics/${organizationId}/${followupId}/average-per-age`,
      {
        params: {
          areaId,
          preschoolId,
        },
        paramsSerializer: () => {
          return paramPayload;
        },
        // Attach the cancel token to the request
        cancelToken: cancelTokenSource2.token,
      },
    );
    return res.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // Handle cancellation here, if needed
      console.log('canceled', error.message);
    } else {
      // Handle other errors
      console.error('Error fetching data:', error.message);
    }
    return [];
  }
};
export const getProgressionStats = async (
  organizationId: string,
  followupId: string[],
  areaIds?: string[],
  preschoolIds?: string[],
  departmentIds?: string[],
  ages?: number[],
): Promise<ProgressionStats> => {
  if (!followupId.length) {
    return {} as ProgressionStats
  }
  const res = await axiosAPIInstance.get(
    `/statistics/${organizationId}/compare-followup`,
    {
      params: {
        followupId: followupId,
        areaId: areaIds,
        preschoolId: preschoolIds,
        departmentId: departmentIds,
        age: ages,
      },
      paramsSerializer: params => qs.stringify(params, { encode: false }),
    },
  )

  return res.data
}
export const getProgressionGrouplevel = async (
  organizationId: string,
  followupIds: string[],
  areaIds?: string[],
  preschoolIds?: string[],
  departmentIds?:string[],
  ages?: number[]
): Promise<ChartData> => {
  if (!followupIds.length) {
    return {} as ChartData;
  }

 
  const res = await axiosAPIInstance.get(
    `/statistics/${organizationId}/compare-followup-group`,
    {
      params: {
        followupIds: followupIds, // Use followupIds instead of followupId
        areaIds: areaIds,
        preschoolIds: preschoolIds,
        departmentIds: departmentIds,
        ages: ages
      },
      paramsSerializer: params => qs.stringify(params, { encode: false }),
    },
  );
  res.data.followupGroups.forEach((group) => {
    // Update groupName based on its current value
    switch (group.groupName) {
      case 'LOW':
        group.groupName = [
          {
            de: 'Niedrig',
            en: 'Low',
            se: 'Låg',
          },
        ];
        break;
      case 'MEDIUM':
        group.groupName = [
          {
            de: 'Mittel',
            en: 'Medium',
            se: 'Medel',
          },
        ];
        break;
      case 'HIGH':
        group.groupName = [
          {
            de: 'Hoch',
            en: 'High',
            se: 'Hög',
          },
        ];
        break;
      default:
        break;
    }
    group.followupGroupComparisons.forEach((comparison) => {
      comparison.percentage = Math.round(comparison.percentage * 100);
    });
  });
  return res.data;
};

// Global variable to store the cancel token source
let cancelTokenSource6: CancelTokenSource | null = null;

export const getAveragePerGenderStatistics = async (
  organizationId: string,
  followupId: string,
  areaId?: string[],
  preschoolId?: string[],
  departmentId?: string[]
): Promise<AveragePerGenderStatistics[]> => {
  const paramPayload = buildFiltersString(areaId, preschoolId,departmentId);

 
  if (!organizationId || !followupId) {
    return [];
  }

  // Cancel the previous request if it exists
  if (cancelTokenSource6) {
    cancelTokenSource6.cancel('canceled');
  }

  // Create a new cancel token source
  cancelTokenSource6 = axios.CancelToken.source();

  try {
    const res = await axiosAPIInstance.get(
      `/statistics/${organizationId}/${followupId}/average-per-gender`,
      {
        params: {
          areaId,
          preschoolId,
          departmentId
        },
        paramsSerializer: () => {
          return paramPayload;
        },
        // Attach the cancel token to the request
        cancelToken: cancelTokenSource6.token,
      },
    );

    return res.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // Handle cancellation here, if needed
      console.log('Request', error.message);
    } else {
      // Handle other errors
      console.error('Error fetching data:', error.message);
    }
    return [];
  }
};


let cancelTokenSource7: CancelTokenSource | null = null;

export const getAveragePerAgeHorizonStatistics = async (
  organizationId: string,
  followupId: string,
  areaId?: string[],
  preschoolId?: string[],
  departmentId?: string[]
): Promise<AveragePerAgeHorizonStatistics[]> => {
  
  const paramPayload = buildFiltersString(areaId, preschoolId, departmentId);

  if (!organizationId || !followupId) {
    return [];
  }

  // Cancel the previous request if it exists
  if (cancelTokenSource7) {
    cancelTokenSource7.cancel('canceled');
  }

  // Create a new cancel token source
  cancelTokenSource7 = axios.CancelToken.source();

  try {
    const res = await axiosAPIInstance.get(
      `/statistics/${organizationId}/${followupId}/average-per-age-summary`,
      {
        params: {
          areaId,
          preschoolId,
          departmentId
        },
        paramsSerializer: () => {
          return paramPayload;
        },
        // Attach the cancel token to the request
        cancelToken: cancelTokenSource7.token,
      },
    );
    return res.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // Handle cancellation here, if needed
      console.log('Request', error.message);
    } else {
      // Handle other errors
      console.error('Error fetching data:', error.message);
    }
    return [];
  }
};

// Global variable to store the cancel token source
let cancelTokenSource8: CancelTokenSource | null = null;

export const getAveragePerSchoolAgeStatistics = async (
  organizationId: string,
  followupId: string,
  areaId?: string[],
  preschoolId?: string[],
  departmentId?:string[]
): Promise<AveragePerSchoolAgeStatistics[]> => {
  const paramPayload = buildFiltersString(areaId, preschoolId, departmentId);

  if (!organizationId || !followupId) {
    return [];
  }

  // Cancel the previous request if it exists
  if (cancelTokenSource8) {
    cancelTokenSource8.cancel('canceled');
  }

  // Create a new cancel token source
  cancelTokenSource8 = axios.CancelToken.source();

  try {
    const res = await axiosAPIInstance.get(
      `/statistics/${organizationId}/${followupId}/average-per-school-age`,
      {
        params: {
          areaId,
          preschoolId,
        },
        paramsSerializer: () => {
          return paramPayload;
        },
        // Attach the cancel token to the request
        cancelToken: cancelTokenSource8.token,
      },
    );
    return res.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // Handle cancellation here, if needed
      console.log('canceled', error.message);
    } else {
      // Handle other errors
      console.error('Error fetching data:', error.message);
    }
    return [];
  }
};
interface UseResultsOverviewProps {
  areaIds?: string[]
  followupId: string
  submittedAt?: string
  organizationId: string
  preschoolIds?: string[]
}

const getResultsStatsOverview = ({
  organizationId,
  followupId,
  areaIds,
  preschoolIds,
  ...rest
}: UseResultsOverviewProps) => {
  if (!organizationId || !followupId)
    return {
      organizationId: '0',
      numberOfSchools: 0,
      numberOfFollowups: 0,
      submittedTotalCount: 0,
      numberOfParticipants: 0,
    }


    return axiosAPIInstance
    .get(`/followupresults/${organizationId}/overview`, {
      params: {
        followupId,
        ...rest,
      },
      paramsSerializer: params => {
        let queryString = qs.stringify(params, { arrayFormat: 'repeat' });
        
        // The parameters are initially provided as areaIds and preschoolIds.
        // To make the API call work correctly, we need to send them as areaId and preschoolId.
        // This code ensures that areaIds and preschoolIds are replaced with areaId and preschoolId.

        if (areaIds && areaIds.length > 0) {
          queryString += '&' + areaIds.map(id => `areaId=${id}`).join('&');
        }

        if (preschoolIds && preschoolIds.length > 0) {
          queryString += '&' + preschoolIds.map(id => `preschoolId=${id}`).join('&');
        }

        return queryString;
      },
    })
    .then(({ data }) => data as ResultsStatistics)
}

export const useResultsStatsOverview = ({
  followupId,
  organizationId,
  ...rest
}: UseResultsOverviewProps) => {

  const getResultsOverviewQuery = useQuery({
    queryKey: ['resultOverview', organizationId, followupId, { ...rest }],
    queryFn: () =>
      getResultsStatsOverview({ organizationId, followupId, ...rest }),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = {
      organizationId: '0',
      numberOfSchools: 0,
      numberOfFollowups: 0,
      submittedTotalCount: 0,
      numberOfParticipants: 0,
    },
    error,
    isLoading: loading,
  } = getResultsOverviewQuery

  return {
    error,
    loading,
    response,
  }
}

interface UseResultsListProps {
  organizationId: string
  followupId: string
  submittedAt?: string
  areaId?: string[]
  preschoolId?: string[]
  pageNumber?: number
  pageSize?: number
}

const getResultsList = ({
  organizationId,
  followupId,
  ...rest
}: UseResultsListProps) => {
  if (!followupId || !organizationId)
    return {
      data: [],
      totalCount: 0,
    }

  return axiosAPIInstance
    .get(`/followupresults/${organizationId}/list/${followupId}`, {
      params: {
        ...rest,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
    .then(({ data }) => data as ResultResponse)
}

export const useResultsList = ({
  organizationId,
  followupId,
  ...rest
}: UseResultsListProps) => {
  const getResultsListQuery = useQuery({
    queryKey: ['resultsList', organizationId, followupId, { ...rest }],
    queryFn: () => getResultsList({ organizationId, followupId, ...rest }),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = {
      data: [],
      totalCount: 0,
    },
    error,
    isLoading: loading,
  } = getResultsListQuery

  return {
    error,
    loading,
    response,
  }
}

interface UseResultDetailsProps {
  organizationId: string
  resultId?: string
}

const getResultDetails = ({
  organizationId,
  resultId,
}: UseResultDetailsProps) => {

  return axiosAPIInstance
    .get(`/followupresults/${organizationId}/detail/${resultId}`)
    .then(({ data }) => data as ResultInfo)
}

export const useResultDetails = ({
  organizationId,
  resultId,
}: UseResultDetailsProps) => {
  const getResultDetailsQuery = useQuery({
    queryKey: ['resultDetails', organizationId, resultId],
    queryFn: () => getResultDetails({ organizationId, resultId }),
    staleTime: 1000 * 60 * 10,
  })

  const { data: response, error, isLoading: loading } = getResultDetailsQuery

  return {
    error,
    loading,
    response,
  }
}



// export const getLanguageAnalysisStatistics = async (
//   organizationId: string,
//   followupId: string[],
//   areaIds?: string[],
//   preschoolIds?: string[],
//   departmentIds?: string[],
//   ages?: number[],
// ): Promise<ProgressionStats> => {
//   if (!followupId.length) {
//     return {} as ProgressionStats;
//   }

//   const res = await axiosAPIInstance.get(
//     `/statistics/1289/52332c63-0b4d-4c8b-ef7d-08dc8ba5b018/language_analysis`,
//     {
//       params: {
//         areaId: areaIds,
//         preschoolId: preschoolIds,
//         departmentId: departmentIds,
//         age: ages,
//       },
//       paramsSerializer: params => qs.stringify(params, {
//         arrayFormat: 'repeat', // Repeat the key for each value in the array
//         encode: false // Do not encode the query parameters
//       }),
//     },
//   );

//   return res.data;
// };


export const getLanguageAnalysisStatistics = async (
  organizationId: string,
  followupId: string,
  areaId?: string[],
  preschoolId?: string[],
  departmentId?:string[]
): Promise<LanguageAnalysisStatisticsType[]> => {
  const paramPayload = buildFiltersString(areaId, preschoolId,departmentId);
 
  if (!organizationId || !followupId) {
    return [];
  }

  // Cancel the previous request if it exists
  if (cancelTokenSource2) {
    cancelTokenSource2.cancel('canceled');
  }

  // Create a new cancel token source
  cancelTokenSource2 = axios.CancelToken.source();


  try {
    const res = await axiosAPIInstance.get(
      `/statistics/${organizationId}/${followupId}/language_analysis`,
      {
        params: {
          areaId,
          preschoolId,
          departmentId,
        },
        paramsSerializer: () => {
          return paramPayload;
        },
        // Attach the cancel token to the request
        cancelToken: cancelTokenSource2.token,
      },
    );
    return res.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // Handle cancellation here, if needed
      console.log('canceled', error.message);
    } else {
      // Handle other errors
      console.error('Error fetching data:', error.message);
    }
    return [];
  }
};