import React from 'react'
import { Image } from '@chakra-ui/react'
import { useDrag } from 'react-dnd'
import { ImagePlacementAnswer } from 'data-models/task-models'
import { apiBase } from 'services/config'

export default function ImagePlacementItem(props: {
  ipa: ImagePlacementAnswer
}) {
  const [_, drag] = useDrag(
    () => ({
      type: 'image',
      item: { image: apiBase + props.ipa.imageUrl },
    }),
    [props.ipa],
  )

  return (
    <Image
      src={apiBase + props.ipa.imageUrl}
      ref={drag}
      maxW='unset'
      zIndex='0'
      cursor='pointer'
    />
  )
}
