import React from 'react'
import { Box, Button, Grid, GridItem, HStack } from '@chakra-ui/react'
import { StatsContainer } from 'pages/SuperAdminSettings/common'
import { UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useTranslation } from 'react-i18next'

interface PreschoolStatsProps {
  loading?: boolean
  numberOfUsers?: number
  numberOfPreschools?: number
  handleOpenCreatePreschool: () => void
}

export const PreschoolStats = ({
  loading = false,
  numberOfUsers = 0,
  numberOfPreschools = 0,
  handleOpenCreatePreschool,
}: PreschoolStatsProps) => {
  const { user } = useUser()

  const isAdmin = [UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role)
  const { t } = useTranslation()
  return (
    <Box w='full' style={{ fontFamily: 'Open Sans' }}>
      <HStack justifyContent={'space-between'} w='full'>
        <Grid w='full' templateColumns='repeat(auto-fill, max(240px))' gap={6}>
          <GridItem w='100%'>
            <StatsContainer
              name={t('DashboardOverview_Number_of_Preschools')}
              count={numberOfPreschools}
              loading={loading}
              imageSrc={'/images/orangeIcon.png'}
            />
          </GridItem>
          <GridItem w='100%'>
            <StatsContainer
              name={t('Num_Of_Users')}
              count={numberOfUsers}
              loading={loading}
              imageSrc={'/images/blueIconPeople.png'}
            />
          </GridItem>
        </Grid>

        {isAdmin && (
          <Button
            mb='2'
            minW='200px'
            height='35px'
            fontSize={'sm'}
            onClick={handleOpenCreatePreschool}
          >
            {t('Create_Preschool_Button')}
          </Button>
        )}
      </HStack>
    </Box>
  )
}
