import { IndicatorMapper } from './types'

export const ageColors: Record<number, string> = {
  1: '#A6EF76',
  2: '#FFE64E',
  3: '#20BE43',
  4: '#FFA418',
  5: '#3B61E5',
  6: '#FD6A52',
  7: '#8A2BE2',
}

export const followUpColors = {
  '2Januari 2021': '#FFE64E',
  '2Maj 2021': '#FFE64E',
  '2Januari 2022': '#FFE64E',
  '2April 2022': '#FFE64E',
  '3Januari 2021': '#20BE43',
  '3Maj 2021': '#20BE43',
  '3Januari 2022': '#20BE43',
  '3April 2022': '#20BE43',
  '4Januari 2021': '#FFA418',
  '4Maj 2021': '#FFA418',
  '4Januari 2022': '#FFA418',
  '4April 2022': '#FFA418',
  '5Januari 2021': '#3B61E5',
  '5Maj 2021': '#3B61E5',
  '5Januari 2022': '#3B61E5',
  '5April 2022': '#3B61E5',
  '6Januari 2021': '#FD6A52',
  '6Maj 2021': '#FD6A52',
  '6Januari 2022': '#FD6A52',
  '6April 2022': '#FD6A52',
}

export const followupLabelColors = {
  'Januari 2021': '#c99973', //#e6b085
  'Maj 2021': '#8f4b14', //#703b10
  'Januari 2022': '#c99973', //#e6b085
  'April 2022': '#8f4b14', //#703b10
}

export const clusterColors = {
  Höga: '#F8CEEE',
  Låga: '#D389C2',
  Medel: '#A0468C',
}

export const IndicatorColors: IndicatorMapper = {
  FindPatterns: '#E2B4E5',
  ImageScore: '#D6C566',
  ExpressOpinions: '#EAC051',
  Cooperation: '#CDA376',
  Reasoning: '#F5A984',
  POJKE: '#86E6FC',
  FLICKA: '#FD9FC1',
  StaffTurnover: '#515151',
  TeacherSurvey: '#C20B9A',
  ParentsWithHigherEducation: '#986B27',
  ForeignBackground: '#5831C6',
  FundingPerChild: '#646c77',
  AverageTotal: '#279198',
  ChildrenResult: '#279198',
  ChildrenResultMunicipality : '#D64161FF',
  ProportionOfBoys: '#dab3da',
  PedagogicalExam: '#967abc',
  SickLeave: '#FF1E00',
  meanAboveMedian: '#D08C64',
  meanBelowMedian: '#894C28',
  Age: '#b4aaa1',
  Gender: '#dbc558',
  GenderMunicipality:'#D198C5FF',
  NumberOfChildren: '#ffa057',
  expressesOwnPerspective: '#D1D367',
  expressesInnerImagesSaga: '#849433',
  expressesInnerImagesFace: '#9F8193',
  expressesInnerImagesSandcastles: '#A6EF76',
  independentChoices: '#D0C5B4',
  expressesInnerImages: '#AAB59B',
  ScoreBelow15hrs: '#BE2929',
  ScoreAbove15hrs: '#008537',
  actualWeeklyPresence: '#646c77',
  result: '#279198',
  AveragePerAge: "'#229408",
  Progression: '#3C00F2',
}

export const commonColors = {
  teal: '#5cd9e1',
  orangeDark: '#ff9c65',
  blue: 'blue',
}

export const assignmentColorsByIds = [
  '',
  IndicatorColors.FindPatterns,
  IndicatorColors.Reasoning,
  IndicatorColors.ExpressOpinions,
  IndicatorColors.Cooperation,
  IndicatorColors.ImageScore,
  IndicatorColors.expressesOwnPerspective,
  IndicatorColors.expressesInnerImagesSaga,
  IndicatorColors.expressesInnerImagesFace,
  IndicatorColors.expressesInnerImagesSandcastles,
  IndicatorColors.independentChoices,
  IndicatorColors.expressesInnerImages,
]
