import React, { useEffect, useState } from 'react'
import ImagePlacementItem from './ImagePlacementItem'
import { Center, SimpleGrid } from '@chakra-ui/react'

export default function ImagePlacementItems(props: { images: string[],task?:any }) {
  //  const currentTask = props.task
  // const task = currentTask as ImageSortingTask
  const [isSortShapes,setIsSortShapes] = useState(false)
  const [isSortShapesLevel2,setIsSortShapesLevel2] = useState(false)
 
  useEffect(()=>{
    if(props.task){
      if(props.task.name === 'Shapes'|| props.task.name === 'Former' || props.task.name === 'Formen'  ){
        setIsSortShapes(true)  
        if((props.task.knowledge === 'Mathematic Level 2' || props.task.knowledge === 'Matematik Nivå 2' || props.task.knowledge === 'Matematik Niveau 2')){
          setIsSortShapesLevel2(true)
        }

      }
      }
  },[props.task.name])
 
 
  return (
    <SimpleGrid columns={5} spacing='30px'>
      {props.images
        // .filter(ao => !ao.placedAtPoint)
        .map(ao =>
          ao != '' ? (
            <Center
              key={ao}
              bgColor='white'
              width={isSortShapes ? 'unset' : '140px'}
              height={isSortShapesLevel2 ? '160px' : '90%'}
              // boxSize="85px"
              borderRadius={isSortShapesLevel2 ? '0' : '2xl'}
              padding={isSortShapes ? '15%' : 0}
            >
              <ImagePlacementItem ipa={ao} sortShapes = {isSortShapes}/>
            </Center>
          ) : (
            <Center
              width='140px'
              height={isSortShapesLevel2 ? '160px' : '90%'}
              // boxSize="85px"
              borderRadius='2xl'
              borderWidth='1px'
              borderStyle='dashed'
              borderColor='white'
              color='rgba(255,255,255,0.25)'
              fontSize='48'
              fontWeight='semibold'
            ></Center>
          ),
        )}
    </SimpleGrid>
  )
}
