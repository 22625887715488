import React, { PropsWithChildren } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

export const TabContainer = ({
  children,
  ...rest
}: PropsWithChildren<FlexProps>) => {
  return (
    <Flex w='full' h='full' pt='5' {...rest}>
      {children}
    </Flex>
  )
}
