import * as yup from 'yup'
import i18n from 'i18next';



export const followupSchema = yup.object().shape({
  name: yup.string()
  .required(() => i18n.t("Name_Required_Field")),
  startDate: yup.date().required(i18n.t('Start_Date_Select')).default(new Date()),
  endDate: yup.date().required(i18n.t('Choose_End_Date')).default(new Date()),
  // academicYear: yup.string().required('Välj ett slutdatum'),
  academicYear: yup.string(),
  description: yup.string(),
  isExportable: yup.bool().required(),
  taskIds: yup
    .array()
    .required()
    .test(
      'tasks-count',
      i18n.t('Between_1_And_5_Tasks'),
      arr => (arr?.length || 0) > 0 && (arr?.length || 0) < 6,
    ),
})
