import { useUser } from '../providers/UserProvider'
import { useOrganizations } from 'services/organizationService'
import { useHistory } from 'react-router-dom'

export const useMainHeader = () => {
  const { user, setUser } = useUser()
  const { response: organizations } = useOrganizations()
  const history = useHistory()

  const organizationOptions = organizations.map(org => ({
    value: org.id,
    label: org.name,
  }))

  const currentOrganization = organizations.find(
    org => org.id == user.organizationId,
  )

  const handleOrganizationChange = organizationOption => {
    setUser({ ...user, organizationId: organizationOption.value })
    history.go(0)
  }

  return {
    user,
    organizationOptions,
    currentOrganization,
    handleOrganizationChange,
  }
}
