import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { addMonths, addDays, subMonths } from 'date-fns'

import { Option, Task } from '../../../../data-models'
import { useTaskService } from 'services/taskService'
import { uniq } from 'lodash-es'
import { useDisclosure } from '@chakra-ui/react'
import { calculateAcademicYear } from 'common/utils'
import { useHistory } from 'react-router-dom'
import { useFilters } from 'providers/FilterProvider'
import i18n from 'i18next';

const sortTasks = (tasks: Task[]) => {

  
  // const sortedByCriteria = tasks.sort((firstTask, secondTask) => {
  //   const criteriaComparison = (firstTask.criteria || '').localeCompare(
  //     secondTask.criteria || '',
  //     'se',
  //     { sensitivity: 'base' },
  //   )

  //   const nameComparison = (firstTask.name || '').localeCompare(
  //     secondTask.name || '',
  //     'se',
  //     { sensitivity: 'base' },
  //   )

  //   if (criteriaComparison === 0) return nameComparison
  //   return criteriaComparison
  // })
  return tasks.sort((a, b) => {
    // Provide a default empty string if criteria is undefined
    const skillAreaA = a.skillArea || '';
    const skillAreaB = b.skillArea || '';

    return skillAreaA.localeCompare(skillAreaB);
  });
}

interface UseFollowupDetailsProps {
  followupId: string
}

export const useFollowupDetails = ({ followupId }: UseFollowupDetailsProps) => {
  const history = useHistory()
  const formMethods = useFormContext()
  const { setValue, watch } = formMethods

  const { setCurrentFollowupId } = useFilters()

  const { tasks } = useTaskService()
  const orderedTasks = sortTasks(tasks)



  const [taskCriteria, setTaskCriteria] = useState<Option>()

  const criteriaOptions = [
    { value: '', name: i18n.language === 'se' ? '--Alla--' : i18n.language === 'de' ? '--Alle--' :  '--All--'  },
    ...uniq(tasks.map(q => q.criteria)).map(criteria => ({
      name: criteria,
      value: criteria,
    })),
  ]

  const [selectedTaskId, setSelectedTaskId] = useState<string>('')
 

  
  const {
    isOpen: isTaskPreviewOpen,
    onOpen: onTaskPreviewOpen,
    onClose: onTaskPreviewClose,
  } = useDisclosure()

  function openPreview(e: any) {
    setSelectedTaskId(e)
    onTaskPreviewOpen()
  }

 

  const tasksSelected = watch('taskIds')
  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const maxEndDate = addMonths(startDate, 3)
  const minEndDate = addDays(startDate, 1)

  const handleSetAcademicYear = (endDate: Date) => {
    setValue('academicYear', calculateAcademicYear(endDate))
  }

  useEffect(() => {
    handleSetAcademicYear(endDate)
  }, [endDate])

  useEffect(() => {
    if (
      new Date(startDate).getTime() >= new Date(endDate).getTime() ||
      new Date(startDate).getTime() < new Date(subMonths(endDate, 3)).getTime()
    ) {
      setValue('endDate', addMonths(startDate, 3))
    }
  }, [startDate])

  const handleGoToStatistics = () => {
    history.push('/statistic')
    setCurrentFollowupId(followupId)
  }

  return {
    minEndDate,
    maxEndDate,
    handleGoToStatistics,
    formMethods,
    taskCriteria,
    setTaskCriteria,
    tasks: orderedTasks,
    criteriaOptions,
    openPreview,
    isTaskPreviewOpen,
    onTaskPreviewClose,
    selectedTaskId,
    tasksSelected,
  }
}
