import React from 'react'
import { Box, Button, Grid, GridItem, HStack } from '@chakra-ui/react'
import { StatsContainer } from 'pages/SuperAdminSettings/common'
import { useHistory } from 'react-router-dom'
import { UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useTranslation } from 'react-i18next'

interface OrganizationStatsProps {
  loading: boolean
  numberOfUsers?: number
  numberOfPreschools?: number
  numberOfOrganizations?: number
}

export const OrganizationStats = ({
  loading,
  numberOfUsers = 0,
  numberOfPreschools = 0,
  numberOfOrganizations = 0,
}: OrganizationStatsProps) => {
  const history = useHistory()
  const { user } = useUser()
  const { t } = useTranslation()
  const handleCreateOrganization = () => {
    history.push('/organization/create')
  }

  const isSuperAdmin = [UserRole.SUPERADMIN].includes(user.role)

  return (
    <Box w='full' style={{ fontFamily: 'Open Sans' }}>
      <HStack justifyContent='space-between' w='full'>
        <Grid w='full' templateColumns='repeat(auto-fill, max(240px))' gap={6}>
          <GridItem w='100%'>
            <StatsContainer
              name={t('Number_Of_Org')}
              count={numberOfOrganizations}
              loading={loading}
              imageSrc='/images/blueIconPeople.png'
            />
          </GridItem>
          <GridItem w='100%'>
            <StatsContainer
              name={t('DashboardOverview_Number_of_Preschools')}
              count={numberOfPreschools}
              loading={loading}
              imageSrc='/images/orangeIcon.png'
            />
          </GridItem>
          <GridItem w='100%'>
            <StatsContainer
              name={t('Num_Of_Users')}
              count={numberOfUsers}
              loading={loading}
              imageSrc='/images/blueIconPeople.png'
            />
          </GridItem>
        </Grid>
        {isSuperAdmin && (
          <Button
            mb='2'
            minW='200px'
            height='35px'
            fontSize='sm'
            onClick={handleCreateOrganization}
          >
            {t('Add_organization')}
          </Button>
        )}
      </HStack>
    </Box>
  )
}
