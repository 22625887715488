import { useForm } from 'react-hook-form'
import { useUser } from 'providers/UserProvider'
import { UserRole, ViewUserShape } from 'data-models'
import { yupResolver } from '@hookform/resolvers/yup'
import { createUserSchema } from './schema'
import { useCreateUser } from 'services/userService'
import { useToast } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { pick } from 'lodash-es'

export const useCreateUserFromInvite = () => {
  const {
    user: { role },
  } = useUser()

  const toast = useToast()
  const history = useHistory()

  const params = useParams()
  const { activationToken } = params as any

  const formMethods = useForm<ViewUserShape>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(createUserSchema),
    //this might need removing
    context: {
      id: '',
    },
  })

  const { handleSubmit } = formMethods

  const createUser = useCreateUser()

  const onCreate = async (values: ViewUserShape) => {
    try {
      await createUser.mutateAsync({
        values: {
          ...pick(values, ['name', 'email', 'phoneNumber', 'password']),
          password: values.newPassword,
        },
        token: activationToken,
      })

      toast({
        title: 'Användare har skapats',
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/login')
    } catch (error) {
      toast({
        title: 'Det gick inte att skapa användare',
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleCreate = handleSubmit(onCreate)

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(role)

  return {
    formMethods,
    isAdmin,
    handleCreate,
    isLoading: createUser.isLoading,
  }
}
