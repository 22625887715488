import React from 'react'
import { FormProvider } from 'react-hook-form'
import { Flex } from '@chakra-ui/react'
import { User } from 'data-models'
import { useUserEditor } from './hooks'
import { CreateUserInvite } from './CreateUserInvite'
import { ActionsMenu } from '../common/ActionsMenu'
import { CreateButton } from '../common/CreateButton'
import { InviteButton } from '../common/InviteButton'
import { DetailsWrapper } from '../common/EntityViewer.layout'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { getAxiosInstance } from 'services/api'
import { apiUrl } from 'services/config'
import { useUser } from 'providers/UserProvider'
import { usePreschoolService } from 'services/preschoolService'
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { error } from 'console'

interface UserEditorProps {
  user: User
  isCreate: boolean
}

export const CreateUserInviteEditor = ({ isCreate, user }: UserEditorProps) => {
  const { isAdmin, formMethods, handleCreate, isCreateLoading } = useUserEditor(
    {
      initialUser: user,
    },
  )
  const { setValue } = formMethods
  const toast = useToast()
  const { t } = useTranslation()
  const inviteUser = async (values: any): Promise<{ token: string }> => {
    const res = await getAxiosInstance(apiUrl).post(`/invites`, { ...values })
    return res.data
  }
  const history = useHistory()
  const usr = useUser()
  const handleinvitaion = async (e: any) => {
    e.preventDefault()
    const useForm = formMethods.getValues()
    const UserInfo = {
      organizationId: usr.user.organizationId,
      preschoolIds: useForm.preschoolIds,
      role: useForm.role,
      email: useForm.email,
    }

    try {
      if (UserInfo.email === '') {
        toast({
          title: `${t('Please_Enter_the_Valid_Email')}`,
          isClosable: true,
          duration: 3000,
          status: 'error',
        })
      } else {
        inviteUser(UserInfo)
        toast({
          title: `${t('sent_an_invitation_Link')}`,
          isClosable: true,
          duration: 5000,
          status: 'success',
        })
        history.push('/settings/users')
      }
    } catch (error) {
      toast({
        title: 'something wrong',
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }
  const { preschools } = usePreschoolService(usr.user.organizationId)
  const preschoolOptions = preschools.map(preschool => ({
    value: preschool.id,
    label: preschool.name,
  }))
  const selectAllPreschools = () => {
    setValue(
      'preschoolIds',
      preschoolOptions.map(preschoolOption => preschoolOption.value),
    )
  }
  return (
    <Flex w='full' h='fit-content' bg='white'>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} noValidate={true}>
          <ActionsMenu name={user?.name || i18n.t('User')}>
            {isAdmin && (
              <>
                {isCreate ? (
                  <CreateButton
                    onClick={handleCreate}
                    isLoading={isCreateLoading}
                  />
                ) : (
                  <InviteButton onClick={e => handleinvitaion(e)} />
                )}
              </>
            )}
          </ActionsMenu>

          <DetailsWrapper>
            <CreateUserInvite
              user={user}
              preschoolOptions={preschoolOptions}
              selectAllPreschools={selectAllPreschools}
            />
          </DetailsWrapper>
        </form>
      </FormProvider>
    </Flex>
  )
}
