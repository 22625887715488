import { useUser } from 'providers/UserProvider'
import { useResultDetails as useResultDetailsQuery } from 'services/followupResultService'

export const useResultDetails = (resultId: string) => {
  const {
    user: { organizationId },
  } = useUser()

  const { response: resultDetails, loading } = useResultDetailsQuery({
    organizationId,
    resultId,
  })

  return { resultDetails, loading }
}
