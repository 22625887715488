import React from 'react'

import { VStack } from '@chakra-ui/react'
import { ResultInfo } from 'data-models'
import { ActionsMenu } from '../common/ActionsMenu'
import { ResultDetails } from './ResultDetails'
import { DetailsWrapper } from '../common/EntityViewer.layout'

interface ResultEditorProps {
  result: ResultInfo
}

export const ResultEditor = ({ result }: ResultEditorProps) => {
  return (
    <VStack w='full' h='fit-content' bg='white'>
      <ActionsMenu name={result?.followupName || 'Resultat'}></ActionsMenu>

      <DetailsWrapper>
        <ResultDetails result={result} />
      </DetailsWrapper>
    </VStack>
  )
}
