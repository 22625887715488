import React from 'react'
import { Text, Collapse, Box } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

interface helpProps {
    helpDetailText: string,
    isOpen: boolean
}
const ChartHelpComponent = ({ helpDetailText, isOpen }: helpProps) => {
    const { t } = useTranslation()
    return (
        <Collapse in={isOpen} animateOpacity>
            <Box
                p='40px'
                mt='4'
                bg='gray.100'
                rounded='md'
            >
                <Text lineHeight={2}><Trans>{t(helpDetailText)}</Trans></Text>
            </Box>
        </Collapse>
    )
}

export default ChartHelpComponent
