import React from 'react'
import { Image } from '@chakra-ui/react'
import { useDrag } from 'react-dnd'
import { apiBase } from 'services/config'

export default function ImagePlacementItem(props: { ipa: string,sortShapes?:boolean }) {
  const [_, drag] = useDrag(
    () => ({
      type: 'image',
      item: { image: apiBase + props.ipa },
    }),
    [props.ipa],
  )
  return (
    <Image
      src={apiBase + props.ipa}
      ref={drag}
      // minW="140px"
      borderRadius={props.sortShapes ? '0' : '2xl'}
      // pos="absolute"
      zIndex='0'
      cursor='pointer'
    />
  )
}
