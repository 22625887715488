import { emptyResult } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useHistory, useParams } from 'react-router-dom'
import { useResultDetails } from 'services/followupResultService'

export const useResultContainer = () => {
  const params = useParams()
  const { id } = params as any
  const history = useHistory()

  const {
    user: { organizationId },
  } = useUser()

  const { response: result = emptyResult, loading } = useResultDetails({
    organizationId,
    resultId: id,
  })

  if (id && !result?.id && !loading) {
    history.push('/overview-results')
  }

  return {
    result,
    loading: loading,
  }
}
