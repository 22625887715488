import { getAxiosInstance } from './api'
import { idpUrl } from './config'

export const getPasswordResetToken = async (
  id: string,
): Promise<{ token: string }> => {
  const res = await getAxiosInstance(idpUrl).get(
    `/users/${id}/password-reset-token`,
  )

  return res.data
}

interface ResetPasswordUsingTokenProps {
  token: string
  id: string
  newPassword: string
}

export const resetPasswordUsingToken = async ({
  token,
  id,
  newPassword,
}: ResetPasswordUsingTokenProps): Promise<void> => {
  const res = await getAxiosInstance(idpUrl).post(
    `/users/${id}/password-reset`,
    {
      token,
      newPassword,
    },
    {
      auth: {
        username: '',
        password: token
      }
    }
  )

  return res.data
}

interface ResetOwnPasswordProps {
  oldPassword: string
  newPassword: string
  id: string
}

export const resetOwnPassword = async ({
  newPassword,
  oldPassword,
  id,
}: ResetOwnPasswordProps): Promise<{ token: string }> => {
  const res = await getAxiosInstance(idpUrl).post(`/users/${id}/password`, {
    newPassword,
    oldPassword,
  })

  return res.data
}
