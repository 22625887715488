import React from 'react'
import { Spinner, VStack, Text } from '@chakra-ui/react'
import { useResultContainer } from './hooks'
import { ResultEditor } from './ResultEditor'
import { useTranslation } from 'react-i18next'

export const ResultContainer = () => {
  const { loading, result } = useResultContainer()
  const { t } = useTranslation()
  return (
    <>
      {loading ? (
        <VStack spacing='5' w='full' h='full' justify='center' pt='20'>
          <Spinner size='xl' color='black' />
          <Text
            color='black'
            mb='14'
            mt='7'
            fontSize='large'
            fontWeight='light'
          >
            {t('Fetch_Results_Text')}
          </Text>
        </VStack>
      ) : (
        <ResultEditor result={result} />
      )}
    </>
  )
}
