import { useForm } from 'react-hook-form'
import { ResetPasswordShape, User } from 'data-models'

import { yupResolver } from '@hookform/resolvers/yup'

import { resetPasswordSchema } from '../schema'
import { useState } from 'react'
import {
  getPasswordResetToken,
  resetPasswordUsingToken,
} from 'services/idpService'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface UseUserPasswordResetProps {
  user: User
  onClose: () => void
}

export const useUserPasswordReset = ({
  user,
  onClose,
}: UseUserPasswordResetProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const formMethods = useForm<ResetPasswordShape>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  })

  const { handleSubmit } = formMethods
  const { t } = useTranslation()
  const submitHandler = async (values: ResetPasswordShape) => {
    try {
      setIsLoading(true)
      const tokenResponse = await getPasswordResetToken(user.id)
      await resetPasswordUsingToken({
        token: tokenResponse.token,
        id: user.id,
        newPassword: values.newPassword,
      })

      toast({
        title: `${t('Password_Reset_Success_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      onClose()
    } catch (error) {
      toast({
        title: `${t('Password_Reset_Fail_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const onSubmit = handleSubmit(submitHandler)

  return { formMethods, onSubmit, isLoading }
}
