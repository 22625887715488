import { emptyPreschool } from 'data-models/preschool'
import { useUser } from 'providers/UserProvider'
import { useHistory, useParams } from 'react-router-dom'
import { useMunicipalityService } from 'services/municipalityService'
import { useOrganizations } from 'services/organizationService'
import { usePreschoolDetails } from 'services/preschoolService'

export const usePreschoolContainer = () => {
  const params = useParams()
  const { id } = params as any
  const history = useHistory()
  const {
    user: { organizationId },
  } = useUser()

  const { municipalities } = useMunicipalityService()
  const { response } = useOrganizations()


  const currentOrganization = response.find(
    organization => Number(organization.id) === Number(organizationId),
  )

  const currentMunicipalityId =
    municipalities.find(
      municipality => municipality.id === currentOrganization?.municipalityId,
    )?.id || ''

  const { data: preschool = emptyPreschool, isInitialLoading } =
    usePreschoolDetails({
      preschoolId: id,
      organizationId,
    })

  if (id && !preschool?.id && !isInitialLoading) {
    history.push('/settings/preschool')
  }

  return {
    preschool: { ...preschool, municipalityId: currentMunicipalityId },
    loading: isInitialLoading,
  }
}
