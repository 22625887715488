export enum FeedbackType {
  REPORT_ISSUE = 'REPORT_ISSUE',
  SUGGESTION = 'SUGGESTION',
}

export const FeedbackTypeLabel: Record<FeedbackType, string> = {
  REPORT_ISSUE: 'Rapportera ett problem',
  SUGGESTION: 'Förslag',
}
export const FeedbackTypeLabelEn: Record<FeedbackType, string> = {
  REPORT_ISSUE: 'Report a problem',
  SUGGESTION: 'Suggestions',
}
export const FeedbackTypeLabelDe: Record<FeedbackType, string> = {
  REPORT_ISSUE: 'Problem melden',
  SUGGESTION: 'Vorschlag',
};
export interface FeedbackShape {
  feedback: string
  type: FeedbackType
}
