import React from 'react'
import {
  Text,
  Flex,
  Button,
  HStack,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react'
import CustomSelect from 'common/CustomSelect'
import { Followup } from 'data-models/followup'
import Preschool from 'data-models/preschool'
import { Area } from 'data-models/area'
import { useTranslation } from 'react-i18next'
import { useUser } from 'providers/UserProvider'
import { useFollowups } from 'services/followupService'
import { defaultFilter, ResultsStatsFilterType } from 'data-models'
import { useAreas } from 'services/areaService'
import { usePreschoolList } from 'services/preschoolService'

// import { subYears, format, subMonths } from 'date-fns'
// import { sv } from 'date-fns/locale'
// import { capitalize } from 'lodash-es'

// const generateStartDateOptions = () => {
//   const currentDate = new Date()
//   const oldestStartDate = subYears(currentDate, 7)
//   const dateList: any[] = []

//   let iteratorDate = currentDate
//   while (iteratorDate > oldestStartDate) {
//     dateList.push({
//       label: capitalize(
//         `${format(iteratorDate, 'LLLL', {
//           locale: sv,
//         })} ${iteratorDate.getFullYear()}`,
//       ),
//       value: iteratorDate,
//     })
//     iteratorDate = subMonths(iteratorDate, 1)
//   }

//   return dateList
// }

interface ResultsFilterProps {
  filter: ResultsStatsFilterType
  onFilterChange: (filter: Partial<ResultsStatsFilterType>) => void
  isDisabled?: boolean
}

export const ResultsFilter = ({
  filter,
  onFilterChange,
  isDisabled = false,
}: ResultsFilterProps) => {
  const { user } = useUser()

  const { response: areaOptions } = useAreas({
    organizationId: user.organizationId,
  })

  const {
    response: { preschools },
  } = usePreschoolList({ organizationId: user.organizationId })

  const preschoolOptions = preschools.map(preschool => ({
    ...preschool,
    name: preschool.preschoolName,
    id: preschool.preschoolId,
  }))

  const {
    loading: filtersLoading,
    response: { data: followups },
  } = useFollowups({ organizationId: user.organizationId })

  const followupOptions = followups.map(x => {
    return {
      id: x.id,
      name: x.name,
      startDate: x.startDate,
      endDate: x.endDate,
    }
  })

  const resetFilter = () => {
    onFilterChange({
      ...defaultFilter,
      followupId: followups?.length
        ? followups[0]?.id
        : defaultFilter.followupId,
    })
  }

  const onFollowupChange = (selectedFollowup: Followup) => {
    onFilterChange({
      followupId: selectedFollowup.id,
      areaIds: [],
      preschoolIds: [],
      // submittedAt: new Date(),
      pageNumber: 1,
    })
  }

  const onAreaChange = (areas?: Area[]) => {
    onFilterChange({
      areaIds: areas ? areas.map(area => area.id) : [],
      pageNumber: 1,
    })
  }

  const onPreschoolChange = (preschools?: Preschool[]) => {
    onFilterChange({
      preschoolIds: preschools
        ? preschools.map(preschool => preschool.preschoolId!)
        : [],
      pageNumber: 1,
    })
  }
  const { t } = useTranslation()
  // const startDateOptions = generateStartDateOptions()

  // const onBirthDateDateChange = birthDateOption => {
  //   onFilterChange({
  //     submittedAt: birthDateOption.value,
  //   })
  // }

  return (
    <>
      {filtersLoading ? (
        <Flex pt='20' justifyContent={'center'} w='full' h='full'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <HStack
          alignItems='flex-start'
          justifyContent='space-between'
          id='statisticsFilter'
          w='full'
          pt='8'
        >
          <HStack spacing='7'>
            <HStack alignItems='center' spacing='3'>
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text
                  width={'100%'}
                  height={'20px'}
                  fontSize='12px'
                  color='#5A738B'
                >
                  {t('FollowupFilter_FollowUp_Name')}
                </Text>
                <CustomSelect<Followup>
                  name='followups'
                  value={followupOptions.find(
                    followup => followup.id === filter.followupId,
                  )}
                  options={followupOptions}
                  onChange={onFollowupChange}
                  className='bold-value'
                  searchable={true}
                  isMulti={false}
                  placeholder={t('ResultFilter_Choose_FollowUp')}
                  minWidth='250px'
                  isDisabled={isDisabled}
                  maxWidth='250px'
                />
              </SimpleGrid>
            </HStack>
            <HStack alignItems='center' spacing='3'>
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text
                  width={'100%'}
                  height={'20px'}
                  fontSize='12px'
                  color='#5A738B'
                >
                  {t('ResultFilter_Area')}
                </Text>
                <CustomSelect
                  name='areas'
                  value={areaOptions.filter(area =>
                    filter.areaIds.includes(area.id),
                  )}
                  options={areaOptions}
                  onChange={onAreaChange}
                  searchable={true}
                  isMulti={true}
                  minWidth='250px'
                  placeholder={t('ResultFilter_Choose_Area')}
                  isDisabled={isDisabled}
                />
              </SimpleGrid>
            </HStack>
            <HStack alignItems='center' spacing='3'>
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text
                  width={'100%'}
                  height={'20px'}
                  fontSize='12px'
                  color='#5A738B'
                >
                  {t('ResultFilter_Preschool')}{' '}
                </Text>
                <CustomSelect
                  name='preschools'
                  value={preschoolOptions.filter(preschool =>
                    filter.preschoolIds.includes(preschool.preschoolId!),
                  )}
                  options={preschoolOptions}
                  onChange={onPreschoolChange}
                  searchable={true}
                  isMulti={true}
                  minWidth='250px'
                  placeholder={t('ResultFilter_Choose_Preschool')}
                  isDisabled={isDisabled}
                />
              </SimpleGrid>
            </HStack>

            {/* <HStack alignItems='center' spacing='3'>
        <SimpleGrid columns={1} row={2} gap={0}>
          <Text
            width={'100%'}
            height={'20px'}
            fontSize='12px'
            color='#5A738B'
          >
            Startdatum{' '}
          </Text>
          <CustomSelect
            name='dateOfBirth'
            value={startDateOptions.filter(
              option =>
                option.value.setHours(0, 0, 0, 0) ===
                filter.submittedAt.setHours(0, 0, 0, 0),
            )}
            options={startDateOptions}
            onChange={onBirthDateDateChange}
            searchable={true}
            minWidth='250px'
            placeholder='Välj startdatum'
            isDisabled={isDisabled}
          />
        </SimpleGrid>
      </HStack> */}

            {!isDisabled && (
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text width={'100%'} height={'20px'} fontSize='12px'></Text>
                <Button variant='ghost' onClick={resetFilter}>
                  {t('ResultFilter_Reset')}
                </Button>
              </SimpleGrid>
            )}
          </HStack>
        </HStack>
      )}
    </>
  )
}
