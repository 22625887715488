import { useToast } from '@chakra-ui/react'
import { useUser } from 'providers/UserProvider'
import { usePreschoolList } from 'services/preschoolService'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const usePreschool = () => {
  const {
    user: { organizationId },
  } = useUser()

  const { loading, error, response } = usePreschoolList({ organizationId })
  const toast = useToast()
  const history = useHistory()
  const { t } = useTranslation()
  if (error) {
    toast({
      title: `${t('Preschool_Load_Error_Text')}`,
      isClosable: true,
      duration: 3000,
      status: 'error',
    })
  }

  const handleOpenCreatePreschool = () => {
    history.push(`/preschool/create`)
  }

  return {
    response,
    loading,
    handleOpenCreatePreschool,
  }
}
