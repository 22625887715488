import { ChartData } from 'data-models/progression'
import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getProgressionGrouplevel } from 'services/followupResultService'

export const useProgressionGroup = (filter: any) => {
  const [stats, setStats] = useState({} as ChartData)

  const {
    user: { organizationId },
  } = useUser()

  const response = useAsync(
    () =>
      getProgressionGrouplevel(
        organizationId,
        filter.followups?.map(followup => followup.id),
        filter.areaIds,
        filter.preschoolIds,
        filter.departmentIds,
        filter.ages?.map(age => age.value),
      ),
    [filter],
  )

  const { value, loading } = response

  useEffect(() => {
    setStats(value ? value : ({} as ChartData))
  }, [filter, value])
  return { stats, loading }
}
