import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import { PlacementPoint } from 'data-models/task-models'
import { Center, Stack } from '@chakra-ui/react'
import ImagePlacementItem from './ImagePlacementItem'
import Ellipse from '../../../../../assets/images/Ellipse.svg'

export default function ImagePlacementPoint(props: {
  pp: PlacementPoint
  pi: any
  onChange: (point: PlacementPoint, item: string) => void
  text: number 
  taskIndex: number
}) {
  const currentPoint = props.pp
  const [newPoint, _setNewPoint] = useState(currentPoint as PlacementPoint)
  const [itemId, setNewItem] = useState('')


  const changeNewPoint = (item: any, _point: any) => {
    const placement = item.image.split('/assets/')[1] //.split('/')[1].split('-')[1].split('.')[0]
    const itemUrl = '/assets/' + placement
    setNewItem(itemUrl)
    if (placement.includes('mathematics')) {
      props.onChange(newPoint, placement.split('/')[1].split('.')[0])
    } else {
      props.onChange(
        newPoint,
        placement.split('/')[1].split('-')[1].split('.')[0],
      )
    }
  }
  const [{ isOver: _isOver, canDrop: _canDrop }, drop] = useDrop(
    () => ({
      accept: 'image',
      drop: (item, monitor) => changeNewPoint(item, monitor.getClientOffset()),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [newPoint, itemId],
  )

  /// fetch the answers and set item url for the palcedatpoint not null
  let text = itemId == '' ? props.text : ''
  if (props.pi.placedAtPoint == null) text = props.text
  if (props.pi.placedAtPoint == null) text = props.text
  const cSize = props.text * 20 + 'px'
  return (
    <Center
      width={typeof(props.text) === 'string' ? '100px' : '140px'}
      height='130px'
      // boxSize="85px"
      borderRadius='2xl'
      borderWidth={typeof(props.text) === 'string' ? 0 : '1px'}
      borderStyle='dashed'
      borderColor='white'
      color='rgba(255,255,255,0.25)'
      fontSize='48'
      fontWeight='semibold'
      ref={drop}
    >
      {/* {itemId == '' ? props.text : ''} */}
      {props.taskIndex != 7 && typeof(props.text) === 'number' ? (
        text
      )
      :
      props.taskIndex != 7 && typeof(props.text) === 'string' ? (
        <img src={String(text)} width={'60%'}/>
      )
      : (
        <Stack
          width={cSize}
          height={cSize}  
          style={{
            backgroundColor: 'rgba(255,255,255,0.25)',
            borderColor: 'white',
            borderRadius: '100%',
          }}
        ></Stack>
      )}
      {props.pi.placedAtPoint != null && <ImagePlacementItem ipa={itemId} />}

     
    </Center>
  )
}
