export interface Followup {
  id: string
  name: string
  tasks: []
  startDate: string
  endDate: string
  status: FollowupStatus
  numberOfSchools: number
  numberOfSubmissions: number
  numberOfParticipants: number
  createdAt: string
  description: string
  academicYear: string
  isExportable: boolean
  dataSource?: string
  taskIds?: string[]
  isDraft: boolean
}

export enum FollowupStatus {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  SUBMITTED = 'Submitted',
}

export interface FollowupsResponse {
  data: Followup[]
  totalCount: number
}

export interface Tasks {
  id: string
  title: string
  criteria: string
  description: string
}

export interface NewFollowup {
  name: string
  startDate: string
  endDate: string
  municipalityId: number
  dataSource: string
  taskIds: string[]
}

export const defaultFollowup: Followup = {
  name: 'Fall 2022',
  id: '1E72169F-EBA8-460C-9DE8-6CBDAAB818D1',
  startDate: '2022-10-03 00:00:00.0000000',
  endDate: '2022-11-30 00:00:00.0000000',
  tasks: [],
  createdAt: '2022-10-03 00:00:00.0000000',
  description: '',
  status: FollowupStatus.ACTIVE,
  academicYear: '22/23',
  isExportable: false,
  numberOfParticipants: 0,
  numberOfSchools: 0,
  numberOfSubmissions: 0,
  isDraft: false,
}

export const emptyFollowup: Followup = {
  id: '',
  name: '',
  tasks: [],
  taskIds: [],
  endDate: '',
  startDate: '',
  createdAt: '',
  isDraft: false,
  description: '',
  academicYear: '',
  numberOfSchools: 0,
  isExportable: false,
  numberOfSubmissions: 0,
  numberOfParticipants: 0,
  status: FollowupStatus.DRAFT,
}

export interface FollowupShape {
  id: string
  name: string
  tasks: []
  startDate: string
  endDate: string
  status: FollowupStatus
  numberOfSchools: number
  numberOfSubmissions: number
  numberOfParticipants: number
  createdAt: string
  description: string
  academicYear: string
  isExportable: boolean
  dataSource?: string
  taskIds: string[]
  organizationId: string
  isDraft: boolean
}

export type FollowupDTO = Partial<
  Followup & { taskIds: string[]; organizationId: string }
>
