import React from 'react'
import { Box } from '@chakra-ui/react'

import { FollowupFilter, FollowupTable } from './components'
import { useFollowupOverview } from './hooks/useFollowupOverview'

export const FollowupOverview = () => {
  const { filter, handleFilterChange, response, loading } =
    useFollowupOverview()

  return (
    <Box h='full' w='full'>
      <FollowupFilter filter={filter} onFilterChange={handleFilterChange} />
      <FollowupTable
        filter={filter}
        loading={loading}
        response={response}
        onFilterChange={handleFilterChange}
      />
    </Box>
  )
}
