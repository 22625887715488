import { UseToastOptions } from '@chakra-ui/toast'
import { ValueIteratee } from 'lodash'
import {
  partialRight,
  sum,
  toLower,
  trim,
  round,
  values,
  groupBy,
} from 'lodash-es'
import i18n from 'i18next';

export const skolanalysModules = {
  grundskola: i18n.language === 'se' ? 'Grundskola' :  i18n.language === 'de' ? 'Grundschule'  : 'Elementary school',
  forskola: i18n.language === 'se' ? 'Forskola' :  i18n.language === 'de' ? 'Vorschule' : 'Preschool' ,
  gymnasium: 'Gymnasium',
}

export const getInitials = (name: string) => {
  const first = (str: string) => str[0]
  return name.split(' ').map(first).join('')
}

export const formatDate = (date: Date | undefined) => {
  if (!date) {
    return ''
  }
  return date.toLocaleString('sv-SE', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

// *Math operations
export const round1 = partialRight(round, 1) as any // fix 'any' to something more type-correct https://github.com/DefinitelyTyped/DefinitelyTyped/issues/50418
export const round2 = partialRight(round, 2) as any
export const round3 = partialRight(round, 3) as any
export const round4 = partialRight(round, 4) as any

export const avgInteger = (arr: number[]) =>
  arr.length > 0 ? Math.round(sum(arr) / arr.length) : 0
export const avg = (arr: number[], decimals?: number) => {
  if (arr.length === 0) return 0
  return decimals
    ? +(sum(arr) / arr.length).toFixed(decimals)
    : sum(arr) / arr.length
}
export const avg3 = (arr: number[]) => round3(avg(arr))
export const avg2 = (arr: number[]) => round2(avg(arr))
export const avg1 = (arr: number[]) => round1(avg(arr))
// *Math operations

export const lowerTrim = (str?: string) => toLower(trim(str || ''))

export const successToast = (msg: string): UseToastOptions => ({
  status: 'success',
  title: msg,
  position: 'bottom-right',
})

export const errorToast = (msg: string): UseToastOptions => ({
  status: 'error',
  title: msg,
  position: 'bottom-right',
})

export const generateColor = () => {
  const hexValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F']
  let hex = '#'

  for (let i = 0; i < 6; i++) {
    const index = Math.floor(Math.random() * hexValues.length)
    hex += hexValues[index]
  }
  return hex
}

export type valueof<T> = T[keyof T]

export const keylessGroupBy = <T>(x: T[], fn: ValueIteratee<T>) =>
  values(groupBy(x, fn))

export const strToColour = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}





export const generateChartBarName = ({
  categoryId,
  name,
}: {
  categoryId: string
  name?: string
}) => {

  const multiPartCategories: Record<string, string> = {
    UnderstandsOthersExpressesEmotionsA: i18n.t('BlockTower_Legend_Text_A'),
    UnderstandsOthersExpressesEmotionsB: i18n.t('BlockTower_Legend_Text_B'),
  };

  const isMultiPart = Object.keys(multiPartCategories).includes(categoryId)

  return isMultiPart ? multiPartCategories[categoryId] : name
}
export const generateChartBarNameProgressionChart = ({
  categoryId,
  name,
}: {
  categoryId: string
  name?: string
}) => {

  const multiPartCategoriesProgressionChart: Record<string, string> = {
    UnderstandsOthersExpressesEmotionsA: i18n.t('BlockTower_XAxis_Text_A'),
    UnderstandsOthersExpressesEmotionsB: i18n.t('BlockTower_YAxis_Text_B'),
  }
  const isMultiPart = Object.keys(multiPartCategoriesProgressionChart).includes(categoryId)

  return isMultiPart ? multiPartCategoriesProgressionChart[categoryId] : name
}