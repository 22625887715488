import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useUser } from 'providers/UserProvider'
import { useResultsList } from 'services/followupResultService'
import { defaultFilter, ResultsStatsFilterType } from 'data-models'
import { useFollowups } from 'services/followupService'
import { useFilters } from 'providers/FilterProvider'

const FILTER_STORAGE_KEY = 'resultsFilterState';

export const useResultsOverview = () => {
  const { user } = useUser()
  const {
    response: { data: followups },
  } = useFollowups({ organizationId: user.organizationId })
  const { currentFollowupId } = useFilters()

  const { state: navigationState } = useLocation()

  //Get the saved filter data from session storage 
  const savedFilter = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_KEY) || 'null')|| defaultFilter;

  const [filter, setFilter] = useState<ResultsStatsFilterType>({
    ...savedFilter,
    followupId: currentFollowupId ? currentFollowupId : followups[0]?.id,
  })

  useEffect(()=>{
      sessionStorage.setItem(FILTER_STORAGE_KEY,JSON.stringify(filter));
  },[filter])

  const handleFilterChange = (newFilter: Partial<ResultsStatsFilterType>) => {
    setFilter({ ...filter, ...newFilter })
  }

  const {
    user: { organizationId },
  } = useUser()

  const {
    areaIds,
    followupId,
    preschoolIds,
    // submittedAt,
  } = filter

  const { loading, response: resultResponse } = useResultsList({
    organizationId,
    preschoolId: preschoolIds,
    areaId: areaIds,
    followupId,
    pageNumber: filter.pageNumber,
    pageSize: filter.pageSize,
    // submittedAt: submittedAt.toJSON(),
  })

  return {
    filter,
    loading,
    resultResponse,
    handleFilterChange,
    navigationState,
  }
}
