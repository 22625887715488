import React from 'react'
import { isNil } from 'lodash-es'
import { useFormContext } from 'react-hook-form'
import Select, { GroupBase, Props } from 'react-select'

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group>

export const FormSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  ...rest
}: SelectProps<Option, IsMulti, Group>) => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = !isNil(errors[name!])
  const controlStylesHasError = () =>
    hasError
      ? {
          borderColor: 'red',
          boxShadow: 'red',
        }
      : {}

  return (
    <Select
      {...rest}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          fontSize: '14px',
          color: 'red',
          lineHeight: '21px',
          borderRadius: '8px',
          borderColor: '#B1C5D9',
          ...controlStylesHasError(),
        }),
        option: baseStyles => ({
          ...baseStyles,
        }),
        container: (baseStyles, state) => ({
          ...baseStyles,
          minWidth: '200px',
        }),
        ...rest.styles,
      }}
      menuPlacement='auto'
    />
  )
}
