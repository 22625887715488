import React from 'react'
import { Text, Collapse, Box, Heading,Link,Flex } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'
import { ExternalLinkIcon } from '@chakra-ui/icons'


interface helpProps {
    helpDetailText: string,
    isOpen: boolean
}
const TaskHelpComponent = ({ helpDetailText, isOpen }: helpProps) => {
    const { t } = useTranslation()
    return (
        <Collapse in={isOpen} animateOpacity>
           <Box textAlign={'left'} bg='gray.100' mt='5' mb='5' rounded='md'  p='40px'>

            <Box mb={6}>
              <Text fontSize="lg" fontWeight="bold">{t('Learning_Help_Title')}</Text>
              <Text mt={2} lineHeight={1.4}>{t('Learning_Help_Text')}
              </Text>
            </Box>

            <Box mb={6}>
              <Text fontSize="lg" fontWeight="bold">{t('Knowledge_Help_Title')}</Text>
              <Text mt={2} lineHeight={1.4}>
                {t('Knowledge_Help_Text')}
              </Text>
            </Box>

            <Box>
              <Text fontSize="lg" fontWeight="bold">{t('Skills_Help_Title')}</Text>
              <Text mt={2} lineHeight={1.4}>
              {t('Skills_Help_Text')}
              </Text>
            </Box>

            <Flex mt='4'>
                <Text>
                    {t('Read_More_HelpText1')}
                </Text>
                <Link pl='1' fontWeight={700} color='purple' outline={'none'} href='https://myranappen.se/yearly-activities' isExternal>{t('Read_More_HelpText2')}<ExternalLinkIcon mx='4px' mb='2px'/></Link>
            </Flex>

          </Box>
          
        </Collapse>
    )
}

export default TaskHelpComponent
