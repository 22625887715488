import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Tr,
  Th,
  Td,
  Table,
  Thead,
  Tbody,
  Center,
  Spinner,
  Container,
  TableContainer,
} from '@chakra-ui/react'
import { Area } from 'data-models/area'
import { useTranslation } from 'react-i18next'

interface AreaTableProps {
  loading?: boolean
  areas?: Area[]
}

export const AreaTable = ({ loading, areas }: AreaTableProps) => {
  const history = useHistory()

  const handleEditArea = (area: Area) => {
    history.push(`/area/${area.id}`)
  }
  const { t } = useTranslation()

  return (
    <Container p='0' maxW='full'>
      {loading ? (
        <Center h='100px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        areas && (
          <>
            <TableContainer borderRadius='lg'>
              <Table variant='simple'>
                <Thead>
                  <Tr
                    bgColor={'rgba(177, 197, 217, 0.5)'}
                    borderWidth='1px'
                    borderRadius='10px'
                    height='48px'
                    fontWeight={'bold'}
                    fontFamily={`'Open Sans',sans-serif`}
                    fontSize={'12px'}
                  >
                    <Th>{t('ResultFilter_Area')}</Th>
                    <Th>{t('DashboardOverview_Number_of_Preschools')}</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {areas.map((area, index) => (
                    <Tr
                      key={`${area.id}_${index}`}
                      onClick={() => handleEditArea(area)}
                      cursor={'pointer'}
                      _hover={{
                        backgroundColor: '#EFF1FF',
                      }}
                    >
                      <Td>{area.name}</Td>
                      <Td>{area.numberOfPreschools}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )
      )}
    </Container>
  )
}
