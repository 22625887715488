import { emptyArea } from 'data-models/area'
import { useUser } from 'providers/UserProvider'
import { useHistory, useParams } from 'react-router-dom'
import { useAreaDetails } from 'services/areaService'

export const useAreaContainer = () => {
  const params = useParams()
  const { id } = params as any
  const history = useHistory()
  const {
    user: { organizationId },
  } = useUser()

  const { data: area = emptyArea, isInitialLoading } =
    useAreaDetails({
      areaId: id,
      organizationId,
    })
  

  if (id && !area?.id && !isInitialLoading) {
    history.push('/settings/area')
  }

  return {
    area,
    loading: isInitialLoading,
  }
}
