import { Task } from './task-models'
import { PaginatedSettings } from 'data-models'
import i18n from 'i18next';

export interface ResultInfo {
  id: string
  preschoolId: string
  preschoolName: string
  areaId: string
  areaName: string
  followupId: string
  followupName: string
  startDate: string
  endDate: string
  childCode: string
  individualCommentId: string
  individualComment: string
  teacherEmailAddress: string
  submittedAt: string
  childBirthDate: string
  childGender: number
  tasks: Task[]
}

export const emptyResult: ResultInfo = {
  id: '',
  preschoolId: '',
  preschoolName: '',
  areaId: '',
  areaName: '',
  followupId: '',
  followupName: '',
  startDate: '',
  endDate: '',
  childCode: '',
  individualCommentId: '',
  individualComment: '',
  teacherEmailAddress: '',
  submittedAt: '',
  childBirthDate: '',
  childGender: 1,
  tasks: [],
}

export interface ResultResponse {
  totalCount: number
  data: ResultInfo[]
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum GenderLabels {
  MALE = 'Pojke',
  FEMALE = 'Flicka',
}
export enum GenderLabelsEn {
  MALE = 'Boy',
  FEMALE = 'Girl',
}
export enum GenderLabelsDe {
  MALE = 'Junge',
  FEMALE = 'Mädchen',
}
export const GenderCodeToLabelMapping: Record<Gender, GenderLabels> = {
  [Gender.MALE]: GenderLabels.MALE,
  [Gender.FEMALE]: GenderLabels.FEMALE,
}
export const GenderCodeToLabelMappingEn: Record<Gender, GenderLabelsEn> = {
  [Gender.MALE]: GenderLabelsEn.MALE,
  [Gender.FEMALE]: GenderLabelsEn.FEMALE,
}
export const GenderCodeToLabelMappingDe: Record<Gender, GenderLabelsDe> = {
  [Gender.MALE]: GenderLabelsDe.MALE,
  [Gender.FEMALE]: GenderLabelsDe.FEMALE,
}

export const defaultFilter: ResultsStatsFilterType = {
  followupId: '',
  areaIds: [],
  preschoolIds: [],
  // submittedAt: new Date(),
  pageNumber: 1,
  pageSize: 25,
}

export interface ResultsStatsFilterType extends PaginatedSettings {
  followupId: string
  areaIds: string[]
  preschoolIds: string[]
  // submittedAt: Date
}
