import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import { ImagePlacementAnswer, PlacementPoint } from 'data-models/task-models'
import { Center } from '@chakra-ui/react'
import ImagePlacementItem from './ImagePlacementItem'

export default function ImagePlacementPoint(props: {
  pp: PlacementPoint
  pi?: ImagePlacementAnswer
  onChange: (point: PlacementPoint) => void
}) {
  const currentPoint = props.pp
  const [newPoint, _setNewPoint] = useState(currentPoint as PlacementPoint)
  // const currentAnswer = props.pi
  // const [answer, setAnswer] = useState(currentAnswer as ImagePlacementAnswer)

  const changeNewPoint = (item: any, _point: any) => {
    const placement = item.image.split('/assets/')[1].split('.')[0]
    let itemId = ''
    switch (placement) {
      case 'Nose':
        itemId = 'nose'
        break
      case 'Lips':
        itemId = 'lips'
        break
      case 'Left-Ear':
        itemId = 'ear_left'
        break
      case 'Right-Ear':
        itemId = 'ear_right'
        break
      case 'Right-Eye':
        itemId = 'eye_right'
        break
      case 'Left-Eye':
        itemId = 'eye_left'
        break
      default:
        itemId = ''
    }
    if (newPoint.id == itemId) props.onChange(newPoint)
  }
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'image',
      drop: (item, monitor) => changeNewPoint(item, monitor.getClientOffset()),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [newPoint],
  )

  // useEffect(() => {
  //   props.onChange(newPoint)
  // }, [newPoint])

  const bgColor = canDrop
    ? isOver
      ? 'rgba(255,255,255,0.2)'
      : 'rgba(255, 80, 153, 0.2)'
    : 'transparent'

  const pointSizePX = 75
  const offset = pointSizePX / 2.5

  return (
    <Center
      key={newPoint.id}
      boxSize={pointSizePX + 'px'}
      bgColor={bgColor}
      pos='absolute'
      borderRadius='full'
      top={newPoint.y - offset + 'px'}
      left={newPoint.x - offset + 'px'}
      ref={drop}
    >
      {props.pi && <ImagePlacementItem ipa={props.pi} />}
    </Center>
  )
}
