import React from 'react'
import { Controller } from 'react-hook-form'
import { AgeType, LoginType } from 'data-models'
import {
  Text,
  Flex,
  Grid,
  Input,
  VStack,
  Divider,
  GridItem,
  FormControl,
} from '@chakra-ui/react'
import { FormWrapper } from 'common/Form'
import { FormSelect } from 'common/FormSelect'
import { SystemSettingContainer } from './OrganizationDetails.layout'
import { useOrganizationDetails } from './hooks'
import { useTranslation } from 'react-i18next'

export const OrganizationDetails = () => {
  const {
    formMethods,
    isSuperAdmin,
    municipalityOptions,
    organizationTypeOptions,
  } = useOrganizationDetails()

  const { control, register } = formMethods
  const { t } = useTranslation()
  return (
    <VStack w='full'>
      <VStack w='full' justify='start'>
        <Flex py='5' w='full' justify='start'>
          <Text fontSize='lg' color='darkIndigo' fontWeight='bold'>
            {t('Overview')}
          </Text>
        </Flex>
        <Grid w='full' gridTemplateColumns='repeat(3, 1fr)' gridGap={6}>
          <GridItem>
            <FormWrapper
              name='organizationNumber'
              label={t('Org_Num')}
            >
              <Input
                type='text'
                isDisabled={!isSuperAdmin}
                placeholder={t('Org_Num')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor='white'
                color='#1A2C3D'
                border='1px'
                borderColor='#B1C5D9'
                h='40px'
                w='full'
                borderRadius='lg'
                {...register('organizationNumber')}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='name' label={t('Name')} isRequired>
              <Input
                type='text'
                isDisabled={!isSuperAdmin}
                placeholder={t('Org_Name')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor='white'
                color='#1A2C3D'
                border='1px'
                borderColor='#B1C5D9'
                h='40px'
                w='full'
                borderRadius='lg'
                {...register('name')}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='type' label={t('Type')} isRequired>
              <Controller
                control={control}
                name='type'
                render={({ field: { onChange, value, name } }) => (
                  <FormSelect
                    name={name}
                    isDisabled={!isSuperAdmin}
                    options={organizationTypeOptions}
                    placeholder={t('Type')}
                    value={organizationTypeOptions.find(
                      option => option.value === value,
                    )}
                    onChange={val => onChange(val?.value)}
                  />
                )}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='address1' label={`${t('Address')} 1`}>
              <Input
                type='text'
                isDisabled={!isSuperAdmin}
                placeholder={`${t('Address')} 1`}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor='white'
                color='#1A2C3D'
                border='1px'
                borderColor='#B1C5D9'
                h='40px'
                w='full'
                borderRadius='lg'
                {...register('address1')}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='zipCode' label={t('ZipCode')}>
              <Input
                type='number'
                isDisabled={!isSuperAdmin}
                placeholder={t('ZipCode')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor='white'
                color='#1A2C3D'
                border='1px'
                borderColor='#B1C5D9'
                h='40px'
                w='full'
                borderRadius='lg'
                {...register('zipCode')}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='city' label={t('City')}>
              <Input
                type='text'
                isDisabled={!isSuperAdmin}
                placeholder={t('City')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor='white'
                color='#1A2C3D'
                border='1px'
                borderColor='#B1C5D9'
                h='40px'
                w='full'
                borderRadius='lg'
                {...register('city')}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormControl isRequired>
              <FormWrapper isRequired name='municipalityId' label={t('Municipality')}>
                <Controller
                  control={control}
                  name='municipalityId'
                  render={({ field: { onChange, value, name } }) => (
                    <FormSelect
                      name={name}
                      isDisabled={!isSuperAdmin}
                      options={municipalityOptions}
                      placeholder={t('Municipality')}
                      value={municipalityOptions.find(
                        option => option.value === value,
                      )}
                      onChange={val => onChange(val?.value)}
                    />
                  )}
                />
              </FormWrapper>
            </FormControl>
          </GridItem>
        </Grid>
      </VStack>
      <Divider borderBottomWidth={2} py='5' orientation='horizontal' />
      <VStack w='full'>
        <Flex pt='10' w='full' justify='start'>
          <Text fontSize='lg' color='darkIndigo' fontWeight='bold'>
            {t('Configuration')}
          </Text>
        </Flex>
        <SystemSettingContainer
          name='ageType'
          label={t('Enter_Age_DOB')}
          description={t(
            'Choose_Org_Supports_Age_DOB',
          )}
          options={[
            { value: AgeType.AGE, label: `${t('Age')}` },
            {
              value: AgeType.BIRTH_DATE,
              label: `${t('ResultsTable_DOB')}`,
            },
          ]}
          isDisabled={!isSuperAdmin}
        />
        <SystemSettingContainer
          name='loginType'
          label={t('Login_Type')}
          description={t(
            'Login_Type_For_Org',
          )}
          options={[
            { value: LoginType.SSO, label: 'SSO' },
            { value: LoginType.CUSTOM, label: `${t('Custom_Login')}` },
          ]}
          isDisabled={!isSuperAdmin}
        />
        <SystemSettingContainer
          name='showChildCode'
          label={t('Child_alias')}
          description={t('Choose_Org_Support_Child_Alias')}
          options={[
            { value: true, label: ` ${t('Yes')}` },
            { value: false, label: `${t('No')}` },
          ]}
          isDisabled={!isSuperAdmin}
        />
        <SystemSettingContainer
          name='hasMultipleSections'
          label={t('Section')}
          description={t(
            'Choose_Org_Support_Dept',
          )}
          options={[
            { value: true, label: ` ${t('Yes')}` },
            { value: false, label: `${t('No')}` },
          ]}
          isDisabled={!isSuperAdmin}
        />
         <SystemSettingContainer
          name='hasLanguageAnalysis'
          label={t('Language_Analysis_Text')}
          description={t(
            'Choose_Org_Support_Language_Analysis',
          )}
          options={[
            { value: true, label: ` ${t('Yes')}` },
            { value: false, label: `${t('No')}` },
          ]}
          isDisabled={!isSuperAdmin}
        />
      </VStack>
    </VStack>
  )
}
