import React from 'react'
import { ImagePlacementTask } from 'data-models/task-models'
import ImagePlacementItem from './ImagePlacementItem'
import { Center, SimpleGrid } from '@chakra-ui/react'

export default function ImagePlacementItems(props: { task: any }) {
  const currentTask = props.task
  const task = currentTask as ImagePlacementTask

  return (
    <SimpleGrid columns={3} spacing='5px'>
      {task.answerOptions
        .filter(ao => !ao.placedAtPoint)
        .map(ao => (
          <Center
            key={ao.imageUrl}
            bgColor='white'
            boxSize='85px'
            borderRadius='2xl'
          >
            <ImagePlacementItem ipa={ao} />
          </Center>
        ))}
    </SimpleGrid>
  )
}
