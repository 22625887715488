import { useEffect, useState } from 'react'
import { Municipality } from 'data-models'
import axiosAPIInstance from './api'

export const useMunicipalityService = () => {
  const [municipalities, setMunicipalities] = useState<Municipality[]>([])

  const getMunicipalities = () =>
    axiosAPIInstance
      .get(`/municipalities`, {})
      .then(({ data }) => data as Municipality[])

  const refreshMunicipalities = async () => {
    const response = await getMunicipalities()
    if (response && response.length > 0) {
      setMunicipalities(response)
    }
  }

  useEffect(() => {
    if (municipalities.length === 0) {
      refreshMunicipalities()
    }
  }, [])

  return {
    municipalities,
    setMunicipalities,
  }
}
