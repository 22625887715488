import React from 'react'
import { FormProvider } from 'react-hook-form'

import { Flex } from '@chakra-ui/react'
import { EditButton } from '../common/EditButton'
import { ActionsMenu } from '../common/ActionsMenu'
import { CreateButton } from '../common/CreateButton'
import { DeleteButton } from '../common/DeleteButton'
import { AreaDetails } from './AreaDetails'
import { useAreaEditor } from './hooks/useAreaEditor'
import { Area } from 'data-models/area'
import { DetailsWrapper } from '../common/EntityViewer.layout'
import { useTranslation } from 'react-i18next'

interface AreaEditorProps {
  isCreate: boolean
  area: Area
}

export const AreaEditor = ({ isCreate, area }: AreaEditorProps) => {
  const {
    isAdmin,
    handleEdit,
    formMethods,
    handleCreate,
    handleDelete,
    isEditLoading,
    isCreateLoading,
    isDeleteLoading,
  } = useAreaEditor({
    initialArea: area,
  })
  const { t, i18n } = useTranslation()

  return (
    <Flex w='full' h='fit-content' bg='white'>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} noValidate={true}>
          <ActionsMenu
            name={area?.areaName || i18n.language === 'se' 
            ? 'Område' 
            : i18n.language === 'de' 
            ? 'Bereich' 
            : 'Area'}
          >
            {isAdmin && (
              <>
                {isCreate ? (
                  <CreateButton
                    onClick={handleCreate}
                    isLoading={isCreateLoading}
                  />
                ) : (
                  <>
                    <DeleteButton
                      entityName='Område'
                      onDelete={handleDelete}
                      isLoading={isDeleteLoading}
                    />
                    <EditButton
                      onClick={handleEdit}
                      isLoading={isEditLoading}
                    />
                  </>
                )}
              </>
            )}
          </ActionsMenu>

          <DetailsWrapper>
            <AreaDetails area={area} />
          </DetailsWrapper>
        </form>
      </FormProvider>
    </Flex>
  )
}
