import React, { useEffect, useRef } from 'react'
import { Button, Flex, HStack, VStack, Text } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useRegisterAvesta } from './hooks'
import { useTranslation } from 'react-i18next'

const invalidCredentialsErrorMessage = 'Registreringen misslyckades'

export const RegisterAvesta = () => {
  const { handleRegister, authError, isLoading } = useRegisterAvesta()
  const { t } = useTranslation()
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click()
    }
  }, [])
  return (
    <Flex w='full' h='full'>
      <VStack w='full' alignItems='stretch'>
        <HStack ml='5' w='full' justifyContent='center' paddingTop='20px'>
          <Button
            variant={'solid'}
            type='submit'
            ref={buttonRef}
            onClick={handleRegister}
            rightIcon={<ArrowForwardIcon boxSize='6' />}
            isLoading={isLoading}
          >
            {t('Register_Text')}
          </Button>
        </HStack>
        {authError && (
          <HStack w='full' justifyContent='center' pt='4'>
            <Text fontWeight='light' color='red'>
              {invalidCredentialsErrorMessage}
            </Text>
          </HStack>
        )}
      </VStack>
    </Flex>
  )
}
