import { useFormContext } from 'react-hook-form'
import { OrganizationType, UserRole } from 'data-models'
import { useMunicipalityService } from 'services/municipalityService'
import { useUser } from 'providers/UserProvider'
import { useTranslation } from 'react-i18next'

export const useOrganizationDetails = () => {
  const formMethods = useFormContext()
  const { user } = useUser()

  const isSuperAdmin = [UserRole.SUPERADMIN].includes(user.role)

  const { municipalities } = useMunicipalityService()
  const municipalityOptions = municipalities.map(municipality => ({
    value: municipality.id,
    label: municipality.name,
  }))
  const { t, i18n } = useTranslation();
  const organizationTypeOptions = [
    {
      value: OrganizationType.KOMMUN,
      label: i18n.language === 'se' ? 'Kommun' : i18n.language === 'de' ? 'Gemeinde' : 'Municipality',

    },
    {
      value: OrganizationType.FORSKOLA,
      label: i18n.language === 'se' ? 'Förskola' : i18n.language === 'de' ? 'Vorschule' : 'Preschool',

    },
  ]

  return {
    formMethods,
    municipalityOptions,
    organizationTypeOptions,
    isSuperAdmin,
  }
}
