import React from 'react'
import { FormProvider } from 'react-hook-form'

import { Flex } from '@chakra-ui/react'

import { User } from 'data-models'
import { useUserEditor } from './hooks'
import { UserDetails } from './UserDetails'
import { EditButton } from '../common/EditButton'
import { ActionsMenu } from '../common/ActionsMenu'
import { CreateButton } from '../common/CreateButton'
import { DeleteButton } from '../common/DeleteButton'
import { DetailsWrapper } from '../common/EntityViewer.layout'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

interface UserEditorProps {
  user: User
  isCreate: boolean
}

export const UserEditor = ({ isCreate, user }: UserEditorProps) => {
  const {
    isAdmin,
    handleEdit,
    formMethods,
    handleCreate,
    handleDelete,
    isEditLoading,
    isCreateLoading,
    isDeleteLoading,
  } = useUserEditor({
    initialUser: user,
  })
  const { t } = useTranslation()
  return (
    <Flex w='full' h='fit-content' bg='white'>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} noValidate={true}>
          <ActionsMenu name={user?.name || i18n.t('User')}>
            {isAdmin && (
              <>
                {isCreate ? (
                  <CreateButton
                    onClick={handleCreate}
                    isLoading={isCreateLoading}
                  />
                ) : (
                  <>
                    <DeleteButton
                      entityName={t('User')}
                      onDelete={handleDelete}
                      isLoading={isDeleteLoading}
                    />
                    <EditButton
                      onClick={handleEdit}
                      isLoading={isEditLoading}
                    />
                  </>
                )}
              </>
            )}
          </ActionsMenu>

          <DetailsWrapper>
            <UserDetails user={user} />
          </DetailsWrapper>
        </form>
      </FormProvider>
    </Flex>
  )
}
