import { UseToastOptions } from '@chakra-ui/react'

export const successToast = (msg: string): UseToastOptions => ({
  status: 'success',
  title: msg,
  position: 'bottom-right',
})

export const errorToast = (msg: string): UseToastOptions => ({
  status: 'error',
  title: msg,
  position: 'bottom-right',
})

export const jsonCopy = (x: any) => JSON.parse(JSON.stringify(x))

export const secondsToMMSS = (x: number = 0) =>
  new Date(x * 1000).toISOString().substr(14, 5)

export const checkboxAnswerOption = {
  ChildParticipate: 'childParticipate',
}

export const calculateAcademicYear = (date: Date) => {
  const year = date.getMonth() < 9 ? date.getFullYear() : date.getFullYear() + 1
  const lastyear =
    date.getMonth() < 9 ? date.getFullYear() - 1 : date.getFullYear()
  let academicYear = ''
  academicYear = lastyear.toString().slice(-2) + '/' + year.toString().slice(-2)
  return academicYear
}

export const calculateAcademicYearBasedOnStartDate = (date: Date) => {
  const year = date.getMonth() < 9 ? date.getFullYear() - 1 : date.getFullYear()
  const lastYear =
    date.getMonth() < 9 ? date.getFullYear() : date.getFullYear() + 1
  let academicYear = ''
  academicYear = year.toString().slice(-2) + '/' + lastYear.toString().slice(-2)
  return academicYear
}

export const decapitalizeFirstLetter = (val: string) =>
  val.slice(0, 1).toLowerCase().concat(val.slice(1))
