import React, { useEffect, useState } from 'react'
import TaskTag from '../TaskTag'
import {
  Flex,
  Text,
  Image,
  Box,
  HStack,
  VStack,
  Center,
  Heading,
} from '@chakra-ui/react'
import { LanguageAnalysisTask} from 'data-models/task-models'
import infoImage from '../../../../../assets/images/information-circle.svg'
import { apiBase } from 'services/config'
import recordIcon from '../../../../../assets/images/Record_Button.svg'
import waveImage from '../../../../../assets/images/Wave.png'
import { useTranslation } from 'react-i18next'

export default function LanguageTask(props: { task: any }) {
  const currentTask = props.task

  const [task, setTask] = useState(currentTask as LanguageAnalysisTask)

const {t} = useTranslation();
 
  useEffect(() => {}, [task])

  return (
    <Flex direction='column' h='full' top={10} w='100%'>
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
      >
        <Box>
          <TaskTag text={task.description} />
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mt='3'
            lineHeight='1'
          >
            {task.title}
          </Heading>

          <Text fontWeight='semibold' mt='3' mb='1'>
            {task.subtitle}
          </Text>
        </Box>

<Box display='flex'>
        {task.imageUrl && (
          <Box width='70%' mb='10'>
            <Center
              my='5'
              flex='1'
              alignSelf='center'
              bg='#773FFD'
              width='100%'
              height='100%'
              borderRadius='xl'
            >
              <Image
                src={apiBase + task.imageUrl}
                // p={task.imageBorder === 'no' ? '0' : '10'}
                borderRadius='xl'
              />
            </Center>
          </Box>
        )}

    <Flex align="center" margin="0 auto" p="5" flexDir='column' justifyContent='center'>
          <Box ml={6} >
            <Image
              src={recordIcon}
              cursor="pointer"
            />
          </Box>
         
          <Box ml={6}>
            <Text>{t('Speak')}</Text>
          </Box>
        
        </Flex>
        </Box>
        <VStack
          pb={'6'}
          pt='1'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5' p="2">
            <Image src={infoImage} />
            <Text fontSize='sm'>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>

    
    </Flex>
  )
}
