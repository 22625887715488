import React from 'react'
import { Flex, HStack, VStack } from '@chakra-ui/react'
import { FormSelect } from 'common/FormSelect'
import { SelectOption } from 'data-models'
import { Controller, useFormContext } from 'react-hook-form'
import { FormWrapper } from 'common/Form'
import { BsInfoCircle } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

interface SystemSettingContainerProps {
  name: string
  label: string
  options: SelectOption[]
  description: string
  isDisabled?: boolean
}

export const SystemSettingContainer = ({
  name,
  label,
  options,
  description,
  isDisabled = false,
}: SystemSettingContainerProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  return (
    <>
      <VStack w='full' alignItems='flex-start' pt='10'>
        <Flex>
          <FormWrapper name={name} label={label} isRequired>
            <Controller
              control={control}
              name={name}
              render={({ field: { onChange, value, name } }) => (
                <FormSelect
                  isDisabled={isDisabled}
                  styles={{
                    container: (baseStyles, state) => ({
                      ...baseStyles,
                      minWidth: '300px',
                    }),
                  }}
                  name={name}
                  options={options}
                  placeholder={t('Choose_Alternative')}
                  value={options.find(option => option.value === value)}
                  onChange={val => onChange(val?.value)}
                />
              )}
            />
          </FormWrapper>
        </Flex>
        <HStack>
          <BsInfoCircle color='#3343d4' size={'24px'} />
          <Flex>{description}</Flex>
        </HStack>
      </VStack>
    </>
  )
}
