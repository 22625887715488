import React from 'react'
import { Redirect } from 'react-router-dom'

import { UserRole } from 'data-models'
import { VStack } from '@chakra-ui/react'
import { useUser } from 'providers/UserProvider'
import { useOrganization } from './hooks/useOrganization'
import { OrganizationStats, OrganizationTable } from './components'

export const Organization = () => {
  const { response, loading } = useOrganization()
  const { user } = useUser()

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  if (!isAdmin) {
    return <Redirect to='/settings/my-profile' />
  }

  return (
    <VStack w='full' spacing={'12'}>
      <OrganizationStats {...response} loading={loading} />
      <OrganizationTable {...response} loading={loading} />
    </VStack>
  )
}
