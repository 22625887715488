import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    brandPrimary: {
      50: '#e1f1ff',
      100: '#b4d1ff',
      200: '#85b2f9',
      300: '#5694f5',
      400: '#2975f2',
      500: '#125bd8',
      600: '#0a47a9',
      700: '#03337a',
      800: '#001e4b',
      900: '#000a1e',
    },
    pink: {
      DEFAULT: '#FF5099',
      50: '#ff1041',
      100: '#fff0f6',
      200: '#FFE9F2',
      300: '#FFB6D4',
      400: '#FF83B7',
      500: '#FF5099',
      600: '#FF1D7B',
      700: '#E90061',
      800: '#B6004C',
      900: '#830037',
    },
    mainBlue: {
      50: '#dee4f2',
      500: '#277aff',
      700: '#41567e',
      900: '#2a244d',
    },
    indigo: '#211761',
    darkIndigo: '#2a0f74',
    blue: '#3343d4',
    purplishBlue: '#773ffd',
    error: '#db2269',
    buttonPurple: '#8A2BE2',
    lightBlue: '#B1C5D9',
  },

  styles: {
    global: {
      body: {
        fontFamily: `'Open Sans',sans-serif`,
        lineHeight: '1',
      },
    },
  },

  components: {
    Input: {
      defaultProps: {
        focusBorderColor: '#892BE2',
      },
      variants: {
        outline: {
          field: {
            h: '60px',
            w: '427px',
            bgColor: 'white',
            borderColor: 'gray.500',
            color: '',
            borderRadius: '100',
            bgImage: '',
            fontFamily: `'Catamaran',sans-serif`,
          },
        },
      },
    },

    Textarea: {
      baseStyle: {
        minHeight: '120px',
        fontFamily: `'Catamaran',sans-serif`,
        fontSize: '16px',
      },

      variants: {
        outline: {
          bgColor: 'indigo',
          borderColor: 'transparent',
          color: 'white',
          borderRadius: 'xl',
        },
      },
    },

    Button: {
      baseStyle: {
        color: 'white',
        borderRadius: '40px',
        fontSize: 'sm',
        // _focus: { boxShadow: 'brandPrimary.400' },
      },
      variants: {
        solid: {
          bg: 'buttonPurple',
          boxShadow: 'md',
          fontSize: '20px',
          fontWeight: 'semibold',
          h: '60px',
          px: '20px',
          _focus: { boxShadow: 'brandPrimary.400' },
          _hover: {
            bg: 'buttonPurple',
            opacity: 0.8,
            _disabled: {
              bg: 'buttonPurple',
              opacity: 0.3,
            },
          },
          _active: {
            bg: 'buttonPurple',
          },
        },
        confirmation: {
          bg: 'pink.500',
          boxShadow: 'md',
          fontSize: '20px',
          fontWeight: 'semibold',
          h: '60px',
          px: '20px',
          _focus: { boxShadow: 'brandPrimary.400' },
          _hover: {
            bg: '#EF3A86',
            _disabled: {
              bg: 'pink.200',
            },
          },
          _active: {
            bg: '#DC1F6E',
          },
        },
        outline: {
          borderColor: '#3443D5',
          color: '#3443D5',
          _hover: {
            bg: 'rgba(177, 197, 217, 0.5)',
            borderColor: '#3443D5',
            _disabled: {
              borderColor: 'brandPrimary.200',
            },
          },
          _active: {
            bg: 'brandPrimary.100',
            borderColor: 'brandPrimary.500',
          },
        },
        ghost: {
          color: 'mainBlue.500',
        },
      },
    },

    Tabs: {
      baseStyle: {
        tabpanel: {
          p: 0,
        },
      },
      variants: {
        line: {
          tab: {
            _focus: { boxShadow: 'none' },
            _selected: {
              fontWeight: 'bold',
              color: '#2a244d',
              borderColor: '#3343D4',
              borderBottomWidth: '4px',
            },
          },
        },
      },
    },

    Select: {
      baseStyle: {
        field: {
          bgColor: 'darkIndigo',
        },
      },
      variants: {
        filled: {
          field: {
            borderRadius: 'lg',
            _hover: {
              bgColor: 'darkIndigo',
              _disabled: {
                bgColor: 'pink.200',
              },
            },
            _focusVisible: {
              bgColor: 'darkIndigo',
            },
          },
        },
      },
    },

    Heading: {
      baseStyle: {
        fontFamily: `'Open Sans',sans-serif`,
      },
    },

    Checkbox: {
      baseStyle: {
        icon: {
          height: '100%',
          width: '100%',
          color: '#fff',
          backgroundColor: '#3443D5',
        },
        control: {
          boxSize: '18px',
          borderColor: 'gray.300',
        },
      },
    },

    Table: {
      variants: {
        mytable: {
          tr: {
            _hover: {
              background: '#D9D9D9',
            },
          },
        },
      },
    },
  },
})

export default theme
