import { useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserRole } from 'data-models'
import { EditProfilePasswordShape } from 'data-models/profile'
import { useUser } from 'providers/UserProvider'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { AuthService } from 'services/authService'
import { resetOwnPassword } from 'services/idpService'
import { editProfilePasswordSchema } from '../schema'
import { useTranslation } from 'react-i18next'

export const useProfilePassword = () => {
  const { user } = useUser()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  const formMethods = useForm<EditProfilePasswordShape>({
    defaultValues: {
      oldPassword: '',
      confirmNewPassword: '',
      newPassword: '',
    },
    resolver: yupResolver(editProfilePasswordSchema),
  })

  const { handleSubmit } = formMethods
  const { t } = useTranslation()
  const submitHandler = async (values: EditProfilePasswordShape) => {
    try {
      setIsLoading(true)
      await AuthService.checkPassword({ password: values.oldPassword })
      await resetOwnPassword({
        id: user.id,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
      toast({
        title: `${t('Password_Changed_Success_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
    } catch (error) {
      toast({
        title: `${t('Change_Password_Failed')}`,
        description: `${t('Check_if_the_current_password_is_correct')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }
  const onSubmit = handleSubmit(submitHandler)

  return {
    isAdmin,
    onSubmit,
    isLoading,
    formMethods,
  }
}

