import React from 'react'
import { Box, Flex, HStack, VStack } from '@chakra-ui/layout'
import { Image, Text, useColorModeValue } from '@chakra-ui/react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Logo from '../../assets/images/Logo-InDarkBG.svg'
import LinkedIn from '../../assets/images/ico-linkedin.svg'
import Insta from '../../assets/images/ico-instagram.svg'
import FB from '../../assets/images/ico-facebook.svg'
import { portalUrl } from 'services/config'
import Login from './Login'
import LanguageSwitcher from 'common/LanguageSwitcher'
import { useTranslation } from 'react-i18next'
import { RegisterAvesta } from './Register'
import { CreateUserFromInvite } from './Invite'
import { LoginAvesta } from './LoginAvesta'
import Circles from 'assets/images/Circles-bg.svg'
import { useLocation } from 'react-router-dom'

export default function Unauthenticated() {
  const { t } = useTranslation()
  const languages: string[] = ['se', 'en','de']
  const buttonBgColor = useColorModeValue('#7240fa', '#7240fa')
  const circlesUrl = `url(${Circles})`
  const location = useLocation()
  const isInPath = location.pathname !== '/login/sso'
  return (
    <VStack
      w='full'
      height='100%'
      position='relative'
      bgGradient='linear(to-b, purplishBlue, blue)'      
    >
      <HStack alignItems='center' h='100%' width='100%'>
      {isInPath && (
        <>
        <VStack
          h='full'
          width='45%'
          justifyContent='center'
          position='relative'
          bgColor='darkIndigo'
          borderColor='rgba(255, 255, 255, .3)'
        >
          <Box lineHeight='1.6' pos='relative' zIndex='1'>
            <Image src={Logo} htmlWidth='156px'
                  htmlHeight='125px' />
            <Text fontSize='20px' fontWeight='semibold' color='white'>
              {t('Login_Title')}
            </Text>
            <Text fontSize='12px' fontWeight='light' color='white'>
              {t('Login_Second_Line')}
            </Text>
            <Text
              mt='7'
              width='380px'
              fontSize='12px'
              fontWeight='light'
              color='white'
            >
              {t('Login_Paragraph_PartOne')}
              <br></br>
              {t('Login_Paragraph_PartTwo')}
            </Text>
          </Box>
        </VStack>
        
        </>)}
        <VStack
          h='full'
          width='65%'
          justifyContent='center'         
          bgImage={circlesUrl}
          bgPosition='right center'
          bgSize='50%'
          bgRepeat='no-repeat'                            
        >
          <Box>
            <Switch>
              <Route path='/login/avesta' exact component={LoginAvesta} />
              <Route path='/login/sso' exact component={LoginAvesta} />
              <Route path='/login' component={Login} />
              <Route
                path='/activate/avesta/:activationToken'
                component={RegisterAvesta}
              />
              <Route
                path='/activate/:activationToken'
                component={CreateUserFromInvite}
              />
              <Route path='/' render={() => <Redirect to='/login' />} />
            </Switch>
          </Box>
        </VStack>
      </HStack>
      {isInPath && (
      <HStack
        position='absolute'
        bottom='2'
        alignItems='center'
        width='100%'
        bgColor='transparent'
      >
        <VStack h='full' justifyContent='center' width='45%'>
          <Box lineHeight='1.6' pos='relative' zIndex='1'>
            <Flex width='380px'>
              <Text mt={-1} fontSize='12px' fontWeight='light' color={'white'}>
                {t('Follow_Us')}&nbsp;&nbsp;
              </Text>
              <a
                href='https://www.facebook.com/profile.php?id=100090936694050'
                target='_blank'
                rel='noreferrer'
              >
                <img src={FB} alt='' />
              </a>
              &nbsp;&nbsp;
              <a
                href='https://www.instagram.com/myranappen_se/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={Insta} alt='' />
              </a>
              &nbsp;&nbsp;
              <a
                href='https://www.linkedin.com/company/myranappen/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={LinkedIn} alt='' />
              </a>
            </Flex>
          </Box>
        </VStack>
        <VStack h='full' justifyContent='center' width='65%'>
          <Box>
            <Flex>
              <Text fontSize='14px' fontWeight='light' color='white'>
                <a
                  href='https://myranappen.se/'
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('Website')}
                </a>
              </Text>
              <Text fontSize='14px' fontWeight='light'>
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </Text>
              <Text fontSize='14px' fontWeight='light' color='white'>
                <a href={portalUrl} target='_blank' rel='noreferrer'>
                  {t('Portal')}
                </a>
              </Text>
            </Flex>
          </Box>
        </VStack>
      </HStack>
      )}
      <Flex
        justifyContent='flex-end'
        alignItems='flex-start'
        pos='absolute'
        top='54px'
        right='63px'
        zIndex='1'
      >
        
        <LanguageSwitcher
          languages={languages}
          textColor='white'
          bgColor={buttonBgColor}
          globColor={true}
          activeColor='rgb(142, 102, 238)'
          dropactiveColor='#9e7df4'        
          bgDrop='#7653f4'
          menuItemColor='white'
          borderWidth='none'            
          buttonBorderWidth='1px'
          borderRadius="50px"  
          menuListBorderRadius='0px'                
        />
      </Flex>
    </VStack>
  )
}
