import * as yup from 'yup'
import i18n from 'i18next';

export const resetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required(i18n.t('New_Password_Text'))
    .min(8, i18n.t('Atleast_8_Char'))
    .matches(/(?=.*[a-z])(?=.*[A-Z])/, i18n.t('Both_Capital_Small_Letters'))
    .matches(/(?=.*[0-9])/, i18n.t('One_Numeric_Char'))
    .matches(
      /(?=.*[^A-Za-z0-9])/,
      i18n.t('One_AlphaNumeric_Char'),
    ),
  confirmPassword: yup
    .string()
    .required(i18n.t('Type_Confirm_Password'))
    .oneOf([yup.ref('newPassword')], i18n.t('Password_Match_Text')),
})
