import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Department, UserRole } from 'data-models'
import { useDisclosure } from '@chakra-ui/react'

import { useMunicipalityService } from 'services/municipalityService'
import { useAreasByMunicipality } from 'services/areaService'
import { useUser } from 'providers/UserProvider'

export const usePreschoolDetails = () => {
  const formMethods = useFormContext()
  const { user } = useUser()
  const [selectedDepartment, setSelectedDepartment] = useState<Department>()

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)
  const isSuperAdmin = [UserRole.SUPERADMIN].includes(user.role)

  const {
    isOpen: isEditDepartmentOpen,
    onOpen: onEditDepartmentOpen,
    onClose: onEditDepartmentClose,
  } = useDisclosure()

  const handleEditDepartment = (department: Department) => {
    if (isAdmin) {
      setSelectedDepartment(department)
      onEditDepartmentOpen()
    }
  }

  const { watch } = formMethods

  const { municipalities } = useMunicipalityService()
  const municipalityOptions = municipalities.map(municipality => ({
    value: municipality.id,
    label: municipality.name,
  }))

  const selectedMunicipalityId = watch('municipalityId') || ''

  const { response: areas } = useAreasByMunicipality({
    municipalityId: selectedMunicipalityId,
  })
  const areaOptions = areas.map(area => ({
    value: area.id,
    label: area.name,
  }))

  return {
    isAdmin,
    isSuperAdmin,
    formMethods,
    municipalityOptions,
    areaOptions,
    selectedDepartment,
    handleEditDepartment,
    isEditDepartmentOpen,
    onEditDepartmentClose,
  }
}
