import React from 'react'
import { useEffect, useState } from 'react'

import {
  Button,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  Box,
  VStack,
  Center,
} from '@chakra-ui/react'
import { useFilters } from 'providers/FilterProvider'
import { emptyFollowup, Followup } from 'data-models/followup'
import Preschool from 'data-models/preschool'
import { Area } from 'data-models/area'
import CustomSelect from 'common/CustomSelect'
import { defaultProgressionFilter } from '../Statistic'
import { useUser } from 'providers/UserProvider'
import { useFollowups } from 'services/followupService'
import { take, union } from 'lodash'
import { ProgressionFilterType } from './types'
import { SchoolYearOptions } from 'data-models/progression'
import { useAreas } from 'services/areaService'
import { usePreschoolList } from 'services/preschoolService'
import { Department, SelectOption } from 'data-models'
import { useTranslation } from 'react-i18next'
import { useDepartments } from 'services/departmentService'

interface DashboardFilterProps {
  filter: ProgressionFilterType
  onFilterChange: (newFilter: Partial<ProgressionFilterType>) => void
}

export const ProgressionFilters = ({
  filter,
  onFilterChange,
}: DashboardFilterProps) => {

  const { user } = useUser()
  const {
    response: { data: followups },
  } = useFollowups({ organizationId: user.organizationId })
  const { t } = useTranslation()
  const hasDepartments = sessionStorage.getItem('hasDepartments');
  const schoolYearOptions: SchoolYearOptions[] = []
  const academicYears = union(followups.map(f => f.academicYear))
  academicYears.forEach(year => {
    schoolYearOptions.push({ id: Number(year.split('/')[0]), name: year })
  })
  schoolYearOptions
    .filter(item => item.id)
    .sort((a, b) => (b.id as number) - (a.id as number))

  const { ages: ageOptions } = useFilters()

  const { response: areaOptions } = useAreas({
    organizationId: user.organizationId,
  })
  const { response: departmentOptions } = useDepartments({
    organizationId: user.organizationId,
  })
  const {
    response: { preschools: preschoolOptions },
  } = usePreschoolList({ organizationId: user.organizationId })

  const filteredPreschoolOptions =
    filter.areaIds.length > 0
      ? preschoolOptions.filter(preschool =>
          filter.areaIds.includes(preschool.areaId || ''),
        )
      : preschoolOptions

  const finalFilteredPreschoolOptions = filteredPreschoolOptions.map(
    preschool => {
      return {
        label: preschool.preschoolName || '',
        value: preschool.preschoolId || '',
      }
    },
  )

  defaultProgressionFilter.schoolYears = take(schoolYearOptions, 1)

  const [followUpOptions, _setFollowUpYears] = useState(followups)


  // function resetFilter() {
    
  //   onFilterChange({
  //     ...defaultProgressionFilter,
  //     areaIds: [],
  //     ages: [],
  //     schoolYears: take(schoolYearOptions, 1),
  //     followUpYears: take(
  //       followups.filter(followup => followup.status !== 'Draft' && followup.academicYear === filter.schoolYears.name),
  //       4,
  //     ),
  //     followups:
  //       take(
  //         followups.filter(followup => followup.status !== 'Draft'),
  //         4,
  //       ) || emptyFollowup,
  //     preschoolIds: [],
  //     departmentIds: [],
  //   })
  // }

  function resetFilter() {
    // Extract the selected school year names (assuming schoolYears is an array)
    const selectedSchoolYears = filter.schoolYears.map(schoolYear => schoolYear.name)

    onFilterChange({
      ...defaultProgressionFilter,
      areaIds: [],
      ages: [],
      schoolYears: take(schoolYearOptions, 1), // Keeping the first school year as default
      followUpYears: take(
        followups.filter(followup => 
          followup.status !== 'Draft' && 
          selectedSchoolYears.includes(followup.academicYear)
        ),
        4
      ),
      followups: take(
        followups.filter(followup => 
          followup.status !== 'Draft' && 
          selectedSchoolYears.includes(followup.academicYear)
        ),
        4
      ) || emptyFollowup,
      preschoolIds: [],
      departmentIds: [],
    })

    onSchoolYearChange(filter.schoolYears)
  }
  

  function onFollowupChange(selectedFollowup: Followup[]) {
   localStorage.setItem("load","true")
    onFilterChange({
      areaIds: [],
      ages: [],
      followUpYears: selectedFollowup,
      followups: selectedFollowup || emptyFollowup,
      preschoolIds: [],
    })
  }

  function onSchoolYearChange(selectedYears: any[]) {
    localStorage.setItem("load","true")
    const yearValues = selectedYears.map(selectedYear => selectedYear.name)
    onFilterChange({
     ...defaultProgressionFilter,
      schoolYears: selectedYears,
      followUpYears: take(
        followups.filter(
          followup =>
            yearValues.includes(followup.academicYear) &&
            followup.status !== 'Draft',
        ),
        4,
      ),
      followups: take(
        followups.filter(
          followup =>
            yearValues.includes(followup.academicYear) &&
            followup.status !== 'Draft',
        ),
        4,
      ),
    })

    _setFollowUpYears(
      followups.filter(
        followup =>
          yearValues.includes(followup.academicYear) &&
          followup.status !== 'Draft',
      ),
    )
  }

  const onAreaChange = (areas?: Area[]) => {
    localStorage.setItem("load","true")
    const areaIds = areas ? areas?.map(area => area.id) : []
    const preschools = preschoolOptions.filter(preschool =>
      filter.preschoolIds.includes(preschool.id || ''),
    )
    const preschoolIdsBelongingToAreas = preschools
      .filter(preschool => areaIds.includes(preschool.areaId || ''))
      .map(preschool => preschool.id!)

    onFilterChange({
      areaIds,
      preschoolIds: preschoolIdsBelongingToAreas,
      ages: [],
    })

    
  }

  const onDepartmentChange = (departments?: Department[]) => {
    const departmentIds = departments ? departments?.map(departments => departments.id) : []
    // const preschools = preschoolOptions.filter(preschool =>
    //   filter.preschoolIds.includes(preschool.id || ''),
    // )
    // const preschoolIdsBelongingToDepartments = preschools
    //   .filter(preschool => departmentIds.includes(preschool.departmentId || ''))
    //   .map(preschool => preschool.id!)

    onFilterChange({
      departmentIds
      // preschoolIds: preschoolIdsBelongingToDepartments,
    })
  }


  const onPreschoolChange = (options?: SelectOption[]) => {
    localStorage.setItem("load","true")
    onFilterChange({
      preschoolIds: options ? options.map(option => option.value) : [],
    })
  }


  
  useEffect(() => { 
    // localStorage.setItem('load','false')
     if(localStorage.getItem('load') === "false"){
    //  onSchoolYearChange(take(schoolYearOptions, 1))
      resetFilter();
   } 
  },[])


  return (
    <Stack
      paddingTop='30px'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      className='card'
      id='filter'
    >
      <HStack justifyContent={'space-between'} w='full'>
        <HStack spacing='4'>
          <Stack direction='row' alignItems='center' spacing='2'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('School_Year')}*
              </Text>
              <CustomSelect
                name='schoolYear'
                value={filter.schoolYears}
                options={schoolYearOptions}
                onChange={onSchoolYearChange}
                className='bold-value'
                isMulti={true}
                placeholder='--'
                minWidth='120px'
              />
            </SimpleGrid>
          </Stack>
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                overflow='hidden'
              >
                {t('FollowUp')} (max 4){' '}
              </Text>
              <CustomSelect
                name='followUpYears'
                value={filter.followUpYears}
                options={followUpOptions}
                onChange={onFollowupChange}
                isOptionDisabled={option =>
                  !filter.followUpYears
                    .map(followup => followup.id)
                    .includes(option.id) && filter.followUpYears.length >= 4
                }
                className='bold-value customStyle'
                searchable={true}
                isMulti={true}
                placeholder={t('ResultFilter_Choose_FollowUp')}
                minWidth='270px'
                maxHeight='40px'
              />
            </SimpleGrid>
          </Stack>
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('ResultFilter_Area')}
              </Text>
              <CustomSelect
                name='areas'
                value={areaOptions.filter(area =>
                  filter.areaIds?.includes(area.id),
                )}
                options={areaOptions}
                onChange={onAreaChange}
                searchable={true}
                isMulti={true}
                minWidth='250px'
                placeholder={t('ResultFilter_Choose_Area')}
              />
            </SimpleGrid>
          </Stack>
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('ResultFilter_Preschool')} (max 5){' '}
              </Text>
              <CustomSelect
                name='preschools'
                value={finalFilteredPreschoolOptions.filter(preschool =>
                  filter.preschoolIds?.includes(preschool.value),
                )}
                options={finalFilteredPreschoolOptions}
                onChange={onPreschoolChange}
                isOptionDisabled={option =>
                  !filter.preschoolIds.includes(option.id) &&
                  filter.preschoolIds.length >= 5
                }
                searchable={true}
                isMulti={true}
                minWidth='250px'
                placeholder={t('ResultFilter_Choose_Preschool')}
              />
            </SimpleGrid>
          </Stack>
          {
            hasDepartments === 'true' &&
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('ResultFilter_Department')}
              </Text>
              <CustomSelect
                name='departments'
                value={departmentOptions.filter(department =>
                  filter.departmentIds.includes(department.id),
                )}
                options={departmentOptions}
                onChange={onDepartmentChange}
                searchable={true}
                isMulti={true}
                minWidth='250px'
                placeholder={t('ResultFilter_Choose_Department')}
              />
            </SimpleGrid>
          </Stack>
}
          <Stack direction='row' alignItems='center' spacing='3'>
            <SimpleGrid columns={1} row={2} gap={0}>
              <Text
                width={'100%'}
                height={'20px'}
                fontSize='12px'
                color='#5A738B'
              >
                {t('Age')}{' '}
              </Text>
              <CustomSelect
                name='age'
                value={filter.ages}
                options={ageOptions}
                onChange={ages => {localStorage.setItem("load","true")
                onFilterChange({ ages })}}
                searchable={true}
                isMulti={true}
                minWidth='120px'
                placeholder={t('Select_Age')}
              />
            </SimpleGrid>
          </Stack>
          <SimpleGrid columns={1} row={2} gap={0}>
            <Text width={'100%'} height={'20px'} fontSize='12px'></Text>
            <Button variant='ghost' onClick={resetFilter}>
              {t('ResultFilter_Reset')}
            </Button>
          </SimpleGrid>
        </HStack>
      </HStack>
    </Stack>
  )
}
