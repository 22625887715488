import React, { useState, useContext, createContext, useEffect } from 'react'

import { AssignmentType } from 'data-models/chart'
import Preschool from 'data-models/preschool'
import { Area } from 'data-models/area'
import { range } from 'lodash'
import { useTranslation } from 'react-i18next'
type FilterValues = {
  preschools: Preschool['id'][]
  areas: Area['id'][]
  ages: any
  schoolYears: any
  followUpYears: any
  assignmentTypes: AssignmentType['id'][]
}

const FilterContext = createContext<{
  preschools: Partial<Preschool>[]
  setPreschools: (preschools: Partial<Preschool>[]) => void
  areas: Area[]
  setAreas: (areas: Area[]) => void
  ages: any[]
  setAges: (age: any[]) => void
  schoolYears: any[]
  setSchoolYears: (age: any[]) => void
  followUpYears: any[]
  setFollowUpYears: (followUpYear: any[]) => void
  assignmentTypes: AssignmentType[]
  setAssignmentTypes: (assignmentTypes: AssignmentType[]) => void
  filterValues: FilterValues
  currentFollowupId: string
  setCurrentFollowupId: (newVal: string) => void
} | null>(null)

function FilterProvider(props: { children: React.ReactNode }) {
  const { i18n } = useTranslation()
  const [preschools, setPreschools] = useState([] as Partial<Preschool>[])
  const [areas, setAreas] = useState([] as Area[])
  const [schoolYears, setSchoolYears] = useState([] as any[])
  const [filterValues, setFilterValues] = useState({} as FilterValues)
  const [assignmentTypes, setAssignmentTypes] = useState([] as AssignmentType[])
  const [ages, setAges] = useState(
    range(2, 7).map(x => ({
      value: x.toString(),
      label: `${x}Y`,
    })),
  )
  const [followUpYears, setFollowUpYears] = useState(
    ['Höst 2022', 'April 2022', 'Januari 2023', 'April 2022'].map(x => ({
      value: x,
      label: x,
    })),
  )
  const [currentFollowupId, setCurrentFollowupId] = useState<string>('')

  useEffect(() => {
    if (preschools && preschools.length > 0) {
      setFilterValues({
        preschools: preschools.map(x => x.id!),
        areas: areas.map(x => x.id),
        ages: ages.map(x => x.value),
        followUpYears: followUpYears,
        schoolYears: schoolYears.map(x => x.value),
        assignmentTypes: assignmentTypes.map(x => x.id),
      })
    }
  }, [preschools, areas, ages, assignmentTypes, i18n.language])

  const value = {
    preschools,
    setPreschools,
    areas,
    setAreas,
    ages,
    setAges,
    schoolYears,
    setSchoolYears,
    followUpYears,
    setFollowUpYears,
    filterValues,
    setAssignmentTypes,
    assignmentTypes,
    currentFollowupId,
    setCurrentFollowupId,
  }

  return (
    <FilterContext.Provider value={value}>
      {props.children}
    </FilterContext.Provider>
  )
}

function useFilters() {
  const ctx = useContext(FilterContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { FilterProvider, useFilters }
