import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  MenuButton,
  MenuItem,
  MenuList,
  SelectProps,
  Button,
  Menu,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import GlobeWhite from '../assets/images/GlobeWhite.png'
import GlobeBlack from '../assets/images/GlobeBlack.png'
interface LanguageSwitcherProps extends SelectProps {
  languages: string[]
  textColor: string
  bgColor: string
  globColor: boolean
  activeColor: string
  dropactiveColor: string
  bgDrop: string
  menuItemColor:string
  borderWidth:string
  buttonBorderWidth:string,
  borderRadius:string
  menuListBorderRadius:string
}
function LanguageSwitcher({
  languages,
  textColor,
  bgColor,
  globColor,
  activeColor,
  dropactiveColor,
  bgDrop,
  menuItemColor,
  borderWidth,
  buttonBorderWidth,
  borderRadius,
  menuListBorderRadius
}: LanguageSwitcherProps) {
  const { i18n } = useTranslation()
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)

  sessionStorage.setItem('language-selected',i18n.language)
  }


  
  return (
    <Menu>
      <>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          leftIcon={
            globColor ? (
              <img src={GlobeWhite} style={{ width: '24px' }} alt='' />
            ) : (
              <img src={GlobeBlack} style={{ width: '24px' }} alt='' />
            )
          }
          colorScheme='custom'
          bg={bgColor}
          borderRadius={borderRadius}
          border={buttonBorderWidth}
          borderColor='white'
          color={textColor}
          boxShadow='none'         
          width='112px'
          height='45px'
          _hover={{ bg: activeColor }}
          _active={{ bg: activeColor }}
          size='sm'
          fontSize='15px'
          margin='0px'
        >
          {i18n.language.toUpperCase()}
        </MenuButton>
        <MenuList w={3} inlineSize={2} p={0} minW='110' width={'100px'} borderWidth={borderWidth}>
          {languages.map(language => (
            <MenuItem
              color={menuItemColor}
              bg={bgDrop}
              p={3}
               _focus={{ bg: dropactiveColor }}
              _hover={{ bg: dropactiveColor }}
              key={language}
              borderRadius={menuListBorderRadius}
              onClick={() => changeLanguage(language)}
            >
              {language === 'se' ? 'Svenska' :  language === 'de' ? 'German' : 'English'}
            </MenuItem> 
          ))}
        </MenuList>
      </>
    </Menu>
  )
}

export default LanguageSwitcher
