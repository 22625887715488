import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Tabs, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react'

import { UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'

import { Users } from './Users'
import { Profile } from './Profile'
import { Preschool } from './Preschool'
import { Organization } from './Organization'
import { Area } from './Area'
import { TabContainer } from './SuperAdminSettings.layout'
import { useTranslation } from 'react-i18next'

const getIndexBasedOnPathname = (pathname: string) => {
  switch (true) {
    case pathname.includes('/settings/my-profile'):
      return 4
    case pathname.includes('/settings/users'):
      return 3
    case pathname.includes('/settings/preschools'):
      return 2
    case pathname.includes('/settings/areas'):
      return 1
    case pathname.includes('/settings/organizations'):
    default:
      return 0
  }
}

export const SuperAdminSettings = () => {
  const { pathname } = useLocation()
  const { user } = useUser()
  const { t } = useTranslation()
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    getIndexBasedOnPathname(pathname),
  )

  useEffect(() => {
    setActiveTabIndex(getIndexBasedOnPathname(pathname))
  }, [pathname])
  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)
  return (
    <Tabs
      isLazy
      variant='line'
      style={{ outline: 'none' }}
      onChange={(tab: any) => setActiveTabIndex(tab)}
      defaultIndex={0}
      index={activeTabIndex}
      onKeyDownCapture={e => {
        e.stopPropagation()
      }}
    >
      <TabList minH='45px' py='0' px='8' w='full'>
        <Tab
          as={Link}
          id='Dashboard'
          isDisabled={!isAdmin}
          display={isAdmin ? 'flex' : 'none'}
          to='/settings/organizations'
        >
          Organisation
        </Tab>
        <Tab id='Area' as={Link} to='/settings/areas'>
          {t('ResultFilter_Area')}
        </Tab>
        <Tab id='Preschool' as={Link} to='/settings/preschools'>
          {t('ResultFilter_Preschool')}
        </Tab>
        <Tab
          as={Link}
          id='Users'
          isDisabled={!isAdmin}
          display={isAdmin ? 'flex' : 'none'}
          to='/settings/users'
        >
          {t('User')}
        </Tab>
        <Tab id='Profile' as={Link} to='/settings/my-profile'>
          {t('My_Profile')}
        </Tab>
      </TabList>

      <TabPanels px='8'>
        <TabPanel>
          <TabContainer>
            <Organization />
          </TabContainer>
        </TabPanel>
        <TabPanel>
          <TabContainer>
            <Area />
          </TabContainer>
        </TabPanel>
        <TabPanel>
          <TabContainer>
            <Preschool />
          </TabContainer>
        </TabPanel>
        <TabPanel>
          <TabContainer>
            <Users />
          </TabContainer>
        </TabPanel>
        <TabPanel>
          <TabContainer>
            <Profile />
          </TabContainer>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
