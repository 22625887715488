import { useForm } from 'react-hook-form'
import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useDisclosure, useToast } from '@chakra-ui/react'

import { useUser } from 'providers/UserProvider'
import { createDepartmentSchema } from '../schema'
import { ViewDepartmentShape, Department } from 'data-models'
import {
  useCreateDepartment,
  useDeleteDepartment,
  useEditDepartment,
} from 'services/departmentService'
import { useTranslation } from 'react-i18next'

const defaultValues: ViewDepartmentShape = {
  name: '',
}

interface IUseDepartmentModalProps {
  onClose: () => void
  department?: Department
  preschoolId: string
}

export const useDepartmentModal = ({
  onClose,
  department,
  preschoolId,
}: IUseDepartmentModalProps) => {
  const toast = useToast()

  const { user } = useUser()
  const { t } = useTranslation()
  const deleteDepartment = useDeleteDepartment()
  const editDepartment = useEditDepartment()
  const createDepartment = useCreateDepartment()

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure()

  const formMethods = useForm<ViewDepartmentShape>({
    defaultValues: department
      ? {
        name: department.name,
      }
      : { ...defaultValues },
    resolver: yupResolver(createDepartmentSchema),
  })

  useEffect(() => {
    if (department) {
      formMethods.reset({
        name: department.name,
      })
    }
  }, [department])

  const { handleSubmit } = formMethods

  const submitHandler = async (values: ViewDepartmentShape) => {
    try {
      if (department) {
        await editDepartment.mutateAsync({
          id: department.id,
          preschoolId,
          ...values,
        })
      } else {
        await createDepartment.mutateAsync({
          ...values,
          organizationId: user.organizationId,
          preschoolId,
        })
      }

      toast({
        title: ` ${t('Dept_Has_Text')} ${department ? t('Updated_Text') : t('Created_Text')}.`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      onClose()
    } catch (error) {
      toast({
        title: `${t('Not_Possible_Text')}  ${department ? t('Dashboardfilter_Update') : t('FollowupFilter_Create')} ${t('avdelningen')}.`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const onSubmit = handleSubmit(submitHandler)

  const handleDelete = async () => {
    try {
      if (department) {
        await deleteDepartment.mutateAsync({ id: department.id, preschoolId })
        toast({
          title: `${t('The_department_has_been_deleted')}`,
          isClosable: true,
          duration: 3000,
          status: 'success',
        })
        onConfirmDeleteClose()
        onClose()
      }
    } catch (error) {
      toast({
        title: `${t('Dept_Delete_Fail')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  return {
    user,
    onSubmit,
    formMethods,
    handleDelete,
    isDeleteLoading: deleteDepartment.isLoading,
    isCreateEditLoading: editDepartment.isLoading || createDepartment.isLoading,
    isConfirmDeleteOpen,
    onConfirmDeleteOpen,
    onConfirmDeleteClose,
  }
}
