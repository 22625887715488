import { useFormContext } from 'react-hook-form'
import { Organization, UserRole } from 'data-models'

import { useMunicipalityService } from 'services/municipalityService'
import { useAreasByMunicipality } from 'services/areaService'
import { useOrganizations } from 'services/organizationService'
import { useUser } from 'providers/UserProvider'
import { isEqual } from 'lodash'

export const useAreaDetails = () => {
  const formMethods = useFormContext()
  const { user } = useUser()
 

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)


  const { setValue } = formMethods

  const { response: organizations } = useOrganizations()

  const currentOrganization: Organization = organizations.find(
    org => org.id == user.organizationId,
  ) as Organization

  const { municipalities } = useMunicipalityService()
  const municipalityOptions = municipalities.map(municipality => ({
    value: municipality.id,
    label: municipality.name,
  }))

  const selectedMunicipalityId = currentOrganization
  ? (municipalityOptions.find(municipality =>
      isEqual(municipality.label, currentOrganization!.name.trim()),
    )?.value as string)
  : ''
if (selectedMunicipalityId) {
  setValue('municipalityId', selectedMunicipalityId)
}
const { response: areas } = useAreasByMunicipality({
  municipalityId: selectedMunicipalityId,
})

  return {
    isAdmin,
    formMethods,
    municipalityOptions,
  }
}
