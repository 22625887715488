import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useFollowups } from 'services/followupService'
import { FollowupOverviewFilterType, defaultFilter } from '../types'

export const useFollowupOverview = () => {
  const { user } = useUser()
  const FILTER_STORAGE_KEY = 'followupFilterState'
  // Retrieve the saved filter state from sessionStorage
  const savedFilter =
    JSON.parse(sessionStorage.getItem(FILTER_STORAGE_KEY) || 'null') ||
    defaultFilter
  const [filter, setFilter] = useState<FollowupOverviewFilterType>({
    ...savedFilter,
  })
  useEffect(() => {
    // Save the filter state to sessionStorage whenever it changes
    sessionStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filter))
  }, [filter])
  const handleFilterChange = (
    newFilter: Partial<FollowupOverviewFilterType>,
  ) => {
    setFilter({ ...filter, ...newFilter })
  }

  const { response, loading } = useFollowups({
    organizationId: user.organizationId,
    ...filter,
  })

  return {
    filter,
    loading,
    response,
    handleFilterChange,
  }
}
