import React from 'react'
import { Button } from '@chakra-ui/react'

import { DepartmentModal } from './DepartmentModal'
import { useDepartmentCreation } from '../hooks'
import { useTranslation } from 'react-i18next'

interface DepartmentCreationProps {
  preschoolId: string
}

export const DepartmentCreation = ({
  preschoolId,
}: DepartmentCreationProps) => {
  const {
    isCreateDepartmentOpen,
    onCreateDepartmentClose,
    onCreateDepartmentOpen,
  } = useDepartmentCreation()
  const { t } = useTranslation()
  return (
    <>
      <Button
        mb='2'
        minW='200px'
        height='35px'
        fontSize={'sm'}
        variant='outline'
        onClick={onCreateDepartmentOpen}
      >
        {t('Create_Dept')}
      </Button>
      <DepartmentModal
        isOpen={isCreateDepartmentOpen}
        onClose={onCreateDepartmentClose}
        preschoolId={preschoolId}
      />
    </>
  )
}
