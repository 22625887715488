import React from 'react'
import { Box, Grid, GridItem, Spinner } from '@chakra-ui/react'
import { useResultsStats } from './useResultsStats'
import { ResultsStatsFilterType } from 'data-models'
import { useTranslation } from 'react-i18next'

interface ResultsStatsProps {
  filter: ResultsStatsFilterType
}

export const ResultsStats = ({ filter }: ResultsStatsProps) => {
  const { summaryData, loading } = useResultsStats(filter)
  const { t,i18n } = useTranslation()

  return (
    <Box w='full' style={{ fontFamily: 'Open Sans' }}>
      <Grid w='full' templateColumns='repeat(auto-fill, max(300px))' gap={6}>
        <GridItem w='100%'>
          <div className='box-detail' style={{ opacity: loading ? 0.6 : 1 }}>
            <div className='box-detail-f1'>
              {' '}
              {t('DashboardOverview_Number_of_Preschools')}
            </div>
            <div className='mt-10'>
              <img className='d-inline' src='/images/orangeIcon.png' alt='' />
              <span className='box-detail-f2'>
                {loading ? <Spinner size='sm' /> : summaryData?.numberOfSchools}
              </span>
            </div>
          </div>
        </GridItem>
        <GridItem w={i18n.language === 'de' ? 'max-content': '100%'}>
          <div className='box-detail' style={{ opacity: loading ? 0.6 : 1 }}>
            <div className='box-detail-f1'>
              {t('DashboardOverview_Number_Completed_FollowUps')}
            </div>
            <div className='mt-10'>
              <img
                className='d-inline'
                width='34'
                src='/images/blueIconPeople.png'
                alt=''
              />
              <span className='box-detail-f2'>
                {loading ? (
                  <Spinner size='sm' />
                ) : (
                  summaryData?.submittedTotalCount
                )}
              </span>
            </div>
          </div>
        </GridItem>
        <GridItem w='100%'>
          <div className='box-detail' style={{ opacity: loading ? 0.6 : 1 }}>
            <div className='box-detail-f1'>
              {t('DashboardOverview_Number_of_Children')}
            </div>
            <div className='mt-10'>
              <img
                className='d-inline'
                width='34'
                src='/images/blueIconPeople.png'
                alt=''
              />
              <span className='box-detail-f2'>
                {loading ? (
                  <Spinner size='sm' />
                ) : (
                  summaryData?.numberOfParticipants
                )}
              </span>
            </div>
          </div>
        </GridItem>
      </Grid>
    </Box>
  )
}
