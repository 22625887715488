import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
  VStack,
  HStack,
  Box,
  Text,
  Divider,
  Center,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { CommentInfo } from 'data-models/comment'
import { ArrowLeftIcon } from '@chakra-ui/icons'
import { ArrowRightIcon } from '@chakra-ui/icons'

interface CommentModalProps {
  comment: string
  data: CommentInfo[]
  isOpen: boolean
  onClose: () => void
}

export const CommentModal = ({
  isOpen,
  onClose,
  comment,
  data,
}: CommentModalProps) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const index = data.findIndex(item => item.comment === comment)
    setCurrentIndex(index >= 0 ? index : 0)
  }, [comment, data])

  const onNextClick = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      // If we are at the last comment, go back to the first comment
      setCurrentIndex(0)
    }
  }

  const onPreviousClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    } else {
      // If we are at the first comment, go to the last comment
      setCurrentIndex(data.length - 1)
    }
  }

  const totalcomment = data.length < 10 ? '0' + data.length : data.length
  const indexPlus1 = currentIndex + 1
  const curentindex = indexPlus1 < 10 ? '0' + indexPlus1 : indexPlus1
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl' isCentered>
      <ModalOverlay />
      <ModalContent h='25%' minH='400px'>
        <ModalHeader
          mt={2}
          fontSize={16}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box color='#2E0F78' display='flex' alignItems='center'>
            {t('Teacher_Comment')}
            {data.length > 1 && (
              <>
                <Center height='20px' color='#2E0F78'>
                  <Divider
                    orientation='vertical'
                    borderColor='#2E0F78'
                    borderStyle='solid'
                    height='20px'
                    ml='2'
                    mr='2'
                  />
                </Center>
                <Text color='#2E0F78'>{t('Overview_Comments')}</Text>

                <Text pl={2} color='#2E0F78'>
                  {curentindex + '/' + totalcomment}
                </Text>
              </>
            )}
          </Box>

          {data && data.length > 1 && (
            <HStack>
              <ArrowLeftIcon
                color='#3343D4'
                boxSize='5'
                onClick={onPreviousClick}
                cursor='pointer'
                ml='80px' // Adjust margin as needed
              />
              <span style={{ margin: '0 8px' }}></span>
              <Text
                onClick={onPreviousClick}
                color='blue'
                fontWeight='normal'
                cursor='pointer'
              >
                {t('Previous_Text')}
              </Text>
              <Text
                onClick={onNextClick}
                fontWeight='normal'
                color='blue'
                cursor='pointer'
              >
                {t('Next_Text')}
              </Text>
              <span style={{ margin: '0 8px' }}></span>
              <ArrowRightIcon
                color='#3343D4'
                boxSize='5'
                onClick={onNextClick}
                cursor='pointer'
                ml='10px' // Adjust margin as needed
              />
            </HStack>
          )}

          <Box>
            <ModalCloseButton
              mt={2}
              mr={3}
              border='0.5px solid black'
              borderRadius='full'
            />
          </Box>
        </ModalHeader>

        <ModalBody>
          <VStack h='full' w='full' justifyContent={'flex-start'}>
            {/* <HStack mb='1' w='full' alignItems={'center'}>
              <Text fontSize={'lg'} fontWeight='bold'>
                Egna anteckningar
              </Text>
              <HelpTooltip
                title='Antydan'
                body='Denna kommentar kommer att göras för barnet.'
                h='full'
                display={'flex'}
                alignItems={'center'}
                imgSize={4}
                color='black'
              />
            </HStack> */}
            <Textarea
              w='full'
              h='full'
              bgColor='white'
              color='black'
              value={
                data &&
                data[currentIndex] &&
                data[currentIndex]?.comment !== undefined
                  ? data[currentIndex]?.comment || ''
                  : comment
              }
              placeholder={t('Write_Comment_Text')}
              resize='none'
              borderColor={'blackAlpha.300'}
              fontSize={'18px'}
              mb='5'
              minH='unset'
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
