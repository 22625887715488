import { useEffect, useState } from 'react'
import axiosAPIInstance from '../services/api'
import { Task } from 'data-models/task-models'

import i18n from 'i18next'
import combinedTasks from '../locales/Tasks.json'



export const useTaskService = () => {
  const [tasks, setTasks] = useState<Task[]>([]);

  const refreshTasks = () => {
    const languageIndex = i18n.language === 'se' ? 0 : i18n.language === 'de' ? 2 : 1;
    const localizedTasks = combinedTasks.map(task => {
      // Handle nested title based on its structure
      let localizedTitle: string | string[] | { [key: string]: string } = '';

      if (Array.isArray(task.title)) {
        // Handle array structure
        localizedTitle = task.title[languageIndex] || '';
      } else if (typeof task.title === 'object') {
        // Handle object structure
        localizedTitle = Object.fromEntries(
          Object.entries(task.title).map(([key, value]) => [key, value[languageIndex]])
        );
      }

        // Handle answerOptions localization
        let localizedAnswerOptions: any = [];
        if (Array.isArray(task.answerOptions)) {
          localizedAnswerOptions = task.answerOptions.map(option => ({
            ...option,
            text: Array.isArray(option.text) ? option.text[languageIndex] || '' : option.text || ''
          }));
        } else if (typeof task.answerOptions === 'object') {
          localizedAnswerOptions = Object.fromEntries(
            Object.entries(task.answerOptions).map(([key, options]) => [
              key,
              options.map((option: any) => ({
                ...option,
                text: Array.isArray(option.text) ? option.text[languageIndex] || '' : option.text || ''
              }))
            ])
          );
        }
      return {
        ...task,
        title: localizedTitle,
        name: task.name?.[languageIndex] || '',
        subtitle: task.subtitle?.[languageIndex] || '',
        description: task.description?.[languageIndex] || '',
        taskInstructions: task.taskInstructions?.[languageIndex] || '',
        criteria: task.criteria?.[languageIndex] || '',
        knowledge: task.knowledge?.[languageIndex] || '',
        skillArea: task.skillArea?.[languageIndex] || '',
        answerOptions: localizedAnswerOptions,
        answerInstructions: task.answerInstructions?.[languageIndex] || ''
      }
    }) as Task[];
    setTasks(localizedTasks);
  };

  useEffect(() => {
    refreshTasks();
  }, [i18n.language]);

  return { tasks, refreshTasks };
};
