import React from 'react'
import { FormProvider } from 'react-hook-form'

import {
  Box,
  Grid,
  Flex,
  Text,
  Input,
  Modal,
  HStack,
  Button,
  GridItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'

import { Department } from 'data-models'
import { FormWrapper } from 'common/Form'
import { useDepartmentModal } from '../hooks'
import { ConfirmationModal } from 'common/Confirmation'
import { useTranslation } from 'react-i18next'

interface DepartmentModalProps {
  isOpen: boolean
  onClose: () => void
  department?: Department
  preschoolId: string
}

export const DepartmentModal = ({
  isOpen,
  onClose,
  department,
  preschoolId,
}: DepartmentModalProps) => {
  const {
    onSubmit,
    formMethods,
    handleDelete,
    isDeleteLoading,
    isCreateEditLoading,
    isConfirmDeleteOpen,
    onConfirmDeleteOpen,
    onConfirmDeleteClose,
  } = useDepartmentModal({ onClose, department, preschoolId })

  const {
    register,
    formState: { isDirty },
  } = formMethods
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit} noValidate={true}>
          <ModalOverlay />
          <ModalContent maxW='700px' p='2' color={'mainBlue.900'}>
            <ModalHeader>
              <Text color={'mainBlue.900'}>{t('Lead_Dept')}</Text>
            </ModalHeader>
            <ModalCloseButton
              color={'mainBlue.900'}
              colorScheme='blackAlpha'
              style={{ color: 'black' }}
            />
            <>
              <ModalBody overflow={'visible'}>
                <Flex w='full' h='full'>
                  <Grid
                    w='full'
                    gridTemplateColumns={'repeat(2, 1fr)'}
                    gridGap={6}
                  >
                    <GridItem>
                      <FormWrapper name='name' label={t('Name')} isRequired>
                        <Input
                          w='full'
                          h='40px'
                          type='text'
                          border='1px'
                          fontSize='14px'
                          bgColor='white'
                          color='#1A2C3D'
                          borderRadius='lg'
                          placeholder={t('Name')}
                          focusBorderColor=''
                          {...register('name')}
                          borderColor='#B1C5D9'
                          _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                        />
                      </FormWrapper>
                    </GridItem>
                  </Grid>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <HStack w='full' justifyContent={'space-between'}>
                  {department ? (
                    <>
                      <Button
                        mr='2'
                        color='red'
                        bgColor='white'
                        variant='outline'
                        fontWeight='bold'
                        borderColor='red'
                        border='2px solid'
                        _hover={{ opacity: 0.7 }}
                        onClick={onConfirmDeleteOpen}
                      >
                        {t('Delete')}
                      </Button>
                      <ConfirmationModal
                        isLoading={isDeleteLoading}
                        isOpen={isConfirmDeleteOpen}
                        onClose={onConfirmDeleteClose}
                        onConfirmCallback={handleDelete}
                        header={
                          <Text color={'mainBlue.900'}>
                            {t('Delete_User')}
                          </Text>
                        }
                        body={
                          <Text color={'black'}>
                            {t('Delete_User_Prompt')}
                          </Text>
                        }
                        cancelText={t('No_GoAhead')}
                        confirmText={t('Yes_Del')}
                      />
                    </>
                  ) : (
                    <Box />
                  )}

                  <HStack>
                    <Button
                      mr='2'
                      color='blue'
                      bgColor='white'
                      onClick={onClose}
                      variant='outline'
                      fontWeight='bold'
                      borderColor='blue'
                      border='2px solid'
                      _hover={{ opacity: 0.7 }}
                    >
                      {t('Cancel_Text')}
                    </Button>
                    <Button
                      type='submit'
                      height='40px'
                      fontSize='md'
                      isDisabled={!isDirty}
                      isLoading={isCreateEditLoading}
                    >
                      {department
                        ? `${t('Dashboardfilter_Update')}`
                        : `${t('Add_Text')}`}
                    </Button>
                  </HStack>
                </HStack>
              </ModalFooter>
            </>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}
