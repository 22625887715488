import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axiosAPIInstance from './api'
import { Department } from 'data-models/department'



interface UseDepartmentsProps {
  organizationId: string
}
const getDepartments = ({ organizationId }: UseDepartmentsProps) =>
  axiosAPIInstance
    .get(`/departments?organizationId=${organizationId}`)
    .then(({ data }) => data as Department[])


    export const useDepartments = ({ organizationId }: UseDepartmentsProps) => {
      const getFollowupsQuery = useQuery({
        queryKey: ['departmentsList'],
        queryFn: () => getDepartments({ organizationId }),
        staleTime: 1000 * 60 * 10,
      })
    
      const { data: response = [], error, isLoading: loading } = getFollowupsQuery

      //  // Remove duplicates
      // const uniqueDepartments = response.filter((department, index, self) =>
      //   index === self.findIndex((d) => d.name === department.name)
      // )

    
      return {
        error,
        loading,
        response: response,
      }
    }
    


interface UseDeleteDepartmentProps {
  id: string
  preschoolId: string
}

const deleteDepartment = async ({
  id,
}: UseDeleteDepartmentProps): Promise<void> =>
  axiosAPIInstance.delete(`/departments/${id}`)

export const useDeleteDepartment = () => {
  const queryClient = useQueryClient()
  const deletePreschoolMutation = useMutation({
    mutationFn: ({ id, preschoolId }: UseDeleteDepartmentProps) =>
      deleteDepartment({ id, preschoolId }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['preschool', `${variables.preschoolId}`], {
        exact: true,
      })
    },
  })

  return deletePreschoolMutation
}

interface UseCreateDepartmentProps {
  name: string
  organizationId: string
  preschoolId: string
}

const createDepartment = (props: UseCreateDepartmentProps): Promise<void> =>
  axiosAPIInstance.post(`/departments`, {
    ...props,
  })

export const useCreateDepartment = () => {
  const queryClient = useQueryClient()
  const createDepartmentMutation = useMutation({
    mutationFn: ({
      name,
      preschoolId,
      organizationId,
    }: UseCreateDepartmentProps) =>
      createDepartment({ organizationId, name, preschoolId }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['preschool', `${variables.preschoolId}`], {
        exact: true,
      })
    },
  })

  return createDepartmentMutation
}

interface UseEditDepartmentProps {
  id: string
  name: string
  preschoolId: string
}

const editDepartment = ({ id, name }: UseEditDepartmentProps): Promise<void> =>
  axiosAPIInstance.put(`/departments/${id}`, {
    name,
  })

export const useEditDepartment = () => {
  const queryClient = useQueryClient()
  const editPreschoolMutation = useMutation({
    mutationFn: ({ id, name, preschoolId }: UseEditDepartmentProps) =>
      editDepartment({ preschoolId, id, name }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['preschool', `${variables.preschoolId}`], {
        exact: true,
      })
    },
  })

  return editPreschoolMutation
}
