import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { organizationSchema } from '../schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { Organization, UserRole, ViewOrganizationShape } from 'data-models'
import {
  useCreateOrganization,
  useDeleteOrganization,
  useEditOrganization,
} from 'services/organizationService'
import { useToast } from '@chakra-ui/react'
import { useUser } from 'providers/UserProvider'
import { useTranslation } from 'react-i18next'

interface UseOrganizationEditorProps {
  initialOrganization: Organization
}

export const useOrganizationEditor = ({
  initialOrganization,
}: UseOrganizationEditorProps) => {
  const history = useHistory()
  const toast = useToast()
  const { user } = useUser()

  const isSuperAdmin = [UserRole.SUPERADMIN].includes(user.role)
  const { t } = useTranslation()
  const createOrganization = useCreateOrganization()
  const editOrganization = useEditOrganization()
  const deleteOrganization = useDeleteOrganization()

  const formMethods = useForm<ViewOrganizationShape>({
    defaultValues: initialOrganization,
    resolver: yupResolver(organizationSchema),
  })

  const { handleSubmit } = formMethods

  useEffect(() => {
    if (initialOrganization) {
      formMethods.reset(initialOrganization)
    }
  }, [initialOrganization])

  const onCreate = async (values: ViewOrganizationShape) => {
    try {
      await createOrganization.mutateAsync({ values })
      toast({
        title: `${t('Organization_Created_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/organizations')
    } catch (error: any) {
      const isDuplicate = error.toString().includes('409')

      toast({
        title: isDuplicate
          ? `${t('Org_Name_Duplicate')}`
          : `${t('Create_Org_Error')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleCreate = handleSubmit(onCreate)

  const onEdit = async (values: ViewOrganizationShape) => {
    try {
      await editOrganization.mutateAsync({
        values: {
          ...values,
          id: initialOrganization.id,
          isActive: initialOrganization?.isActive,
        },
        id: initialOrganization.id,
      })
      toast({
        title: `${t('Create_Org_Success_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
    } catch (error: any) {
      const isDuplicate = error.toString().includes('409')

      toast({
        title: isDuplicate
          ? `${t('Org_Name_Duplicate')}`
          : `${t('Org_Update_Fail')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleEdit = handleSubmit(onEdit)

  const handleDelete = async () => {
    await deleteOrganization.mutateAsync({ id: initialOrganization.id })

    try {
      toast({
        title: `${t('Org_Del_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/organizations')
    } catch (error) {
      toast({
        title: `${t('Org_Del_Fail')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  return {
    isSuperAdmin,
    formMethods,
    handleEdit,
    isEditLoading: editOrganization.isLoading,
    handleDelete,
    isDeleteLoading: deleteOrganization.isLoading,
    handleCreate,
    isCreateLoading: createOrganization.isLoading,
  }
}
