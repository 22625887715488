export enum StatusLabels {
  Submitted = 'Planerat',
  Active = 'Pågående',
  Completed = 'Avslutat',
  Draft = 'Utkast',
}
export enum StatusLabelsEn {
  Submitted = 'Planned',
  Active = 'Ongoing',
  Completed = 'Completed',
  Draft = 'Draft',
}
export enum StatusLabelsDe {
  Submitted = 'Geplant',
  Active = 'Laufend',
  Completed = 'Abgeschlossen',
  Draft = 'Entwurf',
}
export enum TaskType {
  image_selection = 'Visuellt',
  image_sorting = 'Bildsortering',
  checkbox_selection = 'Verbalt',
  image_placement = 'Visuellt',
  video_multi = 'Visuellt och verbalt',
  video = 'Visuellt och verbalt',
}

export enum TaskAltText {
  'Visuellt' = 'Barnet ska svara på frågan med hjälp av bilder.',
  'Verbalt' = 'Barnet ska svara och beskriva med ord.',
  'Visuellt och verbalt' = 'Barnet ska välja bilder samt beskriva med ord.',
}
