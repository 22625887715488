import React from 'react'
import { Button, Flex } from '@chakra-ui/react'
import { useFeedback } from './hooks'
import { FeedbackModal } from './components'
import { FeedbackIcons } from 'common/Icons/FeedbackIcons'

export const Feedback = () => {
  const { isOpen, onClose, onOpen } = useFeedback()

  return (
    <Flex h='32px' w='32px'>
      <Flex onClick={onOpen} h='full' w='full'>
        <Button
          h='full'
          w='full'
          p='0'
          display='flex'
          justifyContent='center'
          alignItems='center'
          variant='unstyled'
          _focus={{
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <FeedbackIcons />
        </Button>
      </Flex>
      <FeedbackModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
