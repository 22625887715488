import React from 'react'
import { Spinner, VStack, Text } from '@chakra-ui/react'
import { useUserContainer } from './hooks'
import { CreateUserInviteEditor } from './CreateUserInviteEditor'
import { useTranslation } from 'react-i18next'

interface UserContainerProps {
  isCreate: boolean
}

export const CreateUserInviteContainer = ({ isCreate }: UserContainerProps) => {
  const { loading, user } = useUserContainer()
  const { t } = useTranslation()
  return (
    <>
      {loading ? (
        <VStack spacing='5' w='full' h='full' justify='center' pt='20'>
          <Spinner size='xl' color='black' />
          <Text
            color='black'
            mb='14'
            mt='7'
            fontSize='large'
            fontWeight='light'
          >
            {t('Fetch_users')}
          </Text>
        </VStack>
      ) : (
        <CreateUserInviteEditor isCreate={isCreate} user={user} />
      )}
    </>
  )
}
