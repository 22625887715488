import { AgeType, LoginType, Organization, OrganizationType } from 'data-models'
import { useHistory, useParams } from 'react-router-dom'
import { useOrganization } from 'services/organizationService'

const defaultValues: Organization = {
  id: '',
  isActive: false,
  name: '',
  city: '',
  zipCode: '',
  address1: '',
  address2: '',
  municipalityId: '',
  type: OrganizationType.KOMMUN,
  loginType: LoginType.CUSTOM,
  ageType: AgeType.BIRTH_DATE,
  showChildCode: false,
  hasMultipleSections: false,
  hasLanguageAnalysis: false,
  organizationNumber: '',
}

export const useOrganizationContainer = () => {
  const params = useParams()
  const { id } = params as any
  const history = useHistory()

  const { data: organization = defaultValues, isInitialLoading } =
    useOrganization({
      id,
    })

  if (id && !organization?.id && !isInitialLoading) {
    history.push('/settings/organizations')
  }

  return {
    organization,
    loading: isInitialLoading,
  }
}
