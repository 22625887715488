import { User } from 'data-models'
import { useUsers } from 'services/userService'
import { useUser } from 'providers/UserProvider'
import { UsersFilterType } from '../UsersFilterType'
import { useHistory } from 'react-router-dom'

interface UseUserTableProps {
  filter: UsersFilterType
}

export const useUserTable = ({ filter }: UseUserTableProps) => {
  const history = useHistory()
  const {
    user: { organizationId },
  } = useUser()
  const { data: users = [], isInitialLoading: loading } = useUsers({
    filter,
    organizationId,
  })

  const handleEditUser = (user: User) => {
    history.push(`/user/${user.id}`)
  }

  return {
    users,
    loading,
    handleEditUser,
  }
}
