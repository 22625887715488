import { InfoOutlineIcon,ChevronUpIcon } from '@chakra-ui/icons'
import { Button, Center, Divider } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface HelpButtonProps{
    onToggle: () => void,
    isOpen: boolean,
    textTitle?:string
}

const TaskHelpButtonComponent = ({onToggle,isOpen,textTitle}:HelpButtonProps) => {
  const {t} = useTranslation();
  return (
    <>
   
        {/* <Divider borderColor="gray.300" orientation={'vertical'} /> */}
    
      <Button onClick={onToggle}  colorScheme="purple"  fontSize='sm' variant="link" style={{boxShadow: 'none',marginRight:'1%'}} leftIcon={isOpen ? <ChevronUpIcon/> : <InfoOutlineIcon/>}>{textTitle ? textTitle : t('Help_Me_Understand')}</Button>
    </>
   )
}

export default TaskHelpButtonComponent
