import React from 'react'
import { FormProvider } from 'react-hook-form'

import { Flex } from '@chakra-ui/react'
import { EditButton } from '../common/EditButton'
import { ActionsMenu } from '../common/ActionsMenu'
import { CreateButton } from '../common/CreateButton'
import { DeleteButton } from '../common/DeleteButton'
import { PreschoolDetails } from './PreschoolDetails'
import { usePreschoolEditor } from './hooks/usePreschoolEditor'
import Preschool from 'data-models/preschool'
import { DetailsWrapper } from '../common/EntityViewer.layout'
import { useTranslation } from 'react-i18next'

interface PreschoolEditorProps {
  isCreate: boolean
  preschool: Preschool
}

export const PreschoolEditor = ({
  isCreate,
  preschool,
}: PreschoolEditorProps) => {
  const {
    isAdmin,
    handleEdit,
    formMethods,
    handleCreate,
    handleDelete,
    isEditLoading,
    isCreateLoading,
    isDeleteLoading,
  } = usePreschoolEditor({
    initialPreschool: preschool,
  })
  const { i18n } = useTranslation()
  return (
    <Flex w='full' h='fit-content' bg='white'>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} noValidate={true}>
          <ActionsMenu
            name={
              preschool?.name || i18n.language === 'se'
              ? 'Förskola'
              : i18n.language === 'de'
              ? 'Vorschule'
              : 'Preschool'
            }
          >
            {isAdmin && (
              <>
                {isCreate ? (
                  <CreateButton
                    onClick={handleCreate}
                    isLoading={isCreateLoading}
                  />
                ) : (
                  <>
                    <DeleteButton
                      entityName='förskola'
                      onDelete={handleDelete}
                      isLoading={isDeleteLoading}
                    />
                    <EditButton
                      onClick={handleEdit}
                      isLoading={isEditLoading}
                    />
                  </>
                )}
              </>
            )}
          </ActionsMenu>

          <DetailsWrapper>
            <PreschoolDetails preschool={preschool} />
          </DetailsWrapper>
        </form>
      </FormProvider>
    </Flex>
  )
}
