import { Box, List, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import MainHeader from './MainHeader'

function ReleaseNotesHistory() {
  const [releaseNotesData, setReleaseNotesData] = useState<any>([])

  const fetchReleaseNotes = async () => {
    try {
      const response = await axios.get('/releaseNotes.json')
      const releaseData = response.data
      setReleaseNotesData(releaseData.releases)
    } catch (error) {
      console.log('error', error)
    }
  }

  const currentPath = location.pathname;
  if (currentPath === '/release-notes'){
    const routerWrapper = document.getElementById('router-wrapper-id')
    const appWrapper = document.getElementById('app-wrapper-id')
    const rootWrapper = document.getElementById('root')
    appWrapper?.classList.add('release-notes-wrapper')
    routerWrapper?.classList.add('release-notes-wrapper')
    rootWrapper?.classList.add('release-root-wrapper')
  }

  useEffect(() => {
    fetchReleaseNotes()
  }, [])


  return (
    <>
      <MainHeader />
   
    <Box backgroundColor={'white'} pb='3%' h='auto'>

      <Box
       
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        pt='3%'
        pl='3%'
      >
        <h1 style={{ color: 'black', fontSize: '24px' }}>
          Myran release notes
        </h1>
        <p style={{ color: 'black', marginTop: '20px' }}>
          The following release notes cover the most recent changes and updates.
        </p>
      </Box>
    
       
        {releaseNotesData.map(data => (
         <Box display='flex' mt='50px' alignItems={'flex-start'} pl='3%' flexDirection={'column'}>
         <Text color={'black'} mb='24px' fontSize={'20px'}> {format(new Date(data.date), 'MMMM d, yyyy')}</Text>
         <Text color={'black'} fontSize={'18px'} fontStyle={'bold'} fontWeight={'600'} pb='20px'>v{' '}{data.version}</Text>
         <Box display={'flex'}>  
         <Box backgroundColor={'darkIndigo'} p='10px' w='100px' textAlign='center' borderRadius={'50px'} alignSelf={'flex-start'} color='white'>Features</Box>
         <UnorderedList styleType="'-'" spacing={3} pl='10px' pt='5px'>
         {data.features.map((feature, featureIndex) => (
           <ListItem key={featureIndex} color={'black'} pb='10px' pl='10px'>{feature}</ListItem>
         ))}
       </UnorderedList> 
       </Box>  
       {
        data.fixes ? 
        <Box display={'flex'} mt='40px'>  
        <Box  p='10px' backgroundColor={'green'} borderRadius={'50px'} alignSelf={'flex-start'} w='100px' textAlign='center' color='white'>Fixed</Box>
        <UnorderedList styleType="'-'" spacing={3} pt='5px' pl='10px'>
        {data.fixes.map((fix, featureIndex) => (
          <ListItem key={featureIndex} color={'black'} pb='10px' pl='10px'>{fix}</ListItem>
        ))}
      </UnorderedList> 
      </Box>  
      :
      ''
       }
     
        </Box>
        



        ))}
       

      </Box>
      </>
    
  )
}

export default ReleaseNotesHistory
