export const defaultFilter: CommentsFilterType = {
  areaIds: [],
  pageSize: 25,
  pageNumber: 1,
  followupId: '',
  preschoolIds: [],
  endDate: undefined,
  startDate: undefined,
}

export type CommentsFilterType = {
  endDate?: Date
  startDate?: Date
  pageSize: number
  areaIds: string[]
  followupId: string
  pageNumber: number
  preschoolIds: string[]
}
