import React from 'react'
import { Spinner, VStack, Text } from '@chakra-ui/react'
import { useOrganizationContainer } from './hooks'
import { OrganizationEditor } from './OrganizationEditor'
import { useTranslation } from 'react-i18next'

interface OrganizationContainerProps {
  isCreate: boolean
}

export const OrganizationContainer = ({
  isCreate,
}: OrganizationContainerProps) => {
  const { loading, organization } = useOrganizationContainer()
  const { t } = useTranslation()
  return (
    <>
      {loading ? (
        <VStack spacing='5' w='full' h='full' justify='center' pt='20'>
          <Spinner size='xl' color='white' />
          <Text
            color='white'
            mb='14'
            mt='7'
            fontSize='large'
            fontWeight='light'
          >
            {t('Fetch_FollowUp')}
          </Text>
        </VStack>
      ) : (
        <OrganizationEditor isCreate={isCreate} organization={organization} />
      )}
    </>
  )
}
