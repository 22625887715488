import React, { PropsWithChildren } from 'react'
import questionMarkImage from 'assets/images/question-mark.svg'
import {
  Heading,
  Tooltip,
  VStack,
  Text,
  Image,
  FlexProps,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

export const helpToolTipText =
i18n.language === 'se'
? 'Barnet finns i rummet tillsammans med dig, men är inte aktiv i undersökningen. (e.x. tittar bort eller lämnar platsen)'
: i18n.language === 'de'
? 'Das Kind ist im Raum bei Ihnen, aber nicht aktiv an der Untersuchung beteiligt. (z.B. wegschauen oder den Platz verlassen)'
: 'The child is in the room with you, but is not active in the examination. (e.g. looking away or leaving the place)'


interface HelpToolTipProps extends FlexProps {
  title?: string
  body?: string
  image?: string
  imgSize?: number
}

export const HelpTooltip = ({
  title,
  body,
  image,
  imgSize = 5,
  children,
  ...rest
}: PropsWithChildren<HelpToolTipProps>) => {
  const { t } = useTranslation()
  return (
    <Tooltip
      placement='bottom'
      hasArrow
      bg='white'
      mt='1'
      p='4'
      borderRadius='lg'
      boxShadow='lg'
      shouldWrapChildren
      label={
        children ? (
          children
        ) : (
          <VStack align='baseline'>
            <Heading fontSize={'md'} color='black'>
              {title ? title : `${t('Child_Not_Participate')}`}
            </Heading>
            <Text
              fontSize={'sm'}
              color='black'
              fontWeight='light'
              lineHeight='normal'
            >
              {body ? body : helpToolTipText}
            </Text>
          </VStack>
        )
      }
    >
      <Flex {...rest}>
        <Image boxSize={imgSize} src={image ? image : questionMarkImage} />
      </Flex>
    </Tooltip>
  )
}
