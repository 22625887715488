import React from 'react'
import {
  Tr,
  Th,
  Td,
  Table,
  Thead,
  Center,
  Spinner,
  Container,
  TableContainer,
  Tbody,
} from '@chakra-ui/react'
import Preschool from 'data-models/preschool'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface PreschoolTableProps {
  loading?: boolean
  preschools?: Preschool[]
}

export const PreschoolTable = ({
  loading,
  preschools,
}: PreschoolTableProps) => {
  const history = useHistory()
  const { t } = useTranslation()

  const handleEditPreschool = (preschool: Preschool) => {
    history.push(`/preschool/${preschool.preschoolId}`)
  }

  return (
    <Container p='0' maxW='full'>
      {loading ? (
        <Center h='100px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        preschools && (
          <>
            <TableContainer borderRadius='lg'>
              <Table variant='simple'>
                <Thead>
                  <Tr
                    bgColor={'rgba(177, 197, 217, 0.5)'}
                    borderWidth='1px'
                    borderRadius='10px'
                    height='48px'
                    fontWeight={'bold'}
                    fontFamily={`'Open Sans',sans-serif`}
                    fontSize={'12px'}
                  >
                    <Th>{t('ResultFilter_Preschool')}</Th>
                    <Th>{t('ResultFilter_Area')}</Th>
                    <Th>{t('Connected_Users')}</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {preschools.map((preschool, index) => (
                    <Tr
                      key={`${preschool.id}_${index}`}
                      onClick={() => handleEditPreschool(preschool)}
                      cursor={'pointer'}
                      _hover={{
                        backgroundColor: '#EFF1FF',
                      }}
                    >
                      <Td>{preschool.preschoolName}</Td>
                      <Td>{preschool.areaName}</Td>
                      <Td>{preschool.numberOfUsers}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )
      )}
    </Container>
  )
}
