import { useUser } from 'providers/UserProvider'
import { useResultsStatsOverview } from 'services/followupResultService'
import { ResultsStatsFilterType } from 'data-models'

export const useResultsStats = (filter: ResultsStatsFilterType) => {
  const { areaIds, followupId, preschoolIds } = filter

  const {
    user: { organizationId },
  } = useUser()

  const { response: summaryData, loading } = useResultsStatsOverview({
    organizationId,
    followupId,
    areaIds,
    preschoolIds,
  })

  return { summaryData, loading }
}
