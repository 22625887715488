import React, { useState } from 'react'
import {
  Td,
  Th,
  Tr,
  Box,
  Flex,
  Tbody,
  Table,
  Thead,
  Textarea,
  useDisclosure,
  TableContainer,
  VStack,
} from '@chakra-ui/react'

import { ResultsFilter } from './ResultsFilter'
import { TaskType, TaskAltText } from 'services/indicators'
import { ResultInfo } from 'data-models'
import TaskPreview from 'pages/MainTabs/OverviewTab/TaskPreview/taskPreview'
import { useTaskService } from 'services/taskService'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

interface ResultDetailsProps {
  result: ResultInfo
}

export const ResultDetails = ({ result }: ResultDetailsProps) => {
  const {
    isOpen: isTaskPreviewOpen,
    onOpen: onTaskPreviewOpen,
    onClose: onTaskPreviewClose,
  } = useDisclosure()
  const [quesId, setQuesId] = useState('')
  const location = useLocation()
  const isInPath = location.pathname === '/followup/create'
  const { tasks } = useTaskService()
  const { t } = useTranslation()
  const openPreview = (event: any) => {
    setQuesId(event.target.id)
    onTaskPreviewOpen()
  }

  return (
    <Flex w='full' h='full'>
      {result && (
        <VStack w='full' h='full'>
          <ResultsFilter
            filter={{
              followupId: result.followupId,
              areaIds: [result.areaId],
              preschoolIds: [result.preschoolId],
              // submittedAt: new Date(result.startDate),
              pageNumber: 0,
              pageSize: 0,
            }}
            onFilterChange={() => {}}
            isDisabled
          />

          <Box pt='4' />

          <Box w='full'>
            <Textarea
              placeholder={t('Description_FollowUp')}
              name='FollowupDesc'
              fontSize='14px'
              bgColor={'white'}
              color={'#1A2C3D !important'}
              border='1px'
              borderColor='#B1C5D9'
              h={'40px'}
              w={'100%'}
              borderRadius={'8px'}
              value={result.individualComment}
              disabled
            />
          </Box>

          <Box pt='8' mb='8' borderBottom='1px solid #B1C5D9' />

          <Box w='full'>
            <TableContainer borderRadius='10px'>
              <Table variant='simple'>
                <Thead position='sticky'>
                  <Tr
                    bgColor={'rgba(177, 197, 217, 0.5)'}
                    borderWidth='1px'
                    borderRadius='10px'
                    height='48px'
                    fontWeight={'bold'}
                    fontFamily={`'Open Sans',sans-serif`}
                    fontSize={'12px'}
                  >
                    <Th>{t('Questions_Text')}</Th>
                    <Th>{t('Task_Type_Text')}</Th>
                    <Th>{t('Correct_Answer_Number')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {result.tasks.map((task, index) => {
                    const currentTask = tasks.find(t => t.id == task.id)

                    return (
                      <Tr key={`${task.id}_${index}`}>
                        <Td
                          id={task.id}
                          onClick={(q: any) => {
                            openPreview(q)
                          }}
                          style={{ cursor: 'pointer', color: 'blue' }}
                        >
                          {task.type === 'video_multi' && (
                            <>
                              {
                                Object.values(
                                  currentTask?.title || task.title,
                                )[0] as string
                              }
                              <br />
                              {
                                Object.values(
                                  currentTask?.title || task.title,
                                )[1] as string
                              }
                            </>
                          )}
                          {task.type !== 'video_multi' && task.title}
                        </Td>
                        <Td
                          title={
                            TaskAltText[
                              TaskType[
                                task.type.replaceAll(
                                  '-',
                                  '_',
                                ) as keyof typeof TaskType
                              ] as keyof typeof TaskAltText
                            ]
                          }
                        >
                          {
                            TaskType[
                              task.type.replaceAll(
                                '-',
                                '_',
                              ) as keyof typeof TaskType
                            ]
                          }
                        </Td>
                        <Td>{task.score}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <TaskPreview
            isInPath={isInPath}
            isOpen={isTaskPreviewOpen}
            onClose={onTaskPreviewClose}
            taskId={quesId}
            selectedId={[]}
            taskOrder={[]}
            onTaskSelectionChange={(taskId, isSelected) => {
              // Dummy function, you can leave it empty if you don't need to handle task selection changes
            }}
          />
        </VStack>
      )}
    </Flex>
  )
}
