import React from 'react'
import { Spinner, VStack, Text } from '@chakra-ui/react'
import { useAreaContainer } from './hooks'
import { AreaEditor } from './AreaEditor'
import { useTranslation } from 'react-i18next'

interface AreaContainerProps {
  isCreate: boolean
}

export const AreaContainer = ({ isCreate }: AreaContainerProps) => {
  const { loading, area } = useAreaContainer()
  const { t } = useTranslation()
  return (
    <>
      {loading ? (
        <VStack p='0' spacing='5' w='full' h='full' justify='center' pt='20'>
          <Spinner size='xl' color='black' />
          <Text
            color='black'
            mb='14'
            mt='7'
            fontSize='large'
            fontWeight='light'
          >
            {t('Pick_Preschool')}
          </Text>
        </VStack>
      ) : (
        <AreaEditor isCreate={isCreate} area={area} />
      )}
    </>
  )
}
