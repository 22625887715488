import { AveragePerAgeStatistics } from 'data-models/preschool'
import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getAveragePerAgeStatistics, getAveragePerTaskStatistics, } from 'services/followupResultService'
import { DashboardFilterType } from '../../DashboardFilterType'

export const useResultsPerAgeChart = (filter: DashboardFilterType) => {
  const [stats, setStats] = useState<AveragePerAgeStatistics[]>([])

  const {
    user: { organizationId },
  } = useUser()

  const response = useAsync(
    () =>
      getAveragePerAgeStatistics(
        organizationId,
        filter.followupId,
        filter.areaIds,
        filter.preschoolIds,
        filter.departmentIds
      ),
    [filter],
  )

  const { value, loading } = response

  useEffect(() => {
    setStats(value ? value : [])
  }, [filter, value])

  return { stats, loading }
}
export const useResultsPerTaskChart = (filter: DashboardFilterType) => {
  const [stats, setStats] = useState<AveragePerAgeStatistics[]>([])

  const {
    user: { organizationId },
  } = useUser()

  const response = useAsync(
    () =>
      getAveragePerTaskStatistics(
        organizationId,
        filter.followupId,
        filter.areaIds,
        filter.preschoolIds,
        filter.departmentIds
      ),
    [filter],
  )

  const { value, loading } = response

  useEffect(() => {
    setStats(value ? value : [])
  }, [filter, value])

  return { stats, loading }
}
