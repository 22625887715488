import React from 'react'

import { Divider, VStack } from '@chakra-ui/react'

import { ResultDetails } from './Details'
import { ResultsStats } from './ResultsStats'
import { ResultsTable } from './ResultsTable'
import { ResultsFilter } from './ResultsFilter'
import { useResultsOverview } from './useResultsOverview'

export const ResultsOverview = () => {
  const {
    filter,
    loading,
    resultResponse,
    navigationState,
    handleFilterChange,
  } = useResultsOverview()

  return (
    <>
      {(navigationState as any)?.activeView === 'Details' ? (
        <ResultDetails />
      ) : (
        <VStack mt='8'>
          <ResultsFilter filter={filter} onFilterChange={handleFilterChange} />
          <Divider
            orientation='horizontal'
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              borderColor: 'lightBlue',
            }}
          />
          <ResultsStats filter={filter} />

          <ResultsTable
            resultResponse={resultResponse}
            loading={loading}
            filter={filter}
            onFilterChange={handleFilterChange}
          />
        </VStack>
      )}
    </>
  )
}
