// Component documentation https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/

import React from 'react'
import { usePagination, DOTS } from './UsePagination'
import classes from './Pagination.module.scss'
import classnames from 'classnames'
import { HStack } from '@chakra-ui/react'
import CustomSelect from 'common/CustomSelect'
import { PaginatedSettings } from 'data-models'
import { useTranslation } from 'react-i18next'

const pageSizeOptions = [10, 25, 50, 100].map(size => ({
  value: size,
  label: size,
}))

interface PaginationProps {
  onPageNumberChange: (page: number) => void
  onPageSizeChange: (page: number) => void
  totalCount: number
  siblingCount: number
  settings: PaginatedSettings
}

export const Pagination = ({
  onPageNumberChange,
  onPageSizeChange,
  siblingCount,
  totalCount,
  settings: { pageNumber, pageSize },
}: PaginationProps) => {
  const paginationRange = usePagination({
    pageNumber: pageNumber,
    totalCount: totalCount,
    siblingCount: siblingCount,
    pageSize: pageSize,
  })
  if (pageNumber === 0 || totalCount === 0) {
    return null
  }

  const onNext = () => {
    onPageNumberChange(pageNumber + 1)
  }

  const onPrevious = () => {
    onPageNumberChange(pageNumber - 1)
  }

  const handlePageSizeChange = (option: any) => {
    onPageSizeChange(option.value)
  }
  const { t } = useTranslation()

  const lastPage = paginationRange[paginationRange.length - 1]
  return (
    <div className={classes['pagination-container']}>
      <HStack>
        <CustomSelect
          name='followups'
          value={pageSizeOptions.find(option => option.value === pageSize)}
          options={pageSizeOptions}
          onChange={handlePageSizeChange}
          className='bold-value'
          searchable={true}
          isMulti={false}
          placeholder='Sidstorlek'
          minWidth='150px'
          maxWidth='150px'
        />
        <div className={classes['pagination-description']}>
          {t('Shows')} {(pageNumber - 1) * pageSize + 1} -{' '}
          {pageNumber * pageSize} {t('of')} {totalCount} {t('files')}
        </div>
      </HStack>
      <ul className={classes['pagination-control']}>
        <li
          className={classnames(
            classes['pagination-element'],
            classes['pagination-arrow'],
            {
              [classes.disabled]: pageNumber === 1,
            },
          )}
          onClick={onPrevious}
        >
          &lt;
        </li>
        {paginationRange.map((pageIndex, i) => {
          if (pageIndex === DOTS) {
            return (
              <li
                key={i}
                className={classnames(
                  classes['pagination-element'],
                  classes['pagination-item'],
                  classes.dots,
                )}
              >
                &#8230;
              </li>
            )
          }

          return (
            <li
              key={i}
              className={classnames(
                classes['pagination-element'],
                classes['pagination-item'],
                {
                  [classes.selected]: pageIndex === pageNumber,
                },
              )}
              onClick={() => onPageNumberChange(pageIndex)}
            >
              {pageIndex}
            </li>
          )
        })}
        <li
          className={classnames(
            classes['pagination-element'],
            classes['pagination-arrow'],
            {
              [classes.disabled]: pageNumber === lastPage,
            },
          )}
          onClick={onNext}
        >
          &gt;
        </li>
      </ul>
    </div>
  )
}
