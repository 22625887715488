import { useFormContext } from 'react-hook-form'
import { UserRole, UserRoleToLabelMapping, UserRoleToLabelMappingEn,UserRoleToLabelMappingDe } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useDisclosure } from '@chakra-ui/react'
import { useOrganizations } from 'services/organizationService'
import { usePreschoolService } from 'services/preschoolService'
import { useTranslation } from 'react-i18next'
export const useUserDetails = () => {
  const formMethods = useFormContext()
  const { i18n } = useTranslation()
  const {
    user: { role },
  } = useUser()

  const {
    isOpen: isResetPasswordModalOpen,
    onOpen: onResetPasswordModalOpen,
    onClose: onResetPasswordModalClose,
  } = useDisclosure()

  const { setValue, watch } = formMethods

  const selectedOrganizationId = watch('organizationId')
  const { response: organizations } = useOrganizations()
  const { preschools } = usePreschoolService(selectedOrganizationId)

  const organizationOptions = organizations.map(organization => ({
    value: organization.id,
    label: organization.name,
  }))

  const preschoolOptions = preschools.map(preschool => ({
    value: preschool.id,
    label: preschool.name,
  }))

  const isSuperAdmin = [UserRole.SUPERADMIN].includes(role)
  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(role)

  const availableRoles = isSuperAdmin
    ? [
      UserRole.TEACHER,
      UserRole.ADMIN,
      UserRole.SUPERADMIN,
      UserRole.PRINCIPAL,
    ]
    : [UserRole.TEACHER, UserRole.ADMIN, UserRole.PRINCIPAL]

  const roleOptions = availableRoles.map(role => ({
    value: role,
    label: i18n.language === 'se'
    ? UserRoleToLabelMapping[role]
    : i18n.language === 'de'
    ? UserRoleToLabelMappingDe[role]
    : UserRoleToLabelMappingEn[role]
  }))

  const selectAllPreschools = () => {
    setValue(
      'preschoolIds',
      preschoolOptions.map(preschoolOption => preschoolOption.value),
    )
  }

  return {
    formMethods,
    roleOptions,
    isAdmin,
    preschoolOptions,
    selectAllPreschools,
    organizationOptions,
    isResetPasswordModalOpen,
    onResetPasswordModalOpen,
    onResetPasswordModalClose,
  }
}
