import Preschool from './preschool'

export interface User {
  id: string
  name: string
  email: string
  role: UserRole
  organizationId: string
  preschoolName?: string
  phoneNumber?: string
  preschoolIds?: string[]
  preschools?: Preschool[]
  username?: string
}

export enum UserRole {
  TEACHER = 'Myran.Teacher',
  ADMIN = 'Myran.Admin',
  SUPERADMIN = 'Myran.SuperAdmin',
  PRINCIPAL = 'Myran.Principal',
}

export const UserRoleToLabelMapping: Record<UserRole, string> = {
  'Myran.SuperAdmin': 'Superadmin',
  'Myran.Admin': 'Admin',
  'Myran.Teacher': 'Lärare',
  'Myran.Principal': 'Rektor',
}
export const UserRoleToLabelMappingEn: Record<UserRole, string> = {
  'Myran.SuperAdmin': 'Superadmin',
  'Myran.Admin': 'Admin',
  'Myran.Teacher': 'Teacher',
  'Myran.Principal': 'Principal',
}
export const UserRoleToLabelMappingDe: Record<UserRole, string> = {
  'Myran.SuperAdmin': 'Superadministrator',
  'Myran.Admin': 'Administrator',
  'Myran.Teacher': 'Lehrer',
  'Myran.Principal': 'Schulleiter',
}
export interface ViewUserShape {
  organizationId: string
  preschoolId?: string
  name: string
  role: UserRole
  email: string
  phoneNumber: string
  newPassword: string
  confirmPassword: string
  preschoolIds?: string[]
  password?: string
  username?: string
}

export type CreateUserDTO = Partial<
  Pick<ViewUserShape, 'name' | 'email' | 'password' | 'phoneNumber'> & {
    type: string
  }
>
export type UpdateUserDTO = Partial<ViewUserShape>
export type InviteUserDTO = Pick<
  ViewUserShape,
  'organizationId' | 'preschoolId' | 'role' | 'email'
>

export interface ResetPasswordShape {
  newPassword: string
  confirmPassword: string
}
