import React from 'react'
import { Text, Flex, Grid, Input, VStack, GridItem } from '@chakra-ui/react'
import { FormWrapper } from 'common/Form'
import { FormSelect } from 'common/FormSelect'
import { Controller } from 'react-hook-form'
import { useAreaDetails } from './hooks/useAreaDetails'
import Area from 'data-models/preschool'
import { useTranslation } from 'react-i18next'
interface AreaDetailsProps {
  area?: Area
}

export const AreaDetails = ({ area }: AreaDetailsProps) => {
  const { isAdmin, formMethods, municipalityOptions } = useAreaDetails()
  const { t } = useTranslation()
  const { control, register } = formMethods
  return (
    <>
      <VStack w='full'>
        <Flex overflow='visible' w='full'>
          <VStack w='full' h='full'>
            <Flex py='5' w='full' justify='start'>
              <Text fontSize='lg' color='darkIndigo' fontWeight='bold'>
                {t('Overview')}
              </Text>
            </Flex>
            <Grid w='full' gridTemplateColumns={'repeat(2, 1fr)'} gridGap={6}>
              <GridItem>
                <FormWrapper name='name' label={t('Name')} isRequired>
                  <Input
                    type='text'
                    placeholder={t('Area_Name')}
                    _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                    focusBorderColor=''
                    fontSize='14px'
                    bgColor={'white'}
                    color={'#1A2C3D'}
                    border='1px'
                    borderColor='#B1C5D9'
                    h={'40px'}
                    w='full'
                    borderRadius={'lg'}
                    isDisabled={!isAdmin}
                    {...register('name')}
                  />
                </FormWrapper>
              </GridItem>
              <GridItem>
                <FormWrapper
                  name='municipalityId'
                  label={t('Municipality')}
                  isRequired
                >
                  <Controller
                    control={control}
                    name='municipalityId'
                    render={({ field: { onChange, value, name } }) => (
                      <FormSelect
                        name={name}
                        options={municipalityOptions}
                        placeholder={t('Municipality')}
                        value={municipalityOptions.find(
                          option => option.value === value,
                        )}
                        isDisabled={!!value || !isAdmin}
                        onChange={val => onChange(val?.value)}
                      />
                    )}
                  />
                </FormWrapper>
              </GridItem>
            </Grid>
          </VStack>
        </Flex>
      </VStack>
    </>
  )
}
