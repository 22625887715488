import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'

import { preschoolSchema } from '../schema'
import { PreschoolShape, UserRole } from 'data-models'
import {
  useCreatePreschool,
  useDeletePreschool,
  useEditPreschool,
} from 'services/preschoolService'
import Preschool from 'data-models/preschool'
import { useUser } from 'providers/UserProvider'
import { useTranslation } from 'react-i18next'

interface UsePreschoolEditorProps {
  initialPreschool: Preschool
}

export const usePreschoolEditor = ({
  initialPreschool,
}: UsePreschoolEditorProps) => {
  const history = useHistory()
  const toast = useToast()
  const { user } = useUser()

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  const editPreschool = useEditPreschool()
  const createPreschool = useCreatePreschool()
  const deletePreschool = useDeletePreschool()

  const formMethods = useForm<PreschoolShape>({
    defaultValues: initialPreschool,
    resolver: yupResolver(preschoolSchema),
  })

  const { handleSubmit } = formMethods

  useEffect(() => {
    if (initialPreschool) {
      formMethods.reset(initialPreschool)
    }
  }, [initialPreschool])
  const { t } = useTranslation()
  const onCreate = async (values: PreschoolShape) => {
    try {
      await createPreschool.mutateAsync({
        values,
        organizationId: user.organizationId,
      })
      toast({
        title: `${t('Preschool_Created_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/preschools')
    } catch (error) {
      toast({
        title: `${t('Preschool_Create_Error')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleCreate = handleSubmit(onCreate)

  const onEdit = async (values: PreschoolShape) => {
    try {
      await editPreschool.mutateAsync({
        values: {
          ...values,
          id: initialPreschool.id,
        },
        preschoolId: initialPreschool.id,
      })
      toast({
        title: `${t('Preschool_Updated_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
    } catch (error) {
      toast({
        title: `${t('Förskoleuppdatering_misslyckade')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleEdit = handleSubmit(onEdit)

  const handleDelete = async () => {
    await deletePreschool.mutateAsync({ id: initialPreschool.id })

    try {
      toast({
        title: `${t('Preschool_Deleted_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/preschools')
    } catch (error) {
      toast({
        title: `${t('Preschool_Delete_Error')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  return {
    isAdmin,
    formMethods,
    handleEdit,
    isEditLoading: editPreschool.isLoading,
    handleDelete,
    isDeleteLoading: deletePreschool.isLoading,
    handleCreate,
    isCreateLoading: createPreschool.isLoading,
  }
}
