import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Tr,
  Th,
  Td,
  Table,
  Thead,
  Tbody,
  Center,
  Spinner,
  Container,
  TableContainer,
} from '@chakra-ui/react'

import { Organization, OrganizationTypeLabel } from 'data-models'
import { useTranslation } from 'react-i18next'

interface OrganizationTableProps {
  loading: boolean
  organizations?: Organization[]
}

export const OrganizationTable = ({
  loading,
  organizations = [],
}: OrganizationTableProps) => {
  const history = useHistory()

  const handleEditOrganization = (organization: Organization) => {
    history.push(`/organization/${organization.id}`)
  }
  const { t } = useTranslation()
  return (
    <Container p='0' maxW='full' h='full'>
      {loading ? (
        <Center h='100px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        <>
          <TableContainer borderRadius='lg' h='full'>
            <Table variant='simple'>
              <Thead>
                <Tr
                  bgColor={'rgba(177, 197, 217, 0.5)'}
                  borderWidth='1px'
                  borderRadius='10px'
                  height='48px'
                  fontWeight={'bold'}
                  fontFamily={`'Open Sans',sans-serif`}
                  fontSize={'12px'}
                >
                  <Th>Organisation</Th>
                  <Th>{t('Municipality/Preschool')}</Th>
                  <Th>{t('City')}</Th>
                  <Th>{t('DashboardOverview_Number_of_Preschools')}</Th>
                  <Th>{t('Num_Of_Admin')}</Th>
                  <Th>{t('Num_Of_Users')}</Th>
                </Tr>
              </Thead>

              <Tbody>
                {organizations.map((organization, index) => (
                  <Tr
                    key={`${organization.id}_${index}`}
                    onClick={() => handleEditOrganization(organization)}
                    cursor={'pointer'}
                    _hover={{
                      backgroundColor: '#EFF1FF',
                    }}
                  >
                    <Td>{organization.name}</Td>
                    <Td>{OrganizationTypeLabel[organization.type]}</Td>
                    <Td>{organization.city}</Td>
                    <Td>{organization.numberOfPreschools}</Td>
                    <Td>{organization.numberOfAdmins}</Td>
                    <Td>{organization.numberOfUsers}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  )
}
