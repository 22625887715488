import React, { useState } from 'react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Td,
  Th,
  Tr,
  Box,
  Text,
  Tbody,
  Table,
  Thead,
  HStack,
  Textarea,
  useDisclosure,
  TableContainer,
} from '@chakra-ui/react'

import { ResultsFilter } from '../ResultsFilter'
import { useResultDetails } from './useResultDetails'
import TaskPreview from '../../TaskPreview/taskPreview'
import { TaskType, TaskAltText } from 'services/indicators'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ResultDetails = () => {
  const params = useParams() as any
  const { id } = params
  const { state } = useLocation()
  const location = useLocation()
  const isInPath = location.pathname === '/followup/create'
  const resultId = id ? id : (state as any).id
  const { resultDetails } = useResultDetails(resultId)

  const {
    isOpen: isTaskPreviewOpen,
    onOpen: onTaskPreviewOpen,
    onClose: onTaskPreviewClose,
  } = useDisclosure()
  const [quesId, setQuesId] = useState('')

  const openPreview = (event: any) => {
    setQuesId(event.target.id)
    onTaskPreviewOpen()
  }
  const { t } = useTranslation()
  return (
    <>
      {resultDetails && (
        <>
          <Box
            bg='#EFF1FF'
            w='100%'
            color='#2E0F78'
            p={8}
            style={{ fontFamily: 'Open Sans' }}
            fontWeight='700'
          >
            <HStack w='100%'>
              <NavLink to='/overview-results' activeClassName='active'>
                <Text fontSize='18px'>
                  <ChevronLeftIcon /> {t('Back_Text')}
                </Text>
              </NavLink>
            </HStack>
          </Box>
          <ResultsFilter
            filter={{
              followupId: resultDetails.followupId,
              areaIds: [resultDetails.areaId],
              preschoolIds: [resultDetails.preschoolId],
              // submittedAt: new Date(resultDetails.startDate),
              pageNumber: 0,
              pageSize: 0,
            }}
            onFilterChange={() => {}}
            isDisabled
          />

          <Box pt='4' />

          <Box>
            <Textarea
              placeholder={t('Description_FollowUp')}
              name='FollowupDesc'
              fontSize='14px'
              bgColor={'white'}
              color={'#1A2C3D !important'}
              border='1px'
              borderColor='#B1C5D9'
              h={'40px'}
              w={'100%'}
              borderRadius={'8px'}
              value={resultDetails.individualComment}
              disabled
            />
          </Box>

          <Box pt='8' mb='8' borderBottom='1px solid #B1C5D9' />

          <Box>
            <TableContainer borderRadius='10px'>
              <Table variant='simple'>
                <Thead position='sticky'>
                  <Tr
                    bgColor={'rgba(177, 197, 217, 0.5)'}
                    borderWidth='1px'
                    borderRadius='10px'
                    height='48px'
                    fontWeight={'bold'}
                    fontFamily={`'Open Sans',sans-serif`}
                    fontSize={'12px'}
                  >
                    <Th>{t('Questions_Text')}</Th>
                    <Th>{t('Task_Type_Text')}</Th>
                    <Th>{t('Correct_Answer_Number')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {resultDetails.tasks.map((task, index) => (
                    <Tr key={`${task.id}_${index}`}>
                      <Td
                        id={task.id}
                        onClick={(q: any) => {
                          openPreview(q)
                        }}
                        style={{ cursor: 'pointer', color: 'blue' }}
                      >
                        {task.type === 'video_multi' && (
                          <>
                            {Object.values(task.title)[0] as string}
                            <br />
                            {Object.values(task.title)[1] as string}
                          </>
                        )}
                        {task.type !== 'video_multi' && task.title}
                      </Td>
                      <Td
                        title={
                          TaskAltText[
                            TaskType[
                              task.type.replaceAll(
                                '-',
                                '_',
                              ) as keyof typeof TaskType
                            ] as keyof typeof TaskAltText
                          ]
                        }
                      >
                        {
                          TaskType[
                            task.type.replaceAll(
                              '-',
                              '_',
                            ) as keyof typeof TaskType
                          ]
                        }
                      </Td>
                      <Td>{task.score}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <TaskPreview
            isInPath={isInPath}
            isOpen={isTaskPreviewOpen}
            onClose={onTaskPreviewClose}
            taskId={quesId}
            selectedId={[]}
            taskOrder={[]}
            onTaskSelectionChange={(taskId, isSelected) => {
              // Dummy function, you can leave it empty if you don't need to handle task selection changes
            }}
          />
        </>
      )}
    </>
  )
}
