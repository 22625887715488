import * as yup from 'yup'
import i18n from 'i18next';

export const editProfileDetailsSchema = yup.object().shape({
  name: yup.string().required(i18n.t('Type_Name')),
  email: yup.string().required(i18n.t('Enter_Email')),
  phoneNumber: yup
    .string()
    .required(i18n.t('Enter_Phone'))
    .matches(
      /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
      `${i18n.language === 'se' ? 'Måste respektera internationell telefonnummerstandard.' : 
      i18n.language === 'de' ? 'Muss den internationalen Telefonnummernstandard einhalten.' : 
       'Must respect international phone number standard.'}`,
    ),
})
