import * as yup from 'yup'
import i18n from 'i18next';

export const userSchema = yup.object().shape({
  organizationId: yup.string().required(i18n.t('Choose_Org')),
  preschoolIds: yup.array().required(i18n.t('Choose_Preschool')),
  name: yup.string().required(i18n.t('Type_Name')),
  role: yup.string().required(i18n.t('Choose_Role')),
  email: yup.string().required(i18n.t('Enter_Email')),
  newPassword: yup.string().when('$id', (id, passSchema) =>
    id
      ? passSchema
      : passSchema
        .required(i18n.t('New_Password_Text'))
        .min(8, i18n.t('Atleast_8_Char'))
        .matches(/(?=.*[a-z])(?=.*[A-Z])/, i18n.t('Both_Capital_Small_Letters'))
        .matches(/(?=.*[0-9])/, i18n.t('One_Numeric_Char'))
        .matches(
          /(?=.*[^A-Za-z0-9])/,
          i18n.t('One_AlphaNumeric_Char'),
        ),
  ),
  confirmPassword: yup
    .string()
    .when('$id', (id, passSchema) =>
      id
        ? passSchema
        : passSchema
          .required(i18n.t('Type_Confirm_Password'))
          .oneOf([yup.ref('newPassword')], i18n.t('Password_Match_Text')),
    ),
})
