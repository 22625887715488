import React from 'react'
import { defaultFollowup } from 'data-models/followup'
import { YRFilter } from './YearlyFilterType'
import {
  ResultsPerAgeChart,
  ResultsPerAgeHorizonChart,
  ResultsPerGenderChart,
  ResultsPerSchoolAgeChart,
} from '../Charts'
import { ChartWrapper } from '../components'
import { DashboardFilterType } from '../../DashboardFilterType'
import { VStack } from '@chakra-ui/react'
import { ResultsPerAnswerCount } from '../Charts/ResultsPerAnswerCount'
import { useTranslation } from 'react-i18next'

export const defaultYRFilter: YRFilter = {
  assignmentTypes: [],
  followups: defaultFollowup,
  areas: [],
  preschools: [],
  ages: [],
}

interface YearlyResultProps {
  filter: DashboardFilterType
}

export const YearlyResult = ({ filter }: YearlyResultProps) => {
  const { t } = useTranslation()

  return (
    <VStack spacing='6' marginTop='6'>
      <ChartWrapper>
        <ResultsPerAgeChart
          filter={filter}
          title={t('Results_Per_Task')}
          helpText={t('YearlyResult_helpText_one')}
          helpDetailText={t('Results_Per_Tasks_HelpDetails')}
        />
      </ChartWrapper>
      <ChartWrapper w='100%' height='100%'>
        <ResultsPerAnswerCount
          filter={filter}
          title={t('Num_Children_Per_Task')}
          helpText={t('YearlyResult_helpText_two')}
          helpDetailText={t('Number_Of_Children_Per_Task_HelpDetails')}
        />
      </ChartWrapper>

      <ChartWrapper w='100%' height='100%'>
        <ResultsPerGenderChart
          filter={filter}
          helpText={t('YearlyResult_helpText_three')}
          helpDetailText={t('Results_By_Gender_HelpDetails')}
        />
      </ChartWrapper>
      <ChartWrapper w='100%' height='100%'>
        <ResultsPerAgeHorizonChart filter={filter} />
      </ChartWrapper>
      <ChartWrapper>
        <ResultsPerSchoolAgeChart filter={filter} helpDetailText={t('Results_By_Area_Age_HelpDetails')}/>
      </ChartWrapper>
    </VStack>
  )
}
