import React from 'react'
import { Controller } from 'react-hook-form'
import { User, UserRole } from 'data-models'
import { Flex, Grid, Input, VStack, GridItem, Button } from '@chakra-ui/react'
import { FormWrapper } from 'common/Form'
import { FormSelect } from 'common/FormSelect'
import { useUserDetails } from './hooks'
import { UserPasswordReset } from './UserPasswordReset'
import CustomSelect from 'common/CustomSelect'
import { PasswordInput } from 'common/PasswordInput'
import { useTranslation } from 'react-i18next'

interface UserDetailsProps {
  user: User
}

export const UserDetails = ({ user }: UserDetailsProps) => {
  const {
    isAdmin,
    formMethods,
    roleOptions,
    preschoolOptions,
    organizationOptions,
    selectAllPreschools,
    isResetPasswordModalOpen,
    onResetPasswordModalOpen,
    onResetPasswordModalClose,
  } = useUserDetails()

  const { control, register } = formMethods
  const { t } = useTranslation()
  return (
    <VStack w='full'>
      <Flex w='full' h='full'>
        <Grid w='full' gridTemplateColumns={'repeat(2, 1fr)'} gridGap={6}>
          <GridItem>
            <FormWrapper
              name='organizationId'
              label={t('Organization_Text')}
              isRequired
            >
              <Controller
                control={control}
                name='organizationId'
                render={({ field: { onChange, value, ...rest } }) => (
                  <FormSelect
                    options={organizationOptions}
                    placeholder={t('Organization_Text')}
                    value={organizationOptions.find(
                      option => option.value == value,
                    )}
                    onChange={val => onChange(val?.value)}
                    isDisabled={!isAdmin}
                    {...rest}
                  />
                )}
              />
            </FormWrapper>
          </GridItem>

          <GridItem>
            <FormWrapper
              name='preschoolIds'
              label={t('ResultFilter_Preschool')}
              isRequired
            >
              <Controller
                control={control}
                name='preschoolIds'
                render={({ field: { onChange, value, ...rest } }) => (
                  <CustomSelect
                    options={preschoolOptions}
                    placeholder={t('ResultFilter_Preschool')}
                    isMulti={true}
                    onChange={e => onChange(e.map(e => e.value))}
                    value={preschoolOptions.filter(preschoolOption =>
                      value?.includes(preschoolOption?.value),
                    )}
                    searchable={true}
                    minWidth='250px'
                    isDisabled={!isAdmin}
                    {...rest}
                  />
                )}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='name' label={t('Name')} isRequired>
              <Input
                type='text'
                placeholder={t('Name')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                isDisabled={!isAdmin}
                {...register('name')}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='role' label={t('Role_Text')} isRequired>
              <Controller
                control={control}
                name='role'
                render={({ field: { onChange, value, ...rest } }) => (
                  <FormSelect
                    options={roleOptions}
                    placeholder={t('Role_Text')}
                    value={roleOptions.find(option => option.value === value)}
                    onChange={val => {
                      if (val?.value === UserRole.ADMIN) {
                        selectAllPreschools()
                      }
                      onChange(val?.value)
                    }}
                    isDisabled={!isAdmin}
                    {...rest}
                  />
                )}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='email' label={t('Email_Address')} isRequired>
              <Input
                type='text'
                placeholder={t('Email_Address')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                isDisabled={!isAdmin}
                {...register('email')}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='phoneNumber' label={t('Phone_Text')}>
              <Input
                type='number'
                placeholder={t('Phone_Text')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                isDisabled={!isAdmin}
                {...register('phoneNumber')}
              />
            </FormWrapper>
          </GridItem>
          {user?.id ? (
            <GridItem>
              <Button
                height='40px'
                fontSize={'md'}
                onClick={onResetPasswordModalOpen}
                isDisabled={!isAdmin}
              >
                {t('Change_Password')}
              </Button>
            </GridItem>
          ) : (
            <>
              <GridItem>
                <FormWrapper
                  name='newPassword'
                  label={t('New_Password')}
                  isRequired
                >
                  <PasswordInput
                    placeholder={t('New_Password')}
                    _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                    focusBorderColor=''
                    fontSize='14px'
                    bgColor={'white'}
                    color={'#1A2C3D'}
                    border='1px'
                    borderColor='#B1C5D9'
                    h={'40px'}
                    w='full'
                    borderRadius={'lg'}
                    isDisabled={!isAdmin}
                    {...register('newPassword')}
                  />
                </FormWrapper>
              </GridItem>
              <GridItem>
                <FormWrapper
                  name='confirmPassword'
                  label={t('Confirm_Password')}
                  isRequired
                >
                  <PasswordInput
                    placeholder={t('Confirm_Password')}
                    _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                    focusBorderColor=''
                    fontSize='14px'
                    bgColor={'white'}
                    color={'#1A2C3D'}
                    border='1px'
                    borderColor='#B1C5D9'
                    h={'40px'}
                    w='full'
                    borderRadius={'lg'}
                    isDisabled={!isAdmin}
                    {...register('confirmPassword')}
                  />
                </FormWrapper>
              </GridItem>
            </>
          )}
        </Grid>
      </Flex>

      {user?.id && (
        <UserPasswordReset
          user={user}
          isOpen={isResetPasswordModalOpen}
          onClose={onResetPasswordModalClose}
        />
      )}
    </VStack>
  )
}
