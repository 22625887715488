import * as yup from 'yup'

export const createUserSchema = yup.object().shape({
  name: yup.string().required('Skriv ett namn'),
  email: yup.string().required('Skriv ett e-postadress'),
  phoneNumber: yup.string().required('Skriv ett telefon'),
  newPassword: yup.string().when('$id', (id, passSchema) =>
    id
      ? passSchema
      : passSchema
          .required('Skriv ett nytt lösenord')
          .min(8, 'Måste innehålla minst 8 tecken')
          .matches(/(?=.*[a-z])(?=.*[A-Z])/, 'Bade stora och sma bokstaver')
          .matches(/(?=.*[0-9])/, 'Måste innehålla minst ett numeriskt tecken')
          .matches(
            /(?=.*[^A-Za-z0-9])/,
            'Måste innehålla minst ett icke-alfanumeriskt tecken',
          ),
  ),
  confirmPassword: yup
    .string()
    .when('$id', (id, passSchema) =>
      id
        ? passSchema
        : passSchema
            .required('Skriv ett bekräftelselösenord')
            .oneOf([yup.ref('newPassword')], 'Lösenorden måste matcha'),
    ),
})
