import React from 'react'
import { Box, Divider } from '@chakra-ui/react'

interface CustomDividerProps {
  spacing?: string
  color?: string
}

export const CustomDivider = ({
  spacing = '4',
  color = 'blackAlpha.500',
}: CustomDividerProps) => {
  return (
    <Box w='full' py={spacing}>
      <Divider borderColor={color} w='full' size={'1'} />
    </Box>
  )
}
