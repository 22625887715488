import React from 'react'
import {
  Td,
  Tr,
  Box,
  Table,
  Thead,
  Tbody,
  VStack,
  Spinner,
  TableContainer,
} from '@chakra-ui/react'
import { useHistory, generatePath } from 'react-router-dom'
import { StatusLabels, StatusLabelsEn,StatusLabelsDe } from 'services/indicators'
import { FollowupsResponse, UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { Pagination } from 'common/Pagination/Pagination'
import { FollowupOverviewFilterType } from '../types'
import { useTranslation } from 'react-i18next'

interface FollowupTableProps {
  loading: boolean
  response: FollowupsResponse
  filter: FollowupOverviewFilterType
  onFilterChange: (filter: Partial<FollowupOverviewFilterType>) => void
}

export const FollowupTable = ({
  filter,
  loading,
  response,
  onFilterChange,
}: FollowupTableProps) => {
  const {
    user: { role },
  } = useUser()
  const history = useHistory()
  const { data = [], totalCount = 0 } = response
  const { t, i18n } = useTranslation()
  const followupList = data
  const followupgridlist = followupList.filter(f => f.tasks.length !== 0)

  const handlePageSizeChange = (newSize: number) => {
    onFilterChange({ pageSize: newSize })
  }

  const handlePageNumberChange = (newIndex: number) => {
    onFilterChange({ pageNumber: newIndex })
  }

  const isSuperAdmin = [UserRole.SUPERADMIN].includes(role)

  if (!loading) {
    return (
      <Box flex={10} bg='white' h='100%'>
        <TableContainer borderRadius='10px'>
          <Table variant='simple'>
            <Thead>
              <Tr
                bgColor={'rgba(177, 197, 217, 0.5)'}
                borderWidth='1px'
                borderRadius='10px'
                height='48px'
                fontWeight={'bold'}
                fontFamily={`'Open Sans',sans-serif`}
                fontSize={'12px'}
              >
                <Td>{t('FollowupTable_Follow-up_name')}</Td>
                <Td>{t('FollowupTable_School_year')}</Td>
                <Td>{t('FollowupTable_Creation_Day')}</Td>
                <Td>{t('FollowupTable_Start_date')}</Td>
                <Td>{t('FollowupTable_End_date')}</Td>
                <Td>{t('FollowupTable_Status')}</Td>
                {/* {isSuperAdmin && <Td>{t('FollowupTable_Exportable')}</Td>} */}
              </Tr>
            </Thead>
            <Tbody>
              {followupgridlist.map(x => (
                <Tr
                  fontWeight={'light'}
                  fontFamily={`'Open Sans',sans-serif`}
                  fontSize={'14px'}
                  key={x.id}
                  // style={(x.tasks.length != 0)?{cursor : 'pointer'}:{cursor : 'default'}}
                  _hover={
                    x.tasks.length !== 0
                      ? { backgroundColor: '#EFF1FF', cursor: 'pointer' }
                      : { cursor: 'default' }
                  }
                  onClick={() => {
                    if (x.tasks.length !== 0 || x.status === 'Draft') {
                      history.push({
                        pathname: generatePath('/followup/:id', { id: x.id }),
                        state: {
                          activeView: 'ListDetails',
                          headerText: x.name,
                          followupId: x.id,
                        },
                      })
                    }
                  }}
                >
                  <Td maxW='400px' overflow='hidden'>
                    {x.name}
                  </Td>
                  <Td>{x.academicYear}</Td>
                  <Td>{x.createdAt.split('T')[0]}</Td>
                  <Td>{x.startDate.split('T')[0]}</Td>
                  <Td>{x.endDate.split('T')[0]}</Td>
                  <Td>
                    {i18n.language === 'se'
                    ? StatusLabels[x.status]
                    : i18n.language === 'de'
                      ? StatusLabelsDe[x.status]
                      : StatusLabelsEn[x.status]}
                  </Td>
                  {/* {isSuperAdmin && (
                    <Td>
                      {' '}
                      {x.isExportable
                        ? i18n.language === 'en'
                          ? 'Yes'
                          : 'Ja'
                        : i18n.language === 'sv'
                        ? 'Nej'
                        : 'No'}
                    </Td>
                  )} */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {totalCount > 10 && (
          <Pagination
            siblingCount={1}
            totalCount={totalCount}
            settings={{
              pageNumber: filter.pageNumber,
              pageSize: filter.pageSize,
            }}
            onPageNumberChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
          />
        )}
      </Box>
    )
  } else {
    return (
      <Box flex={10} bg='white' h='100%'>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr
                bgColor={'rgba(177, 197, 217, 0.5)'}
                borderWidth='1px'
                borderRadius='10px'
                height='48px'
                fontWeight={'bold'}
                fontFamily={`'Open Sans',sans-serif`}
                fontSize={'12px'}
              >
                <Td>{t('FollowupTable_Follow-up_name')}</Td>
                <Td>{t('FollowupTable_School_year')}</Td>
                <Td>{t('FollowupTable_Start_date')}</Td>
                <Td>{t('FollowupTable_End_date')}</Td>
                <Td>{t('FollowupTable_Status')}</Td>
              </Tr>
            </Thead>
          </Table>
          <VStack pt='10' spacing='5' w='full' h='full' justify='center'>
            <Spinner size='xl' color='black' />
          </VStack>
        </TableContainer>
      </Box>
    )
  }
}
