import React from 'react'
import { VStack } from '@chakra-ui/react'
import { CustomDivider } from 'common/Spacing'
import { ProfileDetails, ProfilePassword } from './components'

export const Profile = () => {
  return (
    <VStack w='full'>
      <ProfileDetails />
      <CustomDivider spacing='5' />
      <ProfilePassword />
    </VStack>
  )
}
