import { useDisclosure } from '@chakra-ui/react'

export const useDeleteButton = () => {
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure()

  return {
    isConfirmDeleteOpen,
    onConfirmDeleteOpen,
    onConfirmDeleteClose,
  }
}
