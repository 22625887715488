import { UserRole } from 'data-models'

export const defaultFilter: UsersFilterType = {
  name: '',
  preschoolId: [],
}

export type UsersFilterType = {
  name: string
  preschoolId: string[]
  role?: UserRole
}
