import { AveragePerGenderStatistics } from 'data-models/preschool'
import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getAveragePerGenderStatistics } from 'services/followupResultService'
import { DashboardFilterType } from '../../DashboardFilterType'

export const useResultsPerGenderChart = (filter: DashboardFilterType) => {
  const [stats, setStats] = useState<AveragePerGenderStatistics[]>([])

  const {
    user: { organizationId },
  } = useUser()

  const response = useAsync(
    () =>
      getAveragePerGenderStatistics(
        organizationId,
        filter.followupId,
        filter.areaIds,
        filter.preschoolIds,
        filter.departmentIds
      ),
    [filter],
  )

  const { value, loading } = response

  useEffect(() => {
    setStats(value ? value : [])
  }, [filter, value])

  return { stats, loading }
}
