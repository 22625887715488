import React from 'react'
import { Box } from '@chakra-ui/react'
import MainHeader from '../../common/MainHeader'
import { useHistory } from 'react-router-dom'

export default function FollowUp(props: any) {
  // if(!localStorage.getItem('load')){
    localStorage.setItem("load","false")
  // }
  const history = useHistory()
  const refresh = props.location?.state?.refresh || false
  if (refresh) {
    window.location.reload()
    history.push('/')
  }

  return (
    <Box flex={10} bg='white' h='100%'>
      <MainHeader />
      
    </Box>
  )
}
