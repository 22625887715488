export enum Indicator {
  FindPatterns = 'FindPatterns',
  ImageScore = 'ImageScore',
  ExpressOpinions = 'ExpressOpinions',
  Cooperation = 'Cooperation',
  Reasoning = 'Reasoning',
  POJKE = 'POJKE',
  FLICKA = 'FLICKA',
  StaffTurnover = 'StaffTurnover',
  TeacherSurvey = 'TeacherSurvey',
  ParentsWithHigherEducation = 'ParentsWithHigherEducation',
  ForeignBackground = 'ForeignBackground',
  FundingPerChild = 'FundingPerChild',
  AverageTotal = 'AverageTotal',
  ChildrenResult = 'ChildrenResult',
  ChildrenResultMunicipality = 'ChildrenResultMunicipality',
  PedagogicalExam = 'PedagogicalExam',
  ProportionOfBoys = 'ProportionOfBoys',
  SickLeave = 'SickLeave',
  meanAboveMedian = 'meanAboveMedian',
  meanBelowMedian = 'meanBelowMedian',
  Age = 'Age',
  Progression = 'Progression',
  Gender = 'Gender',
  GenderMunicipality = 'Average result for organization',
  NumberOfChildren = 'NumberOfChildren',
  expressesOwnPerspective = 'expresses_own_perspective',
  expressesInnerImagesSaga = 'expresses_inner_images_saga',
  expressesInnerImagesFace = 'expresses_inner_images_face',
  expressesInnerImagesSandcastles = 'expresses_inner_images_sandcastles',
  independentChoices = 'independent_choices',
  expressesInnerImages = 'expresses_inner_images',
  ScoreBelow15hrs = 'ScoreBelow15hrs',
  ScoreAbove15hrs = 'ScoreAbove15hrs',
  actualWeeklyPresence = 'actualWeeklyPresence',
  result = 'result',
  AveragePerAge = 'AveragePerAge',
}

export type IndicatorMapper = { [x in keyof typeof Indicator]: string }

export const IndicatorLabels: IndicatorMapper = {
  FindPatterns: 'Hitta mönster',
  ImageScore: 'Förklara',
  ExpressOpinions: 'Föra fram åsikter',
  Cooperation: 'Samarbeta',
  Reasoning: 'Resonera',
  POJKE: 'Pojke',
  FLICKA: 'Flicka',
  StaffTurnover: 'Personalomsättning',
  TeacherSurvey: 'HME',
  ParentsWithHigherEducation: 'Föräldrar med akademisk utbildning',
  ForeignBackground: 'Utländsk bakgrund',
  FundingPerChild: 'Uppbyggandemedel per barn',
  AverageTotal: 'Genomsnittligt resultat',
  ChildrenResult: 'Genomsnitt förskola',
  ChildrenResultMunicipality:'Genomsnitt kommun',
  result: 'Genomsnittligt resultat',
  ProportionOfBoys: 'Andel pojkar',
  PedagogicalExam: 'Pedagogisk examen',
  SickLeave: 'Sjukfrånvaro',
  meanAboveMedian: 'Högre än median',
  meanBelowMedian: 'Lägre än median',
  Age: 'Ålder',
  Progression: 'Progression',
  AveragePerAge: 'Genomsnitt efter förskola',
  Gender: 'Genomsnitt förskola',
  GenderMunicipality:'Genomsnitt kommun',
  NumberOfChildren: 'Antal barn',
  expressesOwnPerspective: 'Uttrycker sitt eget perspektiv',
  expressesInnerImagesSaga: 'Uttrycker sina inre bilder (saga)',
  expressesInnerImagesFace: 'Uttrycker sina inre bilder (ansikte)',
  expressesInnerImagesSandcastles: 'Uttrycker sina inre bilder (sandslott)',
  independentChoices: 'Självständiga val',
  expressesInnerImages: 'Uttrycker sina inre bilder',
  ScoreBelow15hrs: 'Veckovis närvaro under 15 timmar',
  ScoreAbove15hrs: 'Veckovis närvaro över 15 timmar',
  actualWeeklyPresence: 'Veckovis närvaro timmar',
}
export const IndicatorLabelsEn: IndicatorMapper = {
  FindPatterns: 'Find Patterns',
  ImageScore: 'Explain',
  ExpressOpinions: 'Presenting Opinions',
  Cooperation: 'Cooperate',
  Reasoning: 'Resoner',
  POJKE: 'Boy',
  FLICKA: 'Girl',
  StaffTurnover: 'Staff Turnover',
  TeacherSurvey: 'HME',
  ParentsWithHigherEducation: 'Parents with Higher Education',
  ForeignBackground: 'Foreign Background',
  FundingPerChild: 'Funding funds per child',
  AverageTotal:'Average result',
  ChildrenResult: 'Average preschool',
  ChildrenResultMunicipality: 'Average organisation',
  result: 'Average result',
  ProportionOfBoys: 'Proportion of boys',
  PedagogicalExam: 'Pedagogical exam',
  SickLeave: 'Sick leave',
  meanAboveMedian: 'Higher than median',
  meanBelowMedian: 'Lower than median',
  Age: 'Age',
  Progression: 'Progression',
  AveragePerAge: 'Average by preschool',
  Gender: 'Average preschool',
  GenderMunicipality: 'Average organisation',
  NumberOfChildren: 'Number of children',
  expressesOwnPerspective: 'Expresses own perspective',
  expressesInnerImagesSaga: 'Expresses its inner images (saga)',
  expressesInnerImagesFace: 'Expresses its inner images (face)',
  expressesInnerImagesSandcastles: 'Expresses its inner images (sandcastles)',
  independentChoices: 'Independent Choices',
  expressesInnerImages: 'Expresses its inner images',
  ScoreBelow15hrs: 'Weekly attendance under 15 hours',
  ScoreAbove15hrs: 'Weekly attendance above 15 hours',
  actualWeeklyPresence: 'Weekly presence hours',
}
export const IndicatorLabelsDe: IndicatorMapper = {
  FindPatterns: 'Muster finden',
  ImageScore: 'Erklären',
  ExpressOpinions: 'Meinungen präsentieren',
  Cooperation: 'Kooperieren',
  Reasoning: 'Argumentieren',
  POJKE: 'Junge',
  FLICKA: 'Mädchen',
  StaffTurnover: 'Personalfluktuation',
  TeacherSurvey: 'HME',
  ParentsWithHigherEducation: 'Eltern mit höherer Bildung',
  ForeignBackground: 'Migrationshintergrund',
  FundingPerChild: 'Finanzierung pro Kind',
  AverageTotal: 'Durchschnittliches Ergebnis',
  ChildrenResult: 'Durchschnitt Kindergarten',
  ChildrenResultMunicipality: 'Durchschnitt Organisation',
  result: 'Durchschnittliches Ergebnis',
  ProportionOfBoys: 'Anteil der Jungen',
  PedagogicalExam: 'Pädagogische Prüfung',
  SickLeave: 'Krankenstand',
  meanAboveMedian: 'Über dem Median',
  meanBelowMedian: 'Unter dem Median',
  Age: 'Alter',
  Progression: 'Fortschritt',
  AveragePerAge: 'Durchschnitt pro Altersgruppe',
  Gender: 'Durchschnitt Kindergarten',
  GenderMunicipality: 'Durchschnitt Organisation',
  NumberOfChildren: 'Anzahl der Kinder',
  expressesOwnPerspective: 'Eigene Perspektive ausdrücken',
  expressesInnerImagesSaga: 'Innere Bilder ausdrücken (Märchen)',
  expressesInnerImagesFace: 'Innere Bilder ausdrücken (Gesicht)',
  expressesInnerImagesSandcastles: 'Innere Bilder ausdrücken (Sandburgen)',
  independentChoices: 'Unabhängige Entscheidungen',
  expressesInnerImages: 'Innere Bilder ausdrücken',
  ScoreBelow15hrs: 'Wöchentliche Anwesenheit unter 15 Stunden',
  ScoreAbove15hrs: 'Wöchentliche Anwesenheit über 15 Stunden',
  actualWeeklyPresence: 'Wöchentliche Anwesenheitsstunden',
}
export interface ClusterResultDistribution {
  clusterName: string
  FindPatterns: number
  Reasoning: number
  ExpressOpinions: number
  Cooperation: number
  ImageScore: number
  age2: string
  age3: string
  age4: string
  age5: string
}

export enum ClusterLabels {
  Höga = 'Höga',
  Låga = 'Låga',
  Medel = 'Medel',
}

export interface ChildResult {
  areaId: number
  assignmentId: number
  preschoolName: string
  followUp: string
  result: number
  age: number
  gender: string
  childCount: number
  year: string
}
