import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const CreateButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      h='40px'
      fontSize='15px'
      bgColor='#8A2BE2'
      color='white'
      type='submit'
      {...props}
    >
      {t('FollowupFilter_Create')}
    </Button>
  )
}
