import React, { useState, useEffect } from 'react'
import {
  Flex,
  Modal,
  Image,
  HStack,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  IconButton,
  Box,
  Spacer,
  Text,
  ModalBody,
  Button,
  Checkbox,
} from '@chakra-ui/react'
import CheckboxTaskCmp from './CheckboxTask'
import ImagePlacementTaskCmp from './ImagePlacement/ImagePlacementTask'
import ImageSortingTaskCmp from './ImageSorting/ImageSortingTask'
import ImageSelectionTaskCmp from './ImageSelectionTask'
import VideoTaskCmp from './VideoTask/VideoTask'
import VideoMultiTaskCmp from './VideoTask/VideoMultiTask'
import { DndProvider, Preview } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Task } from '../../../../data-models'
import LanguageTask from './LanguageAnalysis/LanguageTask'

export default function TaskPreview(props: {
  isOpen: boolean
  isInPath: boolean
  onClose: () => void
  taskId: string
  selectedId: string[]
  taskOrder: Task[]
  onTaskSelectionChange: (tas: string, id: boolean) => void
}) {
  const { t } = useTranslation()
  const [currentTaskIndex, setCurrentTaskIndex] = useState<number>(0)

  function extractTaskIds(data: Task[]): string[] {
    const taskIds: string[] = []

    data.forEach(task => {
      taskIds.push(task.id)
    })

    return taskIds
  }
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([])

  const ids = extractTaskIds(props.taskOrder)
  useEffect(() => {
    const index = props.taskOrder.findIndex(task => task.id === props.taskId)
    if (index !== -1) {
      setCurrentTaskIndex(index)
    }
  }, [props.taskId, props.onClose, props.isOpen])

  useEffect(() => {
    setSelectedTaskIds(props.selectedId)
  }, [props.selectedId])

  const onNextClick = () => {
    const lastIndex = ids.length - 1
    if (currentTaskIndex < lastIndex) {
      setCurrentTaskIndex(currentTaskIndex + 1)
    } else {
      setCurrentTaskIndex(0)
    }
  }

  const onPreviousClick = () => {
    const lastIndex = ids.length - 1

    if (currentTaskIndex > 0) {
      setCurrentTaskIndex(currentTaskIndex - 1)
    } else {
      setCurrentTaskIndex(lastIndex)
    }
  }

  const handleCheckboxChange = (taskId: string) => {
    let updatedSelectedIds = [...selectedTaskIds]

    if (selectedTaskIds.includes(taskId)) {
      updatedSelectedIds = updatedSelectedIds.filter(id => id !== taskId)
    } else {
      updatedSelectedIds.push(taskId)
    }

    setSelectedTaskIds(updatedSelectedIds)

    props.onTaskSelectionChange(taskId, updatedSelectedIds.includes(taskId))
  }

  const taskToPreview = props.taskOrder[currentTaskIndex]

  enum TaskType {
    Checkbox = 'checkbox_selection',
    // Audio = 'audio',
    Video = 'video',
    VideoMulti = 'video_multi',
    ImagePlacement = 'image_placement',
    ImageSelection = 'image_selection',
    ImageSorting = 'image_sorting',
    LanguageAnalysis = 'language_analysis',
  }

  const generatePreview = ({ itemType: _itemType, item, style }: any) => {
    return <Image src={item.image} style={{ ...style, opacity: 0.5 }} />
  }

  const TaskMapping = {
    [TaskType.Checkbox]: (
      <CheckboxTaskCmp key={taskToPreview?.id} task={taskToPreview} />
    ),
    // [TaskType.Audio]: <AudioTaskCmp task = {taskToPreview}/>,
    [TaskType.Video]: (
      <VideoTaskCmp key={taskToPreview?.id} task={taskToPreview} />
    ),
    [TaskType.VideoMulti]: (
      <VideoMultiTaskCmp key={taskToPreview?.id} task={taskToPreview} />
    ),
    [TaskType.ImagePlacement]: (
      <DndProvider options={HTML5toTouch}>
        <ImagePlacementTaskCmp key={taskToPreview?.id} task={taskToPreview} />,
        <Preview generator={generatePreview} />
      </DndProvider>
    ),
    [TaskType.ImageSorting]: (
      <DndProvider options={HTML5toTouch}>
        <ImageSortingTaskCmp key={taskToPreview?.id} task={taskToPreview} />,
        <Preview generator={generatePreview} />
      </DndProvider>
    ),
    [TaskType.ImageSelection]: (
      <ImageSelectionTaskCmp key={taskToPreview?.id} task={taskToPreview} />
    ),
    [TaskType.LanguageAnalysis]: (
      <LanguageTask key={taskToPreview?.id} task={taskToPreview} />
    ),
  }

  function onClosePreview() {
    if (
      taskToPreview &&
      (taskToPreview.type === TaskType.ImagePlacement ||
        taskToPreview.type === TaskType.ImageSorting)
    )
      taskToPreview.answerOptions.forEach((a: any) => (a.placedAtPoint = null))

    props.onClose()
  }
  return taskToPreview ? (
    <Modal
      onClose={onClosePreview}
      isOpen={props.isOpen}
      isCentered={true}
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent maxH='80%' maxW='65%' overflow={'hidden'}>
        <HStack
          // position='sticky'
          justifyContent='space-between'
          top='20px'
          right='20px'
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '20px',
              marginLeft: '20px',
            }}
          >
            <ModalHeader style={{ flex: 1 }} p='0'>
              {t('Preview_Text')}
            </ModalHeader>
          </Box>

          <ModalCloseButton
            // margin='20px'
            top={'20px'}
            right={'20px'}
            style={{
              borderWidth: '1px',
              borderColor: 'black',
              borderRadius: '100%',
              backgroundColor: 'white',
            }}
            _focus={{ boxShadow: 'none' }}
            _hover={{ opacity: 0.3 }}
          />
        </HStack>

        <ModalBody maxH={'-webkit-max-content'}>
          <HStack justifyContent={'center'}>
            {props.isInPath && (
              <Box
                display={'flex'}
                borderRadius='100%'
                justifyContent={'center'}
                alignItems='center'
                h='40px'
                w='40px'
                border='2px solid #8A2BE2'
                onClick={onPreviousClick}
                cursor='pointer'
                position={'fixed'}
                top={'50%'}
                transform='translateY(-50%)'
                left='20%'
              >
                <IconButton
                  onClick={onPreviousClick}
                  icon={<ChevronLeftIcon />}
                  colorScheme='#8A2BE2'
                  color={'#8A2BE2'}
                  backgroundColor={'transparent'}
                  aria-label='Previous'
                  as='span'
                  boxSize='0'
                  fontSize='30px'
                />
              </Box>
            )}
            <Flex
              direction='column'
              h='90vh'
              bg='white'
              w='95%'
              overflow={'hidden'}
              alignItems={'center'}
              ml='0'
            >
              {/* <HStack w='90%' m='0 auto' pb='3%'> */}
              <HStack w='90%' pb='0' mb='-3%' mt='4%'>
                <Box style={{ flex: 1 }}>
                  {props.isInPath
                    ? ` Task list ( ${props.selectedId.length} / 5 )`
                    : null}
                </Box>
                {props.isInPath && (
                  <Button
                    h='40px'
                    fontSize='15px'
                    bgColor='#8A2BE2'
                    color='white'
                    onClick={() => handleCheckboxChange(taskToPreview.id)} // Optional: Handle checkbox change on button click
                    isDisabled={
                      !selectedTaskIds.includes(taskToPreview.id) &&
                      selectedTaskIds.length >= 5
                    } // Disable button if checkbox is disabled
                    display='flex'
                    alignItems='center'
                    cursor={'pointer'}
                  >
                    <>
                      <input
                        type='checkbox'
                        name='checkbox'
                        disabled={
                          !selectedTaskIds.includes(taskToPreview.id) &&
                          selectedTaskIds.length >= 5
                        }
                        checked={selectedTaskIds.includes(taskToPreview.id)}
                        onChange={() => handleCheckboxChange(taskToPreview.id)}
                        style={{
                          width: '18px',
                          height: '18px',
                          accentColor: 'white',
                          cursor: 'pointer',
                        }}
                        className='select-task-check'
                      />

                      <Text pl={2}>{t('Select')}</Text>
                    </>
                  </Button>
                )}
              </HStack>

              <div
                style={{
                  height: 'auto',
                  marginTop: '60px',
                  overflow: 'auto',
                  marginBottom: '20%',
                  width: '90%',
                }}
              >
                <HStack color='white' overflow='hidden' justifyContent='center'>
                  {TaskMapping[taskToPreview.type as TaskType]}
                </HStack>
              </div>
            </Flex>
            {props.isInPath && (
              <Box
                display='flex'
                borderRadius='100%'
                justifyContent={'center'}
                alignItems='center'
                h='40px'
                w='40px'
                border='2px solid #8A2BE2'
                onClick={onNextClick}
                cursor='pointer'
                position={'fixed'}
                top='50%'
                right='20%'
              >
                <IconButton
                  onClick={onNextClick}
                  icon={<ChevronRightIcon />}
                  colorScheme='#8A2BE2'
                  color={'#8A2BE2'}
                  backgroundColor={'transparent'}
                  aria-label='Next'
                  as='span'
                  boxSize='0'
                  fontSize='30px'
                />
              </Box>
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    <></>
  )
}
