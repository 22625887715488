import React, { useState } from 'react'
import { VStack } from '@chakra-ui/react'
import { UserFilter, UserTable } from './components'
import { defaultFilter, UsersFilterType } from './UsersFilterType'
import { UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { Redirect } from 'react-router-dom'

export const Users = () => {
  const [filter, setFilter] = useState<UsersFilterType>(defaultFilter)

  const handleFilterChange = (newFilter: Partial<UsersFilterType>) => {
    setFilter({ ...filter, ...newFilter })
  }

  const { user } = useUser()

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  if (!isAdmin) {
    return <Redirect to='/settings/my-profile' />
  }

  return (
    <VStack w='full' spacing={'12'}>
      <UserFilter filter={filter} onFilterChange={handleFilterChange} />
      <UserTable filter={filter} />
    </VStack>
  )
}
