import React, { PropsWithChildren } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'

export const SimpleActionButton = ({
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  return (
    <Button
      h='40px'
      fontSize='15px'
      bgColor='#8A2BE2'
      color='white'
      type='submit'
      {...rest}
    >
      {children}
    </Button>
  )
}
