import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useToast } from '@chakra-ui/react'
import { useUser } from 'providers/UserProvider'
import { editProfileDetailsSchema } from '../schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditProfileShape } from 'data-models/profile'
import { useEditUser, useUserDetails } from 'services/userService'
import { AuthService } from 'services/authService'
import { UserRole } from 'data-models'
import { useTranslation } from 'react-i18next'

const defaultValues = {
  name: '',
  email: '',
}

export const useProfileDetails = () => {
  const toast = useToast()
  const { user, setUser } = useUser()
  const { t } = useTranslation()
  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  const editUser = useEditUser()

  const { data: userDetails, isInitialLoading: isFetchLoading } =
    useUserDetails({
      id: user.id,
      organizationId: user.organizationId,
    })

  const [isUpdateUserLoading, setUpdateUserLoading] = useState(false)

  const formMethods = useForm<EditProfileShape>({
    defaultValues: userDetails
      ? {
        name: userDetails.name,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber || '', // Conditionally assign phone number value
      }
      : defaultValues,
    resolver: yupResolver(editProfileDetailsSchema),
  })

  useEffect(() => {
    if (userDetails) {
      formMethods.reset({
        name: userDetails.name,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber || '', // Conditionally assign phone number value
      })
    }
  }, [userDetails])

  const submitHandler = async (values: EditProfileShape) => {
    try {
      if (user.email && userDetails) {
        setUpdateUserLoading(true)
        await editUser.mutateAsync({
          values: {
            ...values,
            role: user.role,
            preschoolIds: user.preschoolIds || [],
            username: values.email,
          },
          id: user.id,
          organizationId: user.organizationId,
        })

        toast({
          title: `${t('User_Updated')}`,
          isClosable: true,
          duration: 3000,
          status: 'success',
        })

        AuthService.cacheUser({
          ...user,
          email: values.email,
          phoneNumber: values.phoneNumber,
          name: values.name,
          username: values.email,
        })

        setUser(AuthService.getCachedUser())

        await AuthService.refreshToken()
      }
    } catch (error) {
      toast({
        title: `${t('Update_User_Failed')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    } finally {
      setUpdateUserLoading(false)
    }
  }

  const onSubmit = formMethods.handleSubmit(submitHandler)

  return {
    isAdmin,
    onSubmit,
    formMethods,
    isFetchLoading,
    isUpdateUserLoading,
  }
}
