/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { useState } from 'react'
import {
  Tab,
  Tabs,
  Stack,
  TabList,
  TabPanel,
  useToast,
  TabPanels,
} from '@chakra-ui/react'
import PreschoolResultGridView from './FollowupSummary/PreschoolResultGridView'
import { DashboardFilter } from './Dashboard/DashboardFilter'
import { LanguageAnalysisFilter } from './LanguageAnalysis/LanguageAnalysisFilter'
import { DashboardFilterType } from './Dashboard/DashboardFilterType'
import { DashboardOverview } from './Dashboard/DashboardOverView'
import { LanguageAnalysisOverview } from './LanguageAnalysis/LanguageAnalysisOverview'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { triggerPreschoolStatistics } from 'services/followupResultService'
import { useUser } from 'providers/UserProvider'
import ProgressionCharts from './Progression/ProgressionCharts'
import { useFilters } from 'providers/FilterProvider'
import { ProgressionFilterType } from './Progression/types'
import { useTranslation } from 'react-i18next'

export const defaultDashdoardFilter: DashboardFilterType = {
  followupId: '',
  areaIds: [],
  preschoolIds: [],
  departmentIds: []
}


export const defaultProgressionFilter: ProgressionFilterType = {
  followups: [],
  areaIds: [],
  departmentIds: [],
  ages: [],
  followUpYears: [],
  schoolYears: [],
  preschoolIds: [],
}

export default function Statistic() {
  
  const { t, i18n } = useTranslation()
  const toast = useToast()
  const { user } = useUser()
  const { currentFollowupId } = useFilters()

  const [filter, setFilter] = useState<DashboardFilterType>({
    ...defaultDashdoardFilter,
    followupId: currentFollowupId,
  })



  //reset the followup filters when main tab is changed.
  useEffect(()=>{
    sessionStorage.setItem('resultsFilterState','')
    sessionStorage.setItem('followupFilterState','')
    sessionStorage.setItem('commentsFilterState','')
  },[])

  const isLangAnalysis = sessionStorage.getItem('languageAnalysis');
 

  const [isUpdateLoading, setIsUpdateLoading] = useState(false)

  const p = useParams()
  const jsonP = JSON.stringify(p)
  const params =
    jsonP === undefined ||
    jsonP === JSON.stringify({ active_tab: ':active_tab' })
      ? JSON.stringify({ active_tab: 'statistic' })
      : jsonP
  let activeTab = 0

  if (params === JSON.stringify({ active_tab: 'statistic-dashboard' }))
    activeTab = 0
  if (params === JSON.stringify({ active_tab: 'statistic-progression' }))
    activeTab = 1
  if (params === JSON.stringify({ active_tab: 'statistic-list' })) activeTab = 2
  if (params === JSON.stringify({ active_tab: 'language-analysis' })) activeTab = 3
  const toggle = (tab: any) => {
    if (activeTab !== tab) activeTab = tab
  }

  const handleChangeFilter = (newFilter: Partial<DashboardFilterType>) => {
    setFilter({ ...filter, ...newFilter })
  }

  useEffect(() => {
    if (currentFollowupId) {
      handleChangeFilter({ followupId: currentFollowupId })
    }
  }, [currentFollowupId])

  const resetStatistics = async () => {
    try {
      setIsUpdateLoading(true)
      await triggerPreschoolStatistics({
        organizationId: user.organizationId,
        followupId: filter.followupId,
      })
      toast({
        title:
        i18n.language === 'se'
        ? 'Statistiken har uppdaterats'
        : i18n.language === 'de'
        ? 'Die Statistiken wurden aktualisiert'
        : 'The statistics have been updated',
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      setFilter({ ...filter })
    } catch {
      toast({
        title:
        i18n.language === 'se'
        ? 'Misslyckades med att uppdatera statistik.'
        : i18n.language === 'de'
        ? 'Fehler beim Aktualisieren der Statistik.'
        : 'Failed to update statistics.',
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    } finally {
      setIsUpdateLoading(false)
    }
  }

  return (
    <Tabs
      p='0'
      isLazy
      variant='line'
      defaultIndex={activeTab}
      style={{ outline: 'none' }}
      onChange={(tab: any) => toggle(tab)}
    >
      <TabList minH='45px' py='0' px='8' w='full'>
        <Tab id='Dashboard' as={Link} to='/statistic-dashboard'>
          {t('Statistic_Results')}
        </Tab>
        <Tab id='Progression' as={Link} to='/statistic-progression'>
          Progression
        </Tab>
        <Tab id='List' as={Link} to='/statistic-list'>
          {t('Response_Rate')}
        </Tab>
        {isLangAnalysis === 'true'
        ?
        <Tab id='LanguageAnalysis' as={Link} to='/language-analysis'>
        {t('Language_Analysis')}
      </Tab>
      :
      ''
        }
       
      </TabList>

      <TabPanels px='8'>
        <TabPanel>
          <Stack direction='column' spacing='5'>
            <DashboardFilter
              filter={filter}
              isUpdateLoading={isUpdateLoading}
              resetStatistics={resetStatistics}
              onFilterChange={handleChangeFilter}
            ></DashboardFilter>
            <hr
              style={{
                background: '#B1C5D9',
                color: '#B1C5D9',
                borderColor: '#B1C5D9',
              }}
            />
            <DashboardOverview
              resetStatistics={resetStatistics}
              filter={filter}
            ></DashboardOverview>
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack direction='column' spacing='5'>
            <ProgressionCharts />
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack direction='column' spacing='5'>
            <PreschoolResultGridView />
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack direction='column' spacing='5'>
          <LanguageAnalysisFilter
              filter={filter}
              isUpdateLoading={isUpdateLoading}
              resetStatistics={resetStatistics}
              onFilterChange={handleChangeFilter} />
            
            <hr
              style={{
                background: '#B1C5D9',
                color: '#B1C5D9',
                borderColor: '#B1C5D9',
              }}
            />
            <LanguageAnalysisOverview
              resetStatistics={resetStatistics}
              filter={filter}
            ></LanguageAnalysisOverview>
          </Stack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
