import React from 'react'

import { VStack } from '@chakra-ui/react'
import { PreschoolStats, PreschoolTable } from './components'
import { usePreschool } from './hooks/usePreschool'

export const Preschool = () => {
  const { loading, response, handleOpenCreatePreschool } = usePreschool()

  return (
    <>
      <VStack w='full' spacing={'12'}>
        <>
          <PreschoolStats
            {...response}
            loading={loading}
            handleOpenCreatePreschool={handleOpenCreatePreschool}
          />

          <PreschoolTable {...response} loading={loading} />
        </>
      </VStack>
    </>
  )
}
