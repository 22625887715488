import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { userSchema } from '../schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { User, UserRole, ViewUserShape } from 'data-models'
import {
  inviteUser,
  useEditUser,
  useCreateUser,
  useDeleteUser,
} from 'services/userService'
import { useToast } from '@chakra-ui/react'
import { useUser } from 'providers/UserProvider'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

interface UseUserEditorProps {
  initialUser: User
}

export const useUserEditor = ({ initialUser }: UseUserEditorProps) => {
  const history = useHistory()
  const toast = useToast()
  const { user } = useUser()
  const { t } = useTranslation()
  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  const editUser = useEditUser()
  const createUser = useCreateUser()
  const deleteUser = useDeleteUser()

  const formMethods = useForm<ViewUserShape>({
    defaultValues: {
      ...initialUser,
      newPassword: '',
      confirmPassword: '',
      preschoolIds: initialUser.preschools?.map(p => p.id),
    },
    resolver: yupResolver(userSchema),
    context: {
      id: initialUser.id,
    },
  })

  const { handleSubmit } = formMethods

  useEffect(() => {
    if (initialUser) {
      formMethods.reset({
        ...initialUser,
        preschoolIds: initialUser.preschools?.map(p => p.id),
      })
    }
  }, [initialUser])

  const onCreate = async (values: ViewUserShape) => {
    try {
      const { token } = await inviteUser(values)
      await createUser.mutateAsync({
        values: {
          ...pick(values, ['name', 'email', 'phoneNumber', 'password']),
          password: values.newPassword,
        },
        token,
      })
      toast({
        title: `${t('User_Created_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/users')
    } catch (error) {
      toast({
        title: `${t('Create_User_Fail')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleCreate = handleSubmit(onCreate)

  const onEdit = async (values: ViewUserShape) => {
    try {
      await editUser.mutateAsync({
        values: {
          ...values,
        },
        id: initialUser.id,
        organizationId: user.organizationId,
      })
      toast({
        title: `${t('User_Update_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
    } catch (error) {
      toast({
        title: `${t('User_Update_Fail')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleEdit = handleSubmit(onEdit)

  const handleDelete = async () => {
    await deleteUser.mutateAsync({
      id: initialUser.id,
      organizationId: user.organizationId,
    })

    try {
      toast({
        title: `${t('User_Delete_Success')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/settings/users')
    } catch (error) {
      toast({
        title: `${t('User_Delete_Error')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  return {
    isAdmin,
    formMethods,
    handleEdit,
    isEditLoading: editUser.isLoading,
    handleDelete,
    isDeleteLoading: deleteUser.isLoading,
    handleCreate,
    isCreateLoading: createUser.isLoading,
  }
}
