import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AuthService } from 'services/authService'
import { useCreateUser } from 'services/userService'

export const useRegisterAvesta = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [authError, setAuthError] = useState(false)

  const history = useHistory()

  const params = useParams()
  const { activationToken } = params as any

  const createUser = useCreateUser()

  const handleRegister = async () => {
    setIsLoading(true)
    setAuthError(false)

    try {
      const userData = await AuthService.registerUsingCustomProvider()
      //const userData = { name: 'Mohsen', email: 'mohsen.bahrami@2mnordic.com' }

      await createUser.mutateAsync({
        values: {
          name: userData.name,
          email: userData.email,
          type: 'gmail',
        },
        token: activationToken,
      })
      setAuthError(false)
      history.push('/login/avesta')
    } catch (error) {
      setAuthError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return { handleRegister, isLoading, authError }
}
