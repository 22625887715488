import React from 'react'
import { useState } from 'react'
import { Center, Spinner, VStack } from '@chakra-ui/react'
import { ProgressionFilters } from '.'
import { defaultProgressionFilter } from '../Statistic'
import { ChartWrapper } from '../Dashboard/Graphs/components'
import { ProgressionOverTime } from './ProgressionOverTime'
import { ProgressionTaskLevel } from './ProgressionTaskLevel'
import { ProgressionFilterType } from './types'
import { ProgressionTaskGroupLevel } from './ProgressionTaskGroupLevel'
import { useTranslation } from 'react-i18next'


export default function ProgressionCharts() {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { t } = useTranslation()

  if(location.pathname === "/statistic"){
    // localStorage.setItem("load","false")

  }
  
  const [filter, setFilter] = useState<ProgressionFilterType>(
    defaultProgressionFilter,
  )

  const handleChangeFilter = (newFilter: Partial<any>) => {
   setFilter({ ...filter, ...newFilter })
   Object.assign(defaultProgressionFilter, newFilter);   
  }

  if (isError)
    return (
      <Center h='100px'>
        <p>Something went wrong ...</p>
      </Center>
    )

  return (
    <>
      {isLoading ? (
        <Center h='100px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        <>
    
      <ProgressionFilters
            filter={filter}
            onFilterChange={handleChangeFilter}
          />
        
          <hr
            style={{
              background: '#B1C5D9',
              color: '#B1C5D9',
              borderColor: '#B1C5D9',
            }}
          />
          <VStack spacing='6' marginTop='6'>
            <ChartWrapper>
              <ProgressionOverTime helpDetailText={t('Progression_Over_Time_HelpDetails')} filter={filter} />
            </ChartWrapper>
          </VStack>
          <VStack spacing='6' marginTop='6'>
            <ChartWrapper>
              <ProgressionTaskLevel helpDetailText={t('Task_Level_Progression_HelpDetails')} filter={filter} />
            </ChartWrapper>
          </VStack>
          <VStack spacing='6' marginTop='6'>
            <ChartWrapper>
              <ProgressionTaskGroupLevel filter={filter} />
            </ChartWrapper>
          </VStack>
        </>
      )}
    </>
  )
}