import React, { useState } from 'react'
import {
  ResultInfo,
  ResultResponse,
  GenderCodeToLabelMappingEn,
  GenderCodeToLabelMapping,
  GenderCodeToLabelMappingDe,
  ResultsStatsFilterType,
} from '../../../../data-models'
import {
  Tr,
  Td,
  Box,
  Flex,
  Table,
  Thead,
  Button,
  Spinner,
  useDisclosure,
  TableContainer,
} from '@chakra-ui/react'
import { useHistory, generatePath } from 'react-router-dom'

import { CommentIcon } from 'common/Icons'
import { Pagination } from 'common/Pagination/Pagination'
import { CommentModal } from 'common/CommentModal'
import { useUser } from 'providers/UserProvider'
import { useOrganization } from 'services/organizationService'
import { useTranslation } from 'react-i18next'

interface ResultsTableProps {
  loading: boolean
  resultResponse: ResultResponse
  filter: ResultsStatsFilterType
  onFilterChange: (filter: Partial<ResultsStatsFilterType>) => void
}

export const ResultsTable = ({
  filter,
  loading,
  resultResponse,
  onFilterChange,
}: ResultsTableProps) => {
  const history = useHistory()
  const { data, totalCount } = resultResponse

  const { t, i18n } = useTranslation()
  const handleRedirectToResultDetails = (resultId: string) => {
    history.push({
      pathname: generatePath('/result/:id', {
        id: resultId,
      }),
    })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedComment, setSelectedComment] = useState('')
  const { user } = useUser()

  const { data: organization } = useOrganization({
    id: user.organizationId,
  })

  // const handleCommentOnClick = (result: ResultInfo) => {
  //   if (result.individualComment) {
  //     setSelectedComment(result.individualComment)
  //     onOpen()
  //   }
  // }

  const handlePageSizeChange = (newSize: number) => {
    onFilterChange({ pageSize: newSize })
  }

  const handlePageNumberChange = (newIndex: number) => {
    onFilterChange({ pageNumber: newIndex })
  }

  return (
    <>
      {loading ? (
        <Flex pt='20' justifyContent={'center'} w='full' h='full'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <>
          <TableContainer pt='8' w='full' borderRadius='md'>
            <Table variant='simple' resize={'none'} w='full'>
              <Thead>
                <Tr
                  bgColor={'rgba(177, 197, 217, 0.5)'}
                  borderWidth='1px'
                  borderRadius='10px'
                  height='48px'
                  fontWeight={'bold'}
                  fontFamily={`'Open Sans',sans-serif`}
                  fontSize={'12px'}
                >
                  <Td>{t('ResultFilter_Preschool')}</Td>
                  <Td>{t('ResultsTable_Gender')}</Td>
                  <Td>{t('ResultsTable_DOB')}</Td>
                  <Td>{t('ResultFilter_Area')}</Td>
                  {organization?.showChildCode && (
                    <Td>{t('ResultsTable_Child_Alias')}</Td>
                  )}
                  {/* <Td>{t('ResultsTable_Own_Comment')}</Td> */}
                </Tr>
              </Thead>
              {data.map(result => (
                <Tr
                  fontWeight={'light'}
                  fontFamily={`'Open Sans',sans-serif`}
                  fontSize={'14px'}
                  key={result.id}
                >
                  <Td
                    cursor='pointer'
                    _hover={{ backgroundColor: '#EFF1FF' }}
                    onClick={() => handleRedirectToResultDetails(result.id)}
                  >
                    {result.preschoolName}
                  </Td>
                  <Td>
                    {i18n.language === 'se'
  ? GenderCodeToLabelMapping[result.childGender]
  : i18n.language === 'de'
  ? GenderCodeToLabelMappingDe[result.childGender]
  : GenderCodeToLabelMappingEn[result.childGender]}
                  </Td>
                  <Td>
                    {result.childBirthDate === '0001-01-01T00:00:00'
                      ? ''
                      : result.childBirthDate?.split('T')[0]}
                  </Td>
                  <Td>{result.areaName}</Td>
                  {organization?.showChildCode && <Td>{result.childCode}</Td>}
                  {/* <Td>
                    <Button
                      _hover={{ backgroundColor: '#EFF1FF' }}
                      onClick={() => handleCommentOnClick(result)}
                      variant={'unstyled'}
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      disabled={!result.individualComment}
                    >
                      <CommentIcon />
                    </Button>
                  </Td> */}
                </Tr>
              ))}
            </Table>
            {/* <CommentModal
              comment={selectedComment}
              data={[]}
              isOpen={isOpen}
              onClose={onClose}
            /> */}
          </TableContainer>
          {(!data || data?.length === 0) && (
            <Box style={{ height: '60px' }}>
              <h1 style={{ textAlign: 'center', paddingTop: '20px' }}>
                {t('ResultsTable_No_Data')}
              </h1>
            </Box>
          )}
          {totalCount > 10 && (
            <Pagination
              siblingCount={1}
              totalCount={totalCount}
              settings={{
                pageNumber: filter.pageNumber,
                pageSize: filter.pageSize,
              }}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
        </>
      )}
    </>
  )
}
