import { PaginatedSettings } from 'data-models'

export interface FollowupOverviewFilterType extends PaginatedSettings {
  academicYear?: string
  status?: string
  name?: string
}

export const defaultFilter: FollowupOverviewFilterType = {
  status: undefined,
  academicYear: undefined,
  name: undefined,
  pageNumber: 1,
  pageSize: 10,
}
