import { InfoOutlineIcon,ChevronUpIcon } from '@chakra-ui/icons'
import { Button, Center, Divider } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface HelpButtonProps{
    onToggle: () => void,
    isOpen: boolean
}

const ChartHelpButtonComponent = ({onToggle,isOpen}:HelpButtonProps) => {
  const {t} = useTranslation();
  return (
    <>
    <Center height='50px' display={{ base: "none", md: "block" }}>
        <Divider borderColor="gray.300" orientation={'vertical'} />
    </Center>
      <Button onClick={onToggle}  colorScheme="purple"  fontSize='sm' variant="link" style={{boxShadow: 'none',marginRight:'1%'}} leftIcon={isOpen ? <ChevronUpIcon/> : <InfoOutlineIcon/>}>{ t('Help_Me_Understand')}</Button>
    </>
   )
}

export default ChartHelpButtonComponent
