import { useUser } from 'providers/UserProvider';
import { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import axiosAPIInstance from '../../../../../../services/api';

export const useMunicipalityResultsPerTaskAverage = (filter) => {
    const [municipalityAverageData, setMunicipalityAverageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { user: { organizationId } } = useUser();

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosAPIInstance.get(
                    `/statistics/${organizationId}/${filter.followupId}/average-per-age`,
                    { cancelToken: cancelTokenSource.token }
                );
                setMunicipalityAverageData(response.data);
            } catch (err:any) {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                } else {
                    setError(err);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            cancelTokenSource.cancel('Component unmounted');
        };
    }, [filter, organizationId]);

    return { municipalityAverageData, loading, error };
};

export const useMunicipalityGenderAverage = (filter) => {
    const [municipalityAverageData, setMunicipalityAverageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { user: { organizationId } } = useUser();

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosAPIInstance.get(
                    `/statistics/${organizationId}/${filter.followupId}/average-per-gender`,
                    { cancelToken: cancelTokenSource.token }
                );
                setMunicipalityAverageData(response.data);
            } catch (err:any) {
                if (axios.isCancel(err)) {
                    console.log('Request canceled', err.message);
                } else {
                    setError(err);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            cancelTokenSource.cancel('Component unmounted');
        };
    }, [filter, organizationId]);

    return { municipalityAverageData, loading, error };
};

