import { useUser } from 'providers/UserProvider'
import { UserRole, UserRoleToLabelMapping, UserRoleToLabelMappingEn,UserRoleToLabelMappingDe } from 'data-models'
import { usePreschoolService } from 'services/preschoolService'
import i18n from 'i18next';

export const useUserFilter = () => {
  const { user } = useUser()
  const { preschools } = usePreschoolService(user.organizationId)

  const preschoolOptions = preschools.map(preschool => ({
    value: preschool.id,
    label: preschool.name,
  }))

  const isSuperAdmin = [UserRole.SUPERADMIN].includes(user.role)
  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(user.role)

  const roleOptions = Object.keys(UserRole)
    .filter(item =>
      !isSuperAdmin ? UserRole[item] !== UserRole.SUPERADMIN : true,
    )
    .map(key => ({
      value: UserRole[key],
      label: i18n.language === 'se' ? UserRoleToLabelMapping[UserRole[key]] : i18n.language === 'de' ? UserRoleToLabelMappingDe[UserRole[key]] : UserRoleToLabelMappingEn[UserRole[key]],
    }))

  return {
    roleOptions,
    isAdmin,
    preschoolOptions,
  }
}
