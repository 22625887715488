import React from 'react'
import { Spinner, VStack, Text } from '@chakra-ui/react'
import { usePreschoolContainer } from './hooks'
import { PreschoolEditor } from './PreschoolEditor'
import { useTranslation } from 'react-i18next'

interface PreschoolContainerProps {
  isCreate: boolean
}

export const PreschoolContainer = ({ isCreate }: PreschoolContainerProps) => {
  const { loading, preschool } = usePreschoolContainer()
  const { t } = useTranslation()
  return (
    <>
      {loading ? (
        <VStack p='0' spacing='5' w='full' h='full' justify='center' pt='20'>
          <Spinner size='xl' color='black' />
          <Text
            color='black'
            mb='14'
            mt='7'
            fontSize='large'
            fontWeight='light'
          >
            {t('Pick_Preschool')}
          </Text>
        </VStack>
      ) : (
        <PreschoolEditor isCreate={isCreate} preschool={preschool} />
      )}
    </>
  )
}
