import React from 'react'
import { Center } from '@chakra-ui/react'
import CheckIcon from '../CheckIcon'

export default function CheckboxCircle(props: { checked: boolean }) {
  return (
    <Center
      borderRadius='full'
      border='1px'
      borderColor='white'
      bgColor={props.checked ? 'white' : 'transparent'}
      boxSize='25px'
    >
      {props.checked && <CheckIcon color='#FF5099' />}
    </Center>
  )
}
