import React, { useEffect, useState } from 'react'
import { CheckboxAnswer, VideoTask } from 'data-models/task-models'
import TaskTag from '../TaskTag'
import {
  Flex,
  Text,
  Center,
  VStack,
  HStack,
  Image,
  Box,
  Heading,
} from '@chakra-ui/react'
import infoImage from 'assets/images/information-circle.svg'
import CheckboxAnswerGroup from '../CheckboxAnswerGroup/CheckboxAnswerGroup'
import VideoPlayer from './VideoPlayer'
import { apiBase } from 'services/config'

export default function VideoTaskCmp(props: { task: any }) {
  const currentTask = props.task
  const [task, setTask] = useState(currentTask as VideoTask)

  const onAnswerChange = (answers: CheckboxAnswer[]) => {
    setTask(v => ({ ...v, ...{ answerOptions: answers } }))
  }

  useEffect(() => {}, [task])

  return (
    <Flex direction='column' h='full' top={10} w='100%'>
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
      >
        <Box>
          <TaskTag text={task.description} />
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mt='3'
            lineHeight='1'
          >
            {task.title}
          </Heading>
          <Text fontWeight='semibold' mt='3'>
            {task.subtitle}
          </Text>
        </Box>

        <Center m='5' w='66%' h='35%' flex='1' alignSelf='center'>
          <VideoPlayer videoSrc={apiBase + task.videoUrl} />
        </Center>

        <VStack
          pb={'25px'}
          pt='1'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5'>
            <Image src={infoImage} />
            <Text fontSize='sm'>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>

      <div style={{ height: '20px', backgroundColor: 'white' }}></div>

      <Flex direction='column' h='full' p='5' borderRadius='md' bg='#773FFD'>
        <HStack
          borderBottom='1px solid #352EB1'
          pt={'10px'}
          pb='10px'
          mb='10px'
          lineHeight='1.5'
        >
          <Image src={infoImage} />
          <Text fontSize='sm'>{task.answerInstructions}</Text>
        </HStack>

        <Flex direction='column' w='full' align='center' flex='1' pt='10px'>
          <Flex direction='column' w='full' align='center' flex='1'>
            <CheckboxAnswerGroup
              options={task.answerOptions}
              onChange={onAnswerChange}
            />
          </Flex>
        </Flex>
        {/*      
     <HStack 
           pt="15">
       {task.answerOptions.map(o => (
         o.styleClass =="childParticipate" && (
         <HStack
           key={o.id}
           cursor="pointer"
           w="80%"
           p="15"
           bgColor={o.checked ? 'pink.500' : 'transparent'}
           
         >
           <CheckboxCircle checked={o.checked} />
           <Text>{o.text}</Text>
         </HStack>
         )
       ))}
     </HStack> */}
      </Flex>
    </Flex>
  )
}
