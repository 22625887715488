import React, { useState } from 'react'
import {
  Bar,
  Label,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { ceil, floor } from 'lodash-es'
import { Stack, useDisclosure } from '@chakra-ui/react'
import { VStack, Text, HStack, StackProps } from '@chakra-ui/react'
import { useTaskService } from 'services/taskService'
import { useResultsPerTaskChart } from './useResultsPerAgeChart'
import { DashboardFilterType } from '../../DashboardFilterType'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import { BarChartColors, IndicatorColors } from 'data-models/preschool'
import {
  generateChartBarName,
  IndicatorLabels,
  IndicatorLabelsEn,
  IndicatorLabelsDe,
  round2,
} from '../utils'
import { useTranslation } from 'react-i18next'
import ChartHelpButtonComponent from 'common/ChartDetailHelp/ChartHelpButtonComponent'
import ChartHelpComponent from 'common/ChartDetailHelp/ChartHelpComponent'
import { useMunicipalityResultsPerTaskAverage } from './useMunicipalityAverage'

const findMaxAnswerCount = (data: any) => {
  let maxTotalAnswerCount = 0

  data.forEach((stat: any) => {
    if (stat.totalAnswerCount > maxTotalAnswerCount) {
      maxTotalAnswerCount = stat.totalAnswerCount
    }
  })

  return maxTotalAnswerCount
} 




interface ResultPerAgeChartProps extends Omit<StackProps, 'filter'> {
  title: string
  filter: DashboardFilterType
  helpText: string
  helpDetailText: string | undefined
}

export const ResultsPerAnswerCount = ({
  title,
  filter,
  helpText,
  helpDetailText,
  ...rest
}: ResultPerAgeChartProps) => {
  const { stats, loading } = useResultsPerTaskChart(filter)
  const {municipalityAverageData, error} = useMunicipalityResultsPerTaskAverage(filter)
 

  const { tasks } = useTaskService()
  const { t, i18n } = useTranslation()
  const { isOpen, onToggle } = useDisclosure()

  const categories = (stats[0]?.scores || [])?.map((score, index) => ({
    key: `${score.taskId}_${score.categoryId}`,
    name: generateChartBarName({
      categoryId: score.categoryId,
      name: (tasks.find(task => task.id === score.taskId)?.name +
        ' (' +
        tasks.find(task => task.id === score.taskId)?.description +
        ') ' +
        '') as string,
    }),
    color: BarChartColors[index],
  }))

  const bars = categories.filter(bar => bar?.name)

 
  const data = stats.map(stat => {
    const { scores } = stat
    const stats = scores.reduce((acc, curr) => {
      const keyName = `${curr.taskId}_${curr.categoryId}`
      const totalAnswerCount = curr.answerCount + curr.noAnswerCount

      return {
        ...acc,
        [`${keyName}_answer_count`]: curr.answerCount,
        [`${keyName}_noAnswer_count`]: curr.noAnswerCount,
        totalAnswerCount,
      }
    }, {})

    return {
      ...stats,
      ageYear: `${stat.age} ${t('years')}`,
    }
  })

  const municipalityData = municipalityAverageData.map((stat:any) => {
    const { scores }:any = stat
    const stats = scores.reduce((acc, curr) => {
      const keyName = `${curr.taskId}_${curr.categoryId}`
      const totalAnswerCount = curr.answerCount + curr.noAnswerCount

      return {
        ...acc,
        [`${keyName}_answer_count`]: curr.answerCount,
        [`${keyName}_noAnswer_count`]: curr.noAnswerCount,
        totalAnswerCount,
      }
    }, {})

    return {
      ...stats,
      ageYear: `${stat.age} ${t('years')}`,
    }
  })


  

  const combinedData = data.map((item)=>{
    const municipalityItem = municipalityData.find(m => m.ageYear === item.ageYear)
    return{
      ...item,
      municipalityTotalAnswerCount : municipalityItem ? municipalityItem.totalAnswerCount : undefined

    }
  })


  const maxTotalAnswerCount = findMaxAnswerCount(data)



  // Define a buffer to extend the Y-axis
const buffer = 0.2 * maxTotalAnswerCount;  // 10% of maxTotalAnswerCount
const extendedMaxTotalAnswerCount = ceil(maxTotalAnswerCount + buffer);


  const [tooltip, setTooltip] = useState<string>('')
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const answerCountDataKey = `${tooltip}_answer_count`
      const noAnswerCountDataKey = `${tooltip}_noAnswer_count`
     
      for (const bar of payload)
        if (
          [
            answerCountDataKey,
            noAnswerCountDataKey,
            'totalAnswerCount',
          ].includes(bar.dataKey)
        )
          if (bar.dataKey !== 'totalAnswerCount') {
            return (
              <VStack
                bg='#344352'
                p='3'
                borderRadius='md'
                alignItems='flex-start'
                color='white'
              >
                <Text fontWeight='semibold'>{label}</Text>
                <HStack key={payload[0].name}>
                  <Text fontWeight='semibold'>{bar.name}</Text>
                </HStack>

                <HStack key={`${tooltip}_total_answer_count_municipality`}>
                  <Text fontWeight='semibold'>{t('Total_Count_Municipality')} </Text>
                  <Text>{round2(payload[0].payload.municipalityTotalAnswerCount)}</Text>
                </HStack>

                {
                  (filter.areaIds.length !== 0  || filter.departmentIds.length !== 0 || filter.preschoolIds.length !== 0 ) &&
   <HStack key={`${tooltip}_total_answer_count`}>
   <Text fontWeight='semibold'>{t('Total_Responses')} </Text>
   <Text>{round2(bar.payload.totalAnswerCount)}</Text>
 </HStack>
}
             

                <HStack key={`${answerCountDataKey}`}>
                  <Text fontWeight='semibold'>{t('Amount_Answers')} </Text>
                  <Text>{round2(bar.payload[answerCountDataKey])}</Text>
                </HStack>

                <HStack key={`${noAnswerCountDataKey}`}>
                  <Text fontWeight='semibold'>{t('Child_Not_Participate')} </Text>
                  <Text>{round2(bar.payload[noAnswerCountDataKey])}</Text>
                </HStack>
              </VStack>
            )
          } else {
            return (
              <VStack
                bg='#344352'
                p='3'
                borderRadius='md'
                alignItems='flex-start'
                color='white'
              >
                <Text fontWeight='semibold'>{label}</Text>
                <HStack key={payload[0].name}>
                  <Text fontWeight='semibold'>{bar.name}: </Text>
                  <Text>{round2(bar.value)}</Text>
                </HStack>
              </VStack>
            )
          }

      return null
    }
    return null
  }

  return (
    <ChartContainer
      title={title}
      helptext={helpText}
      loading={loading}
      {...rest}
    >
      {data.length > 0 && (
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Text w={{ base: '100%', md: '80%' }} lineHeight={2}>
            {data.length > 0 ? helpText : null}
          </Text>
          <ChartHelpButtonComponent onToggle={onToggle} isOpen={isOpen} />
        </Stack>
      )}

      {helpDetailText && (
        <ChartHelpComponent helpDetailText={helpDetailText} isOpen={isOpen} />
      )}
      <br></br>
      {data.length > 0 ? (
        <div style={{display:'flex', justifyContent: 'center'}}>
        <ResponsiveContainer width='80%' height={400}>
          <ComposedChart
            data={combinedData}
            barGap='2%'
            margin={{ bottom: 55, top: 10 }}
          >
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <YAxis
              ticks={[
                0,
                floor((1 / 4) * extendedMaxTotalAnswerCount),
                floor((1 / 2) * extendedMaxTotalAnswerCount),
                floor((3 / 4) * extendedMaxTotalAnswerCount),
                extendedMaxTotalAnswerCount
              ]}
              domain={[0, extendedMaxTotalAnswerCount]}
              fontSize={12}
              
            >
              <Label
                angle={-90}
                width={230}
                fontSize={'12px'}
                value={t('Num_Children')}
                textBreakAll={true}
                position='insideLeft'
                fontWeight='bold'
                style={{
                  textAnchor: 'middle',
                }}
                dx={5}
              />
            </YAxis>

          
            <XAxis dataKey='ageYear' fontSize={12}>
              <Label
                value={t('Overview_Task')}
                position='bottom'
                fontWeight='bold'
                fontSize={'12px'}
              />
            </XAxis>
            <Tooltip
              content={<CustomTooltip />}
              formatter={value => `${value}%`}
              cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
            />
            {bars.map((bar, index) => (
              <>
                <Bar
                  key={`${bar.key}_answer_count`}
                  dataKey={`${bar.key}_answer_count`}
                  name={bar.name}
                  fill={bar.color}
                  stackId={`${bar.key}_${index}`}
                  barSize={12}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                />
                <Bar
                  key={`${bar.key}_noAnswer_count`}
                  dataKey={`${bar.key}_noAnswer_count`}
                  name={bar.name}
                  stackId={`${bar.key}_${index}`}
                  fill={'#D3D3D3'}
                  barSize={12}
                  legendType={'none'}
                  isAnimationActive={true}
                  onMouseOver={() => {
                    setTooltip(`${bar.key}`)
                  }}
                />
              </>
            ))}
            <Line
              legendType='square'
              dataKey={t('child_non_participation')}
              stroke='#d4d4d4'
            />
            <Line
              type='monotone'
              name={
                i18n.language === 'se'
                ? IndicatorLabels.AverageTotal
                : i18n.language === 'de'
                ? IndicatorLabelsDe.AverageTotal
                : IndicatorLabelsEn.AverageTotal
              }
              dataKey={'totalAnswerCount'}
              stroke={IndicatorColors.childrenResult}
              strokeWidth={3}
              onMouseOver={() => {
                setTooltip('totalAnswerCount')
              }}
            />
              {/* <Line
              type='monotone'
              name={
                i18n.language === 'en'
                  ? IndicatorLabelsEn.ChildrenResult
                  : IndicatorLabels.ChildrenResult
              }
              dataKey={'municipalityTotalAnswerCount'}
              stroke={IndicatorColors.childrenResult}
              strokeWidth={3}
              onMouseOver={() => {
                setTooltip('municipalityTotalAnswerCount')
              }}
            /> */}
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='bottom'
              wrapperStyle={{ bottom: '15px' }}
            />
          </ComposedChart>
        </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>
          {' '}
          {t('ResultsTable_No_Data')}
        </div>
      )}
    </ChartContainer>
  )
}
