import React, { useState } from 'react'
import {
  Bar,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
} from 'recharts'
import { mean, sortBy } from 'lodash'
import { ChartContainer, LegendItemRenderer } from 'common/Chart'
import { useProgressionGroup } from './useProgressionGroup'
import { ProgressionFilterType } from './types'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import TextProvider from './TextProvider'
import { HStack, VStack, Text } from '@chakra-ui/react'
import i18n from 'i18next'

interface ProgressionOverTimeChartProps {
  filter: ProgressionFilterType
}

export const progressionSchema = yup.object().shape({
  type: yup.string().required('Välj en typ'),
  value: yup.string().required('Välj en progressionstyp'),
})

export const ProgressionTaskGroupLevel = ({
  filter,
}: ProgressionOverTimeChartProps) => {
  const { stats, loading } = useProgressionGroup(filter)
  const chartData = stats
  const ProgressionFollowupColors = ['#CBCD66', '#FFBC8B', '#987687', '#6A8ED1']
  function colorizeFollowups(data) {
    if (!data || !data.followupGroups) {
      console.error('Data or followupGroups is undefined.')
      return data
    }

    const followupNameToColor = {} // Map to store follow-up names to colors
    const followupNameCounts = {} // Map to store the count of each follow-up name

    // Find follow-up names that appear in all three groups
    const commonFollowupNames = Object.keys(followupNameCounts).filter(
      followupName =>
        followupNameCounts[followupName] === data.followupGroups.length,
    )

    // Move common follow-up names to the first index of each group's comparisons
    data.followupGroups.forEach(group => {
      if (group && group.followupGroupComparisons) {
        commonFollowupNames.forEach(commonName => {
          const commonComparisonIndex =
            group.followupGroupComparisons.findIndex(
              comparison => comparison.followupName === commonName,
            )
          if (commonComparisonIndex !== -1 && commonComparisonIndex !== 0) {
            const commonComparison = group.followupGroupComparisons.splice(
              commonComparisonIndex,
              1,
            )[0]
            group.followupGroupComparisons.unshift(commonComparison)
          }
        })
      }
    })

    // Apply the colors to each follow-up comparison
    data.followupGroups.forEach(group => {
      if (group && group.followupGroupComparisons) {
        group.followupGroupComparisons.forEach(comparison => {
          if (comparison && comparison.followupName) {
            // if (!followupNameToColor.hasOwnProperty(comparison.followupName)) {
            //   followupNameToColor[comparison.followupName] =
            //     ProgressionFollowupColors.shift()
            // }
            if (!Object.prototype.hasOwnProperty.call(followupNameToColor, comparison.followupName)) {
              followupNameToColor[comparison.followupName] = ProgressionFollowupColors.shift();
          }
            comparison.color = followupNameToColor[comparison.followupName]
          }
        })
      }
    })

    return data
  }

  const tasksData = colorizeFollowups(chartData)

// Function to sort followupGroupComparisons based on startDate
function sortBasedOnDate(tasksData): void {
  if (tasksData && tasksData.followupGroups) {
      tasksData.followupGroups.forEach(group => {
          group.followupGroupComparisons = sortBy(group.followupGroupComparisons, 'startDate');
      });
  }
}


// Call the sortBasedOnDate function with tasksData
sortBasedOnDate(tasksData);

  function findMaxGroupIndex(data) {
    let maxIndex = 0
    let maxLength = 0

    for (let i = 0; i < data.followupGroups.length; i++) {
      const comparisonsLength =
        data.followupGroups[i].followupGroupComparisons.length

      if (comparisonsLength > maxLength) {
        maxLength = comparisonsLength
        maxIndex = i
      }
    }

    return maxIndex
  }
  function sortFollowups(data) {
    if (!data.followupGroups || data.followupGroups.length === 0) {
      console.log('Error: followupGroups is undefined or empty.')
      return
    }

    const maxIndex = findMaxGroupIndex(data)
    const maxGroup = data.followupGroups[maxIndex]

    // Sort followupGroupComparisons based on count property in descending order for maxGroup
    maxGroup.followupGroupComparisons.sort((a, b) => b.count - a.count)

    for (const comparison of maxGroup.followupGroupComparisons) {
      const followupId = comparison.followupId

      for (let i = 0; i < data.followupGroups.length; i++) {
        if (i !== maxIndex) {
          const group = data.followupGroups[i]
          let found = false

          for (const comp of group.followupGroupComparisons) {
            if (comp.followupId === followupId) {
              found = true
              break
            }
          }

          if (!found) {
            const newComparison = {
              followupId: followupId,
              followupName: comparison.followupName,
              count: 0, // You may want to set default values for count, percentage, and color
              percentage: 0,
              color: comparison.color,
            }
            group.followupGroupComparisons.push(newComparison)
          }
        }
      }

      // Sort followupGroupComparisons based on count property in descending order for each group
      for (const group of data.followupGroups) {
        group.followupGroupComparisons.sort((a, b) => b.count - a.count)
      }
    }

    // Sort followupGroupComparisons alphabetically by followupName for each group
    for (const group of data.followupGroups) {
      group.followupGroupComparisons.sort((a, b) =>
        a.followupName.localeCompare(b.followupName),
      )
    }
  }
  // sortFollowups(tasksData)
  const [tooltip, setTooltip] = useState<any>('')
  function extractContentWithinBrackets(text: string): number[] {
    const regex = /\[(.*?)\]/g
    const matches: number[] = []
    let match: RegExpExecArray | null
    while ((match = regex.exec(text)) !== null) {
      const num = parseFloat(match[1])
      if (!isNaN(num)) {
        matches.push(num)
      }
    }
    return matches
  }

  function generateUniqueFollowupColors(data: any) {
    if (!data || !data.followupGroups || data.followupGroups.length === 0) {
      console.error('Invalid data or empty followupGroups array.')
      return null
    }

    const firstGroup = data.followupGroups[0]
    if (
      !firstGroup.followupGroupComparisons ||
      firstGroup.followupGroupComparisons.length === 0
    ) {
      console.error('No followupGroupComparisons found')
      return null
    }

    return firstGroup.followupGroupComparisons
  }

  const taskBars = generateUniqueFollowupColors(tasksData)
 
  const CustomTooltipTasks = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      for (const bar of payload)
        if (bar.dataKey === tooltip) {
          const desiredContent = extractContentWithinBrackets(bar.dataKey)
          return (
            <VStack
              p='3'
              color='white'
              bg='#344352'
              borderRadius='md'
              alignItems='flex-start'
            >
              <Text fontWeight='semibold'>{label.split('<br>')}</Text>
              <HStack key={bar.name}>
                <Text fontWeight='semibold'>{bar.name}:</Text>
                <Text>{bar.value}%</Text>
              </HStack>
              <HStack key={bar.payload['count']}>
                <Text fontWeight='semibold'>{t('Number_of_task')}:</Text>
                <Text>
                  {
                    bar.payload.followupGroupComparisons[desiredContent[0]]
                      .count
                  }
                </Text>
              </HStack>
            </VStack>
          )
        }
      return null
    }

    return null
  }

  const { t } = useTranslation()
  const groupNameIndex = 0


  return (
    <ChartContainer title={t('Group_level_progression')} loading={loading}>
    {tasksData.followupGroups?.length > 0 &&  <TextProvider />}
      <br></br>
      <div>
        <br></br>
      </div>
      {tasksData.followupGroups?.length > 0 ? (
        <div style={{display:'flex', justifyContent: 'center'}}>
        <ResponsiveContainer width='80%' height={500}>
          <BarChart
            data={tasksData.followupGroups}
            margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
          >
            <CartesianGrid stroke='#dee4f2' fill='#f7fcff' />
            <XAxis
              dataKey={
                i18n.language === 'se'
                  ? `groupName[${groupNameIndex}].se`
                  : i18n.language === 'de'
                  ? `groupName[${groupNameIndex}].de`
                  : `groupName[${groupNameIndex}].en`
              }
              fontSize={12}
            >
              <Label
                offset={10}
                value={t('Group_Text')}
                position='bottom'
                fontWeight='bold'
                fontSize={12}
              />
            </XAxis>
            <YAxis
              ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              unit='%'
              fontSize={12}
            >
              <Label
                dx={-25}
                angle={-90}
                fontSize={'12px'}
                value={`${t('DashboardOverview_Number_of_Children')} %`}
                fontWeight='bold'
                style={{
                  textAnchor: 'middle',
                  width: '100%',
                }}
              />
            </YAxis>
            <Tooltip
              formatter={value => `${value}%`}
              content={CustomTooltipTasks}
              cursor={{ fill: '#277aff', fillOpacity: '0.1' }}
            />
            <Legend
              iconSize={10}
              formatter={LegendItemRenderer}
              verticalAlign='bottom'
              payloadUniqBy
              wrapperStyle={{ bottom: '0px', marginLeft: '10px' }}
            />

            { taskBars &&
              taskBars.map((group, index) => (
                <Bar
                  barSize={25}
                  key={index}
                  dataKey={`followupGroupComparisons[${index}].percentage`}
                  fill={group.color}
                  name={group.followupName}
                  onMouseOver={() => {
                    setTooltip(`followupGroupComparisons[${index}].percentage`)
                  }}
                />
              ))}
          </BarChart>
        </ResponsiveContainer>
        </div>
      ) : (
        <div className='nodata-avaliable'>{t('ResultsTable_No_Data')}</div>
      )}
    </ChartContainer>
  )
}
