import { useState } from 'react'
import { Organization } from 'data-models'
import { useToast } from '@chakra-ui/react'
import { useOrganizationList } from 'services/organizationService'
import { useTranslation } from 'react-i18next'

export const useOrganization = () => {
  const toast = useToast()
  const { error, loading, response } = useOrganizationList()
  const { t } = useTranslation()
  if (error) {
    toast({
      title: `${t('Error_Retrieving_Org')}`,
      isClosable: true,
      duration: 3000,
      status: 'error',
    })
  }

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>()

  const [isInCreateMode, setIsInCreateMode] = useState<boolean>(false)

  const handleSelectOrganization = (organization: Organization) => {
    setIsInCreateMode(false)
    setSelectedOrganization(organization)
  }

  const handleOpenCreateOrganization = () => {
    setIsInCreateMode(true)
    setSelectedOrganization(undefined)
  }

  const goBack = () => {
    setIsInCreateMode(false)
    setSelectedOrganization(undefined)
  }

  return {
    response,
    loading,
    selectedOrganization,
    isInCreateMode,
    goBack,
    handleSelectOrganization,
    handleOpenCreateOrganization,
  }
}
