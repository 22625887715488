import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getOverviewStatistics } from 'services/followupResultService'
import { DashboardFilterType } from './DashboardFilterType'

type DataRecord = {
  preschoolCount: number
  thisWeekSubmitedCount: number
  totalSubmitedCount: number
  totalParticipant: number
  needsUpdate: boolean
}

interface UseDashboardOverviewProps {
  filter: DashboardFilterType
  resetStatistics: () => void
}

export const useDashboardOverview = ({
  filter,
  resetStatistics,
}: UseDashboardOverviewProps) => {
  const [summaryData, setFollowupSummaryData] = useState({} as DataRecord)
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false)

  const {
    user: { organizationId },
  } = useUser()

  const response = useAsync(
    () =>
      getOverviewStatistics(
        organizationId,
        filter.followupId,
        filter.areaIds,
        filter.preschoolIds,
        filter.departmentIds
      ),
    [filter],
  )

  const { value, loading } = response

  useEffect(() => {
    if (value && !loading) {
      setFollowupSummaryData({
        preschoolCount: value.numberOfSchools,
        thisWeekSubmitedCount: value.submittedWeekCount,
        totalParticipant: value.numberOfParticipants,
        totalSubmitedCount: value.submittedTotalCount,
        needsUpdate: value.needsUpdate,
      })
    }
  }, [filter, value])

  useEffect(() => {
    setHasBeenUpdated(false)
  }, [filter.followupId])

  useEffect(() => {
    if (summaryData?.needsUpdate && !hasBeenUpdated) {
      resetStatistics()
      setHasBeenUpdated(true)
    }
  }, [summaryData, hasBeenUpdated, filter])

  return { summaryData, loading }
}
