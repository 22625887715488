import React from 'react'

import { Button, Flex, Grid, GridItem, Input, VStack } from '@chakra-ui/react'

import { FormWrapper } from 'common/Form'
import { useCreateUserFromInvite } from './hooks'
import { PasswordInput } from 'common/PasswordInput'
import { FormProvider } from 'react-hook-form'

//Example of format for the page link: https://myran.admin.se/invite?token=AS2FG44FmV09dfCs25

export const CreateUserFromInvite = () => {
  const { formMethods, handleCreate, isLoading } = useCreateUserFromInvite()

  const { register } = formMethods

  return (
    <VStack w='full' h='full'>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%', height: '100%' }} noValidate={true}>
          <Grid w='full' gridTemplateColumns='repeat(2, 1fr)' gridGap={6}>
            <GridItem>
              <FormWrapper name='name' label='Namn' isRequired>
                <Input
                  type='text'
                  placeholder='Namn'
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  focusBorderColor=''
                  fontSize='14px'
                  bgColor='white'
                  color='#1A2C3D'
                  border='1px'
                  borderColor='#B1C5D9'
                  h='40px'
                  w='full'
                  borderRadius='lg'
                  {...register('name')}
                />
              </FormWrapper>
            </GridItem>
            <GridItem>
              <FormWrapper name='newPassword' label='Nytt lösenord' isRequired>
                <PasswordInput
                  placeholder='Nytt lösenord'
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  focusBorderColor=''
                  fontSize='14px'
                  bgColor='white'
                  color='#1A2C3D'
                  border='1px'
                  borderColor='#B1C5D9'
                  h='40px'
                  w='full'
                  borderRadius='lg'
                  {...register('newPassword')}
                />
              </FormWrapper>
            </GridItem>
            <GridItem>
              <FormWrapper name='email' label='E-postadress' isRequired>
                <Input
                  type='text'
                  placeholder='E-postadress'
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  focusBorderColor=''
                  fontSize='14px'
                  bgColor='white'
                  color='#1A2C3D'
                  border='1px'
                  borderColor='#B1C5D9'
                  h='40px'
                  w='full'
                  borderRadius='lg'
                  {...register('email')}
                />
              </FormWrapper>
            </GridItem>
            <GridItem>
              <FormWrapper
                name='confirmPassword'
                label='Bekräfta lösenord'
                isRequired
              >
                <PasswordInput
                  placeholder='Bekräfta lösenord'
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  focusBorderColor=''
                  fontSize='14px'
                  bgColor='white'
                  color='#1A2C3D'
                  border='1px'
                  borderColor='#B1C5D9'
                  h='40px'
                  w='full'
                  borderRadius='lg'
                  {...register('confirmPassword')}
                />
              </FormWrapper>
            </GridItem>
            <GridItem>
              <FormWrapper name='phoneNumber' label='Telefon'>
                <Input
                  type='number'
                  placeholder='Telefon'
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  focusBorderColor=''
                  fontSize='14px'
                  bgColor='white'
                  color='#1A2C3D'
                  border='1px'
                  borderColor='#B1C5D9'
                  h='40px'
                  w='full'
                  borderRadius='lg'
                  {...register('phoneNumber')}
                />
              </FormWrapper>
            </GridItem>

            <GridItem></GridItem>
            <GridItem></GridItem>

            <GridItem w='full'>
              <Flex w='full' justify={'center'}>
                <Button
                  variant={'solid'}
                  type='submit'
                  onClick={handleCreate}
                  // rightIcon={<ArrowForwardIcon boxSize='6' />}
                  isLoading={isLoading}
                  fontSize='md'
                >
                  Skapa användare
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </VStack>
  )
}
