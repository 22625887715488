import React from 'react'
import { FormProvider } from 'react-hook-form'

import { FormWrapper } from 'common/Form'
import { useProfileDetails } from '../hooks'
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spinner,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const ProfileDetails = () => {
  const { onSubmit, formMethods, isFetchLoading, isUpdateUserLoading } =
    useProfileDetails()
  const { register } = formMethods
  const { t } = useTranslation()
  return (
    <FormProvider {...formMethods}>
      <form
        style={{ width: '100%', height: '100%' }}
        onSubmit={onSubmit}
        noValidate={true}
      >
        {isFetchLoading ? (
          <Center h='100px'>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        ) : (
          <HStack
            w='full'
            h='full'
            justifyContent='flex-start'
            align='flex-start'
          >
            <FormWrapper name='name' label={t('Name')} isRequired maxW='350px'>
              <Input
                type='text'
                placeholder={t('Name')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                disabled={isUpdateUserLoading}
                {...register('name')}
              />
            </FormWrapper>
            <FormWrapper
              name='email'
              label={t('Email_Address')}
              isRequired
              maxW='350px'
            >
              <Input
                type='text'
                placeholder={t('Email_Address')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                disabled={isUpdateUserLoading}
                {...register('email')}
              />
            </FormWrapper>

            <FormControl>
              <FormLabel fontSize='16px' color='#5A738B' fontFamily='Open Sans'>
                {t('Phone_Text')}
              </FormLabel>

              <HStack>
                <Input
                  type='tel'
                  placeholder={t('Phone_Text')}
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  focusBorderColor=''
                  fontSize='14px'
                  bgColor={'white'}
                  color={'#1A2C3D'}
                  border='1px'
                  borderColor='#B1C5D9'
                  h={'40px'}
                  w='full'
                  borderRadius={'lg'}
                  maxW='350px'
                  disabled={isUpdateUserLoading}
                  {...(register('phoneNumber'), { watch: 'phoneNumber' })}
                />
              </HStack>
            </FormControl>

            <Flex pl='10' pt='5' h='full' justify='center' align='start'>
              <Button
                isLoading={isUpdateUserLoading}
                type='submit'
                height='40px'
                fontSize={'md'}
              >
                {t('Dashboardfilter_Update')}
              </Button>
            </Flex>
          </HStack>
        )}
      </form>
    </FormProvider>
  )
}
