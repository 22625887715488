import axiosAPIInstance from './api'
import {
  Organization,
  CreateOrganizationDTO,
  UpdateOrganizationDTO,
  OrganizationList,
} from 'data-models'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

//Get all organizations
const getOrganizations = (): Promise<Organization[]> =>
  axiosAPIInstance
    .get(`/organizations`)
    .then(({ data }) => data as Organization[])

export const useOrganizations = () => {
  const getOrganizationsQuery = useQuery({
    queryKey: ['organizations'],
    queryFn: () => getOrganizations(),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = [],
    error,
    isLoading: loading,
  } = getOrganizationsQuery

  return {
    error,
    loading,
    response,
  }
}

//Get individual organization
interface UseOrganizationProps {
  id?: string
}

const getOrganization = ({ id }: UseOrganizationProps) =>
  axiosAPIInstance
    .get(`/organizations/${id}`)
    .then(({ data }) => data as Organization)

export const useOrganization = ({ id }: UseOrganizationProps) => {
  const getOrganizationQuery = useQuery({
    queryKey: ['organization', `${id}`],
    queryFn: () => getOrganization({ id }),
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
  })

  return getOrganizationQuery
}

//Get an organziation list, alongside stats.

const getOrganizationList = (): Promise<OrganizationList> =>
  axiosAPIInstance
    .get(`/organizations/list`)
    .then(({ data }) => data as OrganizationList)

export const useOrganizationList = () => {
  const getOrganizationListQuery = useQuery({
    queryKey: ['organizationList'],
    queryFn: () => getOrganizationList(),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = [],
    error,
    isLoading: loading,
  } = getOrganizationListQuery

  return {
    error,
    loading,
    response,
  }
}

interface UseDeleteOrganizationProps {
  id: string
}

const deleteOrganization = ({
  id,
}: UseDeleteOrganizationProps): Promise<void> =>
  axiosAPIInstance.delete(`/organizations/${id}`)

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient()
  const deleteOrganizationMutation = useMutation({
    mutationFn: ({ id }: UseDeleteOrganizationProps) =>
      deleteOrganization({ id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['organizations'], {
        exact: true,
      }),
        queryClient.invalidateQueries(['organizationList'], {
          exact: true,
        }),
        queryClient.invalidateQueries(['organization', `${variables.id}`], {
          exact: true,
        })
    },
  })

  return deleteOrganizationMutation
}

interface UseCreateOrganizationProps {
  values: CreateOrganizationDTO
}

const createOrganization = ({
  values,
}: UseCreateOrganizationProps): Promise<void> =>
  axiosAPIInstance.post(`/organizations`, { ...values })

export const useCreateOrganization = () => {
  const queryClient = useQueryClient()
  const createOrganizationMutation = useMutation({
    mutationFn: ({ values }: UseCreateOrganizationProps) =>
      createOrganization({ values }),
    onSuccess: () => {
      queryClient.invalidateQueries(['organizations'], {
        exact: true,
      }),
        queryClient.invalidateQueries(['organizationList'], {
          exact: true,
        })
    },
  })

  return createOrganizationMutation
}

interface UpdateOrganizationProps {
  id: string
  values: UpdateOrganizationDTO
}

const editOrganization = ({
  id,
  values,
}: UpdateOrganizationProps): Promise<void> => {
  return axiosAPIInstance.put(`/organizations/${id}`, {
    ...values,
  })
}

export const useEditOrganization = () => {
  const queryClient = useQueryClient()
  const editOrganizationMutation = useMutation({
    mutationFn: ({ id, values }: UpdateOrganizationProps) =>
      editOrganization({ id, values }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['organization', `${variables.id}`], {
        exact: true,
      })
      queryClient.invalidateQueries(['organizations'], {
        exact: true,
      })
      queryClient.invalidateQueries(['organizationList'], {
        exact: true,
      })
    },
    useErrorBoundary: false,
  })

  return editOrganizationMutation
}
