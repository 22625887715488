import React from 'react'
import { FormProvider } from 'react-hook-form'

import {
  Text,
  Flex,
  Grid,
  Modal,
  Button,
  HStack,
  GridItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react'

import { useUserPasswordReset } from './hooks'
import { PasswordInput } from 'common/PasswordInput'
import { User } from 'data-models'
import { FormWrapper } from 'common/Form'
import { useTranslation } from 'react-i18next'

interface UserPasswordResetProps {
  user: User
  isOpen: boolean
  onClose: () => void
}

export const UserPasswordReset = ({
  user,
  isOpen,
  onClose,
}: UserPasswordResetProps) => {
  const { formMethods, onSubmit, isLoading } = useUserPasswordReset({
    user,
    onClose,
  })
  const { register } = formMethods
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit} noValidate={true}>
          <ModalOverlay />
          <ModalContent maxW='700px' p='2' mt='100' color={'mainBlue.900'}>
            <ModalHeader>
              <Text color={'mainBlue.900'}>
                {t('Reset_Password_Text')}
              </Text>
            </ModalHeader>
            <ModalCloseButton
              color={'mainBlue.900'}
              colorScheme='blackAlpha'
              style={{ color: 'black' }}
            />
            <ModalBody overflow={'visible'}>
              <Flex w='full' h='full'>
                <Grid
                  w='full'
                  gridTemplateColumns={'repeat(2, 1fr)'}
                  gridGap={6}
                >
                  <GridItem>
                    <FormWrapper
                      name='newPassword'
                      label={t('New_Password')}
                      isRequired
                    >
                      <PasswordInput
                        placeholder={t('New_Password')}
                        _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                        focusBorderColor=''
                        fontSize='14px'
                        bgColor={'white'}
                        color={'#1A2C3D'}
                        border='1px'
                        borderColor='#B1C5D9'
                        h={'40px'}
                        w='full'
                        borderRadius={'lg'}
                        {...register('newPassword')}
                      />
                    </FormWrapper>
                  </GridItem>
                  <GridItem>
                    <FormWrapper
                      name='confirmPassword'
                      label={t('Confirm_Password')}
                      isRequired
                    >
                      <HStack>
                        <PasswordInput
                          placeholder={t('Confirm_Password')}
                          _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                          focusBorderColor=''
                          fontSize='14px'
                          bgColor={'white'}
                          color={'#1A2C3D'}
                          border='1px'
                          borderColor='#B1C5D9'
                          h={'40px'}
                          w='full'
                          borderRadius={'lg'}
                          maxW='350px'
                          {...register('confirmPassword')}
                        />
                      </HStack>
                    </FormWrapper>
                  </GridItem>
                </Grid>
              </Flex>
            </ModalBody>
            <ModalFooter pt='10'>
              <HStack w='full' justifyContent={'space-between'}>
                <Button
                  variant={'outline'}
                  color='blue'
                  bgColor={'white'}
                  fontWeight={'bold'}
                  borderColor='blue'
                  border={'2px solid'}
                  _hover={{ opacity: 0.7 }}
                  onClick={onClose}
                  mr='2'
                >
                  {t('Back_Text')}
                </Button>
                <Button
                  height='40px'
                  fontSize={'md'}
                  type='submit'
                  isLoading={isLoading}
                >
                  {t('Update_Password')}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}
