import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import {
  Tr,
  Td,
  Box,
  Text,
  Stack,
  Table,
  Thead,
  Center,
  Spinner,
  TableContainer,
} from '@chakra-ui/react'
import { partialRight, orderBy } from 'lodash-es'
import { PreschoolStatistics } from 'data-models/preschool'
import { getPreschoolStatistics } from 'services/followupResultService'
import * as XLSX from 'xlsx'
import { useUser } from 'providers/UserProvider'
import { PreschoolResultFilters } from './PreschoolResultFilters'
import { PreschoolResultFilterType } from './types'
import { useFollowups } from 'services/followupService'
import { useFilters } from 'providers/FilterProvider'
import { useTranslation } from 'react-i18next'

const initialFilters: PreschoolResultFilterType = {
  followupId: '',
  startDate: new Date(),
  endDate: new Date(),
  schoolYear: '',
}

export default function PreschoolResultGridView() {
  const orderByName = partialRight(orderBy, ['preschoolName'])
  const { user } = useUser()
  const {
    response: { data: followups },
  } = useFollowups({ organizationId: user.organizationId })
  const { t } = useTranslation()
  const [preschoolFollowupStatisticList, setPreschoolFollowupStatisticList] =
    useState<PreschoolStatistics[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const pageSize = 5
  const [currentPageData, setCurrentPageData] = useState<PreschoolStatistics[]>(
    [],
  )
  const [_totalPages, setTotalPages] = useState(1)
  const [pageNumber, _setpageNumber] = useState(1)
  const [csvData, setCsvData] = useState<PreschoolStatistics[]>([])

  const changeCrrentPageData = () => {
    const firstpageNumber = (pageNumber - 1) * pageSize
    const _lastpageNumber = firstpageNumber + pageSize
    const selectedData: PreschoolStatistics[] = preschoolFollowupStatisticList

    setCurrentPageData(orderByName(selectedData))
  }

  const { currentFollowupId } = useFilters()

  const [filters, setFilters] = useState<PreschoolResultFilterType>({
    ...initialFilters,
    followupId: currentFollowupId,
  })

  const handleSetFilters = useCallback(
    (filterKey: keyof PreschoolResultFilterType, value: any) => {
      setFilters({ ...filters, [filterKey]: value })
    },
    [filters],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        setIsError(false)

        const list = await getPreschoolStatistics(
          user.organizationId,
          filters.followupId,
        )

        const addIsSelectedField = list.map(preschool => ({
          ...preschool,
          isSelected: true,
        }))
        setPreschoolFollowupStatisticList(addIsSelectedField)
        setCsvData(orderByName(addIsSelectedField))
        setTotalPages(addIsSelectedField.length)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setIsError(true)
      }
    }

    fetchData()
  }, [filters.followupId])

  useEffect(() => {
    changeCrrentPageData()
  }, [preschoolFollowupStatisticList, pageNumber])

  const handleExport = () => {
    const xlsxData = csvData.map(col => {
      return {
        [`Uppföljning namn`]: followups.find(
          followup => followup.id === filters.followupId,
        )?.name,
        Förskola: col.preschoolName,
        Område: col.areaName,
        [`Denna Vecka`]: col.submittedWeekCount,
        Totalt: col.submittedTotalCount,
      }
    })
    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(xlsxData)
    XLSX.utils.book_append_sheet(wb, ws, 'StatistikData')
    XLSX.writeFile(wb, 'MyranStatistik.xlsx')
  }
  if (isError)
    return (
      <Center h='100px'>
        <p>Something went wrong ...</p>
      </Center>
    )

  return (
    <>
      {isLoading ? (
        <Center h='100px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        <>
          <Stack pt='8'>
            <PreschoolResultFilters
              filters={filters}
              setFilters={handleSetFilters}
              handleExport={handleExport}
              canExport={csvData?.length > 0}
            />
            <Box pt='8'>
              {currentPageData && currentPageData.length > 0 ? (
                <TableContainer
                  borderRadius='10px'
                  maxHeight='70vh'
                  overflowY='auto'
                  style={{ scrollSnapType: 'y' }}
                >
                  <Table variant='simple'>
                    <Thead position='sticky' top={0}>
                      <Tr
                        bgColor={'#d9e4ef'}
                        borderWidth='1px'
                        borderRadius='10px'
                        height='48px'
                        fontWeight={'bold'}
                        fontFamily={`'Open Sans',sans-serif`}
                        fontSize={'12px'}
                        style={{
                          scrollSnapAlign: 'center',
                          scrollSnapStop: 'always',
                        }}
                      >
                        <Td>{t('Follow_up_name')}</Td>
                        <Td>{t('ResultFilter_Preschool')}</Td>
                        <Td>{t('ResultFilter_Area')}</Td>
                        <Td>{t('This_Week')}</Td>
                        <Td>{t('Total')}</Td>
                      </Tr>
                    </Thead>
                    {currentPageData.map((preschool: any) => (
                      <Tr
                        fontWeight={'light'}
                        fontFamily={`'Open Sans',sans-serif`}
                        fontSize={'14px'}
                        key={preschool.id}
                        style={{
                          scrollSnapAlign: 'center',
                          scrollSnapStop: 'always',
                        }}
                      >
                        {/* <Td>
                    <Checkbox isChecked>x</Checkbox>
                  </Td> */}
                        <Td>{preschool.followupName}</Td>
                        <Td>{preschool.preschoolName}</Td>
                        <Td>{preschool.areaName}</Td>
                        <Td>{preschool.submittedWeekCount}</Td>
                        <Td>{preschool.submittedTotalCount}</Td>
                      </Tr>
                    ))}
                  </Table>
                </TableContainer>
              ) : (
                <Box
                  marginTop={'10px'}
                  height='200px'
                  w='100%'
                  textAlign='center'
                  px='16px'
                  py='50px'
                  borderRadius='8px'
                  h='100%'
                >
                  <Text fontSize={'18px'} fontWeight='bold'>
                    {t('ResultsTable_No_Data')}
                  </Text>
                </Box>
              )}
              {/* <Pagination
                currentPage={pageNumber}
                totalCount={totalPages}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={(page) => setpageNumber(page)}
              /> */}
            </Box>
          </Stack>
        </>
      )}
    </>
  )
}
