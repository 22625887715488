import { ProgressionStats } from 'data-models/progression'
import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getProgressionStats } from 'services/followupResultService'

export const useProgression = (filter: any) => {
  const [stats, setStats] = useState({} as ProgressionStats)

  const {
    user: { organizationId },
  } = useUser()

  const response = useAsync(
    () =>
      getProgressionStats(
        organizationId,
        filter.followups?.map(followup => followup.id),
        filter.areaIds,
        filter.preschoolIds,
        filter.departmentIds,
        filter.ages?.map(age => age.value),
      ),
    [filter],
  )

  const { value, loading } = response

  useEffect(() => {
    setStats(value ? value : ({} as ProgressionStats))
  }, [filter, value])

  return { stats, loading }
}
