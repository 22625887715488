import { useEffect, useState } from 'react'

import * as XLSX from 'xlsx'
import { UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { CommentsFilterType, defaultFilter } from '../types'
import { useIndividualComments } from 'services/commentsService'
import { useFollowups } from 'services/followupService'
import { useFilters } from 'providers/FilterProvider'

const FILTER_STORAGE_KEY = 'commentsFilterState'

export const useComments = () => {
  const { user } = useUser()

  const { loading: filtersLoading } = useFollowups({
    organizationId: user.organizationId,
  })

  const { currentFollowupId } = useFilters()

  // Retrieve the saved filter state from localStorage
  const savedFilter = JSON.parse(sessionStorage.getItem(FILTER_STORAGE_KEY) || 'null') || defaultFilter

  const [filter, setFilter] = useState<CommentsFilterType>({
    ...savedFilter,
    followupId: currentFollowupId,
    startDate: savedFilter.startDate ? new Date(savedFilter.startDate) : undefined,
    endDate: savedFilter.endDate ? new Date(savedFilter.endDate) : undefined,
  })

  useEffect(() => {
    // Initialize filter state with saved filter
    setFilter({
      ...savedFilter,
      followupId: currentFollowupId,
      startDate: savedFilter.startDate ? new Date(savedFilter.startDate) : undefined,
      endDate: savedFilter.endDate ? new Date(savedFilter.endDate) : undefined,
    })
  }, [currentFollowupId])

  useEffect(() => {
    // Save the filter state to sessionStorage whenever it changes
    sessionStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(filter))
  }, [filter])

  const handleFilterChange = (newFilter: Partial<CommentsFilterType>) => {
    setFilter({ ...filter, ...newFilter })
  }

  const isAllowedToView = [
    UserRole.SUPERADMIN,
    UserRole.ADMIN,
    UserRole.PRINCIPAL,
  ].includes(user.role)

  const { response, loading: isLoading } = useIndividualComments({
    organizationId: user.organizationId,
    preschoolIds: filter.preschoolIds,
    areaIds: filter.areaIds,
    followupId: filter.followupId,
    pageNumber: filter.pageNumber,
    pageSize: filter.pageSize,
    startDate: filter.startDate?.toJSON(),
    endDate: filter.endDate?.toJSON(),
  })

  const handleExport = () => {
    if (response && response?.data?.length > 0) {
      const { data } = response

      const xlsxData = data.map(col => {
        return {
          Date: col.submittedAt?.split('T')[0],
          Uppföljning: col.followupName,
          Område: col.areaName,
          Förskola: col.preschoolName,
          Email: col.email,
          Comments: col.comment,
        }
      })
      const wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(xlsxData)
      XLSX.utils.book_append_sheet(wb, ws, 'StatistikData')
      XLSX.writeFile(wb, 'MyranStatistik.xlsx')
    }
  }

  return {
    filter,
    isAllowedToView,
    response,
    isLoading,
    filtersLoading,
    handleExport,
    handleFilterChange,
  }
}
