import React, { PropsWithChildren } from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useActionsMenu } from './hooks'

interface ActionsMenuProps {
  name: string
}

export const ActionsMenu = ({
  name,
  children,
}: PropsWithChildren<ActionsMenuProps>) => {
  const { goBack } = useActionsMenu()

  return (
    <HStack
      w='full'
      bg='white'
      justify={'space-between'}
      borderBottom='0.5px solid'
      borderColor='purplishBlue'
      px='7'
      py='3'
    >
      <HStack onClick={goBack} cursor='pointer'>
        <ChevronLeftIcon color='darkIndigo' boxSize='8' />
        <Text color='darkIndigo' fontSize='lg'>
          {name}
        </Text>
      </HStack>
      <HStack>{children}</HStack>
    </HStack>
  )
}
