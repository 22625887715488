import { useDisclosure } from '@chakra-ui/react'

export const useDepartmentCreation = () => {
  const {
    isOpen: isCreateDepartmentOpen,
    onOpen: onCreateDepartmentOpen,
    onClose: onCreateDepartmentClose,
  } = useDisclosure()

  return {
    isCreateDepartmentOpen,
    onCreateDepartmentOpen,
    onCreateDepartmentClose,
  }
}
