import { LanguageAnalysisStatisticsType } from 'data-models/preschool'
import { useUser } from 'providers/UserProvider'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { getAveragePerAgeStatistics, getLanguageAnalysisStatistics } from 'services/followupResultService'
import { LanguageAnalysisType } from '../../LanguageAnalysisType'

export const useNumberOfWords = (filter: LanguageAnalysisType) => {
  const [stats, setStats] = useState<LanguageAnalysisStatisticsType[]>([])

  const {
    user: { organizationId },
  } = useUser()

  const response = useAsync(
    () =>
      getLanguageAnalysisStatistics(
        organizationId,
        filter.followupId,
        filter.areaIds,
        filter.preschoolIds,
        filter.departmentIds
      ),
    [filter],
  )

  const { value, loading } = response

  useEffect(() => {
    setStats(value ? value : [])
  }, [filter, value])

  return { stats, loading }
}

