import React, { PropsWithChildren, ReactNode } from 'react'
import {
  Text,
  Modal,
  Button,
  HStack,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface ConfirmationModalProps {
  onConfirmCallback: () => void
  header: ReactNode
  body: ReactNode
  cancelText?: string
  confirmText?: string
  isOpen: boolean
  onClose: () => void
  isLoading?: boolean
}

export const ConfirmationModal = ({
  onConfirmCallback,
  header,
  body,
  cancelText = 'Annullera',
  confirmText = 'Bekräfta',
  isOpen,
  onClose,
  isLoading = false,
}: PropsWithChildren<ConfirmationModalProps>) => {
  const handleConfirm = () => {
    onConfirmCallback()
  }
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
        <ModalOverlay />
        <ModalContent maxW='480px' p='2' color={'mainBlue.900'}>
          <ModalHeader>
            <Text color={'mainBlue.900'}>{header}</Text>
          </ModalHeader>
          <ModalCloseButton
            color={'mainBlue.900'}
            colorScheme='blackAlpha'
            style={{ color: 'black' }}
          />
          <ModalBody>{body}</ModalBody>
          <ModalFooter>
            <HStack w='full' justifyContent={'flex-end'}>
              <Button
                variant={'outline'}
                color='blue'
                bgColor={'white'}
                fontWeight={'bold'}
                borderColor='blue'
                border={'2px solid'}
                _hover={{ opacity: 0.7 }}
                onClick={onClose}
                mr='2'
              >
                {t('Cancel_Text')}
              </Button>
              <Button
                height='40px'
                width='110px'
                fontSize={'md'}
                onClick={handleConfirm}
                isLoading={isLoading}
                variant='confirmation'
              >
                {t('Confirm_Text')}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
