import React from 'react'
import {
  Box,
  FormLabel,
  FormControl,
  FormControlProps,
  FormErrorMessage,
} from '@chakra-ui/react'
import { isNil } from 'lodash-es'
import { useFormContext } from 'react-hook-form'

interface FormWrapperProps extends FormControlProps {
  name: string
  label: string
}
interface MessageFormWrapperProps extends FormControlProps {
  name: string
  label: string
  specificErrorMessage: string
}
export const FormWrapper = ({
  name,
  label,
  children,
  ...rest
}: FormWrapperProps) => {
  const formContext = useFormContext()
  const {
    formState: { errors },
  } = formContext
  const isInvalid = !isNil(errors[name])

  return (
    <FormControl isInvalid={isInvalid} {...rest}>
      <FormLabel fontSize='16px' color='#5A738B' fontFamily='Open Sans'>
        {label}
      </FormLabel>
      <Box>{children}</Box>
      {errors[name] && (
        <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
      )}
    </FormControl>
  )
}
export const MessageFormWrapper = ({
  name,
  label,
  children,
  specificErrorMessage, // New prop for specific error message
  ...rest
}: MessageFormWrapperProps) => {
  const formContext = useFormContext()
  const {
    formState: { errors },
  } = formContext
  const isInvalid = !isNil(errors[name])

  return (
    <FormControl isInvalid={isInvalid} {...rest}>
      <FormLabel fontSize='16px' color='#5A738B' fontFamily='Open Sans'>
        {label}
      </FormLabel>
      <Box>{children}</Box>
      {errors[name] && (
        <FormErrorMessage>
          {specificErrorMessage || errors[name]?.message}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
