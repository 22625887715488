import React from 'react'
import { Box, Spinner } from '@chakra-ui/react'

interface StatsContainerProps {
  name: string
  count: number
  loading: boolean
  imageSrc: string
}

export const StatsContainer = ({
  count,
  imageSrc,
  loading,
  name,
}: StatsContainerProps) => {
  return (
    <Box
      w='full'
      h='full'
      minW='200px'
      className='box-detail'
      style={{ opacity: loading ? 0.6 : 1 }}
    >
      <div className='box-detail-f1'>{name}</div>
      <div className='mt-10'>
        <img className='d-inline' width='34' src={imageSrc} alt='' />
        <span className='box-detail-f2'>
          {loading ? <Spinner size='sm' /> : count}
        </span>
      </div>
    </Box>
  )
}
