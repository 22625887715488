import React from 'react'
import { useCallback } from 'react'
import DatePicker from 'react-datepicker'

import { Box, Button, FormLabel, HStack, Image } from '@chakra-ui/react'
import CustomSelect from 'common/CustomSelect'
import calendarIcon from '../../../../assets/images/ico-calender.svg'
import { PreschoolResultFilterType } from './types'
import {
  calculateAcademicYear,
  calculateAcademicYearBasedOnStartDate,
} from 'common/utils'
import { emptyFollowup, Followup } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useFollowups } from 'services/followupService'
import { useFilters } from 'providers/FilterProvider'
import { useTranslation } from 'react-i18next'

const computeSchoolYearOptions = (followupList: Followup[]) => {
  const result = new Set(
    followupList.map(f => calculateAcademicYear(new Date(f.endDate))),
  )

  return Array.from(result).map(schoolYear => ({
    id: schoolYear,
    name: schoolYear,
  }))
}

interface PreschoolResultFiltersProps {
  canExport?: boolean
  handleExport: () => void
  filters: PreschoolResultFilterType
  setFilters: (filterKey: keyof PreschoolResultFilterType, value: any) => void
}

export const PreschoolResultFilters = ({
  filters,
  canExport,
  setFilters,
  handleExport,
}: PreschoolResultFiltersProps) => {
  const { user } = useUser()
  const {
    response: { data: followups },
  } = useFollowups({ organizationId: user.organizationId })
  const { t } = useTranslation()

  const followupOptions = useCallback(
    () => followups.filter(f => f.tasks.length !== 0 && f.status !== 'Draft'),
    [followups],
  )()

  const { setCurrentFollowupId } = useFilters()

  const schoolYearOptions = useCallback(
    () => computeSchoolYearOptions(followups),
    [followups],
  )()

  const selectedFollowup =
    followups.find(followup => followup.id === filters.followupId) ||
    emptyFollowup

  const currentSchoolYear = calculateAcademicYearBasedOnStartDate(
    new Date(selectedFollowup?.startDate),
  )

  const handleChangeFollowup = (newFollowup: Followup) => {
    setCurrentFollowupId(newFollowup.id)
    setFilters('followupId', newFollowup.id)
  }

  return (
    <HStack justifyContent={'space-between'}>
      <HStack spacing='5'>
        <Box className='open-sans'>
          <FormLabel fontSize='12px' color='#5A738B'>
            {t('FollowupFilter_FollowUp_Name')}*
          </FormLabel>
          <CustomSelect
            name='followup'
            value={followupOptions.find(
              followup => followup.id === filters.followupId,
            )}
            options={followupOptions}
            onChange={handleChangeFollowup}
            className='bold-value'
            isMulti={false}
            placeholder={t('ResultFilter_Choose_FollowUp')}
            minWidth='250px'
            maxWidth='250px'
            maxHeight='38px'
          />
        </Box>

        <Box>
          <FormLabel fontSize='12px' color='#5A738B'>
            {t('FollowupTable_Start_date')}*
          </FormLabel>

          <div
            className='position-relative'
            style={{ backgroundColor: 'white' }}
          >
            <label>
              <div>
                <DatePicker
                  dateFormat='yyyy-MM-dd'
                  selected={new Date(selectedFollowup?.startDate || new Date())}
                  autoComplete='off'
                  minDate={new Date()}
                  maxDate={new Date(selectedFollowup?.endDate || new Date())}
                  onChange={(date: Date) => setFilters('startDate', date)}
                  disabled
                />
              </div>
              <div className='cal-icon'>
                <Image src={calendarIcon} />
              </div>
            </label>
          </div>
        </Box>

        <Box>
          <FormLabel fontSize='12px' color='#5A738B'>
            {t('FollowupTable_End_date')}*
          </FormLabel>
          <div className='position-relative'>
            <label>
              <div>
                <DatePicker
                  id='endDate'
                  autoComplete='off'
                  selected={new Date(selectedFollowup?.endDate || new Date())}
                  dateFormat='yyyy-MM-dd'
                  startDate={new Date(selectedFollowup?.endDate || new Date())}
                  minDate={new Date(selectedFollowup?.startDate)}
                  onChange={(date: Date) => setFilters('endDate', date)}
                  disabled
                />
              </div>
              <div className='cal-icon'>
                <Image src={calendarIcon} />
              </div>
            </label>
          </div>
        </Box>

        <Box className='open-sans'>
          <FormLabel fontSize='12px' color='#5A738B'>
            {t('School_Year')}*
          </FormLabel>
          <CustomSelect
            name='schoolYear'
            value={{
              id: selectedFollowup?.startDate
                ? currentSchoolYear
                : calculateAcademicYearBasedOnStartDate(new Date()),
              name: selectedFollowup?.startDate
                ? currentSchoolYear
                : calculateAcademicYearBasedOnStartDate(new Date()),
            }}
            options={schoolYearOptions}
            onChange={e => setFilters('schoolYear', e.id)}
            className='bold-value'
            isMulti={false}
            isDisabled={true}
            placeholder='--'
            minWidth='250px'
          />
        </Box>
      </HStack>
      <Box>
        <Button
          variant={'outline'}
          isDisabled={!canExport}
          onClick={handleExport}
        >
          {t('CommentFilter_Export')}
        </Button>
      </Box>
    </HStack>
  )
}
