import React from 'react'
import Select, { components, createFilter } from 'react-select'
import { at, compact, first } from 'lodash-es'
import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
export interface ISelectOption {
  value: string
  label: string
}

export default function CustomSelect<T>(props: {
  name: string
  placeholder?: string
  value: any
  options: any
  searchable?: boolean
  minWidth?: string
  isOptionDisabled?: any
  maxWidth?: string
  maxHeight?: string
  fontSize?: string
  isMulti?: boolean
  className?: string
  isLoading?: boolean
  labelKey?: string
  valueKey?: string
  onChange: (e: any) => void
  isDisabled?: boolean
  borderRadius?: string
  isClearable?: boolean
  customOptionGenerator?: (data: Partial<T>) => React.ReactNode
}) {
  const customStyles = {
    control: (base: any) => ({
      ...base,
      minWidth: props.minWidth || '140px',
      maxWidth: props.maxWidth || '1000px',
      maxHeight: props.maxHeight || '100px',
      border: 'solid 1px #B1C5D9',
      borderRadius: '8px',
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 100,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#2a244d',
      height: '30px',
      padding: '10px 0 12px 2px',
      fontSize: props.fontSize || '14px',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#CBD5E0',
      width: '38px',
      padding: '0 0px',
      paddingLeft: '7px',
      height: '60%',
      alignItems: 'center',
      borderLeft: '1px solid #B1C5D9',
    }),
    clearIndicator: (base: any) => ({
      ...base,
      display: props.isMulti ? 'none' : '',
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: '#e3f0f1',
      borderRadius: '10px',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      fontWeight: 'bold',
      padding: '4.5px 3px 4.5px 10px !important',
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'initial',
        color: '#2a244d',
        cursor: 'pointer',
      },
    }),
  }

  // const getOptionLabel = (x: any) =>
  //  first(compact(at(x, compact([props.labelKey, 'name', 'label']))))

  const getOptionLabel = (x: any) => {
    const label = first(
      compact(at(x, compact([props.labelKey, 'name', 'label']))),
    )
    const maxLabelLength = 25 // Set your desired maximum label length
    const truncatedLabel =
      label.length > maxLabelLength
        ? `${label.slice(0, maxLabelLength)}...`
        : label

    return truncatedLabel
  }

  const getOptionValue = (x: any) =>
    first(compact(at(x, compact([props.valueKey, 'id', 'value']))))?.toString()

  // Used for '+X more' tag in multiselects
  const ValueContainer = (props: any) => {
    const length = props.getValue().length
    const [selections, input] = props.children
    const { t } = useTranslation()
    return (
      <components.ValueContainer {...props}>
        {length > 1 ? (
          <Flex justify='center' align='center'>
            {selections.slice(0, 1)}
            <Text ml='1' w='55px'>
              +{length - 1} {t('More_Text')}
            </Text>
            {React.cloneElement(input)}
          </Flex>
        ) : (
          <>{props.children}</>
        )}
      </components.ValueContainer>
    )
  }

  return (
    <Select
      {...props}
      className={`app-select ${props.className}`}
      classNamePrefix='app-select'
      styles={customStyles}
      isSearchable={props.searchable || false}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      hideSelectedOptions={false}
      components={{
        ValueContainer,
      }}
      menuPlacement='auto'
      formatOptionLabel={props.customOptionGenerator}
      filterOption={createFilter({ ignoreAccents: false })}
    />
  )
}
