import { useToast } from '@chakra-ui/react'
import { useUser } from 'providers/UserProvider'
import { useAreas } from 'services/areaService'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const useArea = () => {
  const {
    user: { organizationId },
  } = useUser()

  const { loading: loadingAreas, error: errorAreas, response: responseAreas } = useAreas({ organizationId })

  const toast = useToast()
  const history = useHistory()
  const { t } = useTranslation()




  if (errorAreas) {
    toast({
      title: `${t('Preschool_Load_Error_Text')}`,
      isClosable: true,
      duration: 3000,
      status: 'error',
    })
  }

  const handleOpenCreateArea = () => {
    history.push(`/area/create`)
  }

  return {
    responseAreas,
    loadingAreas,
    handleOpenCreateArea,
  }

}
