import Cookies from 'js-cookie'

const isLocalhost = process.env.NODE_ENV === 'development'

const config = isLocalhost
  ? {
      domain: 'localhost',
    }
  : {
      // domain: 'myran-admin-test.azurewebsites.net',
      domain: '.myranappen.se',
      secure: true,
      sameSite: 'none',
    }

// Create a cookies instance with the specified configuration
export const cookies = Cookies.withAttributes(config)
