import React, { useEffect, useState } from 'react'
import { ImagePlacementTask, PlacementPoint } from 'data-models/task-models'
import { Box, Image } from '@chakra-ui/react'
import ImagePlacementPoint from './ImagePlacementPoint'
import { apiBase } from 'services/config'

export default function ImagePlacementBase(props: { task: any }) {
  const currentTask = props.task
  const [task, setTask] = useState(currentTask as ImagePlacementTask)

  const changePlacement = (newXY: PlacementPoint) => {
    //   task.answerOptions.forEach((element, index) => {
    //     if(element.correctPoint == newXY.id) {
    //       task.answerOptions[index] = {...element,placedAtPoint: newXY.id};
    //     }
    // })
    const newQues = task
    newQues.answerOptions.forEach((a, index) => {
      if (newXY)
        if (a.correctPoint == newXY.id)
          newQues.answerOptions[index].placedAtPoint = newXY.id
    })
    const answerPlacement = newQues.answerOptions
    //  const placement = ((a.imageUrl.split('/assets/'))[1].split('.'))[0]
    //  const pointPlacement = task.placementPoints.map(a =>
    //   {
    //     if(newXY)
    //     if (a.id == newXY.id)
    //     return {...a,x : newXY.x,y:newXY.y}
    //     else return {...a}
    //     else return {...a}
    //   }
    //  )
    setTask(v => ({ ...v, ...{ answerOptions: answerPlacement } }))
  }

  return (
    <Box pos='relative' zIndex='0'>
      <Image src={apiBase + task.imageUrl} pos='relative' zIndex='-1' />

      {task.placementPoints.map((pp: any) => {
        const placedImage = task.answerOptions.find(
          (a: any) => a.placedAtPoint === pp.id,
        )
        return (
          <ImagePlacementPoint
            key={pp.id}
            pp={pp}
            pi={placedImage}
            onChange={changePlacement}
          />
        )
      })}
    </Box>
  )
}
