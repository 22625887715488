import React from 'react'

export const LegendItemRenderer = (value: string) => {
  return (
    <span
      style={{
        color: '#41567e',
        left: '10px',
        top: '2px',
        marginRight: '10px',
        lineHeight: '25px',
        height: '18px',
        fontSize:'12px'
      }}
    >
      {value}
    </span>
  )
}

export const LegendItemRendererWithState = (value: string, list: string[]) => {
  if (list.includes(value)) return null
  return (
    <span
      style={{
        color: '#41567e',
        left: '10px',
        top: '2px',
        marginRight: '10px',
        lineHeight: '24px',
      }}
    >
      {value}
    </span>
  )
}
