import qs from 'qs'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { idpUrl } from './config'
import axiosAPIInstance, { getAxiosInstance } from './api'
import { User, CreateUserDTO, UpdateUserDTO, InviteUserDTO } from 'data-models'
import { UsersFilterType } from 'pages/SuperAdminSettings/Users/UsersFilterType'
import { useToast } from '@chakra-ui/react'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'

interface UseUsersProps {
  filter: Partial<UsersFilterType>
  organizationId?: string
}

//Get all users
const getUsers = ({ filter, organizationId }: UseUsersProps): Promise<User[]> =>
  getAxiosInstance()
    .get(`/users/${organizationId}`, {
      params: { ...filter },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
    .then(({ data }) => data as User[])

export const useUsers = ({ filter, organizationId }: UseUsersProps) => {
  const getUsersQuery = useQuery({
    queryKey: ['users', organizationId, filter],
    queryFn: () => getUsers({ organizationId, filter }),
    staleTime: 1000 * 60 * 10,
  })

  return getUsersQuery
}

//Get individual organization
interface UseUserProps {
  id?: string
  organizationId?: string
}

const getUser = ({ id, organizationId }: UseUserProps) =>
  axiosAPIInstance
    .get(`/users/${organizationId}/${id}`)
    .then(({ data }) => data as User)

export const useUserDetails = ({ id, organizationId }: UseUserProps) => {
  const getUserDetailsQuery = useQuery({
    queryKey: ['user', `${id}`],
    queryFn: () => getUser({ id, organizationId }),
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
  })

  return getUserDetailsQuery
}

interface DeleteUserProps {
  id: string
  organizationId: string
}

const deleteUser = ({ id, organizationId }: DeleteUserProps): Promise<void> =>
  axiosAPIInstance.delete(`/users/${organizationId}/${id}`)

export const useDeleteUser = () => {
  const queryClient = useQueryClient()
  const deleteUserMutation = useMutation({
    mutationFn: ({ id, organizationId }: DeleteUserProps) =>
      deleteUser({ id, organizationId }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['users']),
        queryClient.invalidateQueries(['user', `${variables.id}`], {
          exact: true,
        })
    },
  })

  return deleteUserMutation
}

export const inviteUser = async (
  values: InviteUserDTO,
): Promise<{ token: string }> => {
  const res = await getAxiosInstance(idpUrl).post(`/invites`, { ...values })

  return res.data
}

interface CreateUserProps {
  token: string
  values: CreateUserDTO
}

const createUser = ({
  token,
  values,
}: CreateUserProps): Promise<{ token: string; refresh_token: string }> => {
  return getAxiosInstance(idpUrl)
    .post(
      `/users`,
      {
        ...values,
      },
      {
        auth: {
          username: '',
          password: token,
        },
      },
    )
    .then(({ data }) => data as { token: string; refresh_token: string })
}

export const useCreateUser = () => {
  const history = useHistory()
  const toast = useToast();
  const queryClient = useQueryClient()
  const createUserMutation = useMutation({
    mutationFn: (props: CreateUserProps) => createUser(props),
    onSuccess: () => {
      queryClient.invalidateQueries(['users'])
    },
    onError: (error: any) => {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors[0] && error.response.data.errors[0].code
        // Customize the error message based on the User already exists
        if (errorMessage === "409") {
          toast({
            title: `${t('User_is_already_active')}`,
            isClosable: true,
            duration: 5000,
            status: 'error',
          })
          history.push('/login/avesta')
        }
      } else {
        // Handle unexpected error structure or other cases
        toast({
          title: `${t('An_unexpected_error_occurred')}`,
          isClosable: true,
          duration: 3000,
          status: 'error',
        })
      }
    },
  })

  return createUserMutation
}

interface UseUpdateUserProps {
  id: string
  values: UpdateUserDTO
  organizationId: string
}

const editUser = ({
  id,
  values,
  organizationId,
}: UseUpdateUserProps): Promise<void> => {
  return axiosAPIInstance.put(`/users/${organizationId}/${id}`, {
    ...values,
  })
}

export const useEditUser = () => {
  const queryClient = useQueryClient()
  const editUserMutation = useMutation({
    mutationFn: (props: UseUpdateUserProps) => editUser(props),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['user', `${variables.id}`], {
        exact: true,
      })
      queryClient.invalidateQueries(['users'])
    },
    useErrorBoundary: false,
  })

  return editUserMutation
}
