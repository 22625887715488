import React from 'react'
import {
  Center,
  HStack,
  Stack,
  Text,
  Spinner,
  TextProps
} from '@chakra-ui/react'

const CenteredSpinner = () => {
  return (
    <Stack alignItems='center' justifyContent='center' pb='5'>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    </Stack>
  )
}

const Title = ({ children }: TextProps) => {
  return (
    <Text
      margin='10px 0px 15px 0px'
      fontWeight='bold'
      fontStretch='normal'
      fontStyle='normal'
      lineHeight='normal'
      color='#2a244d'
    >
      {children}
    </Text>
  )
}

export const ChartContainer = ({
  isSquareContainer,
  title,
  topRight,
  loading,
  children,
  ...rest
}: {
  title: string
  helptext?: string
  helpDetailText?:string
  loading?: boolean
  isSquareContainer?: boolean
  children: React.ReactNode
  topRight?: React.ReactNode
}) => {
  return (
    <Stack
      direction='column'
      className={isSquareContainer ? 'card square' : 'card'}
      {...rest}
    >
      <HStack align='center' justify='space-between' className='card-title'>
        <Center>
          <HStack spacing={3}>
            <Title>{title}</Title>
            {/* <InfoTooltipSection>{helptext}</InfoTooltipSection> */}
          </HStack>         
        </Center>
        {topRight}       
      </HStack>
      {loading ? <CenteredSpinner /> : children}
    </Stack>
  )
}
