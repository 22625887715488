import React from 'react'
import {
  Tr,
  Th,
  Td,
  Table,
  Thead,
  Tbody,
  Center,
  Spinner,
  Container,
  TableContainer,
} from '@chakra-ui/react'
import { useUserTable } from '../hooks'
import { UsersFilterType } from '../UsersFilterType'
import {
  UserRoleToLabelMapping,
  UserRoleToLabelMappingEn,
  UserRoleToLabelMappingDe,
} from 'data-models'
import { useTranslation } from 'react-i18next'

interface UserTableProps {
  filter: UsersFilterType
}

export const UserTable = ({ filter }: UserTableProps) => {
  const { users, loading, handleEditUser } = useUserTable({ filter })
  const { t, i18n } = useTranslation()
  return (
    <Container p='0' maxW='full'>
      {loading ? (
        <Center h='100px'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      ) : (
        <>
          <TableContainer borderRadius='lg'>
            <Table variant='simple'>
              <Thead>
                <Tr
                  bgColor={'rgba(177, 197, 217, 0.5)'}
                  borderWidth='1px'
                  borderRadius='10px'
                  height='48px'
                  fontWeight={'bold'}
                  fontFamily={`'Open Sans',sans-serif`}
                  fontSize={'12px'}
                >
                  <Th>{t('Name')}</Th>
                  <Th>{t('Email_Address')}</Th>
                  <Th>{t('Phone_Text')}</Th>
                  <Th>{t('Role_Text')}</Th>
                </Tr>
              </Thead>

              <Tbody>
                {users.map((user, index) => (
                  <Tr
                    key={`${user.id}_${index}`}
                    onClick={() => handleEditUser(user)}
                    cursor={'pointer'}
                  >
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.phoneNumber}</Td>
                    <Td>
                      {i18n.language === 'se'
                        ? UserRoleToLabelMapping[user.role]
                        : i18n.language === 'de'
                        ? UserRoleToLabelMappingDe[user.role]
                        : UserRoleToLabelMappingEn[user.role]}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  )
}
