import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, Link } from '@chakra-ui/react'
import * as csstype from 'csstype'
import { useTranslation } from 'react-i18next'

function textWidth(text: string, fontFamily: csstype.Property.FontFamily) {
  const tag = document.createElement('div')
  tag.style.whiteSpace = 'nowrap'
  tag.style.position = 'absolute'
  tag.style.fontSize = '15px'
  tag.style.fontFamily = fontFamily
  tag.appendChild(document.createTextNode(text))
  document.body.appendChild(tag)
  const result = tag.scrollWidth
  document.body.removeChild(tag)
  return result
}

export default function InfoMoreSection(props: { children?: any }) {
  const [showMore, setShowMore] = useState(false)
  const [inputLenghty, setInputLengthy] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current !== null) {
      const eventListener = () => {
        if (ref.current !== null) {
          setInputLengthy(
            textWidth(props.children, 'inherit') >
              (ref.current.getBoundingClientRect().width as number),
          )
        }
      }
      eventListener()
      window.addEventListener('resize', eventListener)

      return () => window.removeEventListener('resize', eventListener)
    }
  }, [props.children])
  const { t } = useTranslation()
  return (
    <>
      {typeof props.children === 'string' && props.children.length > 0 && (
        <>
          {inputLenghty ? (
            showMore ? (
              <Box ref={ref}>
                <Box
                  flex='1'
                  textAlign='left'
                  style={{
                    display: 'inline',
                    lineHeight:"2"
                  }}
                >
                  {props.children}
                </Box>
                <Link
                  color='brandPrimary.300'
                  fontWeight='bold'
                  paddingLeft='1'
                  onClick={() => setShowMore(false)}
                >
                  {t('Show_Less_Text')}
                </Link>
              </Box>
            ) : (
              <Flex ref={ref}>
                <Box
                  flex='1'
                  textAlign='left'
                  style={{
                    textOverflow: 'ellipsis',
                    width: '85%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'inline-block',
                  }}
                >
                  {props.children}
                </Box>
                <Link
                  color='brandPrimary.300'
                  fontWeight='bold'
                  onClick={() => setShowMore(true)}
                >
                  {t('Show_More_Text')}
                </Link>
              </Flex>
            )
          ) : (
            <Box
              ref={ref}
              flex='1'
              textAlign='left'
              style={{
                display: 'inline',
              }}
            >
              {props.children}
            </Box>
          )}
          <br />
        </>
      )}
    </>
  )
}
