import React, { forwardRef, Ref, useState } from 'react'
import {
  Input,
  Image,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import hidePswd from '../assets/images/ico-hide-pwd.svg'
import showPswd from '../assets/images/ico-show-pwd.svg'

export const PasswordInput = forwardRef(
  ({ isDisabled, ...rest }: InputProps, ref: Ref<HTMLInputElement>) => {
    const [showPassword, setShowPassword] = useState(false)

    const togglePassword = () => setShowPassword(!showPassword)

    return (
      <InputGroup w='full'>
        <Input
          ref={ref}
          pr='55px'
          isDisabled={isDisabled}
          {...rest}
          type={showPassword ? 'text' : 'password'}
        />
        <InputRightElement m='0' h='full'>
        <Image
            src={showPassword ? hidePswd : showPswd}
            onClick={isDisabled ? () => {} : togglePassword}
            style={{
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              opacity: isDisabled ? 0.4 : 0.8,
            }}
          />
         
        </InputRightElement>
      </InputGroup>
    )
  },
)

PasswordInput.displayName = 'PasswordInput'
