import React from 'react'
import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const TextProvider = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Text>{t('chart_show_the_progression_group')}</Text>

      <UnorderedList pl={5} lineHeight={1.5}>
        <ListItem>{t('Group_Low_has_33%_lowest_results')}</ListItem>
        <ListItem>{t('Group_Medium_lies_between_groups_Low_High')}</ListItem>
        <ListItem>{t('Group_High_has_33%_highest_results')}</ListItem>
      </UnorderedList>

      <Text>{t('percentage_of_points_out_maximum_number_of_points')}</Text>
    </Box>
  )
}

export default TextProvider
