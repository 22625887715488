import React from 'react'
import switchuser from 'assets/images/Switch-User.svg'
import { Button, Flex } from '@chakra-ui/react'

const handleClick = async () => {
  // if (process.env.NODE_ENV === 'development') {
  //   window.location.href = 'https://portal.test.myranappen.se'
  // } else {
  //   window.location.href = 'https://portal.myranappen.se'
  // }
  window.location.href = `${process.env.REACT_APP_PORTAL_URL}`
}

export const PortalNav = () => {
  return (
    <Flex h='32px' w='32px'>
      <Flex onClick={handleClick} h='full' w='full'>
        <Button
          h='full'
          w='full'
          p='0'
          display='flex'
          justifyContent='center'
          alignItems='center'
          variant='unstyled'
          _focus={{
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <img src={switchuser} alt='Switch User' style={{ height: '30px' }} />
        </Button>
      </Flex>
    </Flex>
  )
}
