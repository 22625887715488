import React from 'react'
import { Spinner, VStack, Text } from '@chakra-ui/react'
import { useFollowupContainer } from './hooks'
import { FollowupEditor } from './FollowupEditor'
import { useTranslation } from 'react-i18next'

interface FollowupContainerProps {
  isCreate: boolean
}

export const FollowupContainer = ({ isCreate }: FollowupContainerProps) => {
  const { loading, followup } = useFollowupContainer()
  const { t } = useTranslation()
  return (
    <>
      {loading ? (
        <VStack spacing='5' w='full' h='full' justify='center' pt='20'>
          <Spinner size='xl' color='black' />
          <Text
            color='black'
            mb='14'
            mt='7'
            fontSize='large'
            fontWeight='light'
          >
            {t('Fetch_FollowUp')}
          </Text>
        </VStack>
      ) : (
        <FollowupEditor isCreate={isCreate} followup={followup} />
      )}
    </>
  )
}
