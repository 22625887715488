import React, { useEffect, useState } from 'react'
import classes from './MainHeader.module.css'
import logo from '../assets/images/Logo-App-Nav.svg'
import {
  Tab,
  Box,
  Tabs,
  Flex,
  Text,
  Image,
  VStack,
  Button,
  HStack,
  Portal,
  Popover,
  TabList,
  TabPanel,
  TabPanels,
  PopoverArrow,
  PopoverFooter,
  PopoverHeader,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
  Divider,
  Center,
} from '@chakra-ui/react'

import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { AuthService } from '../services/authService'
import { Overview } from 'pages/MainTabs/OverviewTab/Overview'
import Statistic from 'pages/MainTabs/StatisticTab/Statistic'
import { SuperAdminSettings } from 'pages/SuperAdminSettings'
import CustomSelect from './CustomSelect'
import { useMainHeader } from './useMainHeader'
import { UserRole } from 'data-models'
import { Feedback } from './Feedback'
import { PortalNav } from './PortalNav'
import { OrganizationContainer } from 'pages/EntityViewers'
import { FollowupContainer } from 'pages/EntityViewers/Followup'
import { PreschoolContainer } from 'pages/EntityViewers/Preschool'
import { AreaContainer } from 'pages/EntityViewers/Area'
import { UserContainer } from 'pages/EntityViewers/User/UserContainer'
import { CreateUserInviteContainer } from 'pages/EntityViewers/User/CreateUserInviteContainer'
import { ResultContainer } from 'pages/EntityViewers/Result'
import { useDownloadFile } from 'services/fileService'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from './LanguageSwitcher'

export default function NavigationPanel() {
  const { t,i18n } = useTranslation()
  const languages: string[] = ['se', 'en','de']
  const buttonBgColor = useColorModeValue('#733ffa', '#733ffa')
  const {
    user,
    currentOrganization,
    organizationOptions,
    handleOrganizationChange,
  } = useMainHeader()

  const handleAnalysisTabChange = () => {
    const hasDepartments = currentOrganization?.hasMultipleSections?.toString();
    if(hasDepartments){
      sessionStorage.setItem('hasDepartments',hasDepartments)
    }
  };
  
  handleAnalysisTabChange()


 const currentPath  = location.pathname;
 let showHeader = true;

 currentPath === '/release-notes' ? showHeader = false : showHeader = true
 

  //check whether organization has language analysis enabled and store it in session storage.
  const doesOrgHasLanguageAnalysis = currentOrganization?.hasLanguageAnalysis;
  sessionStorage.setItem('languageAnalysis', JSON.stringify(doesOrgHasLanguageAnalysis));
 
  const { pathname } = useLocation()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    switch (true) {
      //Entity viewers
      case pathname.includes('/organization/'):
      case pathname.includes('/followup/'):
      case pathname.includes('/preschool/'):
      case pathname.includes('/area/'):
      case pathname.includes('/user/'):
      case pathname.includes('/result/'):
        setActiveTab(3)
        break
      case pathname.includes('settings'):
        setActiveTab(2)
        break
      case pathname.includes('statistic'):
      case pathname.includes('statistic-dashboard'):
      case pathname.includes('statistic-progression'):
      case pathname.includes('statistic-list'):
      case pathname.includes('language-analysis'):
        setActiveTab(1)
        break
      case pathname.includes('overview'):
      case pathname.includes('overview-followups'):
      case pathname.includes('overview-results'):
      case pathname.includes('overview-comments'):
      default:
        setActiveTab(0)
        break
    }
  }, [pathname])

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const canSeeDashboard = [
    UserRole.SUPERADMIN,
    UserRole.ADMIN,
    UserRole.PRINCIPAL,
  ].includes(user.role)

  const goToSettings = () => {
    history.push('/settings/organizations')
  }

  const { resourceURL: userManualDownloadEnglishURL } = useDownloadFile({
    path: '/assets/Myran-UserGuide-EN.pdf',
  })

  const { resourceURL: userManualDownloadSwedishURL } = useDownloadFile({
    path: '/assets/Myran-UserGuide-SE.pdf',
  })


 

  return (
    <>
      <Tabs
        isLazy
        height='64px'
        variant='line'
        fontSize={'15px'}
        style={{ outline: 'none' }}
        onChange={(tab: any) => toggle(tab)}
        index={activeTab}
      >
        <Box bgGradient='linear(to-l, purplishBlue, blue)' p='2'>
          <HStack className={classes['navigation-panel']} zIndex='999'>
            <HStack className={classes['navigation-items-left']}>
              <Image src={logo} />
              {
  showHeader && 

              <TabList
                height='64px'
                paddingLeft='30px'
                pb='1.5'
                border='none'
                color='white'
              >
                <Tab
                  id='Overview'
                  as={Link}
                  to='/overview-followups'
                  _selected={{
                    borderBottomColor: 'pink.DEFAULT',
                    borderBottomWidth: '4px',
                    fontWeight: 'bold',
                  }}
                  display={canSeeDashboard ? 'flex' : 'none'}
                >
                  {t('MainHeader_FollowUp')}
                </Tab>
                <Tab
                  id='Statistic'
                  as={Link}
                  to='/statistic'
                  _selected={{
                    borderBottomColor: 'pink.DEFAULT',
                    borderBottomWidth: '4px',
                    fontWeight: 'bold',
                  }}
                  onClick={handleAnalysisTabChange}
                >
                  {t('MainHeader_Statistic')}
                </Tab>
                <Tab
                  id='Settings'
                  as={Link}
                  to='/settings/organizations'
                  _selected={{
                    borderBottomColor: 'pink.DEFAULT',
                    borderBottomWidth: '4px',
                    fontWeight: 'bold',
                  }}
                  display='none'
                >
                  {t('MainHeader_Setting')}
                </Tab>
                <Tab
                  id='EntityViewer'
                  _selected={{
                    borderBottomColor: 'pink.DEFAULT',
                    borderBottomWidth: '4px',
                    fontWeight: 'bold',
                  }}
                  display='none'
                >
                  Entity viewer
                </Tab>
              </TabList>
}
            </HStack>
            {
  showHeader && 

            <HStack spacing='6' className={classes['navigation-items-right']}>
              {user.role === UserRole.SUPERADMIN && (
                <Box>
                  <CustomSelect
                    className='bold-value'
                    name='semester'
                    placeholder={t('MainHeader_Select_organization')}
                    value={organizationOptions.find(
                      orgOption =>
                        parseInt(orgOption.value) ===
                        parseInt(user.organizationId),
                    )}
                    options={organizationOptions}
                    valueKey='code'
                    minWidth='200px'
                    onChange={handleOrganizationChange}
                  />
                </Box>
              )}

              <Feedback />
              <Center height='30px'>
                <Divider orientation='vertical' />
              </Center>
              <Flex>
                <Text
                  ml={-2}
                  mr={-2}
                  fontSize='14px'
                  fontWeight='light'
                  color='white'
                >
                  <LanguageSwitcher
                    globColor={true}
                    languages={languages}
                    bgColor={buttonBgColor}
                    textColor='white'
                    activeColor='rgb(142, 102, 238)'
                    dropactiveColor='#f5f5f2'                
                    bgDrop='white'                    
                    menuItemColor='black'
                    borderWidth='1px'                    
                    buttonBorderWidth='0px'
                    borderRadius="7px"
                    menuListBorderRadius='var(--chakra-radii-md)'
                  />
                </Text>
              </Flex>
              <Center height='30px'>
                <Divider orientation='vertical' />
              </Center>
              <PortalNav />

              <Center height='30px'>
                <Divider orientation='vertical' />
              </Center>
              <Popover offset={[-80, 10]} arrowSize={20}>
                {({ onClose: popoverOnClose }) => (
                  <>
                    <PopoverTrigger>
                      <Button
                        style={{
                          color: 'white',
                          borderWidth: '1px',
                          borderColor: 'white',
                          borderRadius: '100%',
                          width: '40px',
                          height: '40px',
                          backgroundColor: 'transparent',
                        }}
                      >
                        {user.name
                          .split('@')[0]
                          .split('.')
                          .reduce((prev, curr) => prev + curr.charAt(0), '')
                          .toUpperCase()}
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        width='260px'
                        _focus={{ boxShadow: 'none' }}
                      >
                        <PopoverArrow />
                        <PopoverHeader pt='20px'>
                          <div>{user.email}</div>
                          <div style={{ color: '#979797', paddingTop: '20px' }}>
                            {currentOrganization?.name}
                          </div>
                          <div>
                            <br />
                          </div>
                        </PopoverHeader>
                        <VStack h='full' w='full'>
                          <Flex
                            p='10px'
                            w='full'
                            h='50px'
                            _hover={{
                              backgroundColor: '#EFF1FF',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              goToSettings()
                              popoverOnClose()
                            }}
                            alignItems='center'
                          >
                            <Text>{t('MainHeader_Setting')}</Text>
                          </Flex>
                        </VStack>
                        {(
                          <VStack h='full' w='full'>
                            <Flex
                              p='10px'
                              w='full'
                              h='50px'
                              alignItems='center'
                              borderTop={'0.5px solid #E2E8F0'}
                              _hover={{
                                backgroundColor: '#EFF1FF',
                                cursor: 'pointer',
                              }}
                            >
                               <a
                                href={i18n.language === 'se' ? userManualDownloadSwedishURL : userManualDownloadEnglishURL}
                                download={i18n.language === 'se'? 'Myran-UserGuide-SE.pdf': 'Myran-UserGuide-EN.pdf' }
                              >
                                {t('MainHeader_Download_manual')}
                              </a>
                            </Flex>
                          </VStack>
                        )}
  {(
                          <VStack h='full' w='full'>
                            <Flex
                              p='10px'
                              w='full'
                              h='50px'
                              alignItems='center'
                              borderTop={'0.5px solid #E2E8F0'}
                              _hover={{
                                backgroundColor: '#EFF1FF',
                                cursor: 'pointer',
                              }}
                            >
                             <a href="/release-notes" target="_blank" rel="noopener noreferrer">
  {t('Release_Notes')}
</a>
                            </Flex>
                          </VStack>
                        )}
                       
                        <PopoverFooter
                          height='60px'
                          id='logoutButtonId'
                          color='#FD6A52'
                          pt='20px'
                          onClick={() => AuthService.logout()}
                          _hover={{
                            backgroundColor: '#EFF1FF',
                            cursor: 'pointer',
                          }}
                        >
                          {t('MainHeader_Logout')}
                        </PopoverFooter>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            </HStack>
}
          </HStack>
        </Box>
{
  showHeader && 

        <Box bgColor={'#fff'} paddingBottom={10}>
          <TabPanels>
            <TabPanel>
              <Overview />
            </TabPanel>

            <TabPanel>
              <Statistic />
            </TabPanel>

            <TabPanel>
              <SuperAdminSettings />
            </TabPanel>

            <TabPanel p='0'>
              <Switch>
                <Route path='/organization/create'>
                  <OrganizationContainer isCreate={true} />
                </Route>
                <Route path='/organization/:id'>
                  <OrganizationContainer isCreate={false} />
                </Route>
                <Route path='/followup/create'>
                  <FollowupContainer isCreate={true} />
                </Route>
                <Route path='/followup/:id'>
                  <FollowupContainer isCreate={false} />
                </Route>
                <Route path='/preschool/create'>
                  <PreschoolContainer isCreate={true} />
                </Route>
                <Route path='/preschool/:id'>
                  <PreschoolContainer isCreate={false} />
                </Route>
                <Route path='/area/create'>
                  <AreaContainer isCreate={true} />
                </Route>
                <Route path='/area/:id'>
                  <AreaContainer isCreate={false} />
                </Route>
                <Route path='/user/create'>
                  <UserContainer isCreate={true} />
                </Route>
                <Route path='/user/invite'>
                  <CreateUserInviteContainer isCreate={false} />
                </Route>
                <Route path='/user/:id'>
                  <UserContainer isCreate={false} />
                </Route>
                <Route path='/result/:id'>
                  <ResultContainer />
                </Route>
              </Switch>
            </TabPanel>
          </TabPanels>
        </Box>
      }
      </Tabs>
    </>
  )
}
