import React, { useEffect, useState } from 'react'
import { Spinner, Text, Center, VStack } from '@chakra-ui/react'
import FollowUp from '../Followup/FollowUp'
import { useFollowups } from '../../services/followupService'
import { FollowupStatus } from '../../data-models'
import { useFilters } from 'providers/FilterProvider'
import { PreschoolService } from 'services/preschoolService'
import { useUser } from 'providers/UserProvider'
import { useEditFollowup } from 'services/followupService'
import { useTranslation } from 'react-i18next'
import ReleaseNotes from 'common/ReleaseNotes'

export default function Authenticated() {
  const { t } = useTranslation()

  const { user } = useUser()
  const {
    setAreas,
    setPreschools,
    currentFollowupId,
    setAssignmentTypes,
    setCurrentFollowupId,
  } = useFilters()
  const [refresh, setRefresh] = useState(0)
  const [loading, setLoading] = useState(true)

  const { response } = useFollowups({
    organizationId: user.organizationId,
  })

  const editFollowup = useEditFollowup()

  useEffect(() => {
    if (!currentFollowupId && response.data?.length > 0) {
      const { id } = response.data.filter(
        followup => followup.status !== 'Draft',
      )![0]
      setCurrentFollowupId(id)
    }
  }, [response.data])

  useEffect(() => {
    response.data.forEach(followup => {
      const setFStatus = {
        id: followup.id,
        name: followup.name,
        startDate: followup.startDate,
        endDate: followup.endDate,
        organizationId: user.organizationId,
        dataSource: '',
        taskIds: followup.tasks.map((x: any) => x.id),
        description: '',
        status: followup.status,
        isExportable: false,
      }
      const today = new Date().getTime()
      let fStartDate = new Date(setFStatus.startDate).getTime()
      let fEndDate = new Date(setFStatus.endDate).getTime()
      if (setFStatus.status !== 'Draft') {
        if (fStartDate <= today && fEndDate > today && fStartDate > 0)
          if (setFStatus.status !== FollowupStatus.ACTIVE) {
            setFStatus.status = FollowupStatus.ACTIVE
            setRefresh(1)
            fStartDate = -1
            editFollowup.mutateAsync({
              id: setFStatus.id,
              newFollowup: setFStatus,
            })
          }

        if (fEndDate < today && fEndDate > 0)
          if (setFStatus.status !== FollowupStatus.COMPLETED) {
            setFStatus.status = FollowupStatus.COMPLETED
            setRefresh(1)
            fEndDate = -1
            editFollowup.mutateAsync({
              id: setFStatus.id,
              newFollowup: setFStatus,
            })
          }
      }
    })
    if (refresh > 0) {
      window.location.reload()
      setRefresh(0)
    }
  }, [response.data])

  useEffect(() => {
    setLoading(true)
    Promise.all([
      PreschoolService.getPreschools(user.organizationId),
      PreschoolService.getAreas(user.organizationId),
      PreschoolService.getAssignmentTypes(),
    ])
      .then(([preschools, areas, assignmentTypes]) => {
        setPreschools(preschools)
        setAreas(areas)
        setAssignmentTypes(assignmentTypes)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [user])

  return loading ? (
    <Center h='full' w='full'>
      <VStack spacing='5'>
        <Spinner size='xl' color='white' />
        <Text color='white' mb='14' mt='7' fontSize='large' fontWeight='light'>
          {t('Fetch_FollowUp')}
        </Text>
      </VStack>
    </Center>
  ) : response?.data ? (
    <>
    <ReleaseNotes />
    <FollowUp />
    </>
    
  ) : (
    <></>
  )
}
