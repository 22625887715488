import React from 'react'
import { FormProvider } from 'react-hook-form'

import { Flex } from '@chakra-ui/react'
import { Organization } from 'data-models'
import { EditButton } from '../common/EditButton'
import { ActionsMenu } from '../common/ActionsMenu'
import { CreateButton } from '../common/CreateButton'
import { DeleteButton } from '../common/DeleteButton'
import { OrganizationDetails } from './OrganizationDetails'
import { useOrganizationEditor } from './hooks/useOrganizationEditor'
import { DetailsWrapper } from '../common/EntityViewer.layout'

interface OrganizationEditorProps {
  isCreate: boolean
  organization: Organization
}

export const OrganizationEditor = ({
  isCreate,
  organization,
}: OrganizationEditorProps) => {
  const {
    handleEdit,
    formMethods,
    handleCreate,
    handleDelete,
    isEditLoading,
    isCreateLoading,
    isDeleteLoading,
    isSuperAdmin,
  } = useOrganizationEditor({
    initialOrganization: organization,
  })

  return (
    <Flex w='full' h='fit-content' bg='white'>
      <FormProvider {...formMethods}>
        <form style={{ width: '100%' }} noValidate={true}>
          <ActionsMenu name={organization?.name || 'Organization'}>
            {isSuperAdmin && (
              <>
                {isCreate ? (
                  <CreateButton
                    onClick={handleCreate}
                    isLoading={isCreateLoading}
                  />
                ) : (
                  <>
                    <DeleteButton
                      entityName='organization'
                      onDelete={handleDelete}
                      isLoading={isDeleteLoading}
                    />
                    <EditButton
                      onClick={handleEdit}
                      isLoading={isEditLoading}
                    />
                  </>
                )}
              </>
            )}
          </ActionsMenu>

          <DetailsWrapper>
            <OrganizationDetails />
          </DetailsWrapper>
        </form>
      </FormProvider>
    </Flex>
  )
}
