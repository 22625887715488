export interface Area {
  id: string
  name: string
  areaId: string
  areaName?: string
  numberOfUsers?: number
  numberOfPreschools?: number
  organizationId?: string
  municipalityId?: string
  preschoolName?: string
  preschoolId?: string
}


export const emptyArea: Area = {
  id: '',
  areaId: '',
  name: '',
  areaName: '',
  municipalityId: '',
  numberOfUsers: 0,
  numberOfPreschools: 0,
  organizationId: '',
  preschoolId: '',
  preschoolName: '',
}


export type AreaShape = Pick<Area, "id" | 'name'>

export interface AreaDTO {
  id: string
  name: string
  areaId: string
  municipalityId: string
  organizationId: string
}

export type CreateAreaDTO = Partial<Omit<AreaDTO, 'id'>>
export type EditAreaDTO = Partial<Omit<AreaDTO, 'municipalityId'>>

export interface areaList {
  numberOfPreschools: number
  numberOfUsers: number
  preschools: Area[]
}