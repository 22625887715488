import React from 'react'
import {
  Td,
  Th,
  Tr,
  Text,
  Flex,
  Grid,
  Table,
  Thead,
  Input,
  Tbody,
  HStack,
  VStack,
  Divider,
  GridItem,
  Container,
  TableContainer,
} from '@chakra-ui/react'
import { FormWrapper } from 'common/Form'
import { FormSelect } from 'common/FormSelect'
import { Controller } from 'react-hook-form'
import { usePreschoolDetails } from './hooks/usePreschoolDetails'
import { DepartmentCreation, DepartmentModal } from './Department'
import Preschool from 'data-models/preschool'
import { useTranslation } from 'react-i18next'

interface PreschoolDetailsProps {
  preschool?: Preschool
}

export const PreschoolDetails = ({ preschool }: PreschoolDetailsProps) => {
  const {
    isAdmin,
    isSuperAdmin,
    formMethods,
    areaOptions,
    selectedDepartment,
    municipalityOptions,
    handleEditDepartment,
    isEditDepartmentOpen,
    onEditDepartmentClose,
  } = usePreschoolDetails()
  const { t } = useTranslation()

  const { control, register } = formMethods
  return (
    <>
      <VStack w='full'>
        <Flex overflow='visible' w='full'>
          <VStack w='full' h='full'>
            <Flex py='5' w='full' justify='start'>
              <Text fontSize='lg' color='darkIndigo' fontWeight='bold'>
                {t('Overview')}
              </Text>
            </Flex>
            <Grid w='full' gridTemplateColumns={'repeat(2, 1fr)'} gridGap={6}>
              <GridItem>
                <FormWrapper name='name' label={t('Name')} isRequired>
                  <Input
                    type='text'
                    placeholder={t('Preschool_Name')}
                    _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                    focusBorderColor=''
                    fontSize='14px'
                    bgColor={'white'}
                    color={'#1A2C3D'}
                    border='1px'
                    borderColor='#B1C5D9'
                    h={'40px'}
                    w='full'
                    borderRadius={'lg'}
                    isDisabled={!isAdmin}
                    {...register('name')}
                  />
                </FormWrapper>
              </GridItem>
              <GridItem>
                <FormWrapper
                  name='municipalityId'
                  label={t('Municipality')}
                  isRequired
                >
                  <Controller
                    control={control}
                    name='municipalityId'
                    render={({ field: { onChange, value, name } }) => (
                      <FormSelect
                        name={name}
                        options={municipalityOptions}
                        placeholder={t('Municipality')}
                        value={municipalityOptions.find(
                          option => option.value === value,
                        )}
                        isDisabled={true}
                        onChange={val => onChange(val?.value)}
                      />
                    )}
                  />
                </FormWrapper>
              </GridItem>
              <GridItem>
                <FormWrapper
                  name='areaId'
                  label={t('ResultFilter_Area')}
                  isRequired
                >
                  <Controller
                    control={control}
                    name='areaId'
                    render={({ field: { onChange, value, name } }) => (
                      <FormSelect
                        name={name}
                        options={areaOptions}
                        placeholder={t('ResultFilter_Area')}
                        value={
                          areaOptions.find(option => option.value === value) ||
                          null
                        }
                        onChange={val => onChange(val?.value)}
                        isDisabled={!isAdmin}
                      />
                    )}
                  />
                </FormWrapper>
              </GridItem>
            </Grid>
          </VStack>
        </Flex>

        {preschool?.id && (
          <VStack w='full'>
            <Divider
              borderBottomWidth={2}
              py='5'
              mb='5'
              orientation='horizontal'
            />
            <HStack py='5' w='full' justify='space-between'>
              <Text fontSize='lg' color='darkIndigo' fontWeight='bold'>
                {t('Depts')}
              </Text>

              {preschool && isAdmin && (
                <DepartmentCreation preschoolId={preschool?.id} />
              )}
            </HStack>
            <Container p='0' maxW='full'>
              {preschool?.departments && (
                <TableContainer borderRadius='lg'>
                  <Table variant='simple'>
                    <Thead>
                      <Tr
                        bgColor={'rgba(177, 197, 217, 0.5)'}
                        borderWidth='1px'
                        borderRadius='10px'
                        height='48px'
                        fontWeight={'bold'}
                        fontFamily={`'Open Sans',sans-serif`}
                        fontSize={'12px'}
                      >
                        <Th>{t('Name')}</Th>
                      </Tr>
                    </Thead>

                    <Tbody>
                      {preschool?.departments.map((department, index) => (
                        <Tr
                          key={`${department.id}_${index}`}
                          onClick={() => {
                            handleEditDepartment(department)
                          }}
                          cursor={'pointer'}
                          _hover={{
                            backgroundColor: '#EFF1FF',
                          }}
                        >
                          <Td>{department?.name}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </Container>
          </VStack>
        )}

        {preschool && (
          <DepartmentModal
            isOpen={isEditDepartmentOpen}
            onClose={onEditDepartmentClose}
            preschoolId={preschool.id}
            department={selectedDepartment}
          />
        )}
      </VStack>
    </>
  )
}
