import React, { useContext, createContext, useState, useEffect } from 'react'
import { User } from '../data-models/user'
import { AuthService } from '../services/authService'

const UserContext = createContext<{
  user: User
  setUser: (user: User) => void
} | null>(null)

function UserProvider(props: { children: React.ReactNode }) {
  const [user, setUser] = useState(AuthService.getCachedUser())
  const value = { user, setUser }

  useEffect(() => {
    AuthService.cacheUser(user)
  }, [user])

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}

function useUser() {
  const ctx = useContext(UserContext)
  if (ctx === null) throw new Error('hook must be used inside its provider')
  return ctx
}

export { UserProvider, useUser }
