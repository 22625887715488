import React, { useEffect } from 'react'
import { useState } from 'react'
import {
  Text,
  Image,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Center,
  ChakraProvider,
  Divider
} from '@chakra-ui/react'
import { HStack, VStack } from '@chakra-ui/layout'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { AuthService } from '../../services/authService'
import { useUser } from '../../providers/UserProvider'
import email from '../../assets/images/ico-email.svg'
import pswd from '../../assets/images/ico-password.svg'
import hidePswd from '../../assets/images/ico-hide-pwd.svg'
import showPswd from '../../assets/images/ico-show-pwd.svg'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const invalidCredentialsErrorMessage = 'Ogiltigt e-post eller lösenord!'

export default function Login() {
  const { t, i18n } = useTranslation()
  const initialValues = {
    email: '',
    password: '',
  }

  const [authError, setAuthError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(
    invalidCredentialsErrorMessage,
  )

  let errorMsg = { email: '', password: '' }

  const validate = (values: any) => {
    const errors = { email: '', password: '' }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
    if (!values.email) {
      errors.email =
        i18n.language === 'se' ? 'E-post krävs' : i18n.language === 'de' ? 'E-Mail ist erforderlich' : 'Email is required'
    } else if (!regex.test(values.email)) {
      errors.email =
        i18n.language === 'se' ? 'Ogiltigt e-post' : i18n.language === 'de' ? 'Ungültige E-Mail' : 'Invalid email'
    }

    if (!values.password) {
      errors.password =
        i18n.language === 'se' ? 'Lösenord krävs' : i18n.language === 'de' ? 'Passwort ist erforderlich' : 'Password is required'
    }
    if (errors.email === '' && errors.password === '') {
      errorMsg = { email: values.email, password: values.password }
    } else {
      errorMsg = { email: '', password: '' }
    }
    return errors
  }



  const [isLoading, setIsLoading] = useState(false)
  const { setUser } = useUser()
  const [passwordShown, setPasswordShown] = useState(false)
  const [eyeShow, seteyeShown] = useState(showPswd)
  const history = useHistory()

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
    seteyeShown(eyeShow === showPswd ? hidePswd : showPswd)
  }
  const handleClick = () => {
    history.push('/login/sso')
  }
  const onSubmit = (_data: any) => {
    if (errorMsg.email !== '' && errorMsg.password !== '') {
      setIsLoading(true)
      AuthService.login({
        username: errorMsg.email,
        password: errorMsg.password,
      })
        // eslint-disable-next-line camelcase
        .then((payload: { access_token: string; refresh_token: string }) => {
          AuthService.clearCookie()
          AuthService.storeTokens(payload)
          const usr = AuthService.getUser()
          if (usr) {
            setUser(usr)
            setAuthError(false)
          }
        })
        .catch(_ => {
          setErrorMessage(invalidCredentialsErrorMessage)
          setAuthError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {formik => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
        } = formik
        return (
          <form onSubmit={handleSubmit} autoComplete='off'>
            <VStack spacing='12' alignItems='stretch'>
              <VStack spacing='5' alignItems='stretch'>
                <VStack alignItems='stretch' >                                                  
                  <HStack justifyContent='space-between'>
                    <Text fontWeight='light' color='white'>
                      {errors.email}
                    </Text>
                  </HStack>
                  <Input
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    h={50}
                    backgroundColor={'white'}
                    color='black'
                    placeholder={t('Login_Email_PlaceHolder')}
                    _placeholder={{ color: 'black' }}
                    className='remove-input-auto-fill'
                    borderRadius="10px"                    
                    autoFocus
                    type='email'
                    name='email'
                    id='email'                    
                  />
                </VStack>

                <VStack alignItems='stretch'>
                  {errors.password && touched.password && (
                    <HStack justifyContent='space-between'>
                      <Text fontWeight='light' color='white'>
                        {errors.password}
                      </Text>
                    </HStack>
                  )}
                  <InputGroup>
                    <InputRightElement>
                      <Image
                        src={eyeShow}
                        onClick={togglePassword}
                        paddingRight='22px'
                        style={{ cursor: 'pointer' }}                        
                      />
                    </InputRightElement>
                    <Input
                      h={50}
                      backgroundColor={'white'}
                      color='black'
                      placeholder={t('Login_Pass_PlaceHolder')}
                      _placeholder={{ color: 'black' }}
                      className='remove-input-auto-fill'
                      borderRadius="10px"
                      type={passwordShown ? 'text' : 'password'}                  
                      bgRepeat={'no-repeat'}
                      id='password'
                      name='password'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                  />
                  </InputGroup>

                  {/* <i onClick={togglePassword}>{eye}</i> */}
                  {/* <Button><Image src='/assets/images/ico-view-eye.svg' onClick={togglePassword}/></Button> */}
                </VStack>
              </VStack>
              <VStack alignItems='stretch'>
                {authError && (
                  <HStack justifyContent='space-between'>
                    <Text fontWeight='light' color='red'>
                      {errorMessage}
                    </Text>
                  </HStack>
                )}
                <HStack justifyContent='flex-end' paddingTop='20px'>
                  <Button
                    h={10}
                    id="loginButtonId"
                    fontSize='14px'
                    type='submit'
                    onClick={onSubmit}
                    rightIcon={<ArrowForwardIcon boxSize='4' />}
                    isLoading={isLoading}
                    backgroundColor={'#ff5099'}
                    _hover={{ bg: "#EF3A86" }}
                   
                  >
                    {t('Login_Button')}
                  </Button>                  
                </HStack>
              </VStack>
              <Divider color='indigoBlue' orientation='horizontal' />
              <Button h={10} fontSize='14px' type='submit' onClick={handleClick} backgroundColor={'#ff5099'} _hover={{ bg: "#EF3A86" }}>
                {t('Login_With_SSO')}
              </Button>
            </VStack>
          </form>
        )
      }}
    </Formik>
  )
}
