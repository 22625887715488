import React from 'react'
import { HStack, VStack, Text, Center } from '@chakra-ui/react'
import { CheckboxAnswer, TaskType } from 'data-models/task-models'
import CheckboxCircle from './CheckboxCircle'
import { useTranslation } from 'react-i18next'

interface CheckboxAnswerGroupProps {
  options: CheckboxAnswer[]
  onChange: (state: CheckboxAnswer[]) => void
}

export default function CheckboxAnswerGroup({
  onChange,
  options,
}: CheckboxAnswerGroupProps) {
  function onSelectedChange(x: CheckboxAnswer['id']) {
    const newOptionsState = options.map(o => ({
      ...o,
      checked: o.id === x,
    }))

    onChange(newOptionsState)
  }
  const { t } = useTranslation()
  const skipAnswerOption: CheckboxAnswer = {
    checked: false,
    id: (options.length + 1).toString(),
    styleClass: 'childParticipate',
    text: `${t('Child_Not_Participate')}`,
    type: TaskType.Checkbox,
  }

  const existingSkipOption = options.find(
    option => option.styleClass && option.styleClass === 'childParticipate',
  )

  if (!existingSkipOption) {
    options.push(skipAnswerOption)
  }

  return (
    <Center flex='1' w='100%'>
      <VStack spacing='5' w='100%'>
        {options.map(
          o =>
            o.styleClass !== 'childParticipate' && (
              <HStack
                key={o.id}
                cursor='pointer'
                border='1px'
                borderColor='white'
                borderRadius='xl'
                w='60%'
                p='2.5'
                bgColor={o.checked ? 'pink.500' : 'transparent'}
                onClick={() => onSelectedChange(o.id)}
                _hover={
                  window.matchMedia('(hover: hover)').matches
                    ? { opacity: '60%', background: '#5A4FD3' }
                    : {}
                }
                boxShadow={o.checked ? '0px 0px 8px 0px indigo' : 'none'}
              >
                <CheckboxCircle checked={o.checked} />
                <Text maxWidth='85%'>{o.text}</Text>
              </HStack>
            ),
        )}
        {options.map(
          o =>
            o.styleClass === 'childParticipate' && (
              <HStack
                key={o.id}
                cursor='pointer'
                w='100%'
                p='15'
                // bgColor={o.checked ? 'pink.500' : 'transparent'}
                onClick={() => onSelectedChange(o.id)}
              >
                <CheckboxCircle checked={o.checked} />
                <Text>{o.text}</Text>
              </HStack>
            ),
        )}
        {/* </HStack>
            ))} */}
      </VStack>
    </Center>
  )
}
