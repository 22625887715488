import axiosAPIInstance from './api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Area,
  EditAreaDTO,
  CreateAreaDTO,
} from 'data-models/area'

interface UseAreasProps {
  organizationId: string
}
const getAreas = ({ organizationId }: UseAreasProps) =>
  axiosAPIInstance
    .get(`/areas/${organizationId}`)
    .then(({ data }) => data as Area[])

export const useAreas = ({ organizationId }: UseAreasProps) => {
  const getFollowupsQuery = useQuery({
    queryKey: ['areasList'],
    queryFn: () => getAreas({ organizationId }),
    staleTime: 1000 * 60 * 10,
  })

  const { data: response = [], error, isLoading: loading } = getFollowupsQuery

  return {
    error,
    loading,
    response,
  }
}


interface UseAreasByMunicipalityProps {
  municipalityId: string
}

const getAreasByMunicipality = ({
  municipalityId,
}: UseAreasByMunicipalityProps) => {
  if (!municipalityId) return []

  return axiosAPIInstance
    .get(`municipalities/${municipalityId}/areas`)
    .then(({ data }) => data as Area[])
}

export const useAreasByMunicipality = ({
  municipalityId,
}: UseAreasByMunicipalityProps) => {
  const getAreasByMunicipalitiesQuery = useQuery({
    queryKey: ['areasByMunicipality', municipalityId],
    queryFn: () => getAreasByMunicipality({ municipalityId }),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = [],
    error,
    isLoading: loading,
  } = getAreasByMunicipalitiesQuery

  return {
    error,
    loading,
    response,
  }
}

interface UseAreaDetailsProps {
  organizationId?: string
  areaId?: string
}

const getAreaDetails = ({
  organizationId,
  areaId,
}: UseAreaDetailsProps): Promise<Area | void> => {
  if (!organizationId || !areaId) return Promise.resolve(undefined)
  return axiosAPIInstance
    .get(`/areas/${organizationId}/${areaId}`)
    .then(({ data }) => data as Area)
}

export const useAreaDetails = ({
  organizationId,
  areaId,
}: UseAreaDetailsProps) => {
  const getAreaDetailsQuery = useQuery({
    queryKey: ['areas', `${areaId}`],
    queryFn: () => getAreaDetails({ organizationId, areaId }),
    staleTime: 1000 * 60 * 10,
    enabled: !!areaId,
  })

  return getAreaDetailsQuery
}


//Delete mutation
interface UseDeleteAreaProps {
  id: string
  organizationId: string
}

const deleteArea = ({ id,organizationId }: UseDeleteAreaProps) =>
  axiosAPIInstance.delete(`/areas/${organizationId}/${id}`)

export const useDeleteArea = () => {
  const queryClient = useQueryClient()
  const deleteAreaMutation = useMutation({
    mutationFn: ({ id, organizationId }: UseDeleteAreaProps) => deleteArea({ id,organizationId}),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['areasList'], { exact: true }),
      queryClient.invalidateQueries(['areasByMunicipality']),
      queryClient.invalidateQueries(['areas', `${variables.id}`], {
          exact: true,
        })
    },
  })

  return deleteAreaMutation
}

//Create mutation
interface UseCreateAreaProps {
  values: CreateAreaDTO
  organizationId: string
}

const createArea = async ({
  values,
  organizationId,
}: UseCreateAreaProps) =>
  axiosAPIInstance.post(`/areas/${organizationId}`, {
    ...values,
    organizationId,
  })

export const useCreateArea = () => {
  const queryClient = useQueryClient()
  const createAreaMutation = useMutation({
    mutationFn: ({ organizationId, values }: UseCreateAreaProps) =>
      createArea({ organizationId, values }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['areasList'], { exact: true })
      await queryClient.invalidateQueries(['areasByMunicipality'])
    },
  })

  return createAreaMutation
}

//Edit mutation
interface UseEditAreaProps {
  values: EditAreaDTO
  areaId: string
  organizationId: string
}

const editArea = ({ areaId, values,organizationId }: UseEditAreaProps) =>
  axiosAPIInstance.put(`/areas/${organizationId}/${areaId}`, {
    ...values,
    id: areaId,
    organizationId: organizationId
  })

export const useEditArea = () => {
  const queryClient = useQueryClient()
  const editAreaMutation = useMutation({
    mutationFn: ({ areaId, values ,organizationId}: UseEditAreaProps) =>
      editArea({ areaId, values,organizationId }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['areasList'], { exact: true }),
      queryClient.invalidateQueries(['areasByMunicipality'])
        queryClient.invalidateQueries(
          ['areas', `${variables.areaId}`],
          {
            exact: true,
          },
        )
    },
  })

  return editAreaMutation
}
