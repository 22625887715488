import React from 'react'
import { FormProvider } from 'react-hook-form'

import { Button, Flex, Heading, HStack } from '@chakra-ui/react'

import { FormWrapper } from 'common/Form'
import { useProfilePassword } from '../hooks'
import { PasswordInput } from 'common/PasswordInput'
import { useTranslation } from 'react-i18next'

export const ProfilePassword = () => {
  const { formMethods, onSubmit, isLoading } = useProfilePassword()
  const { register } = formMethods
  const { t } = useTranslation()
  return (
    
    <FormProvider {...formMethods}>
      <form
        style={{ width: '100%', height: '100%' }}
        onSubmit={onSubmit}
        noValidate={true}
      >
       

        <HStack
          w='full'
          h='full'
          justifyContent='flex-start'
          align='flex-start'
        >
          <HStack w='full' justifyContent='flex-start' align='flex-start'>
            <FormWrapper
              name='oldPassword'
              label={t('Current_Password')}
              isRequired
              maxW='350px'
            >
              <PasswordInput
                placeholder={t('Current_Password')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                {...register('oldPassword')}
              />
            </FormWrapper>
            <FormWrapper
              name='newPassword'
              label={t('New_Password')}
              isRequired
              maxW='350px'
            >
              <PasswordInput
                placeholder={t('New_Password')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                {...register('newPassword')}
              />
            </FormWrapper>
            <FormWrapper
              maxW='350px'
              name='confirmNewPassword'
              label={t('Confirm_Password')}
              isRequired
            >
              <PasswordInput
                placeholder={t('Confirm_Password')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                {...register('confirmNewPassword')}
              />
            </FormWrapper>
          </HStack>

          <Flex pl='10' pt='5' h='full' justify='center' align='start'>
            <Button
              type='submit'
              height='40px'
              fontSize={'md'}
              isLoading={isLoading}
            >
              {t('Dashboardfilter_Update')}
            </Button>
          </Flex>
        </HStack>
      </form>
    </FormProvider>
  )
}
