import * as yup from 'yup'
import i18n from 'i18next';

export const organizationSchema = yup.object().shape({
  name: yup.string().required(i18n.t('Type_Name')),
  type: yup.string().required(i18n.t('Choose_Type')),
  municipalityId: yup.string().required(i18n.t('Select_Municipality')),
  loginType: yup.string().required(i18n.t('Choose_Option')),
  ageType: yup.string().required(i18n.t('Choose_Option')),
  showChildCode: yup.string().required(i18n.t('Choose_Option')),
  hasMultipleSections: yup.string().required(i18n.t('Choose_Option')),
  hasLanguageAnalysis: yup.string().required(i18n.t('Choose_Option')),
})
