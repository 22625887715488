import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { followupSchema } from '../schema'
import { yupResolver } from '@hookform/resolvers/yup'
import { Followup, FollowupShape, FollowupStatus, UserRole } from 'data-models'
import {
  useCreateFollowup,
  useDeleteFollowup,
  useEditFollowup,
} from 'services/followupService'
import { useToast } from '@chakra-ui/react'
import { useUser } from 'providers/UserProvider'
import { addHours } from 'date-fns'
import { useTranslation } from 'react-i18next'

interface UseFollowupEditorProps {
  initialFollowup: Followup
}

export const useFollowupEditor = ({
  initialFollowup,
}: UseFollowupEditorProps) => {
  const history = useHistory()
  const toast = useToast()
  const {
    user: { organizationId, role },
  } = useUser()
  const { t } = useTranslation()
  const createFollowup = useCreateFollowup()
  const editFollowup = useEditFollowup()
  const deleteFollowup = useDeleteFollowup()

  const formMethods = useForm<FollowupShape>({
    defaultValues: initialFollowup,
    resolver: yupResolver(followupSchema),
  })

  const { handleSubmit } = formMethods

  useEffect(() => {
    if (initialFollowup) {
      formMethods.reset(initialFollowup)
    }
  }, [initialFollowup])

  const generateCreateHandler =
    (customValues: Partial<FollowupShape>) => async (values: FollowupShape) => {

      console.log('Received Values:', values);
      console.log('Custom Values:', customValues);
      console.log('Merged Values:', { ...values, ...customValues });

      await createFollowup.mutateAsync({
        newFollowup: {
          ...values,
          ...customValues,
          organizationId,
        },
      })
      toast({
        title: `${t('Followup_Created_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })

      history.push('/overview-followups')

    }

  const generateEditHandler =
    (customValues: Partial<FollowupShape>) => async (values: FollowupShape) => {
      try {
        await editFollowup.mutateAsync({
          newFollowup: {
            ...values,
            ...customValues,
            startDate: addHours(new Date(values?.startDate), 6).toISOString(),
            endDate: addHours(new Date(values?.endDate), 6).toISOString(),
            organizationId,
          },
          id: initialFollowup.id,
        })
        toast({
          title: `${t('FollowUp_Update_Text')}`,
          isClosable: true,
          duration: 3000,
          status: 'success',
        })
      } catch (error) {
        toast({
          title: `${t('Followup_Update_Fail')}`,
          isClosable: true,
          duration: 3000,
          status: 'error',
        })
      }
    }

  const handleEdit = handleSubmit(generateEditHandler({}))

  const handleActivateFollowup = handleSubmit(
    generateEditHandler({ isDraft: false }),
  )

  const handleCreateActive = handleSubmit(
    generateCreateHandler({
      isDraft: false,
    }),
  )

  const handleCreateDraft = handleSubmit(
    generateCreateHandler({
      isDraft: true,
    }),
  )

  const handleDelete = async () => {
    await deleteFollowup.mutateAsync({ id: initialFollowup.id })

    try {
      toast({
        title: `${t('Followup_Removed')}`,
        isClosable: true,
        duration: 3000,
        status: 'success',
      })
      history.push('/overview-followups')
    } catch (error) {
      toast({
        title: `${t('Followup_Delete_Fail_Text')}`,
        isClosable: true,
        duration: 3000,
        status: 'error',
      })
    }
  }

  const handleCopy = () => {
    history.push({
      pathname: '/followup/create',
      state: {
        ...formMethods.watch(),
      },
    })
  }

  const fDate = new Date(initialFollowup.startDate).getTime()
  const currentDate = new Date().getTime()

  const isAdmin = [UserRole.ADMIN, UserRole.SUPERADMIN].includes(role)

  const isEditable =
    fDate >= currentDate || initialFollowup.status === 'Draft' ? true : false

  const isDraft = initialFollowup.status === FollowupStatus.DRAFT

  return {
    formMethods,
    handleActivateFollowup,
    handleEdit,
    isEditLoading: editFollowup.isLoading,
    handleDelete,
    isDeleteLoading: deleteFollowup.isLoading,
    handleCreateActive,
    handleCreateDraft,
    isCreateLoading: createFollowup.isLoading,
    isEditable,
    handleCopy,
    isAdmin,
    isDraft,
  }
}
