import React from 'react'

import {
  Modal,
  Button,
  HStack,
  VStack,
  Textarea,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  useRadioGroup,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import {
  FeedbackType,
  FeedbackTypeLabel,
  FeedbackTypeLabelEn,
  FeedbackTypeLabelDe
} from 'data-models'

import { FormWrapper } from 'common/Form'
import { useFeedbackModal } from '../hooks'
import { CustomRadioButton } from './CustomRadioButtonWithCheckmark'
import CheckIcon from 'pages/MainTabs/OverviewTab/TaskPreview/CheckIcon'
import { useTranslation } from 'react-i18next'

interface FeedbackModalProps {
  isOpen: boolean
  onClose: () => void
}

export const FeedbackModal = ({ isOpen, onClose }: FeedbackModalProps) => {
  const { formMethods, isLoading, onSubmit } = useFeedbackModal({ onClose })
  const { register, control } = formMethods
  const { t, i18n } = useTranslation()

  const options = [FeedbackType.REPORT_ISSUE, FeedbackType.SUGGESTION]
  const optionsen = [FeedbackType.REPORT_ISSUE, FeedbackType.SUGGESTION]

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'type',
    defaultValue: FeedbackType.REPORT_ISSUE,
  })

  const group = getRootProps()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit} >
          <ModalOverlay />
          <ModalContent h='fit-content' minH='200px'>
            <ModalHeader>{t('Send_Feedback')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack
                spacing={6}
                h='full'
                w='full'
                justifyContent={'flex-start'}
              >
                <FormWrapper name={'type'} label={''}>
                  <Controller
                    name='type'
                    control={control}
                    render={({ field }) => (
                      <HStack {...group} {...field}>
                           {i18n.language === 'se'
                          ? options.map(value => {
                            const radio = getRadioProps({
                              value,
                              enterKeyHint: value,
                            })
                            return (
                              <CustomRadioButton
                                label={FeedbackTypeLabel[value]}
                                key={value}
                                {...radio}
                              >
                                <CheckIcon size={'16'} color='white' />
                              </CustomRadioButton>
                            )
                          }) :


                          i18n.language === 'de' ?
                          optionsen.map(value => {
                            const radio = getRadioProps({
                              value,
                              enterKeyHint: value,
                            })
                            return (
                              <CustomRadioButton
                                label={FeedbackTypeLabelDe[value]}
                                key={value}
                                {...radio}
                              >
                                <CheckIcon size={'16'} color='white' />
                              </CustomRadioButton>
                            )
                          })
                          :

                          optionsen.map(value => {
                              const radio = getRadioProps({
                                value,
                                enterKeyHint: value,
                              })
                              return (
                                <CustomRadioButton
                                  label={FeedbackTypeLabelEn[value]}
                                  key={value}
                                  {...radio}
                                >
                                  <CheckIcon size={'16'} color='white' />
                                </CustomRadioButton>
                              )
                            })
                          }
                      
                      </HStack>
                    )}
                  />
                </FormWrapper>

                <FormWrapper
                  name='feedback'
                  label={t('We_Like_Feedback')}
                
                >
                  <Textarea
                    w='full'
                    h='200px'
                    bgColor='white'
                    color='black'
                    placeholder={t('Write_Your_Feedback')}
                    resize='none'
                    borderColor={'blackAlpha.300'}
                    mb='5'
                    fontFamily='Open Sans'
                    minH='unset'
                    disabled={isLoading}
                    {...register('feedback')}
                  />
                
                

                  
                </FormWrapper>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  fontSize='15px'
                  height='40px'
                  width='110px'
                  type='submit'
                  isLoading={isLoading}
                >
                  {t('Send_Text')}
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}
