import React, { ComponentProps } from 'react'
import { Flex, HStack, FormLabel } from '@chakra-ui/react'

import { HelpTooltip } from 'common/HelpTooltip'
import { useUser } from 'providers/UserProvider'
import { UserRole } from 'data-models'
import { useTranslation } from 'react-i18next'

interface CheckboxIsExportableProps extends ComponentProps<any> {
  isExportable?: boolean
  handleOnChange?: () => void
}

export const CheckboxIsExportable = ({
  isExportable = false,
  handleOnChange = () => {},
  ...rest
}: CheckboxIsExportableProps) => {
  const {
    user: { role },
  } = useUser()

  const isAdmin = [UserRole.SUPERADMIN, UserRole.ADMIN].includes(role)
  const { t } = useTranslation()

  return (
    <>
      {isAdmin ? (
        <Flex pt='4' justifyContent='flex-end' h='full'>
          <HStack spacing={2} align='center'>
            <input
              id='isExportable'
              type='checkbox'
              // name='checkbox'
              style={{
                width: '18px',
                height: '18px',
                accentColor: '#3443D5',
              }}
              checked={isExportable}
              onChange={handleOnChange}
              {...rest}
            />
            <FormLabel htmlFor='isExportable'>{t('Can_Be_Exported')}</FormLabel>
            <HelpTooltip
              title={t('Export_To_SchoolAnalysis')}
              body={t('Will_Data_Exported')}
            />
          </HStack>
        </Flex>
      ) : (
        <Flex></Flex>
      )}
    </>
  )
}
