import React from 'react'
import {
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react'
import { useVideo } from 'react-use'
import { secondsToMMSS } from 'common/utils'
import {
  BsVolumeUpFill,
  BsVolumeDownFill,
  BsVolumeOffFill,
  BsVolumeMuteFill,
} from 'react-icons/bs'
import { FaPlay, FaPause } from 'react-icons/fa'
import { isIOS } from 'react-device-detect'

export default function VideoPlayer(props: { videoSrc: string }) {
  const [
    video,
    { duration, playing, time, volume },
    { pause, play, seek, volume: setVolume },
  ] = useVideo(
    <video
      src={props.videoSrc}
      style={{ maxHeight: '80%', borderRadius: '10px' }}
    />,
  )

  // const [isSeeking, setIsSeeking] = useState(false)

  let isSeeking = false
  const onScrub = (v: number) => {
    if (playing) {
      pause()
      isSeeking = true
      seek(v)
      isSeeking = false
      play()
    } else seek(v)
  }
  // const onScrubStart = () => {
  //   if (playing) pause()
  //   setIsSeeking(true)
  // }

  // const onScrubEnd = (v: number) => {
  //   seek(v)
  //   setIsSeeking(false)
  //   if (!playing) play()
  // }

  const volumeIcon =
    volume > 0.8 ? (
      <BsVolumeUpFill fontSize='30px' />
    ) : volume > 0.4 ? (
      <BsVolumeDownFill fontSize='30px' />
    ) : volume > 0 ? (
      <BsVolumeOffFill fontSize='30px' />
    ) : (
      <BsVolumeMuteFill fontSize='30px' />
    )

  const toggleVolume = () => (volume ? setVolume(0) : setVolume(1))

  return (
    <Flex
      direction='column'
      align='center'
      borderRadius='2xl'
      border='1px solid #432DBA'
      p='2'
      h='100%'
      maxH='60vh'
      gridGap='2'
    >
      {video}

      <Flex width='full' align='center' flex='1'>
        <Box>
          {playing ? (
            <IconButton
              aria-label='pause-btn'
              icon={<FaPause />}
              onClick={pause}
              boxSize='40px'
              borderRadius='full'
              background={'pink.500'}
            />
          ) : (
            <IconButton
              aria-label='play-btn'
              icon={<FaPlay />}
              onClick={play}
              boxSize='40px'
              borderRadius='full'
              background={'pink.500'}
            />
          )}
        </Box>

        <Text
          w='90px'
          fontSize='small'
          fontFamily='Open Sans'
          whiteSpace='nowrap'
          ml='16px'
          mr='8px'
        >
          {secondsToMMSS(time)} / {secondsToMMSS(duration)}
        </Text>

        <Slider
          aria-label='track-progress'
          min={0}
          max={duration}
          // focusThumbOnChange={false}
          onChange={onScrub}
          // onChangeStart={onScrubStart}
          // onChangeEnd={onScrubEnd}
          flex='1'
          mr='16px'
          // While seeking switch to uncontrolled input, otherwise rendering is laggy
          // https://dev.azure.com/2mnordic/Skolanalys-F%C3%B6rskola-Myran/_sprints/taskboard/Teacher%20Team/Skolanalys-F%C3%B6rskola-Myran/Teacher/Sprint%206?workitem=7433
          {...(!isSeeking && { value: time })}
        >
          <SliderTrack bg='darkIndigo' h='1.5' borderRadius='md'>
            <SliderFilledTrack bg='pink.500' />
          </SliderTrack>
          <SliderThumb boxSize='5' boxShadow='lg'>
            <Box borderRadius='full' bgColor='pink.500' boxSize='2'></Box>
          </SliderThumb>
        </Slider>
        {!isIOS && (
          <Slider
            aria-label='volume'
            value={volume}
            min={0}
            max={1}
            step={0.1}
            focusThumbOnChange={false}
            onChange={setVolume}
            w='15%'
            mr='24px'
          >
            <SliderTrack bg='darkIndigo' h='1.5' borderRadius='md'>
              <SliderFilledTrack bg='pink.500' />
            </SliderTrack>
            <SliderThumb boxSize='5' boxShadow='lg'>
              <Box borderRadius='full' bgColor='pink.500' boxSize='2'></Box>
            </SliderThumb>
          </Slider>
        )}
        {!isIOS && (
          <Box onClick={toggleVolume} cursor='pointer'>
            {volumeIcon}
          </Box>
        )}
      </Flex>
      {/* <Flex width="full" height='10px' align="center" flex="1"></Flex> */}
    </Flex>
  )
}
