import React from 'react'

import { VStack } from '@chakra-ui/react'
import {AreaStats ,AreaTable } from './components'
import { useArea } from './hooks/useArea'

export const Area = () => {
  const { loadingAreas, responseAreas, handleOpenCreateArea } = useArea()
 
  
const uniqueNames = Array.from(new Set(responseAreas.map((area) => area.name)));
const totalUniqueNames = uniqueNames.length;

  return (
    <>
      <VStack w='full' spacing={'12'}>
        <>
        <AreaStats
            loading={loadingAreas}
            numberOfAreas={totalUniqueNames}
            handleOpenCreateArea={handleOpenCreateArea}
          /> 
          <AreaTable areas={responseAreas} loading={loadingAreas}/>
        </>
      </VStack>
    </>
  )
}
