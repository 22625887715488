import React, { PropsWithChildren } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

export const DetailsWrapper = ({
  children,
  ...rest
}: PropsWithChildren<FlexProps>) => {
  return (
    <Flex w='full' h='fit-content' bg='white' px='8' py='9' {...rest}>
      {children}
    </Flex>
  )
}
