import { emptyFollowup, Followup } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useFollowup } from 'services/followupService'

import addMonths from 'date-fns/addMonths'

export const useFollowupContainer = () => {
  const location = useLocation()
  const params = useParams()
  const { id } = params as any
  const history = useHistory()
  const {
    user: { organizationId },
  } = useUser()

  const copyFollowupState = location.state as Followup

  const defaultFollowup = (location.state as any)?.id
    ? {
        ...copyFollowupState,
        name: '',
        startDate:
          (copyFollowupState.startDate as unknown as Date) > new Date()
            ? copyFollowupState.startDate
            : new Date(),
      }
    : {
        ...emptyFollowup,
        startDate: new Date() as unknown as string,
        endDate: addMonths(new Date(), 1) as unknown as string,
      }

  const { data: followup = defaultFollowup, isInitialLoading } = useFollowup({
    followupId: id,
    organizationId,
  })

  if (id && !followup?.id && !isInitialLoading) {
    history.push('/overview-followups')
  }

  return {
    followup: {
      ...followup,
      startDate: new Date(followup.startDate) as unknown as string,
      endDate: new Date(followup.endDate) as unknown as string,
    },

    loading: isInitialLoading,
  }
}
