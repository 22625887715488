import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import extendedTheme from './theme'
import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from './providers/UserProvider'
import GA4React from 'ga-4-react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import enTranslation from './locales/en.json'
import svTranslation from './locales/sv.json'
import deTranslation from './locales/de.json'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


const browserLocaleLanguage = navigator.language.split(/[-_]/)[0]
let siteLanguage;
if(browserLocaleLanguage === 'sv'){
 siteLanguage = 'se'
}
else if(browserLocaleLanguage === 'de'){
  siteLanguage = 'de'
}
else{
siteLanguage = 'en'
}

i18n.init({
  interpolation: { escapeValue: false },
  lng: sessionStorage.getItem('language-selected') ? sessionStorage.getItem('language-selected'): siteLanguage, // Default language
  resources: {
    en: {
      translation: enTranslation,
    },
    se: {
      translation: svTranslation,
    },
    de: {
      translation: deTranslation
    }
  },
})
const queryClient = new QueryClient()
const googleAnalytics4 = new GA4React(
  process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID!,
)

;(async () => {
  try {
    await googleAnalytics4.initialize()
  } catch (error) {
    console.log('Google analytics initialization error:', error)
  }
  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ChakraProvider theme={extendedTheme}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <UserProvider>
                  <App />
                  {/* <ReactQueryDevtools /> */}
                </UserProvider>
              </QueryClientProvider>
            </BrowserRouter>
          </ChakraProvider>
        </Provider>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
