import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { UserRole } from 'data-models'
import { Flex, Grid, Input, VStack, GridItem } from '@chakra-ui/react'
import { FormWrapper } from 'common/Form'
import { FormSelect } from 'common/FormSelect'
import { useUserDetails } from './hooks'
import CustomSelect from 'common/CustomSelect'
import { useTranslation } from 'react-i18next'

export const CreateUserInvite = props => {
  const {
    isAdmin,
    formMethods,
    roleOptions,
    preschoolOptions,
    organizationOptions,
    selectAllPreschools,
  } = useUserDetails()
  const { control, register } = formMethods
  const [admin, setAdmin] = useState(true)
  const { t } = useTranslation()
  return (
    <VStack w='full'>
      <Flex w='full' h='full'>
        <Grid w='full' gridTemplateColumns={'repeat(2, 1fr)'} gridGap={6}>
          <GridItem>
            <FormWrapper name='role' label={t('Role_Text')} isRequired>
              <Controller
                control={control}
                name='role'
                render={({ field: { onChange, value, ...rest } }) => (
                  <FormSelect
                    options={roleOptions}
                    placeholder={t('Role_Text')}
                    value={roleOptions.find(option => option.value === value)}
                    onChange={val => {
                      if (val?.value === UserRole.ADMIN) {
                        setAdmin(false)
                        props.selectAllPreschools()
                      } else {
                        setAdmin(true)
                      }
                      onChange(val?.value)
                    }}
                    isDisabled={!isAdmin}
                    {...rest}
                  />
                )}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper
              name='preschoolIds'
              label={t('ResultFilter_Preschool')}
              isRequired
            >
              <Controller
                control={control}
                name='preschoolIds'
                render={({ field: { onChange, value, ...rest } }) => (
                  <CustomSelect
                    options={admin ? props.preschoolOptions : null}
                    placeholder={t('ResultFilter_Preschool')}
                    isMulti={true}
                    onChange={e => onChange(e.map(e => e.value))}
                    value={props.preschoolOptions.filter(preschoolOption =>
                      value?.includes(preschoolOption?.value),
                    )}
                    searchable={true}
                    minWidth='250px'
                    isDisabled={!admin}
                    {...rest}
                  />
                )}
              />
            </FormWrapper>
          </GridItem>
          <GridItem>
            <FormWrapper name='email' label={t('Email_Address')} isRequired>
              <Input
                type='text'
                placeholder={t('Email_Address')}
                _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                focusBorderColor=''
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w='full'
                borderRadius={'lg'}
                isDisabled={!isAdmin}
                {...register('email')}
              />
            </FormWrapper>
          </GridItem>
        </Grid>
      </Flex>
    </VStack>
  )
}
