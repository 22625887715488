import React from 'react'
import { Tag, TagLabel } from '@chakra-ui/tag'

export default function TaskTag(props: { text: string }) {
  return (
    <Tag borderRadius='full' bg='rgba(255,255,255,0.1)' p='2'>
      <TagLabel fontSize='xs' color='white'>
        {props.text}
      </TagLabel>
    </Tag>
  )
}
