import React from 'react'
import { HStack, VStack, Text } from '@chakra-ui/react'
import { ImageSelectionAnswer, TaskType } from 'data-models/task-models'
import CheckboxCircle from './CheckboxCircle'

export default function CheckboxImageAnswerGroup(props: {
  options: ImageSelectionAnswer[]
  onChange: (state: ImageSelectionAnswer[]) => void
}) {
  function onSelectedChange(x: ImageSelectionAnswer['id']) {
    const newOptionsState = props.options.map(o => ({
      ...o,
      checked: o.id === x,
    }))
    props.onChange(newOptionsState)
  }

  return (
    <VStack spacing='5' width='90%'>
      {props.options.map(
        o =>
          o.type === TaskType.Checkbox && (
            <HStack
              key={o.id}
              cursor='pointer'
              border='0px'
              borderColor='white'
              borderRadius='xl'
              w='full'
              p='2.5'
              bgColor={'transparent'}
              onClick={() => onSelectedChange(o.id)}
              boxShadow={'none'}
            >
              <CheckboxCircle checked={o.checked} />
              <Text>{o.text}</Text>
            </HStack>
          ),
      )}
    </VStack>
  )
}
